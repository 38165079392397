import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_TOTAL_COUNT = 0;

/** Pipe for transform total items count to title for list page. */
@Pipe({
  name: 'protctcListTitle',
})
export class ListTitlePipe implements PipeTransform {

  /**
   * @param totalCount Total count of items.
   * @param titleTemplate String before count.
   * @returns
   */
  public transform(totalCount: number | null, titleTemplate: string): string {
    if (totalCount) {
      return `${titleTemplate} (${totalCount})`;
    }
    return `${titleTemplate} (${DEFAULT_TOTAL_COUNT})`;
  }
}

import { Component } from '@angular/core';
import { IconsService } from '@protctc/common/core/services/icon.service';

/** Base app component. */
@Component({
  selector: 'protctw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {

  public constructor(
    iconsService: IconsService,
  ) {
    iconsService.registerIcons();
  }
}

import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { InvoiceCustomer, InvoiceCustomerCreationData } from '../../models/customer';

import { InvoiceCustomerCreationDto, InvoiceCustomerDto } from './dto/customer-dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';

/** Mapper for customer entities. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceCustomerMapper
implements IMapperFromDto<InvoiceCustomerDto, InvoiceCustomer>,
IMapperToDto<InvoiceCustomerCreationDto, InvoiceCustomerCreationData>,
ValidationErrorMapper<InvoiceCustomerCreationDto, InvoiceCustomerCreationData> {

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<InvoiceCustomerCreationDto> | null | undefined,
  ): EntityValidationErrors<InvoiceCustomerCreationData> {
    return {
      firstName: extractErrorMessage(errorDto?.first_name),
      lastName: extractErrorMessage(errorDto?.last_name),
      email: extractErrorMessage(errorDto?.email),
      phone: extractErrorMessage(errorDto?.phone),
      address: {
        line1Address: extractErrorMessage(errorDto?.address),
        city: extractErrorMessage(errorDto?.address_city),
        state: extractErrorMessage(errorDto?.address_state),
        zip: extractErrorMessage(errorDto?.address_zip),
      },
      companyName: extractErrorMessage(errorDto?.company_name),
      isActive: extractErrorMessage(errorDto?.is_active),
      firstAdditionalField: extractErrorMessage(errorDto?.additional_field_1),
      secondAdditionalField: extractErrorMessage(errorDto?.additional_field_2),
      thirdAdditionalField: extractErrorMessage(errorDto?.additional_field_3),
    };
  }

  /** @inheritdoc */
  public toDto(data: InvoiceCustomerCreationData): InvoiceCustomerCreationDto {
    return {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      address: data.address.line1Address,
      address_city: data.address.city,
      address_state: data.address.state,
      address_zip: data.address.zip,
      additional_field_1: data.firstAdditionalField,
      additional_field_2: data.secondAdditionalField,
      additional_field_3: data.thirdAdditionalField,
      company_name: data.companyName,
      is_active: data.isActive,
    };
  }

  /** @inheritdoc */
  public fromDto(data: InvoiceCustomerDto): InvoiceCustomer {
    return {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      phone: data.phone,
      address: {
        line1Address: data.address,
        line2Address: '',
        city: data.address_city,
        state: data.address_state,
        zip: data.address_zip,
      },
      firstAdditionalField: data.additional_field_1 ?? '',
      secondAdditionalField: data.additional_field_2 ?? '',
      thirdAdditionalField: data.additional_field_3 ?? '',
      companyName: data.company_name,
      isActive: data.is_active ?? false,
      hasInvoices: data.has_invoices ?? false,
    };
  }
}

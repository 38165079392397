<ng-container [formGroup]="formGroup">
  <div class="form-field-container">
    <mat-form-field class="form-field">
      <mat-label>{{ labels.line1Address }}</mat-label>
      <input matInput type="text" formControlName="line1Address" />
    </mat-form-field>
    <protctc-form-control-validation-message
      controlName="line1Address"
    ></protctc-form-control-validation-message>
  </div>

  <div class="form-field-container">
    <mat-form-field class="form-field">
      <mat-label>{{ labels.line2Address }}</mat-label>
      <input matInput type="text" formControlName="line2Address" />
    </mat-form-field>
    <protctc-form-control-validation-message
      controlName="line2Address"
    ></protctc-form-control-validation-message>
  </div>

  <div class="row">
    <div class="form-field-container">
      <mat-form-field class="form-field">
        <mat-label>{{ labels.city }}</mat-label>
        <input protctcCapitalize matInput type="text" formControlName="city" />
      </mat-form-field>
      <protctc-form-control-validation-message
        controlName="city"
      ></protctc-form-control-validation-message>
    </div>

    <div class="form-field-container">
      <mat-form-field class="form-field">
        <mat-label>{{ labels.state }}</mat-label>
        <mat-select formControlName="state" [compareWith]="state.compare">
          <mat-option
            *ngFor="let st of states$ | async; trackBy: state.track"
            [value]="st"
          >
            {{ st.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <protctc-form-control-validation-message
        controlName="state"
      ></protctc-form-control-validation-message>
    </div>

    <div class="form-field-container">
      <mat-form-field class="form-field">
        <mat-label>{{ labels.zip }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="zip"
          [imask]="masks.addressZipMask"
        />
      </mat-form-field>
      <protctc-form-control-validation-message
        controlName="zip"
      ></protctc-form-control-validation-message>
    </div>
  </div>
  <div class="address-text">PO Boxes are not acceptable for onboarding</div>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

/**
 * Shortcut USD currency pipe.Alias for currency: "USD":"symbol":"1.2-2".
 */
@Pipe({
  name: 'USDCurrency',
})
export class USDCurrencyPipe implements PipeTransform {

  public constructor(
    private readonly currencyPipe: CurrencyPipe,
  ) {
  }

  /**
   * Transforms a number to a currency string.
   * @param value Number.
   */
  public transform(value: number | string): string | null {
    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2');
  }
}

/** Fields available for sorting the line items. */
export enum LineItemsSortField {

  /** Id. */
  Id = 'id',

  /** Name. */
  Name = 'name',

  /** Description. */
  Description = 'description',

  /** Price. */
  Price = 'price',

  /** Description. */
  Type = 'type',

  /** Modified. */
  Modified = 'modified',

  /** Default sort field. */
  Default = Modified,
}

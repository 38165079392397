import { CustomKeyValue } from '../models/custom-key-value';
import { enumToArray } from '../utils/enum-to-array';

/** Invoice delivery. */
export enum InvoiceDelivery {

  /** Send invoice via customer's SMS. */
  SMS = 'sms',

  /** Send invoice via customer's email. */
  Email = 'email',

  /** Send invoice via customer' email and SMS. */
  Both = 'both',
}

/**
 * Namespace for invoice delivery.
 * Contains util methods and props for easier enum use.
 */
export namespace InvoiceDelivery {
  const READABLE_MAP: Record<InvoiceDelivery, string> = {
    [InvoiceDelivery.SMS]: `Send invoice via customer's SMS`,
    [InvoiceDelivery.Email]: `Send invoice via customer's email`,
    [InvoiceDelivery.Both]: `Send invoice via customer's email and SMS`,
  };

  /** Get list for all invoice delivery methods. */
  export function toArray(): InvoiceDelivery[] {
    return enumToArray(InvoiceDelivery);
  }

  /**
   * Return human-readable invoice delivery representation.
   * @param option Invoice delivery option.
   */
  export function toReadable(option: InvoiceDelivery): string {
    return READABLE_MAP[option];
  }

  /** Get list for key value invoice delivery array. */
  export function toKeyValueArray(): CustomKeyValue<string, InvoiceDelivery>[] {
    return InvoiceDelivery.toArray().map(value => ({ value, key: InvoiceDelivery.toReadable(value) }));
  }
}

import { Injectable } from '@angular/core';

import { InvoiceStatus } from '../../enums/invoice-status';
import { PaymentMethod } from '../../enums/payment-method';
import { EntityValidationErrors } from '../../models/app-error';
import { InvoiceCreationData } from '../../models/invoice';

import { InvoiceDelivery } from '../../enums/invoice-delivery';

import { DateMapper } from './date.mapper';
import { DeliveryPeriodMapper } from './delivery-period.mapper';
import { InvoiceCreationDataDto } from './dto/invoice-dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';
import { InvoiceCreationCustomerMapper } from './invoice-creation-customer.mapper';
import { InvoiceDeliveryMapper } from './invoice-delivery.mapper';
import { InvoiceCustomerCreationDto } from './dto/customer-dto';
import { InvoiceCustomerMapper } from './invoice-customer.mapper';
import { InvoiceLineItemMapper } from './invoice-line-item.mapper';
import { IMapper } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';
import { PaymentCardMapper } from './payment/payment-card.mapper';
import { PaymentCardDto } from './dto/payment/payment-card.dto';
import { InvoiceDeliveryDto } from './dto/invoice-delivery-dto';

/** Mapper for invoice entities. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceCreationDataMapper implements
IMapper<InvoiceCreationDataDto, InvoiceCreationData>,
ValidationErrorMapper<InvoiceCreationDataDto, InvoiceCreationData> {

  public constructor(
    private readonly invoiceCreationCustomerMapper: InvoiceCreationCustomerMapper,
    private readonly invoiceCustomerMapper: InvoiceCustomerMapper,
    private readonly invoiceLineItemMapper: InvoiceLineItemMapper,
    private readonly dateMapper: DateMapper,
    private readonly invoiceDeliveryMapper: InvoiceDeliveryMapper,
    private readonly deliveryPeriodMapper: DeliveryPeriodMapper,
    private readonly paymentCardMapper: PaymentCardMapper,
  ) { }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<InvoiceCreationDataDto> | null | undefined,
  ): EntityValidationErrors<InvoiceCreationData> {

    return {
      invoiceNumber: extractErrorMessage(errorDto?.mask_number),
      fieldUser: extractErrorMessage(errorDto?.field_user),
      customer: this.invoiceCustomerMapper.validationErrorFromDto(
        errorDto?.customer_info as ValidationErrorDto<InvoiceCustomerCreationDto>,
      ),
      customerSignature: extractErrorMessage(errorDto?.customer_signature),
      lineItems: extractErrorMessage(errorDto?.line_items),
      paymentMethod: extractErrorMessage(errorDto?.payment_method),
      dueDate: extractErrorMessage(errorDto?.due_date),
      disclaimer: extractErrorMessage(errorDto?.disclaimer),
      note: extractErrorMessage(errorDto?.note),
      status: extractErrorMessage(errorDto?.status),
    };
  }

  /** @inheritdoc */
  public fromDto(data: InvoiceCreationDataDto): InvoiceCreationData {
    return {
      id: data.id,
      fieldUser: data.field_user,
      customer: this.invoiceCreationCustomerMapper.fromDto(data.customer_info),
      lineItems: data.line_items.map(this.invoiceLineItemMapper.fromDto),
      paymentMethod: data.payment_method as PaymentMethod,
      disclaimer: data.disclaimer ?? '',
      customerSignature: data?.customer_signature,
      note: data.note ?? '',
      dueDate: data.due_date ? this.dateMapper.fromDto(data.due_date) : undefined,
      status: data.status as InvoiceStatus,
      paymentToken: data.payment_token ? data.payment_token : undefined,
      deliveryMethod: this.mapInvoiceDeliveryFromDto(data.invoice_delivery),
      paymentDetails: data.payment_details ? this.paymentCardMapper.fromDto(data.payment_details as PaymentCardDto) : undefined,
      deliveryPeriod: data.delivery_period ? this.deliveryPeriodMapper.fromDto(data.delivery_period) : null,
      customerId: data.customer ?? null,
      firstAdditionalFieldValue: data.first_additional_field_value ?? '',
      secondAdditionalFieldValue: data.second_additional_field_value ?? '',
      thirdAdditionalFieldValue: data.third_additional_field_value ?? '',
      invoiceNumber: data.mask_number,
      invoicePrefix: data.mask_prefix,
    };
  }

  /** @inheritdoc */
  public toDto(data: InvoiceCreationData): InvoiceCreationDataDto {
    return {
      id: data.id,
      field_user: data.fieldUser,
      customer_signature: data.customerSignature ? data.customerSignature : undefined,
      customer_info: this.invoiceCreationCustomerMapper.toDto(data.customer),
      customer: data.customerId ?? null,
      line_items: data.lineItems.map(this.invoiceLineItemMapper.toDto),
      payment_method: data.paymentMethod,
      due_date: data.dueDate ? this.dateMapper.toDateOnlyStringDto(data.dueDate) : undefined,
      disclaimer: data.disclaimer,
      note: data.note,
      status: data.status,
      payment_token: data.paymentToken ? data.paymentToken : undefined,
      invoice_delivery: this.mapInvoiceDeliveryToDto(data.deliveryMethod, data.paymentMethod),
      payment_details: data.paymentDetails ? this.paymentCardMapper.toDto(data.paymentDetails) : undefined,
      delivery_period: data.deliveryPeriod ? this.deliveryPeriodMapper.toDto(data.deliveryPeriod) : undefined,
      first_additional_field_value: data.firstAdditionalFieldValue,
      second_additional_field_value: data.secondAdditionalFieldValue,
      third_additional_field_value: data.thirdAdditionalFieldValue,
      mask_prefix: data.invoicePrefix,
      mask_number: data.invoiceNumber,
    };
  }

  private mapInvoiceDeliveryToDto(deliveryMethod: InvoiceDelivery | null, paymentMethod: PaymentMethod): InvoiceDeliveryDto | undefined {
    if (paymentMethod === PaymentMethod.CreditCard || paymentMethod === PaymentMethod.ACH) {
      return InvoiceDeliveryDto.Paid;
    }
    return deliveryMethod ? this.invoiceDeliveryMapper.toDto(deliveryMethod) : undefined;
  }

  private mapInvoiceDeliveryFromDto(deliveryMethod: InvoiceDeliveryDto | undefined): InvoiceDelivery | null {
    if (!deliveryMethod || deliveryMethod === InvoiceDeliveryDto.Paid) {
      return null;
    }
    return this.invoiceDeliveryMapper.fromDto(deliveryMethod);
  }

}

import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupTyped } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaymentHistorySortField } from '@protctc/common/core/enums/payment-history-sort-field';
import { SortDirection } from '@protctc/common/core/enums/sort-direction';
import { DateRange } from '@protctc/common/core/models/date-range/date-range';
import { DateRangeForm } from '@protctc/common/core/models/date-range/date-range-form';
import { PaginationData } from '@protctc/common/core/models/pagination-data';
import { PayrixPagination } from '@protctc/common/core/models/pagination/payrix-pagination';
import { PaymentHistoryPaginationOptions } from '@protctc/common/core/models/payment-history-pagination-options';
import { PaymentTransaction } from '@protctc/common/core/models/payment/payment-transaction';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';
import { listenControlChanges } from '@protctc/common/core/rxjs/listen-control-changes';
import { toggleExecutionState } from '@protctc/common/core/rxjs/toggle-execution-state';
import { BillingPaymentService } from '@protctc/common/core/services/billing-payment.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { enumToArray } from '@protctc/common/core/utils/enum-to-array';
import { paginatePayrix } from '@protctc/common/core/utils/paginate';
import { routePaths } from '@protctc/common/core/utils/route-paths';
import { DEFAULT_PAGINATION_DATA } from '@protctc/common/shared/components/table/table.component';
import { BehaviorSubject, filter, Observable, of, shareReplay, skip, switchMap, tap } from 'rxjs';

import { TablePaginatorIntlWithoutTotalCount } from '../../../billing-payment/components/deposits-table/deposits-table-paginator-intl';

const COUNT_FOR_SKIP_EMITS_BEFORE_TABLE_LOADING = 2;

/** Payments table. */
@DestroyableComponent()
@Component({
  selector: 'protctw-payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatPaginatorIntl, useClass: TablePaginatorIntlWithoutTotalCount }],
})
export class PaymentsTableComponent implements OnInit {

  /** Date range control. */
  @Input()
  public dateRangeControl: FormGroupTyped<DateRange> | FormGroup<DateRangeForm> | null = null;

  /** Company id. */
  @Input()
  public set companyId(id: number) {
    this.companyId$.next(id);
  }

  /** Payment load emitter. */
  @Output()
  public readonly paymentLoad = new EventEmitter<boolean>();

  /** Default parameters of pagination. */
  protected readonly defaultPaginationData: PaginationData = DEFAULT_PAGINATION_DATA;

  /** Payment history sort field. */
  protected readonly paymentHistorySortField = PaymentHistorySortField;

  /** Route paths. */
  public readonly routePaths = routePaths;

  /** Payments page. */
  public readonly paymentsPage$: Observable<PayrixPagination<PaymentTransaction>>;

  /** Is loading stream. */
  public readonly isLoading$ = new BehaviorSubject(false);

  /** Current date range options stream. */
  protected readonly currentDateRangeOptions$ = new BehaviorSubject<DateRange>({
    start: null,
    end: null,
  });

  /** Current pagination options stream. */
  public readonly currentPaginationData$ = new BehaviorSubject<PaginationData>(this.defaultPaginationData);

  /** Current sort options stream. */
  public readonly currentSortOptions$ = new BehaviorSubject<SortOptions<PaymentHistorySortField>>({
    direction: SortDirection.Asc,
    column: PaymentHistorySortField.Default,
  });

  /** Company id. */
  protected readonly companyId$ = new BehaviorSubject<number | null>(null);

  /** List of columns to be displayed in the table. */
  public readonly displayedColumns = enumToArray(PaymentHistorySortField);

  public constructor(
    private readonly billingPaymentService: BillingPaymentService,

  ) {

    this.paymentsPage$ = this.companyId$.pipe(
      filterNull(),
      switchMap(companyId => paginatePayrix<PaymentTransaction, PaymentHistoryPaginationOptions>({
        paginationData: this.currentPaginationData$,
        sortOptions: this.currentSortOptions$,
        dateRange: this.currentDateRangeOptions$,
        id: of(companyId),
      }, options => this.billingPaymentService.getPayments(options).pipe(
        toggleExecutionState(this.isLoading$),
      ))
        .pipe(
          shareReplay({ refCount: true, bufferSize: 1 }),
        )),
    );
  }

  /** @inheritdoc */
  public ngOnInit(): void {

    if (this.dateRangeControl) {
      listenControlChanges<DateRange>(this.dateRangeControl, { debounceTime: 1000 }).pipe(
        skip(1),
        takeUntilDestroy(this),
        filter(() => this.dateRangeControl?.valid === true),
        tap(value => this.currentDateRangeOptions$.next(value)),
      )
        .subscribe();
    }

    this.subscribeToLoading();
  }

  /** Subscribe to loading. */
  private subscribeToLoading(): void {
    this.isLoading$.pipe(
      skip(COUNT_FOR_SKIP_EMITS_BEFORE_TABLE_LOADING),
      tap(value => this.paymentLoad.emit(value)),
      takeUntilDestroy(this),
    )
      .subscribe();
  }
}

/** Data needed for dashboard char data construction. */
export type DashboardChartConstructorData = DashboardChartData;

/** Dashboard chart data. */
export class DashboardChartData {

  /** Label. */
  public readonly label: string;

  /** All Transaction. */
  public readonly allTransaction: number;

  /** Credit Card Transaction. */
  public readonly creditCardTransaction: number;

  /** Credit card fee saved. */
  public readonly creditCardFeeSaved: number;

  public constructor(data: DashboardChartConstructorData) {
    this.label = data.label;
    this.allTransaction = data.allTransaction;
    this.creditCardTransaction = data.creditCardTransaction;
    this.creditCardFeeSaved = data.creditCardFeeSaved;
  }
}

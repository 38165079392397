/** Fields available for sorting merchant partner items. */
export enum MerchantPartnerSortField {

  /** Id. */
  Id = 'id',

  /** Name. */
  Name = 'name',

  /** Employer Identification Number. */
  EIN = 'ein',

  /** Email. */
  Email = 'email',

  /** Invoice count. */
  InvoiceCount = 'invoiceCount',

  /** Last activty. */
  LastActivity = 'lastActivity',

  /** Deactivated at. */
  DeactivatedAt = 'deactivatedAt',

  /** Default sort field. */
  Default = Id,
}

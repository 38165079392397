import { Injectable } from '@angular/core';

import { CompanyFieldUser } from '../../models/company-field-user';

import { DateMapper } from './date.mapper';
import { CompanyFieldUserDto } from './dto/company-field-user-dto';
import { IMapperFromDto } from './mappers';

/** Company field user mapper. */
@Injectable({
  providedIn: 'root',
})
export class CompanyFieldUserMapper implements IMapperFromDto<CompanyFieldUserDto, CompanyFieldUser> {

  public constructor(
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: CompanyFieldUserDto): CompanyFieldUser {
    return {
      id: data.id,
      name: `${data.first_name} ${data.last_name}` ?? '',
      invoiceCount: data.invoice_count ?? 0,
      totalAmount: data.total_amount ? Number(data.total_amount) : 0,
      lastLoginDate: data.last_login ? this.dateMapper.fromDto(data.last_login) : null,
      hasSentInvoice: data.has_sent_invoice,
    };
  }
}

<mat-form-field class="form-field date-picker">
  <mat-label>{{ label ?? "Enter a date range" }}</mat-label>
  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="MM/DD/YYYY" />
    <input matEndDate formControlName="end" placeholder="MM/DD/YYYY" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker>
    <mat-date-range-picker-actions>
      <button type="button" mat-button matDateRangePickerCancel>Cancel</button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        matDateRangePickerApply
      >
        Apply
      </button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>

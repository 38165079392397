import { LineItemType } from '../enums/line-item-type';

import { LineItem } from './line-item';
import { LineItemGroup } from './line-item-group';

import { User } from './user';

/** Data needed for the construction of union line item model. */
export type UnionLineItemConstructorData = UnionLineItem;

/**
 * Union line item data
 * Depending to the type of line item.
 */
export type UnionLineItemData = {

  /** Line item type. */
  readonly type: LineItemType.LineItem;

  /** Single line item data. */
  readonly singleLineItem: LineItem;
} | {

  /** Line item type. */
  readonly type: LineItemType.GroupLineItem;

  /** Line item group data. */
  readonly lineItemGroup: LineItemGroup;
};

/** Union Line item model. */
export class UnionLineItem {

  /** Id. */
  public readonly id: number;

  /** Line item name. */
  public readonly name: string;

  /** Price. */
  public readonly price: number;

  /** Description. */
  public readonly description: string;

  /** Type. */
  public readonly type: LineItemType;

  /** Modified date. */
  public readonly modified: Date;

  /** Updated by user. */
  public readonly updatedBy: User | null;

  /** Union line item data. */
  public readonly unionLineItemData: UnionLineItemData;

  public constructor(data: UnionLineItemConstructorData) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.price = data.price;
    this.type = data.type;
    this.modified = data.modified;
    this.updatedBy = data.updatedBy;
    this.unionLineItemData = data.unionLineItemData;
  }
}

/**
 * Namespace for union line items.
 * Contains util methods and props for easier enum use.
 */
export namespace UnionLineItem {

  /**
   * Makes line item human-readable.
   * @param lineItem UnionLineItem.
   */
  export function toReadable(lineItem: UnionLineItem): string {
    if (lineItem.unionLineItemData.type === LineItemType.GroupLineItem) {
      return LineItemGroup.toReadable(lineItem.unionLineItemData.lineItemGroup);
    }
    return LineItem.toReadable(lineItem.unionLineItemData.singleLineItem);
  }

  /**
   * Checks whether two line items are equal.
   * @param lineItem1 Line item.
   * @param lineItem2 Another line item.
   */
  export function compare(lineItem1: UnionLineItem, lineItem2: UnionLineItem): boolean {
    return lineItem1.id === lineItem2.id;
  }

  /**
   * Track by function.
   * @param _ Index.
   * @param item Union line item.
   */
  export function trackBy(_: number, item: UnionLineItem): number {
    return item.id;
  }
}

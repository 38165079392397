import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { AdminInvoiceShortSortField } from '@protctc/common/core/enums/admin-invoice-short-sort-field';
import { InvoiceStatus } from '@protctc/common/core/enums/invoice-status';
import { PaymentMethod } from '@protctc/common/core/enums/payment-method';
import { SortDirection } from '@protctc/common/core/enums/sort-direction';
import { AdminInvoiceExtra } from '@protctc/common/core/models/admin-invoice-extra';
import { AdminInvoiceShort } from '@protctc/common/core/models/admin-invoice-short';
import { ExtraPagination } from '@protctc/common/core/models/extra-pagination';
import { PaginationData } from '@protctc/common/core/models/pagination-data';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { masks } from '@protctc/common/core/utils/masks';
import { routePaths } from '@protctc/common/core/utils/route-paths';

const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30];

/** Type for admin invoice displayed columns. */
export type AdminInvoiceDisplayedColumnsType = 'invoiceNumber'
  | 'transactionId'
  | 'createdDate'
  | 'businessId'
  | 'businessEIN'
  | 'customerName'
  | 'location'
  | 'paymentMethod'
  | 'amount'
  | 'fee'
  | 'sharedRevenue'
  | 'status';

/** Admin invoices table. */
@Component({
  selector: 'protctw-admin-invoices-table',
  templateUrl: './admin-invoices-table.component.html',
  styleUrls: ['./admin-invoices-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminInvoicesTableComponent {

  /** Sort options. */
  @Input()
  public sortOptions: SortOptions<AdminInvoiceShortSortField> | null = null;

  /** Invoices page. */
  @Input()
  public invoicesPage: ExtraPagination<AdminInvoiceShort, AdminInvoiceExtra> | null = null;

  /** Displayed columns. */
  @Input()
  public displayedColumns: AdminInvoiceDisplayedColumnsType[] = [];

  /** Is admin invoices table for dashboard. */
  @Input()
  public isAdminInvoicesForDashboard = false;

  /** Page size options. */
  @Input()
  public pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;

  /** Empty preview message. */
  @Input()
  public emptyPreviewMessage = '';

  /** Change page emitter. */
  @Output()
  public readonly pageChanged = new EventEmitter<PaginationData>();

  /** Change sort emitter. */
  @Output()
  public readonly sortChanged = new EventEmitter<SortOptions<AdminInvoiceShortSortField>>();

  /** Route paths. */
  public readonly routePaths = routePaths;

  /** Employer Identification mask. */
  public readonly einMask = masks.taxNumberMask;

  /** To readable payment method. */
  public readonly toReadablePaymentMethod = PaymentMethod.toReadable;

  /** To readable invoice status. */
  public readonly toReadableInvoiceStatus = InvoiceStatus.toReadable;

  /** Invoice status. */
  public readonly invoiceStatus = InvoiceStatus;

  /** Get active sort direction. */
  public get activeSortDirection(): SortDirection {
    return this.sortOptions?.direction as SortDirection ?? SortDirection.Asc;
  }

  /** Get active sort column. */
  public get activeSortColumn(): string {
    return this.sortOptions?.column ?? AdminInvoiceShortSortField.Default;
  }

  /** Function to track invoices in array.
   * @param _ Index.
   * @param invoice Invoice to track.
   */
  public trackInvoice(_: number, invoice: AdminInvoiceShort): number {
    return invoice.id;
  }

  /**
   * On change page.
   * @param event Page event.
   */
  public onPageChange(event: PageEvent): void {
    this.pageChanged.emit({
      page: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  /**
   * Change filter's sort configuration.
   * @param sort Sort info.
   */
  public onSortChange(sort: Sort): void {
    const sortDirection = sort.direction || SortDirection.Asc;
    this.sortChanged.emit({
      direction: sortDirection,
      column: sort.active as AdminInvoiceShortSortField,
    });
  }
}

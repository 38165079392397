import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';

/**
 * Dialog header component.
 * Supposed to be used in a dialog component.
 */
@Component({
  selector: 'protctc-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {

  /** Dialog title. */
  @Input()
  public title = '';

  /** Block's role. */
  @HostBinding('attr.role')
  public readonly role = 'presentation';
}

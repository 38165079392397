import { Injectable } from '@angular/core';

import { DeliveryPeriod } from '../../models/delivery-period';
import { reverseRecord } from '../../utils/reverse-record';

import { DeliveryPeriodDto } from './dto/delivery-period.dto';
import { IMapper } from './mappers';

const TO_DELIVERY_PERIOD_DTO: Readonly<Record<DeliveryPeriod, DeliveryPeriodDto>> = {
  [DeliveryPeriod.Daily]: DeliveryPeriodDto.Daily,
  [DeliveryPeriod.Weekly]: DeliveryPeriodDto.Weekly,
  [DeliveryPeriod.Monthly]: DeliveryPeriodDto.Monthly,
};

const FROM_DELIVERY_PERIOD_DTO = reverseRecord(TO_DELIVERY_PERIOD_DTO);

/** Delivery period mapper. */
@Injectable({
  providedIn: 'root',
})
export class DeliveryPeriodMapper implements IMapper<DeliveryPeriodDto, DeliveryPeriod> {

  /** @inheritdoc */
  public fromDto(data: DeliveryPeriodDto): DeliveryPeriod {
    return FROM_DELIVERY_PERIOD_DTO[data];
  }

  /** @inheritdoc */
  public toDto(data: DeliveryPeriod): DeliveryPeriodDto {
    return TO_DELIVERY_PERIOD_DTO[data];
  }
}

<mat-sidenav-container class="sidebar" fullscreen>
  <mat-sidenav
    #sidenav
    class="sidebar__container"
    [opened]="(isCatchBreakpoint$ | async) === false"
    [mode]="(isCatchBreakpoint$ | async) ? 'over' : 'side'"
  >
    <div
      *ngIf="currentUser$ | async as user"
      class="sidebar__header-container"
      [class.sidebar__header-container_admin]="
        user.role === userRole.SuperAdmin
      "
    >
      <div *userHasRole="userRole.BusinessOwner" class="sidebar__company-logo">
        <div
          class="logo-loader"
          *ngLet="companyLogo$ | async as logo"
          [protctcLoading]="logo === null"
        >
          <protctc-image-uploader-preview
            class="company-logo"
            [src]="logo?.url ? logo.url : 'assets/company_avatar_default.svg'"
            alt="Company logo"
          ></protctc-image-uploader-preview>
        </div>
      </div>

      <div *userHasRole="userRole.SuperAdmin" class="sidebar__logo">
        <a [routerLink]="routePaths.home">
          <protctw-main-logo class="sidenav-main__logo"></protctw-main-logo>
        </a>
      </div>

      <div class="sidebar__user-info user-info">
        <div class="user-info__container">
          <p class="user-info__welcome">Welcome,</p>
          <p class="user-info__name">
            {{ user.firstName }} {{ user.lastName }}
          </p>
          <p class="user-info__role" *userHasRole="userRole.BusinessOwner">
            {{ toReadableUserRole(user.role) }}
          </p>
        </div>
        <button
          [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger"
          (menuOpened)="(menuTrigger.menuOpen)"
          (menuClosed)="(menuTrigger.menuOpen)"
          class="logout-button"
          mat-icon-button
        >
          <mat-icon
            *ngIf="menuTrigger.menuOpen === false"
            class="sidebar__icon sidebar__icon_drop-down"
            svgIcon="arrow_drop_down"
          ></mat-icon>
          <mat-icon
            *ngIf="menuTrigger.menuOpen"
            class="sidebar__icon"
            svgIcon="arrow_drop_up"
          ></mat-icon>
        </button>
      </div>

      <mat-menu
        #menu="matMenu"
        [overlapTrigger]="false"
        class="sidebar__mat-menu mat-menu"
        xPosition="before"
        yPosition="below"
      >
        <a
          class="mat-menu__link"
          mat-menu-item
          [routerLink]="
            user.role === userRole.BusinessOwner
              ? routePaths.accountSettings
              : routePaths.adminAccountSettings
          "
        >
          <mat-icon svgIcon="account_settings"></mat-icon>
          <span>Account settings</span>
        </a>
        <button mat-menu-item type="button" (click)="logout()">
          <mat-icon svgIcon="sign_out"></mat-icon>
          <span>Sign out</span>
        </button>
      </mat-menu>
    </div>

    <ul class="sidebar__navigation">
      <li
        class="sidebar__navigation-item"
        *ngFor="let link of links$ | async; trackBy: trackByNavigationLink"
      >
        <a
          class="sidebar__link"
          [class.sidebar__link-with-children]="link.children"
          routerLinkActive="active"
          [routerLink]="link.route"
        >
          <span class="sidebar__link-wrapper">
            <mat-icon
              *ngIf="link.icon"
              class="sidebar__link-icon"
              [svgIcon]="link.icon"
            ></mat-icon>
            {{ link.text }}
          </span>
          <button
            type="button"
            mat-icon-button
            class="expand-button"
            (click)="toggleOpenMenu(link.text, $event)"
            *ngIf="link.children"
          >
            <mat-icon
              class="expand-icon expand-icon_more"
              *ngIf="!isOpenedMenu(link.text)"
              svgIcon="expand_more"
            ></mat-icon>
            <mat-icon
              class="expand-icon"
              *ngIf="isOpenedMenu(link.text)"
              svgIcon="expand_less"
            ></mat-icon>
          </button>
        </a>
        <ul
          #subnav
          class="sidebar__subnavigation sidebar__navigation"
          *ngIf="link.children && isOpenedMenu(link.text)"
        >
          <li
            class="sidebar__subnavigation-item sidebar__navigation-item"
            *ngFor="let child of link.children; trackBy: trackByNavigationLink"
          >
            <a
              class="sidebar__sublink sidebar__link"
              routerLinkActive="active"
              [routerLink]="child.route"
            >
              {{ child.text }}
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <footer *userHasRole="userRole.BusinessOwner" class="sidebar__footer">
      <!-- TODO (Sakhno V): Update tooltip logic when it will be clear what this hint will be about and where to lead. -->
      <div class="sidebar__help sidebar__link">
        <a
          class="sidebar__help-link"
          matTooltip="Some tooltip text"
          [matTooltipPosition]="'right'"
        >
          <mat-icon class="sidebar__link-icon" svgIcon="help"></mat-icon>
          Help
        </a>
      </div>

      <div class="sidebar__logo">
        <a [routerLink]="routePaths.home">
          <protctw-main-logo class="sidenav-main__logo"></protctw-main-logo>
        </a>
      </div>
    </footer>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-main">
    <mat-toolbar
      *ngIf="isCatchBreakpoint$ | async"
      class="sidenav-main__header"
    >
      <a [routerLink]="routePaths.home">
        <protctw-main-logo class="sidenav-main__logo"></protctw-main-logo>
      </a>
      <button
        class="sidenav-main__button"
        type="button"
        mat-icon-button
        (click)="sidenav.toggle()"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
    <div class="sidenav-main__content">
      <protctw-breadcrumbs></protctw-breadcrumbs>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

import { Injectable } from '@angular/core';

import { TokenKey } from '../../models/user/token-key';

import { TokenDto } from './dto/user/token.dto';
import { IMapperToDto } from './mappers';

/** Token mapper. */
@Injectable({
  providedIn: 'root',
})
export class TokenMapper implements IMapperToDto<TokenDto, TokenKey> {

  /** @inheritdoc */
  public toDto(data: TokenKey): TokenDto {
    // Explanation of token and uid
    // Example MQ-5b2-e2c1ce64d63673f0e78f, where MQ - is uid or user id
    // And 5b2-e2c1ce64d63673f0e78f - token for resetting password

    const UID_SEPARATOR = '-';
    const firstSeparatorIndex = data.key.indexOf(UID_SEPARATOR);

    return {
      uid: data.key.slice(0, firstSeparatorIndex),

      // +1 is to remove the separator from token
      token: data.key.slice(firstSeparatorIndex + 1),
    };
  }
}

<ng-container *ngIf="formControl$ | async as formControl">
  <ng-container *ngIf="shouldDisplayErrorMessage(formControl)">
    <protctc-validation-message [errors]="formControl.errors">
    </protctc-validation-message>
  </ng-container>
  <ng-container *ngIf="shouldDisplayHintMessage(formControl)">
    <div class="hint-block">
      <ng-content select="[hint]"></ng-content>
    </div>
  </ng-container>
  <ng-container *ngIf="shouldDisplayFakeBlock(formControl)">
    <!-- Use fake block to avoid markup moving when error is occurred. -->
    <protctc-validation-message> </protctc-validation-message>
  </ng-container>
</ng-container>

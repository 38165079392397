import { Injectable } from '@angular/core';
import { defer, merge, Observable, ReplaySubject, tap } from 'rxjs';

import { Login } from '../models/login';

import { RememberData } from '../models/remember-data';

import { LocalStorageService } from './local-storage.service';

/** Key for saving data that need to be saved. */
const REMEMBER_ME_DATA_STORAGE_KEY = 'remember';

/** Remember me service. */
@Injectable({
  providedIn: 'root',
})
export class RememberMeService {

  /** Remember data. */
  public readonly rememberData$: Observable<RememberData | null>;

  /** Current remember data value. */
  private readonly rememberDataValue$ = new ReplaySubject<RememberData | null>(1);

  public constructor(
    private readonly storageService: LocalStorageService,
  ) {
    this.rememberData$ = this.createRememberDataStream();
  }

  /**
   * Update remember data.
   * @param data Login data.
   * @param rememberMe Save email for next login flag.
   */
  public update(data: Login, rememberMe: boolean): Observable<void> {
    const rememberData: RememberData = {
      rememberMe,
      email: rememberMe ? data.email : '',
    };

    return defer(() => this.storageService.save(REMEMBER_ME_DATA_STORAGE_KEY, rememberData)).pipe(
      tap(() => this.rememberDataValue$.next(rememberData)),
    );
  }

  /**
   * Create remember data stream.
   */
  private createRememberDataStream(): Observable<RememberData | null> {
    const rememberDataChange$ = this.rememberDataValue$;
    return merge(
      defer(() => this.storageService.get<RememberData | null>(REMEMBER_ME_DATA_STORAGE_KEY)),
      rememberDataChange$,
    );
  }
}

import { Injectable } from '@angular/core';

import { SortDirection } from '../../enums/sort-direction';
import { SortOptions } from '../../models/sort-options';

/** Sort mapper. */
@Injectable({
  providedIn: 'root',
})
export class SortMapper {

  /**
   * Maps sort options to dto representation.
   * @param sortFieldMapper Mapper to map sort field from domain to local.
   * @param sortOptions Sort options.
   */
  public mapSortOptionsToDto<T extends string>(sortOptions: SortOptions<T>, sortFieldMapper: Readonly<Record<T, string>>): string {
    const column = sortFieldMapper[sortOptions.column];
    const direction = SortDirection.SORT_FIELD_MAP[sortOptions.direction as SortDirection];
    return `${direction}${column}`;
  }
}

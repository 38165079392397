import { Component, ChangeDetectionStrategy, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroupTyped } from '@angular/forms';
import { InvoiceStatus } from '@protctc/common/core/enums/invoice-status';
import { PaymentMethod } from '@protctc/common/core/enums/payment-method';
import { DateRangeFormData } from '@protctc/common/core/forms/date-range-form-data';
import { InvoiceShortFilterData } from '@protctc/common/core/models/invoice-short-filter-data';
import { IDialog, IDialogOptions } from '@protctc/common/shared/dialogs/dialog';

import { InvoiceFilterFormData } from './invoice-filter-form-data';

/** Define what filter control should be shown or hidden in filter invoice dialog. */
export interface ShouldShowInvoiceFilterControlType {

  /** Should show created date. */
  readonly shouldShowCreatedDate?: boolean;

  /** Should show due date. */
  readonly shouldShowDueDate?: boolean;

  /** Should show invoice status. */
  readonly shouldShowInvoiceStatus?: boolean;

  /** Should show payment method. */
  readonly shouldShowPaymentMethod?: boolean;
}

const DEFAULT_INVOICE_FILTER_CONTROLS_SHOW_MODE: ShouldShowInvoiceFilterControlType = {
  shouldShowCreatedDate: true,
  shouldShowDueDate: true,
  shouldShowInvoiceStatus: true,
  shouldShowPaymentMethod: true,
};

/** Invoice filter dialog options. */
interface InvoiceFilterDialogOptions {

  /** Filter data. */
  readonly filterData: InvoiceShortFilterData;

  /** Controls show mode. */
  readonly shouldShowInvoiceFilterControl?: ShouldShowInvoiceFilterControlType;
}

/** Invoice filter dialog. */
@Component({
  selector: 'protctw-invoice-filter-dialog',
  templateUrl: './invoice-filter-dialog.component.html',
  styleUrls: ['./invoice-filter-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceFilterDialogComponent implements IDialog<InvoiceFilterDialogOptions, InvoiceShortFilterData | void>, OnInit {

  /** Form containing prompt input. */
  public readonly form: FormGroupTyped<InvoiceFilterFormData>;

  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritdoc */
  public readonly closed = new EventEmitter<InvoiceShortFilterData | void>();

  /** Props. */
  public readonly props!: InvoiceFilterDialogOptions;

  /** Payment methods. */
  public readonly paymentMethods = PaymentMethod.getFilterPaymentsMethods();

  /** Invoice statuses. */
  public readonly invoiceStatuses = InvoiceStatus.toKeyValueArray();

  /** Invoice filter controls show mode. */
  public filterDialogControlsShowMode = DEFAULT_INVOICE_FILTER_CONTROLS_SHOW_MODE;

  public constructor(
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.form = InvoiceFilterFormData.initializeFormGroup(this.formBuilder);
  }

  /** Created date range. */
  public get createdDateRange(): FormGroupTyped<DateRangeFormData> {
    return this.form.get('created') as FormGroupTyped<DateRangeFormData>;
  }

  /** Due date range. */
  public get dueDateRange(): FormGroupTyped<DateRangeFormData> {
    return this.form.get('due') as FormGroupTyped<DateRangeFormData>;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.options = {
      closable: true,
    };

    if (this.props.shouldShowInvoiceFilterControl) {
      this.filterDialogControlsShowMode = {
        ...this.filterDialogControlsShowMode,
        ...this.props.shouldShowInvoiceFilterControl,
      };
    }

    this.form.patchValue({
      invoiceStatus: this.props.filterData.invoiceStatus,
      paymentMethod: this.props.filterData.paymentMethod,
      created: {
        start: this.props.filterData.invoiceStartDate,
        end: this.props.filterData.invoiceEndDate,
      },
      due: {
        start: this.props.filterData.invoiceDueStartDate,
        end: this.props.filterData.invoiceDueEndDate,
      },
    });
  }

  /** Handle submit action. */
  public onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.closed.next(InvoiceFilterFormData.mapToInvoiceFilterData(this.form.value));
  }
}

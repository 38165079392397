import { InvoiceView } from './invoice-view';

/** Type for default view data values. */
type DefaultValuesViewDataType = Pick<InvoiceView,
  | 'cardinal'
  | 'createdDate'
  | 'dueDate'
  | 'totalDue'
  | 'lineItems'
  | 'subtotal'
  | 'tax'
  | 'total'
  | 'cashPrice'
>;

/**
 * Default values of view data.
 * Needs to show full invoice preview without
 * invoice data and with only invoice template.
 */
export const DEFAULT_TEMPLATE_INVOICE_VIEW: DefaultValuesViewDataType = {
  cardinal: 123,
  createdDate: 'MM/DD/YYYY',
  dueDate: 'MM/DD/YYYY',
  totalDue: '$XXX.XX',
  lineItems: [
    {
      title: '[Item 1]',
      description: 'Description of item 1 goes here',
      quantity: 1,
      price: '$XX.XX',
      amount: '$XX.XX',
      subtotal: '$XX.XX',
    },
    {
      title: '[Item 2]',
      description: 'Description of item 2 goes here',
      quantity: 1,
      price: '$XX.XX',
      amount: '$XX.XX',
      subtotal: '$XX.XX',
    },
  ],
  subtotal: '$XX.XX',
  tax: '$XX.XX',
  total: '$XXX.XX',
  cashPrice: '$XXX.XX',
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Login } from '../models/login';
import { PasswordReset } from '../models/password-reset';
import { PasswordResetConfirmation } from '../models/password-reset-confirmation';
import { UserSecret } from '../models/user-secret';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { DetailsDto } from './mappers/dto/details-dto';
import { UserSecretDto } from './mappers/dto/user-secret-dto';
import { LoginMapper } from './mappers/login.mapper';
import { ResetPasswordConfirmationMapper } from './mappers/reset-password-confirmation.mapper';
import { ResetPasswordMapper } from './mappers/reset-password.mapper';

/**
 * Stateless api-level service for handling the authorization requests.
 */
@Injectable()
export class AuthService {
  private readonly loginUrl: string;

  private readonly resetPasswordUrl: string;

  private readonly confirmResetPasswordUrl: string;

  public constructor(
    appConfig: AppConfigService,
    protected readonly httpClient: HttpClient,
    protected readonly appErrorMapper: AppErrorMapper,
    protected readonly loginMapper: LoginMapper,
    private readonly resetPasswordMapper: ResetPasswordMapper,
    private readonly resetPasswordConfirmationMapper: ResetPasswordConfirmationMapper,
  ) {
    this.loginUrl = new URL('auth/login/', appConfig.apiUrl).toString();
    this.resetPasswordUrl = new URL('auth/password-reset/', appConfig.apiUrl).toString();
    this.confirmResetPasswordUrl = new URL('auth/password-reset-confirm/', appConfig.apiUrl).toString();
  }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<UserSecret> {
    return this.httpClient.post<UserSecretDto>(
      this.loginUrl,
      this.loginMapper.toDto(loginData),
    ).pipe(
      map(authData => this.loginMapper.fromDto(authData)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.loginMapper),
    );
  }

  /**
   * Sends request to reset the password.
   * @param data Data for password reset.
   * @returns Success message.
   */
  public resetPassword(data: PasswordReset): Observable<string> {
    return this.httpClient.post<DetailsDto>(
      this.resetPasswordUrl,
      this.resetPasswordMapper.toDto(data),
    ).pipe(
      map(result => result.detail),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.resetPasswordMapper),
    );
  }

  /**
   * Confirms password reset and applies new passwords to the account.
   * @param data New passwords data.
   * @returns Success message.
   */
  public confirmPasswordReset(data: PasswordResetConfirmation): Observable<string> {
    return this.httpClient.post<DetailsDto>(
      this.confirmResetPasswordUrl,
      this.resetPasswordConfirmationMapper.toDto(data),
    ).pipe(
      map(result => result.detail),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.resetPasswordConfirmationMapper),
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_LENGTH_OF_STARS = 2;
const LENGTH_OF_LAST_CARD_NUMBERS = 4;

/**
 * Pipe to transform full card number string to preview
 * with showing starts and last numbers of this card.
 * @example
 * -> '000000******0001' | pipe 'protctcToLastNumbersCard' > '**0001';
 */
@Pipe({
  name: 'protctcToLastNumbersCard',
})
export class ToLastNumbersCardPipe implements PipeTransform {

  /**
   * Transform full card number string to preview.
   * @param value Value to transform.
   * @param starsLength Stars length in the string.
   */
  public transform(value: string, starsLength: number = DEFAULT_LENGTH_OF_STARS): string {
    const stringLength = LENGTH_OF_LAST_CARD_NUMBERS + starsLength;
    const transformedString = value.slice(-stringLength);
    return transformedString.padStart(starsLength, '*');
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { DashboardStatisticCardData } from '../dashboard-statistics-card/dashboard-statistic-card.component';

/** Dashboard statistics card list. */
@Component({
  selector: 'protctw-dashboard-statistics-card-list',
  templateUrl: './dashboard-statistics-card-list.component.html',
  styleUrls: ['./dashboard-statistics-card-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardStatisticsCardListComponent {

  /** Statistics card data items. */
  @Input()
  public items: DashboardStatisticCardData[] | null = [];

  /** Is new items loading state to show progress bar. */
  @Input()
  public isLoading: boolean | null = false;

  /** Track by card data function. */
  public readonly trackByCardDataFunction = DashboardStatisticCardData.trackBy;
}

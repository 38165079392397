<p class="message">Filter</p>
<form class="form" (ngSubmit)="onSubmit()" [formGroup]="form">
  <protctw-date-range-picker
    *ngIf="filterDialogControlsShowMode.shouldShowCreatedDate"
    label="Invoice date"
    [dateRangeForm]="createdDateRange"
  ></protctw-date-range-picker>

  <mat-form-field
    class="form-field"
    *ngIf="filterDialogControlsShowMode.shouldShowLocation"
  >
    <mat-label>Location</mat-label>
    <mat-select formControlName="location" [compareWith]="compareState">
      <mat-option *ngFor="let location of states$ | async" [value]="location">
        {{ location.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="form-field"
    *ngIf="filterDialogControlsShowMode.shouldShowPaymentMethod"
  >
    <mat-label>Transaction type</mat-label>
    <mat-select formControlName="paymentMethod">
      <mat-option *ngFor="let method of paymentMethods" [value]="method.value">
        {{ method.key }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="form-field"
    *ngIf="filterDialogControlsShowMode.shouldShowInvoiceStatus"
  >
    <mat-label>Transaction status</mat-label>
    <mat-select formControlName="invoiceStatus">
      <mat-option *ngFor="let status of invoiceStatuses" [value]="status.value">
        {{ status.key }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="buttons-block">
    <button
      class="dialog__buttons--cancel button basic"
      type="button"
      (click)="closed.emit()"
    >
      Cancel
    </button>
    <button class="dialog__buttons--confirm button flat" type="submit">
      Save
    </button>
  </div>
</form>

import { ChangeDetectionStrategy, Component, Host, Input, Optional } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { combineLatest, first, map, Observable, tap } from 'rxjs';

import { SortDirective } from '../sort.directive';

/**
 * Sort control.
 * @example
 * ```html
      <table [protctcSort]="sort$ | async" (protctcSortChange)="sort$.next($event)">
        <tr>
          <th [protctcSortButton]="sortOptions.Name">Name</th>
          <th [protctcSortButton]="sortOptions.LastLogin">Last Login</th>
        </tr>
      <table/>
 * ```
 */
@DestroyableComponent()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'th[protctcSortButton]',
  templateUrl: './table-sort-button.component.html',
  styleUrls: ['./table-sort-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSortButtonComponent<T extends number | string> {

  /** Sort field. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('protctcSortButton')
  public field: T | null = null;

  /** Whether the sort button is activated. */
  protected readonly isActive$: Observable<boolean>;

  /** Sort direction. */
  protected readonly sort$: Observable<SortDirection | null>;

  public constructor(
    @Host() @Optional()
    private readonly sortDirective: SortDirective<T>,
  ) {
    if (sortDirective == null) {
      throw Error('Using the sort button is able only inside the sort directive');
    }

    this.isActive$ = this.sortDirective.value$.pipe(
      map(value => value?.column === this.field),
    );

    this.sort$ = combineLatest([
      this.isActive$,
      this.sortDirective.value$.pipe(filterNull()),
    ]).pipe(
      map(([isActive, value]) => isActive ? value.direction : null),
    );
  }

  /** Handles click on sort button. */
  protected onClicked(): void {
    this.sortDirective.value$.pipe(
      filterNull(),
      first(),
      tap(value => this.updateSortValue(value)),
      takeUntilDestroy(this),
    ).subscribe();
  }

  private toggleSort(sort: SortDirection): SortDirection {
    return sort === 'asc' ? 'desc' : 'asc';
  }

  private updateSortValue(value: SortOptions<T>): void {
    assertNonNull(this.field);
    const wasSameFieldToggled = this.field === value.column;
    const newSort: SortOptions<T> = {
      column: this.field,
      direction: wasSameFieldToggled ?
        this.toggleSort(value.direction) :
        value.direction,
    };
    this.sortDirective.value = newSort;
    this.sortDirective.valueChange.emit(newSort);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { routePaths } from '@protctc/common/core/utils/route-paths';

/** Policy links component. */
@Component({
  selector: 'protctc-policy-links',
  templateUrl: './policy-links.component.html',
  styleUrls: ['./policy-links.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PolicyLinksComponent {

  /** Deployment url, we need link by full url for opening links in browser for mobile apps. */
  @Input()
  public deploymentUrl = '';

  /** Route paths. */
  protected readonly routePaths = routePaths;
}

import { Injectable } from '@angular/core';
import { CompanyContact } from '@protctc/common/core/models/company/company-contact';

import { AddressMapper } from '../address/address.mapper';
import { CompanyContactDto } from '../dto/company/company-contact.dto';
import { IMapper } from '../mappers';

/** Company contact mapper. */
@Injectable({
  providedIn: 'root',
})
export class CompanyContactMapper implements IMapper<CompanyContactDto, CompanyContact> {

  public constructor(
    private readonly addressMapper: AddressMapper,
  ) { }

  /** @inheritdoc */
  public toDto(data: CompanyContact): CompanyContactDto {
    return {
      first_name: data.firstName,
      last_name: data.lastName,
      title: data.title,
      email: data.email,
      phone: data.phone,
      ...this.addressMapper.toDto(data.address),
    };
  }

  /** @inheritdoc */
  public fromDto(data: CompanyContactDto): CompanyContact {
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      title: data.title,
      email: data.email,
      phone: data.phone,
      address: this.addressMapper.fromDto(data),
    };
  }
}

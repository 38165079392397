<section class="stepper">

  <div class="stepper__content" [class.display-content]="selected">
    <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
    <div class="stepper__toggles">
      <div
        *ngFor="let step of steps; let i = index"
        class="step-toggle"
        (click)="onStepClick(step)"
        [class.step-active]="selectedIndex === i"
      ></div>
    </div>
  </div>

  <footer class="stepper__footer">
    <button
      class="stepper__button button flat"
      type="button"
      (click)="onNextStepClick()"
    >
      {{ nextButtonText }}
    </button>
  </footer>

</section>

<ng-container *ngLet="isWeb$ | async as isWeb">
  <div class="file-picker">
    <div [class.visually-hidden]="selectedFiles?.length">
      <div class="file-picker__container">
        <input
          #fileInput
          type="file"
          [id]="currentInputId"
          multiple
          class="file-picker__input"
          (change)="onChange(fileInput)"
        >
        <label [for]="currentInputId" class="file-picker__upload-button button stroked">Upload</label>
      </div>
    </div>
    <div *ngIf="selectedFiles?.length" class="file-picker__filled">
      <button
        *ngIf="selectedFiles?.length !== maxNotes"
        type="button"
        (click)="fileInput.click()"
        class="file-picker__upload-button button stroked"
      >
        Upload
      </button>
      <div class="file-picker__file-list">
        <button
          type="button"
          (click)="selectFile.emit(file)"
          [disabled]="isWeb"
          *ngFor="let file of selectedFiles; trackBy: trackInvoiceNote; let i = index"
          class="link-button file-picker__file-item file-item">
          <div class="file-item__info">
            <mat-icon class="file-item__default-icon" svgIcon="file"></mat-icon>
            <span class="file-item__name">{{ file.name }}</span>
          </div>
          <button
            type="button"
            (click)="removeFile(i);$event.stopPropagation()"
            class="file-item__delete-button"
            mat-icon-button
          >
            <mat-icon *protctcMobileOnly class="file-item__delete-icon">cancel</mat-icon>
            <mat-icon *protctcWebOnly class="file-item__delete-icon" svgIcon="delete"></mat-icon>
          </button>
        </button>
      </div>
    </div>
  </div>
</ng-container>

/** Options required to paginate list. */
export interface PaginationData {

  /** 0-based age number. */
  readonly page: number;

  /** Amount of items on the page. */
  readonly pageSize: number;
}

/**
 * Namespace for pagination data.
 * Contains util methods and props for easier enum use.
 */
export namespace PaginationData {

  /**
   *
   * @param object1 Pagination data.
   * @param object2 Another pagination data.
   */
  export function compare(object1: PaginationData, object2: PaginationData): boolean {
    return object1.page === object2.page && object1.pageSize === object2.pageSize;
  }
}

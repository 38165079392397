<ng-container *ngIf="invoicesPage">
  <protctw-empty-table-preview
    *ngIf="invoicesPage.hasItems === false"
    [title]="emptyPreviewMessage"
  ></protctw-empty-table-preview>
  <div class="table" *ngIf="invoicesPage.hasItems">
    <mat-table
      matSort
      (matSortChange)="onSortChange($event)"
      [matSortDirection]="activeSortDirection"
      [matSortActive]="activeSortColumn"
      [dataSource]="invoicesPage.items"
      [trackBy]="trackInvoice"
    >
      <ng-container matColumnDef="invoiceNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Invoice #
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.invoicePrefix | protctcInvoicePrefix}}{{ data.invoiceNumber }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>Totals:</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="transactionId">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Transaction ID
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <a
            class="inline-table-button button basic"
            [routerLink]="['/admin', 'invoices', 'details', data.id]"
          >
            {{ data.transactionId | protctcNullable}}
          </a>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>Totals:</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Date
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <time [dateTime]="data.createdDate">
            {{ data.createdDate | date: "MM/dd/yyyy" }}&nbsp;
          </time>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="businessId">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Business ID
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.businessId | leadingZeros }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="businessEIN">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Business EIN
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.businessEIN | imask: einMask }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="customerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.customerName | protctcNullable }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Location
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <ng-container *ngIf="isAdminInvoicesForDashboard">
            {{ data.customerCity }},
            <br />
          </ng-container>
          {{ data.stateCode }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="paymentMethod">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <ng-container *ngIf="data.paymentMethod; else empty">
            {{ toReadablePaymentMethod(data.paymentMethod) }}
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.amount | USDCurrency }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{
          invoicesPage.extraData.totalAmount | USDCurrency
        }}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="fee">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          2$ fee
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <ng-container *ngIf="isAdminInvoicesForDashboard">
            <mat-icon
              class="done-icon"
              *ngIf="data.fee"
              svgIcon="done_without_circle"
            ></mat-icon>
            <mat-icon
              class="cross-icon"
              *ngIf="!data.fee"
              svgIcon="cross"
            ></mat-icon>
          </ng-container>
          <ng-container *ngIf="!isAdminInvoicesForDashboard">
            <ng-container *ngIf="data.fee; else empty">
              {{ data.fee | USDCurrency }}
            </ng-container>
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{
          invoicesPage.extraData.totalFee | USDCurrency
        }}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="sharedRevenue">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Shared revenue
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <ng-container *ngIf="data.sharedRevenue; else revenueEmpty">
            {{ data.sharedRevenue | USDCurrency }}
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{
          invoicesPage.extraData.totalSharedRevenue | USDCurrency
        }}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Status
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span class="status">
            {{ toReadableInvoiceStatus(data.status) }}
            <mat-icon
              *ngIf="data.status === invoiceStatus.Overdue"
              class="overdue-invoice"
              svgIcon="border"
            ></mat-icon>
          </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> Total amount: </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        [class.display-none]="isAdminInvoicesForDashboard"
        *matFooterRowDef="displayedColumns; sticky: true"
      ></mat-footer-row>
    </mat-table>
    <mat-paginator
      *ngIf="invoicesPage"
      (page)="onPageChange($event)"
      [pageIndex]="invoicesPage.page"
      [length]="invoicesPage.totalCount"
      [pageSize]="invoicesPage.size"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

<ng-template #revenueEmpty>
  <span>$x.xx</span>
</ng-template>


import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { toggleExecutionState } from '@protctc/common/core/rxjs/toggle-execution-state';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { BehaviorSubject, Observable } from 'rxjs';

import { IDialog, IDialogOptions } from '../dialog';

/**
 * Confirm options.
 */
export interface ConfirmOptions {

  /**
   * Dialog title.
   */
  readonly title?: string;

  /**
   * Confirmation message.
   */
  readonly message?: string;

  /**
   * Confirm button title.
   */
  readonly confirmButtonTitle?: string;

  /**
   * Refuse button title.
   */
  readonly refuseButtonTitle?: string;

  /**
   * Whether the action is potentially dangerous. Applies styles for extra attention when `true`.
   */
  readonly isConfirmButtonDanger?: boolean;

  /** Confirm action. */
  readonly confirmAction?: Observable<void>;
}

/**
 * Confirm dialog component.
 */
@DestroyableComponent()
@Component({
  selector: 'protctc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent implements IDialog<ConfirmOptions, boolean>, OnInit {

  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritdoc */
  public readonly closed = new EventEmitter<boolean>();

  /** @inheritdoc */
  public readonly props!: ConfirmOptions;

  /** Default dialog property values. */
  public readonly defaultProps: ConfirmOptions = {
    title: 'Confirm the action',
    confirmButtonTitle: 'Confirm',
    refuseButtonTitle: 'Cancel',
  };

  /** Is loading. */
  public readonly isLoading$ = new BehaviorSubject(false);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.options = {
      closable: true,
      closeButton: true,
      title: this.props?.title ?? this.defaultProps.title,
    };
  }

  /** Confirm action. */
  public confirm(): void {
    if (this.props.confirmAction) {
      this.props.confirmAction.pipe(
        toggleExecutionState(this.isLoading$),
        takeUntilDestroy(this),
      )
        .subscribe(() => this.closed.next(true));
      return;
    }
    this.closed.next(true);
  }
}

/**
 * Validation error code.
 */
export enum ValidationErrorCode {

  /**
   * Wrong email.
   */
  Email = 'email',

  /**
   * Wrong url.
   */
  Url = 'url',

  /**
   * Required field.
   */
  Required = 'required',

  /**
   * Match of values error. When value of one control does not match to another.
   */
  Match = 'match',

  /**
   * Minimal length restriction.
   */
  MinLength = 'minlength',

  /**
   * Maximal length restriction.
   */
  MaxLength = 'maxlength',

  /** Equal length restriction. */
  EqualLength = 'equalLength',

  /**
   * Maximum value restriction.
   */
  Min = 'min',

  /**
   * Minimum value restriction.
   */
  Max = 'max',

  /**
   * Pattern restriction.
   */
  Pattern = 'pattern',

  /**
   * Jus law error.
   */
  AppError = 'appError',

  /**
   * Value is greater than the compared one.
   */
  Greater = 'greater',

  /**
   * Expiration date need be in MM/YY format.
   */
  ExpirationDate = 'expirationDate',

  /**
   * Start date should be less than end date.
   */
  StartDateInvalid = 'matStartDateInvalid',

  /**
   * End date should be greater than start date.
   */
  EndDateInvalid = 'matEndDateInvalid',

  /**
   * Parse error from date picker.
   */
  DatepickerParse = 'matDatepickerParse',

  /**
   * Max date.
   */
  MaxDate = 'matDatepickerMax',

  /**
   * Min date.
   */
  MinDate = 'matDatepickerMin',

  /**
   * Max year.
   */
  MaxYear = 'maxYear',

  /**
   * No white spaces only.
   */
  NoWhiteSpacesOnly = 'noWhiteSpacesOnly',

  /**
   * Email exists.
   */
  EmailExists = 'emailExists',
}

/**
 * Match validation error data.
 */
export interface MatchErrorData {

  /**
   * Control name.
   */
  controlName: string;

  /**
   * Control title.
   */
  controlTitle: string;
}

/**
 * Length validation error data.
 */
export interface LengthErrorData {

  /**
   * Actual length.
   */
  actualLength: number;

  /**
   * Required length.
   */
  requiredLength: number;
}

/** Equal length error data. */
export interface EqualLengthErrorData {

  /** Required length. */
  requiredLength: number;

  /** Message. */
  message: string;
}

/**
 * Pattern validation error data.
 */
export interface PatternErrorData {

  /**
   * Actual length.
   */
  actualValue: string;

  /**
   * Required length.
   */
  requiredPattern: string;
}

/**
 * App validation error data.
 */
export interface AppErrorData {

  /**
   * Message.
   */
  message: string;
}

/**
 * Min value validation error data.
 */
export interface MinValueErrorData {

  /**
   * Actual value.
   */
  actual: number;

  /**
   * Min value.
   */
  min: number;
}

/**
 * Max value validation error data.
 */
export interface MaxValueErrorData {

  /**
   * Actual value.
   */
  actual: number;

  /**
   * Min value.
   */
  max: number;
}

/**
 * Expiration date error data.
 */
export interface ExpirationErrorData {

  /**
   * Message.
   */
  message: string;
}

/**
 * Min date error data.
 */
export interface MinDateErrorData {

  /** Actual date. */
  actual: Date;

  /** Min. */
  min: Date;
}

/**
 * Max date error data.
 */
export interface MaxDateErrorData {

  /** Actual date. */
  actual: Date;

  /** Max. */
  max: Date;
}

<section *ngIf="data" class="statistic-card surface-card">
  <h3 class="statistic-card__title">{{ data.title }}</h3>
  <p class="statistic-card__value">{{ data.value }}</p>
  <footer
    class="statistic-card__difference difference"
    *ngLet="{
      isPositiveDifference: isPositiveDifference(),
      isNegativeDifference: isNegativeDifference()
    } as footerData"
    [class.difference_positive]="footerData.isPositiveDifference"
    [class.difference_negative]="footerData.isNegativeDifference"
  >
    <p
      class="difference__value"
      [class.difference__value_positive]="footerData.isPositiveDifference"
      [class.difference__value_negative]="footerData.isNegativeDifference"
    >
      {{
        addPlusSignToPercentDifference(getDecimalPercentDifference() | percent)
      }}
    </p>
    <ng-template
      *ngIf="
        !footerData.isPositiveDifference && !footerData.isNegativeDifference
      "
      [ngTemplateOutlet]="empty"
    ></ng-template>
    <mat-icon
      *ngIf="footerData.isPositiveDifference"
      class="difference__icon"
      svgIcon="arrow_up"
    ></mat-icon>
    <mat-icon
      *ngIf="footerData.isNegativeDifference"
      class="difference__icon"
      svgIcon="arrow_down"
    ></mat-icon>
  </footer>
</section>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

<section class="stepper">
  <header class="stepper__header">
    <ul class="stepper__toggles">
      <li
        *ngFor="let step of steps; let i = index"
        class="stepper__toggle"
        [class.stepper__toggle--active]="selectedIndex === i"
        [class.stepper__toggle--completed]="step.completed"
      >
        <p class="stepper__toggle-number">{{ i + 1 }}</p>
        <p class="stepper__toggle-label">{{ step.label }}</p>
      </li>
    </ul>
  </header>

  <div class="stepper__content" [class.stepper__content--selected]="selected">
    <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
  </div>

  <footer class="stepper__footer">
    <button
      *ngIf="selected !== steps.first"
      type="button"
      class="stepper__prev-button button stroked"
      cdkStepperPrevious
    >
      Back
    </button>
    <button
      class="stepper__next-button button flat"
      type="button"
      (click)="onNextStepClick()"
      [protctcLoading]="isLoading"
      [protctcInvalidControlScroll]="currentControl"
    >
      <mat-icon *ngIf="isLastStep" class="stepper__next-button-icon">
        done
      </mat-icon>
      {{ nextButtonText }}
    </button>
  </footer>
</section>

/** Data needed for company statistics model creation. */
export type CompanyStatisticsConstructorData = CompanyStatistics;

/**
 * Company statistics.
 * TODO (Sakhno V): Update model when fee saved data will be appeared.
 */
export class CompanyStatistics {

  /** Total revenue count. */
  public readonly totalRevenueCount: number;

  /** Total revenue difference. */
  public readonly totalRevenueDifference: number;

  /** Total invoice count. */
  public readonly totalInvoiceCount: number;

  /** Total invoice difference. */
  public readonly totalInvoiceDifference: number;

  /** Total customer count. */
  public readonly totalCustomerCount: number;

  /** Total customer difference. */
  public readonly totalCustomerDifference: number;

  /** Total new customer count. */
  public readonly totalNewCustomerCount: number;

  /** Total new customer difference. */
  public readonly totalNewCustomerDifference: number;

  /** Total transaction count. */
  public readonly totalTransactionCount: number;

  /** Total transaction count difference. */
  public readonly totalTransactionDifference: number;

  /** Total fee saved count. */
  public readonly totalFeeSavedCount: number;

  /** Total fee saved count difference. */
  public readonly totalFeeSavedCountDifference: number;

  public constructor(data: CompanyStatisticsConstructorData) {
    this.totalRevenueCount = data.totalRevenueCount;
    this.totalRevenueDifference = data.totalRevenueDifference;
    this.totalInvoiceCount = data.totalInvoiceCount;
    this.totalInvoiceDifference = data.totalInvoiceDifference;
    this.totalCustomerCount = data.totalCustomerCount;
    this.totalCustomerDifference = data.totalCustomerDifference;
    this.totalNewCustomerCount = data.totalNewCustomerCount;
    this.totalNewCustomerDifference = data.totalNewCustomerDifference;
    this.totalTransactionCount = data.totalTransactionCount;
    this.totalTransactionDifference = data.totalTransactionDifference;
    this.totalFeeSavedCount = data.totalFeeSavedCount;
    this.totalFeeSavedCountDifference = data.totalFeeSavedCountDifference;
  }
}

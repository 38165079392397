import { ViewContainerRef, TemplateRef, Directive, ChangeDetectorRef, OnInit } from '@angular/core';
import { AppConfigService } from '@protctc/common/core/services/app-config.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';

import { ConditionalRenderingDirective } from './conditional-rendering.directive';

/**
 * Directive that render content only if debug mode is turned on.
 */
@DestroyableComponent()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showForDebug]',
})
export class ShowForDebugDirective extends ConditionalRenderingDirective implements OnInit {

  public constructor(
    vcr: ViewContainerRef,
    cdr: ChangeDetectorRef,
    templateRef: TemplateRef<unknown>,
    private readonly appConfig: AppConfigService,
  ) {
    super(vcr, cdr, templateRef);
  }

  /** @inheritDoc */
  public ngOnInit(): void {
    this.appConfig.debugMode$
      .pipe(
        takeUntilDestroy(this),
      ).subscribe(isDebug => {
        if (isDebug) {
          this.renderContent();
        } else {
          this.clearContent();
        }
      });
  }
}

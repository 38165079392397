import { CustomKeyValue } from '../models/custom-key-value';

/** Payment method. */
export enum PaymentMethod {

  /** Credit card. */
  CreditCard = 'CC',

  /** Cash. */
  Cash = 'cash',

  /** Check. */
  Check = 'check',

  /** Union cash/check. */
  CashCheck = 'cash/check',

  /**
   * Payment through Automated Clearing House network.
   * Type of electronic bank-to-bank payment in the US.
   */
  ACH = 'ACH',

  /** None. */
  None = '',

  /** Send invoice by email/sms. */
  SendInvoice = 'sendInvoice',
}

/**
 * Namespace for payment method.
 * Contains util methods and props for easier enum use.
 */
export namespace PaymentMethod {

  const READABLE_MAP: Record<PaymentMethod, string> = {
    [PaymentMethod.CreditCard]: 'Credit card',
    [PaymentMethod.Cash]: 'Cash',
    [PaymentMethod.Check]: 'Check',
    [PaymentMethod.CashCheck]: 'Cash/Check',
    [PaymentMethod.ACH]: 'ACH',
    [PaymentMethod.None]: 'All types',
    [PaymentMethod.SendInvoice]: 'Send',
  };

  /**
   * Return human-readable method representation.
   * @param method Payment method.
   */
  export function toReadable(method: PaymentMethod): string {
    return READABLE_MAP[method];
  }

  /** Get list for all payment methods. */
  export function toArray(): PaymentMethod[] {
    const enumType = typeof PaymentMethod.CreditCard;
    return Object.values(PaymentMethod)
      .filter(method => typeof method === enumType)
      .map(method => method as PaymentMethod);
  }

  /** Get creation payment methods only. */
  export function getCreationPaymentMethodsOnly(): CustomKeyValue<string, PaymentMethod>[] {
    const includedMethods = [
      PaymentMethod.CreditCard,
      PaymentMethod.CashCheck,
      PaymentMethod.ACH,
      PaymentMethod.SendInvoice,
    ];

    return PaymentMethod.toKeyValueArray().filter(keyValue => includedMethods.includes(keyValue.value));
  }

  /** Get payment methods for filter. */
  export function getFilterPaymentsMethods(): CustomKeyValue<string, PaymentMethod>[] {
    const excludesMethods = [PaymentMethod.CashCheck];

    return PaymentMethod.toKeyValueArray().filter(keyValue => !excludesMethods.includes(keyValue.value));
  }

  /**  */

  /** Get list for key value payment methods array. */
  export function toKeyValueArray(): CustomKeyValue<string, PaymentMethod>[] {
    return PaymentMethod.toArray().map(value => ({ value, key: PaymentMethod.toReadable(value) }));
  }
}

import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserRole } from '@protctc/common/core/enums/user-role';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { merge, tap } from 'rxjs';

import { UserSaveForm } from './user-save-form-data';

/** User save form. */
@DestroyableComponent()
@Component({
  selector: 'protctw-user-save-form',
  templateUrl: './user-save-form.component.html',
  styleUrls: ['./user-save-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSaveFormComponent implements OnInit {

  /** Save user form. */
  @Input()
  public saveUserForm!: FormGroup<UserSaveForm>;

  /** Is admin page. */
  @Input()
  public isAdminPage = false;

  /** List of available user create/update roles. */
  public readonly userRoles = UserRole.getOnlySaveUserRoles();

  /** Represent user role like human-readable string. */
  public readonly toReadableUserRole = UserRole.toReadable;

  public constructor(
    private readonly cdr: ChangeDetectorRef,
  ) { }

  /** Should hide user role. */
  public get shouldHideUserRole(): boolean {
    return this.isAdminPage && this.saveUserForm.controls.role.disabled;
  }

  /** @inheritdoc */
  public ngOnInit(): void {

    /** When errors come from the backend, there is no rendering. */
    const runDetectChangesSideEffect$ = this.saveUserForm.statusChanges.pipe(
      tap(() => this.cdr.markForCheck()),
    );

    merge(
      runDetectChangesSideEffect$,
    ).pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }
}

<div class="main-content">
  <mat-icon class="main-content__icon" svgIcon="error"></mat-icon>
  <h1 class="main-content__header">Change Invoice Status</h1>
  <p class="main-content__message">
    You are about to change your invoice status. Once the invoice status is
    updated from Pending, you cannot make any edits to the invoice.<br />
    <span class="main-content__message-question"
      >Are you sure you want to proceed?</span
    >
  </p>
</div>
<div class="buttons-block">
  <button class="button basic" type="button" (click)="closed.next(false)">
    Cancel
  </button>
  <button
    class="button flat"
    type="button"
    (click)="closed.next(true)"
    autofocus
  >
    Update invoice
  </button>
</div>

import { Injectable } from '@angular/core';
import { UploadedDocument } from '@protctc/common/core/models/uploaded-document';

import { UploadedDocumentDto } from '../dto/company/uploaded-document.dto';
import { IMapper } from '../mappers';

/** Uploaded document mapper. */
@Injectable({
  providedIn: 'root',
})
export class UploadedDocumentMapper implements IMapper<UploadedDocumentDto, UploadedDocument> {

  /** @inheritdoc */
  public fromDto(data: UploadedDocumentDto): UploadedDocument {
    return {
      name: data.name,
      fileUri: data.file,
    };
  }

  /** @inheritdoc */
  public toDto(data: UploadedDocument): UploadedDocumentDto {
    return {
      file: data.fileUri,
      name: data.name,
    };
  }
}

<div class="backdrop" (click)="tryClose()" role="presentation"></div>
<protctc-dialog-container>
  <header class="overlay-header" [class.shadow]="contentScrolled">
    <protctc-dialog-header
      [class.right]="options?.isTitleAlignmentRight ?? false"
      [title]="options?.title || ''"
    ></protctc-dialog-header>
    <button
      *ngIf="options?.closeButton"
      (click)="closed.next()"
      title="Close the dialog"
      class="close-button"
      type="button"
    ></button>
  </header>
  <main class="dialog-content" (scroll)="onScroll($event)">
    <ng-content></ng-content>
  </main>
</protctc-dialog-container>

<protctc-table
  class="table"
  [page]="fieldUsers"
  [displayedColumns]="columns"
  [paginationFilters]="paginationData"
  [pageSizeOptions]="pageSizeOptions"
  [hasCustomRows]="true"
  (pageChange)="pageChanged.emit($event)"
  [protctcSort]="sortOptions"
  (protctcSortChange)="sortChanged.emit($event)"
>
  <ng-container matColumnDef="name">
    <th [protctcSortButton]="sortFields.Name" mat-header-cell *matHeaderCellDef>
      Field user
    </th>
    <td
      class="table__info-column"
      mat-cell
      *matCellDef="let user; dataSource: fieldUsers?.items"
    >
      <a [routerLink]="['/users', 'details', user.id]">
        {{ user.name | protctcNullable }}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="invoiceCount">
    <th
      [protctcSortButton]="sortFields.InvoiceCount"
      mat-header-cell
      *matHeaderCellDef
    >
      # of invoices
    </th>
    <td
      class="table__info-column"
      mat-cell
      *matCellDef="let user; dataSource: fieldUsers?.items"
    >
      <ng-container *ngIf="user.invoiceCount; else empty">
        {{ user.invoiceCount | number: "1.0" }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="totalAmount">
    <th
      [protctcSortButton]="sortFields.TotalAmount"
      mat-header-cell
      *matHeaderCellDef
    >
      Total amount
    </th>
    <td
      class="table__info-column"
      mat-cell
      *matCellDef="let user; dataSource: fieldUsers?.items"
    >
      <ng-container *ngIf="user.totalAmount; else empty">
        {{ user.totalAmount | USDCurrency }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="lastActivity">
    <th
      [protctcSortButton]="sortFields.LastActivity"
      mat-header-cell
      *matHeaderCellDef
    >
      Last activity
    </th>
    <td
      class="table__info-column"
      mat-cell
      *matCellDef="let user; dataSource: fieldUsers?.items"
    >
      {{ user.lastLoginDate | EnUsDate | protctcNullable }}
    </td>
  </ng-container>

  <tr class="mat-row" *matNoDataRow>
    <td *ngIf="fieldUsers?.items" class="mat-cell" colspan="4">
      No field users matched your criteria
    </td>
  </tr>

  <tr
    class="table__row"
    [class.highlighted]="row.hasSentInvoice"
    mat-row
    *matRowDef="let row; dataSource: fieldUsers?.items; columns: columns"
  ></tr>
</protctc-table>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

import { Injectable } from '@angular/core';

import { ContentType, ExistingContentTypes } from '../enums/content-type';

/**
 * Helper service for work with files.
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {

  /**
   * Get file name without its extension.
   * Example:
   * drowpdown.png -> dropdown
   * my.doc.png -> my.doc
   * etc.
   * @param file File.
   */
  public extractName(file: File): string {
    return file.name
      .split('.')
      .slice(0, -1)
      .join('.');
  }

  /**
   * Extract extension of the file.
   * Example:
   * dropdown.png -> png
   * my.doc.pdf -> pdf
   * etc.
   * @param file File.
   */
  public extractExtension(file: File): string {
    return file.name
      .split('.')
      .slice(-1)
      .join('');
  }

  /**
   * Get content type from file.
   * @param file File.
   */
  public fromFile(file: File): ContentType {

    /**
     * We doing so because all file types represented in form like 'image/jpeg' | 'video/mp4'.
     * Because of that first word before slash always represent content type of current file.
     * For more info check https://www.iana.org/assignments/media-types/media-types.xhtml.
     */
    const fileContentType = file.type.split('/')[0] as ExistingContentTypes;
    return ContentType.SUPPORTED_CONTENT_TYPES[fileContentType];
  }
}

import { Observable, of } from 'rxjs';

import { AsyncPaginationOptions } from '../utils/paginate';

import { Pagination } from './pagination';
import { PaginationData } from './pagination-data';
import { PaginationOptions } from './pagination-options';
import { SearchPaginationOptions } from './search-pagination-options';

export type ToReadableFunction<T> = (object: T) => string;
export type ComparatorFunction<T> = (object1: T, object2: T) => boolean;
export type PaginationQueryFunction<T> = (options: SearchPaginationOptions) => Observable<Pagination<T>>;
export type TrackByFunction<T> = (_: number, item: T) => string | number;

/** Configuration for autocomplete-like components. */
export interface AutocompleteConfiguration<TItem> {

  /** Function that fetches the value with provided pagination options. */
  readonly fetch: PaginationQueryFunction<TItem>;

  /** Comparator function. */
  readonly comparator: ComparatorFunction<TItem>;

  /**
   * Function that makes option human-readable.
   * @param item Option.
   */
  readonly toReadable: ToReadableFunction<TItem>;

  /** Track by function. */
  readonly trackBy: TrackByFunction<TItem>;
}

export namespace AutocompleteConfiguration {

  export const PAGINATION_DEFAULT_DATA: PaginationData = {
    pageSize: 10,
    page: 0,
  };

  export const PAGINATION_DEFAULTS: AsyncPaginationOptions<PaginationOptions> = {
    paginationData: of(PAGINATION_DEFAULT_DATA),
  };
}

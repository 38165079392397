import { Directive, ElementRef, AfterViewInit } from '@angular/core';

/** Directive to add asterisk to required field, which we use without mat-form-field. */
@Directive({
  selector: 'mat-label[protctcAddAsteriskToRequiredField]',
})
export class AddAsteriskToRequiredFieldDirective implements AfterViewInit {

  public constructor(
    private elementRef: ElementRef,
  ) { }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    const labelElement = this.elementRef.nativeElement;

    if (labelElement) {
      const asteriskElement = document.createElement('span');
      asteriskElement.className = 'asterisk';
      asteriskElement.textContent = '*';
      labelElement.appendChild(asteriskElement);

      const tooltipElement = document.createElement('mat-tooltip');
      tooltipElement.setAttribute('matTooltip', 'Required');
      labelElement.appendChild(tooltipElement);
    }
  }

}

import { Injectable } from '@angular/core';

import { DashboardChartConstructorData, DashboardChartData } from '../../models/dashboard-chart-data';

import { DashboardChartDataDto } from './dto/dashboard-chart-data-dto';
import { IMapperFromDto } from './mappers';

/** Dashboard chart data mapper. */
@Injectable({
  providedIn: 'root',
})
export class DashboardChartDataMapper
implements IMapperFromDto<DashboardChartDataDto, DashboardChartData> {

  /** @inheritdoc */
  public fromDto(data: DashboardChartDataDto): DashboardChartData {
    return new DashboardChartData(this.mapToDashboardChartConstructorData(data));
  }

  private mapToDashboardChartConstructorData(
    data: DashboardChartDataDto,
  ): DashboardChartConstructorData {
    return {
      label: data.label,
      allTransaction: data.all_transaction,
      creditCardTransaction: data.cc_transaction,
      creditCardFeeSaved: data.cc_fee_saved,
    };
  }
}

<ng-container *ngIf="paymentsPage$ | async as paymentsPage; else loading">
  <protctc-table
    *ngIf="paymentsPage.hasItems; else empty"
    [page]="paymentsPage"
    [displayedColumns]="displayedColumns"
    [paginationFilters]="
      (currentPaginationData$ | async) ?? defaultPaginationData
    "
    [showFirstLastButtons]="false"
    [hasNextPage]="paymentsPage.hasMore"
    (pageChange)="currentPaginationData$.next($event)"
    [protctcSort]="currentSortOptions$ | async"
    (protctcSortChange)="currentSortOptions$.next($event)"
  >

    <ng-container [matColumnDef]="paymentHistorySortField.Date">
      <th
      mat-header-cell
      *matHeaderCellDef
      [protctcSortButton]="paymentHistorySortField.Date"
        >Date</th
      >
      <td mat-cell *matCellDef="let data">
        {{ data.date | date : "MM/dd/yyyy" }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="paymentHistorySortField.TransactionId">
      <th
        mat-header-cell
        *matHeaderCellDef
        [protctcSortButton]="paymentHistorySortField.TransactionId"
      >
        Transaction ID
      </th>
      <td mat-cell *matCellDef="let data; dataSource: paymentsPage.items">
        {{ data.id }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="paymentHistorySortField.Detail">
      <th
        mat-header-cell
        *matHeaderCellDef
        [protctcSortButton]="paymentHistorySortField.Detail"
      >
      Details
      </th>
      <td mat-cell *matCellDef="let data">
        {{ data.detail}}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="paymentHistorySortField.Amount">
      <th
        mat-header-cell
        *matHeaderCellDef
        [protctcSortButton]="paymentHistorySortField.Amount"
      >
        Amount
      </th>
      <td mat-cell *matCellDef="let data; dataSource: paymentsPage.items">
        {{ data.amount | USDCurrency }}
      </td>
    </ng-container>
  </protctc-table>
</ng-container>

<ng-template #loading>
  <protctc-page-loader
  class="loader"
  [diameter]="40"></protctc-page-loader>
</ng-template>

<ng-template #placeholder>&mdash;</ng-template>

<ng-template #empty>
  <protctw-empty-table-preview
    title="No payments history"
  ></protctw-empty-table-preview>
</ng-template>

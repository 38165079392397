/* eslint-disable @typescript-eslint/member-ordering */
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  TemplateRef,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  AutocompleteConfiguration,
  ToReadableFunction,
  TrackByFunction,
} from '@protctc/common/core/models/autocomplete-configuration';
import { PaginationData } from '@protctc/common/core/models/pagination-data';
import { listenControlChanges } from '@protctc/common/core/rxjs/listen-control-changes';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { DestroyableComponent } from '@protctc/common/core/utils/destroyable';
import { paginate } from '@protctc/common/core/utils/paginate';
import { ViewContext } from '@protctc/common/core/utils/types/view-context';
import {
  controlProviderFor,
  SimpleValueAccessor,
} from '@protctc/common/core/utils/value-accessor';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  switchMap,
} from 'rxjs';

interface AutocompleteOptionViewContext<T> {

  /** Option. */
  readonly option: T;

  /** Option name. */
  readonly optionName: string;
}

/** The list of entities should be displayed only after entering this count of characters. */
const MIN_SEARCH_TERM_LENGTH = 2;

/**
 * Autocomplete component.
 * @example
 * ```html
 *  <protctw-autocomplete
 *    placeholder="Customer"
 *    formControlName="customer"
 *    [configuration]="customerAutocompleteConfig"
 *  >
 *  </protctw-autocomplete>
 * ```
 * Where `autoCompleteConfig` is
 * ```ts
 * class SomeComponent {
 *  // ...
 *  public readonly customerAutocompleteConfig: AutocompleteConfiguration<Customer>;
 *  public constructor(...) {
 *   this.customerAutocompleteConfig = {
 *     fetch: options => this.customerService.getCustomers({ options })),
 *     comparator: Customer.compare,
 *     toReadable: Customer.toReadable,
 *   };
 *  }
 * }
 * ```
 */
@DestroyableComponent()
@Component({
  selector: 'protctc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(AutocompleteComponent)],
})
export class AutocompleteComponent<T> extends SimpleValueAccessor<T | string> {
  /** Placeholder text. */
  @Input()
  public placeholder = '';

  /** Autocomplete field icon. */
  @Input()
  public icon = '';

  /** Required flag. */
  @Input()
  public required = false;

  /** Autocomplete configuration. */
  @Input()
  public set configuration(config: AutocompleteConfiguration<T> | null) {
    if (config !== null) {
      this.configuration$.next(config);
    }
  }

  /** Is data loading. */
  @Input()
  public isLoading: boolean | null = false;

  /** Autocomplete has pagination. */
  @Input()
  public hasPagination = false;

  /** Option template. */
  @Input()
  public template: TemplateRef<unknown> | null = null;

  /**
   * Set the value only when selected in autocomplete.
   * Disable changing the control value from text input.
   */
  @Input()
  public isDisableChangeValueFromInput = false;

  /** Search value control. */
  public readonly filterValueControl =
    this.formBuilder.controlTyped<string>('');

  /** Fetched objects. */
  public readonly data$: Observable<readonly T[] | null>;

  /** Total count. */
  public readonly totalCount$ = new BehaviorSubject<number>(0);

  /** Function, obtained from configuration, makes T item human-readable. */
  public readonly toReadable$: Observable<ToReadableFunction<T | null>>;

  /** Function, obtained from configuration, track list of entities. */
  public readonly trackBy$: Observable<TrackByFunction<T>>;

  private readonly configuration$ = new ReplaySubject<AutocompleteConfiguration<T>>(1);

  /** Indicates if there are more items available with the same search terms. */
  public readonly hasMoreItems$ = new ReplaySubject<boolean>(1);

  /** Pagination data. */
  private readonly paginationData$ = new BehaviorSubject<PaginationData>(
    AutocompleteConfiguration.PAGINATION_DEFAULT_DATA,
  );

  /** Minimal length of search term to display autocomplete options. */
  public readonly minSearchTermLength = MIN_SEARCH_TERM_LENGTH;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    super(changeDetectorRef);
    this.data$ = this.initDataStream();
    this.toReadable$ = this.initToReadableStream();
    this.trackBy$ = this.initTrackByStream();
  }

  /** @inheritdoc */
  public override writeValue(data: T | string | null): void {
    super.writeValue(data);

    if (data) {
      if (typeof data === 'string') {
        this.filterValueControl.setValue(data);
        return;
      }
    }
    this.filterValueControl.setValue('');
  }

  /**
   * Handles autocomplete change.
   * @param data Autocomplete data.
   */
  public onChange(data: string | T): void {
    if (typeof data === 'string') {
      this.filterValueControl.setValue(data);
    } else {
      this.filterValueControl.setValue('');
    }
    if (typeof data === 'string' && this.isDisableChangeValueFromInput && data === '') {
      this.controlValue = data;
    }
    if (typeof data === 'string' && this.isDisableChangeValueFromInput) {
      return;
    }
    this.controlValue = data;
  }

  /**
   * On blur handler.
   * @param event Event.
   * Event need to understand that the blur was on mat-option item,
   * so as not to highlight the field.
   * Https://github.com/angular/components/issues/19536.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onInputBlur(event: any): void {
    if (event?.relatedTarget && event.relatedTarget?.tagName === 'MAT-OPTION') {
      // the input was blurred, but the user is still interacting with the component, they've simply
      // selected a mat-option
      return;
    }
    if (!this.isTouched) {
      this.emitTouched();
    }
  }

  /** Autocomplete scroll handler. */
  public autocompleteScroll(): void {
    if (
      this.hasPagination
    ) {
      if (this.paginationData$.value.pageSize < this.totalCount$.value) {
        this.hasMoreItems$.next(true);
        this.paginationData$.next({
          pageSize: this.paginationData$.value.pageSize + AutocompleteConfiguration.PAGINATION_DEFAULT_DATA.pageSize,
          page: AutocompleteConfiguration.PAGINATION_DEFAULT_DATA.page,
        });
      } else {
        this.hasMoreItems$.next(false);
      }
    }
  }

  /**
   * Get option view context to injection.
   * @param option Option.
   * @param optionName Option name.
   */
  public getOptionViewContext(option: T, optionName: string): ViewContext<AutocompleteOptionViewContext<T>> {
    return {
      $implicit: {
        option,
        optionName,
      },
    };
  }

  private initDataStream(): Observable<readonly T[] | null> {
    return this.configuration$.pipe(
      switchMap(configuration => {
        const paginationOptions = this.hasPagination ?
          {
            paginationData: this.paginationData$.pipe(
              distinctUntilChanged(PaginationData.compare),
            ),
          } :
          AutocompleteConfiguration.PAGINATION_DEFAULTS;
        return listenControlChanges<string>(this.filterValueControl).pipe(
          filter(value => value.length >= this.minSearchTermLength),
          switchMap(searchTerm => paginate({
            ...paginationOptions,
              searchString: of(searchTerm),
          },
            options => {
              assertNonNull(configuration.fetch);
              return configuration.fetch(options);
            })),
        );
      }),
      map(page => {
        const data = page?.items.slice() ?? null;
        this.totalCount$.next(page.totalCount);
        return data;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private initTrackByStream(): Observable<TrackByFunction<T>> {
    return this.configuration$.pipe(
      map(({ trackBy }) => (_: number, value: T) => trackBy(_, value)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private initToReadableStream(): Observable<ToReadableFunction<T | null>> {
    return this.configuration$.pipe(
      map(({ toReadable }) => (value: T | null) => {
        if (value !== null) {
          return toReadable(value);
        }
        return '';
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}

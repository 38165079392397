import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonSharedModule } from '@protctc/common/shared/common-shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortModule } from '@protctc/common/shared/components/sort/sort.module';
import { YesNoPipe } from '@protctc/common/shared/pipes/yes-no.pipe';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { MainLogoComponent } from './components/main-logo/main-logo.component';
import { HeaderPageLayoutComponent } from './layouts/header-page-layout/header-page-layout.component';
import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';
import { DatepickerModule } from './datepicker/datepicker.module';
import { EmptyTablePreviewComponent } from './components/empty-table-preview/empty-table-preview.component';
import { InvoiceFilterDialogComponent } from './components/invoice-filter-dialog/invoice-filter-dialog.component';
import { UserSaveFormComponent } from './components/user-save-form/user-save-form.component';
import { InvoicesTableComponent } from './components/invoices-table/invoices-table.component';
import { AdminInvoiceFilterDialogComponent } from './components/admin-invoice-filter-dialog/admin-invoice-filter-dialog.component';
import { CompanyGeneralInfoComponent } from './components/company-general-info/company-general-info.component';
import { PaymentsTableComponent } from './components/payments-table/payments-table.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { InvoicePdfDownloadButtonComponent } from './components/invoice-pdf-download-button/invoice-pdf-download-button.component';
import { RefundInvoiceButtonComponent } from './components/refund-invoice-button/refund-invoice-button.component';
import { FieldUsersTableComponent } from './components/field-users-table/field-users-table.component';
import { DashboardStatisticCardComponent } from './components/dashboard-statistics-card/dashboard-statistic-card.component';
import { DashboardStatisticsCardListComponent } from './components/dashboard-statistics-card-list/dashboard-statistics-card-list.component';
import { AdminInvoicesTableComponent } from './components/admin-invoices-table/admin-invoices-table.component';
import { MerchantPartnersTableComponent } from './components/merchant-partners-table/merchant-partners-table.component';
import { AttachedFileListComponent } from './components/attached-file-list/attached-file-list.component';
import { MerchantHeaderComponent } from './layouts/merchant-header/merchant-header.component';
import { RecurringInvoicesTableComponent } from './components/recurring-invoices-table/recurring-invoices-table.component';

const EXPORTED_DECLARATIONS = [
  BreadcrumbsComponent,
  PageLayoutComponent,
  EmptyTablePreviewComponent,
  InvoiceFilterDialogComponent,
  UserSaveFormComponent,
  InvoicesTableComponent,
  AdminInvoiceFilterDialogComponent,
  CompanyGeneralInfoComponent,
  PaymentsTableComponent,
  UsersTableComponent,
  InvoicePdfDownloadButtonComponent,
  RefundInvoiceButtonComponent,
  DashboardStatisticCardComponent,
  FieldUsersTableComponent,
  DashboardStatisticsCardListComponent,
  AdminInvoicesTableComponent,
  MerchantPartnersTableComponent,
  AttachedFileListComponent,
  MerchantHeaderComponent,
  HeaderPageLayoutComponent,
  RecurringInvoicesTableComponent,
];

const EXPORTED_MODULES = [
  CommonSharedModule,
  ReactiveFormsModule,
  FormsModule,
  DatepickerModule,
];

/** Shared module. */
@NgModule({
  declarations: [
    MainLayoutComponent,
    SidebarComponent,
    ...EXPORTED_DECLARATIONS,
  ],
  imports: [
    YesNoPipe,
    CommonModule,
    RouterModule,
    SortModule,
    MainLogoComponent,
    ...EXPORTED_MODULES,
  ],
  exports: [...EXPORTED_DECLARATIONS, ...EXPORTED_MODULES],
})
export class SharedModule { }

import { InvoiceStatus } from '../enums/invoice-status';
import { PaymentMethod } from '../enums/payment-method';

import { RecurringSchedule } from './recurring-schedule/recurring-schedule';

/** Data needed for the construction of invoice short model. */
export type InvoiceShortConstructorData = InvoiceShort;

/** Invoice short model. */
export class InvoiceShort {

  /** Id. */
  public readonly id: number;

  /** Status. */
  public readonly status: InvoiceStatus;

  /** Customer name. */
  public readonly customerName: string;

  /** Created date. */
  public readonly createdDate: Date;

  /** Due date. */
  public readonly dueDate?: Date;

  /** Amount. */
  public readonly amount: number;

  /** Payment method. */
  public readonly paymentMethod: PaymentMethod;

  /** Cardinal number of this invoice in the company. */
  public readonly cardinal: number;

  /** Fee saved. */
  public readonly savedFee: number;

  /** Transaction id. */
  public readonly transactionId: string;

  /** Work details. */
  public readonly workDetails: string[] = [];

  /** Invoice prefix. */
  public readonly invoicePrefix: string;

  /** Invoice number. */
  public readonly invoiceNumber: number;

  /** Recurring schedule information. */
  public readonly recurringSchedule: RecurringSchedule | null;

  public constructor(data: InvoiceShortConstructorData) {
    this.id = data.id;
    this.workDetails = data.workDetails;
    this.status = data.status;
    this.customerName = data.customerName;
    this.createdDate = data.createdDate;
    this.dueDate = data.dueDate;
    this.amount = data.amount;
    this.paymentMethod = data.paymentMethod;
    this.cardinal = data.cardinal;
    this.savedFee = data.savedFee;
    this.transactionId = data.transactionId;
    this.invoiceNumber = data.invoiceNumber;
    this.invoicePrefix = data.invoicePrefix;
    this.recurringSchedule = data.recurringSchedule;
  }
}

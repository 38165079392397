<div class="file-picker">
  <div [class.visually-hidden]="controlValue !== null">
    <div class="file-picker__container">
      <input
        #fileInput
        type="file"
        [id]="currentInputId"
        class="file-picker__input"
        [accept]="isImageOnly ? 'image/*' : '*'"
        (change)="onChange(fileInput)"
      >
      <label [for]="currentInputId" class="file-picker__upload-button button stroked">Upload</label>
    </div>
  </div>
  <div *ngIf="controlValue !== null" class="file-picker__filled">
    <button
      type="button"
      (click)="fileInput.click()"
      class="file-picker__upload-button button stroked"
    >
      Upload
    </button>
    <ul class="file-picker__file-list">
      <li class="file-picker__file-item file-item">
        <div class="file-item__info">
          <protctc-image-uploader-preview
           *ngIf="isImageOnly; else iconPreview"
            class="file-item__preview"
            [src]="controlValue"
          ></protctc-image-uploader-preview>
          <span class="file-item__name">{{ controlValue.name || 'Business Logo' }}</span>
        </div>
        <button
          type="button"
          (click)="removeFile()"
          class="file-item__delete-button"
          mat-icon-button
        >
          <mat-icon class="file-item__delete-icon" svgIcon="delete"></mat-icon>
        </button>
      </li>
    </ul>
  </div>
</div>

<ng-template #iconPreview>
  <mat-icon class="file-item__default-icon" svgIcon="file"></mat-icon>
</ng-template>


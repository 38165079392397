import { Injectable } from '@angular/core';

import { State } from '../../models/state';

import { StateDto } from './dto/state-dto';
import { IMapperFromDto } from './mappers';

/**
 * Mapper for US State entities.
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class StateMapper implements IMapperFromDto<StateDto, State> {

  /** @inheritdoc */
  public fromDto(data: StateDto): State {
    return {
      code: data.code,
      name: data.name,
    };
  }
}

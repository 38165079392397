import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@protctc/common/shared/material.module';

import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from './custom-date-adapter';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';

/**
 * Datepicker module.
 * Contains datepicker component and custom date adapter for format customization.
 */
@NgModule({
  declarations: [DateRangePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [DateRangePickerComponent],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class DatepickerModule { }

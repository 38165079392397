import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from '@protctc/common/core/interceptors';
import { FileUploadService } from '@protctc/common/core/services/file-upload';
import { S3UploadService } from '@protctc/common/core/services/s3-upload.service';
import { WINDOW_PROVIDERS } from '@protctc/common/core/services/window.service';
import { CommonSharedModule } from '@protctc/common/shared/common-shared.module';
import { DialogsModule } from '@protctc/common/shared/dialogs/dialogs.module';
import { DEFAULT_DIALOG_HOST_PROVIDER } from '@protctc/common/shared/dialogs/dialogs.service';
import '@saritasa/angular-typed-forms';

import { AuthService } from '@protctc/common/core/services/auth.service';

import { CurrencyPipe, DatePipe } from '@angular/common';

import { IMaskPipe } from 'angular-imask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';

/** Base app module. */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonSharedModule,
    SharedModule,
    DialogsModule,
  ],
  providers: [
    ...WINDOW_PROVIDERS,
    ...httpInterceptorProviders,
    AuthService,
    CurrencyPipe,
    DatePipe,
    IMaskPipe,
    DEFAULT_DIALOG_HOST_PROVIDER,
    {
      provide: FileUploadService,
      useClass: S3UploadService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

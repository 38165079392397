import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CompanyFieldUserSortField } from '@protctc/common/core/enums/company-field-user-sort-field';
import { CompanyFieldUser } from '@protctc/common/core/models/company-field-user';
import { Pagination } from '@protctc/common/core/models/pagination';
import { PaginationData } from '@protctc/common/core/models/pagination-data';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { routePaths } from '@protctc/common/core/utils/route-paths';
import { DEFAULT_PAGE_SIZE_OPTIONS, DEFAULT_PAGINATION_DATA } from '@protctc/common/shared/components/table/table.component';

/** Field users table. */
@Component({
  selector: 'protctw-field-users-table',
  templateUrl: './field-users-table.component.html',
  styleUrls: ['./field-users-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldUsersTableComponent {

  /** Field users. */
  @Input()
  public fieldUsers: Pagination<CompanyFieldUser> | null = null;

  /** Sort options. */
  @Input()
  public sortOptions: SortOptions<CompanyFieldUserSortField> | null = null;

  /** Pagination data. */
  @Input()
  public paginationData: PaginationData = DEFAULT_PAGINATION_DATA;

  /** Page size options. */
  @Input()
  public pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;

  /** Change page emitter. */
  @Output()
  public readonly pageChanged = new EventEmitter<PaginationData>();

  /** Change sort emitter. */
  @Output()
  public readonly sortChanged = new EventEmitter<SortOptions<CompanyFieldUserSortField>>();

  /** Route paths. */
  protected readonly routePaths = routePaths;

  /** Columns. */
  protected readonly columns = ['name', 'invoiceCount', 'totalAmount', 'lastActivity'];

  /** Users sort fields. */
  protected readonly sortFields = CompanyFieldUserSortField;
}

/**
 * User role.
 * @deprecated
 */
export enum UserRole {

  /** Business owner. */
  BusinessOwner = 'BO',

  /** Field User. */
  FieldUser = 'FU',

  /** Super Admin. */
  SuperAdmin = 'SA',
}

/**
 * Namespace for user roles.
 * Contains util methods and props for easier enum use.
 */
export namespace UserRole {

  const READABLE_MAP: Record<UserRole, string> = {
    [UserRole.BusinessOwner]: 'Business owner',
    [UserRole.FieldUser]: 'Field user',
    [UserRole.SuperAdmin]: 'Super admin',
  };

  /**
   * Return human-readable role representation.
   * @param role User role.
   */
  export function toReadable(role: UserRole): string {
    return READABLE_MAP[role];
  }

  /**
   * Get list available user roles for save.
   */
  export function getOnlySaveUserRoles(): UserRole[] {
    return UserRole.toArray()
      .filter(role => role !== UserRole.SuperAdmin);
  }

  /** Get list for all user roles. */
  export function toArray(): UserRole[] {
    const enumType = typeof UserRole.BusinessOwner;
    return Object.values(UserRole)
      .filter(role => typeof role === enumType)
      .map(role => role as UserRole);
  }
}

import {
  Directive,
  Inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

/** Let context. */
export class LetContext<T> {
  public constructor(private readonly dir: LetDirective<T>) {}

  /** Get ng let. */
  public get ngLet(): T {
    return this.dir.ngLet;
  }
}

/**
 * Works like *ngIf but does not have a condition
 * Use it to declare the result of pipes calculation
 * (i.e. Async pipe).
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngLet]',
})
export class LetDirective<T> {

  /** Ng let. */
  @Input()
  public ngLet!: T;

  public constructor(
  @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
    @Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>,
  ) {
    viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
  }
}

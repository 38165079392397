import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { PaymentHistorySortField } from '../enums/payment-history-sort-field';

import { CardDetails } from '../models/card-details';
import { Company } from '../models/company/company';
import { PayrixPagination } from '../models/pagination/payrix-pagination';
import { PaymentHistoryPaginationOptions } from '../models/payment-history-pagination-options';
import { PaymentTransaction } from '../models/payment/payment-transaction';
import { composeHttpParams } from '../utils/compose-http-params';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { CardDetailsMapper } from './mappers/card-details.mapper';
import { DateRangeMapper } from './mappers/date-range/date-range.mapper';
import { CardDetailsDto } from './mappers/dto/card-details-dto';
import { PayrixPaginationDto } from './mappers/dto/pagination/payrix-pagination.dto';
import { PaymentHistoryPaginationOptionsDto } from './mappers/dto/payment/payment-history-pagination-options.dto';
import { PaymentTransactionDto } from './mappers/dto/payment/payment-transaction.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { PaymentTransactionMapper } from './mappers/payment-transaction.mapper';
import { SortMapper } from './mappers/sort.mapper';

/** Mapper to map payments sort field from domain to local. */
const PAYMENT_HISTORY_SORT_FIELD_MAP: Readonly<Record<PaymentHistorySortField, string>> = {
  [PaymentHistorySortField.Date]: 'date',
  [PaymentHistorySortField.TransactionId]: 'transaction_id',
  [PaymentHistorySortField.Amount]: 'amount',
  [PaymentHistorySortField.Detail]: 'detail',
};

/**
 * Billing and payment service.
 * Provide ability to work with billing and payment entities.
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class BillingPaymentService {

  private readonly paymentMethodInfoUrl: string;

  public constructor(
    private readonly appConfigService: AppConfigService,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly paginationMapper: PaginationMapper,
    private readonly sortMapper: SortMapper,
    private readonly paymentTransactionMapper: PaymentTransactionMapper,
    private readonly httpClient: HttpClient,
    private readonly cardDetailsMapper: CardDetailsMapper,
    private readonly dateRangeMapper: DateRangeMapper,
  ) {
    this.paymentMethodInfoUrl = new URL('companies/payment_method_info/', this.appConfigService.apiUrl).toString();

  }

  /** Init payment method information of current company. */
  public getPaymentMethodInformation(): Observable<CardDetails | null> {
    return this.httpClient.get<CardDetailsDto>(this.paymentMethodInfoUrl).pipe(
      map(cardDetailsDto => this.isEmptyCardDetailsDto(cardDetailsDto) ? null : this.cardDetailsMapper.fromDto(cardDetailsDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get a page of the payments.
   * @param options Pagination options.
   */
  public getPayments(options: PaymentHistoryPaginationOptions): Observable<PayrixPagination<PaymentTransaction>> {
    const url = this.getPaymentTransactionUrl(options.id);

    const pagination: PaymentHistoryPaginationOptionsDto = {
      id: options.id,
      ...this.paginationMapper.mapOptionsToPayrixOptionsDto(options),
      ...this.dateRangeMapper.toDto(options.dateRange),
      ordering: this.sortMapper.mapSortOptionsToDto(options.sortOptions, PAYMENT_HISTORY_SORT_FIELD_MAP),
    };

    const params = composeHttpParams(pagination);

    return this.httpClient.get<PayrixPaginationDto<PaymentTransactionDto>>(
      url.toString(),
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPayrixPaginationFromDto(page, options, this.paymentTransactionMapper)),
    );
  }

  private isEmptyCardDetailsDto(cardDetailsDto: CardDetailsDto): boolean {
    return (
      (cardDetailsDto.card_name === undefined || cardDetailsDto.card_name === '') &&
      cardDetailsDto.card_number === '' &&
      cardDetailsDto.card_exp === ''
    );
  }

  private getPaymentTransactionUrl(id: Company['id']): URL {
    return new URL(
      `companies/company/${id}/payment_history/`, this.appConfigService.apiUrl,
    );
  }
}

import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';

/** Directive to focus input. */
@Directive({
  selector: 'input[protctcFocusInput]',
})
export class FocusInputDirective implements AfterViewInit {

  /** Should input be focused. */
  @Input()
  public protctcFocusInput = false;

  public constructor(
    private elementRef: ElementRef,
  ) { }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    if (!this.protctcFocusInput) {
      return;
    }
    const inputElement = this.elementRef.nativeElement;
    if (inputElement) {
      inputElement.focus();
    }
  }
}

import { Location } from '@angular/common';
import { Directive, HostListener } from '@angular/core';

/** Handle click event of the element to back navigation. */
@Directive({ selector: 'button[protctcNavigationBack]' })
export class NavigationBackDirective {

  public constructor(
    private readonly location: Location,
  ) { }

  /**
   * Navigate to back.
   * @param event Click event.
   */
  @HostListener('click', ['$event'])
  public goBack(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.location.back();
  }
}

<ng-container *ngIf="data; else loading">
  <section class="section">
    <h2 class="section__header page-content-title">Business information</h2>

    <div class="section__content">
      <div class="row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Business legal name</p>
          <p class="content-item__value">{{ data.name | protctcNullable }}</p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Business DBA name</p>
          <p class="content-item__value">{{ data.dba | protctcNullable }}</p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">
            Federal Employer Identification Number (FEIN)
          </p>
          <p class="content-item__value">
            {{ data.ein | imask : masks.taxNumberMask | protctcNullable }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="section__content-item content-item">
          <p class="content-item__title">MCC directory</p>
          <p class="content-item__value">
            {{ data.merchantCategoryCode?.mcc | protctcNullable }}
            <span *ngIf="data.merchantCategoryCode?.description as desc">
              ({{ desc }})
            </span>
          </p>
        </div>
      </div>

      <div class="custom-grid-row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Date business open</p>
          <p class="content-item__value">
            {{ data.openDate | date : "MM/dd/YYYY" | protctcNullable }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Refund policy</p>
          <p class="content-item__value">{{ data.refundPolicy.key }}</p>
        </div>
      </div>

      <div class="row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Email</p>
          <p class="content-item__value">
            <a href="mailto:{{ data.email }}">{{ data.email }}</a>
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Phone number</p>
          <p class="content-item__value">
            {{ data.phone | imask : masks.phoneNumber | protctcNullable }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Fax number</p>
          <p class="content-item__value">
            {{ data.fax | imask : masks.phoneNumber | protctcNullable }}
          </p>
        </div>
      </div>

      <div class="section__content-item content-item">
        <p class="content-item__title">Website URL</p>
        <p class="content-item__value">
          <a *ngIf="data.url; else empty" [href]="data.url" target="_blank" rel="noopener noreferrer">
            {{ data.url }}
          </a>
        </p>
      </div>

      <div class="custom-grid-row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Company type</p>
          <p class="content-item__value">
            {{ data.companyType.key | protctcNullable }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Seasonal status</p>
          <p class="content-item__value">
            {{ data.seasonalStatus | protctcYesNo }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Public company</p>
          <p class="content-item__value">
            {{ data.isPublic| protctcYesNo }}
          </p>
        </div>
      </div>

      <div class="section__content-item content-item">
        <p class="content-item__title">Services</p>
        <p class="content-item__value">
          {{ data.businessService | protctcNullable }}
        </p>
      </div>

      <div class="custom-grid-row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Business address</p>
          <p class="content-item__value">
            {{ data.address.line1Address }}
            <br />
            <span *ngIf="data.address.line2Address">
              {{ data.address.line2Address | protctcNullable }}
              <br />
            </span>
            {{ data.address.city }},
            {{ data.address.state.code }}
            {{ data.address.zip }}
          </p>
        </div>
      </div>
      <div class="custom-grid-row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Annual CC sales</p>
          <p class="content-item__value">
            {{ data.annualCCSales | USDCurrency }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Average ticket amount</p>
          <p class="content-item__value">
            {{ data.ticketAverage | USDCurrency }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="section">
    <h2 class="section__header page-content-title">Contact information</h2>

    <div class="section__content">
      <div class="row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Contact name</p>
          <p class="content-item__value">
            {{ data.contactInfo?.firstName | protctcNullable }}
            <span *ngIf="data.contactInfo?.lastName as lastName">
              {{ lastName | protctcNullable }}
            </span>
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Title</p>
          <p class="content-item__value">
            {{ data.contactInfo?.title | protctcNullable }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Email</p>
          <p class="content-item__value">
            {{ data.contactInfo?.email | protctcNullable }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Phone number</p>
          <p class="content-item__value">
            {{
            data.contactInfo?.phone ?? ""
            | imask : masks.phoneNumber
            | protctcNullable
            }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="section">
    <h2 class="section__header page-content-title">
      Business owners information
    </h2>
    <div class="section__content" *ngFor="let businessOwner of data.businessOwners; let i=index;">
      <div class="section__content">
        <h2>Business owner {{i+1}}</h2>
        <div class="row">
          <div class="section__content-item content-item">
            <p class="content-item__title">Contact name</p>
            <p class="content-item__value">
              {{ businessOwner.firstName | protctcNullable }}
              <span *ngIf="businessOwner.lastName as lastName">
                {{ lastName | protctcNullable }}
              </span>
            </p>
          </div>
          <div class="section__content-item content-item">
            <p class="content-item__title">Title</p>
            <p class="content-item__value">
              {{ businessOwner.title | protctcNullable }}
            </p>
          </div>
          <div class="section__content-item content-item">
            <p class="content-item__title">Social Security Number</p>
            <p class="content-item__value">
              {{ businessOwner.ssn | protctcHideWithStars }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="section__content-item content-item">
            <p class="content-item__title">Email</p>
            <p class="content-item__value">
              {{ businessOwner.email | protctcNullable }}
            </p>
          </div>
          <div class="section__content-item content-item">
            <p class="content-item__title">Phone number</p>
            <p class="content-item__value">
              {{
              businessOwner?.phone ?? ""
              | imask : masks.phoneNumber
              | protctcNullable
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="section__content-item content-item">
        <p class="content-item__title">Owner birth date</p>
        <p class="content-item__value">
          {{
          businessOwner.birthDate | date : "MM/dd/YYYY" | protctcNullable
          }}
        </p>
      </div>
      <div class="row">
        <div class="section__content-item content-item" *ngIf="businessOwner.address as businessOwnerAddress">
          <p class="content-item__title">Contact address</p>
          <p class="content-item__value">
            {{ businessOwnerAddress.line1Address | protctcNullable }}
            <br />
            <span *ngIf="businessOwnerAddress.line2Address">
              {{ businessOwnerAddress.line2Address | protctcNullable }}
              <br />
            </span>
            {{ businessOwnerAddress.city }},
            {{ businessOwnerAddress.state.code }}
            {{ businessOwnerAddress.zip }}
          </p>
        </div>
      </div>

      <div class="section__content-item section__content-item_driver content-item">
        <p class="content-item__title">Driver license</p>
        <div class="content-item__value">
          <ng-container *ngIf="businessOwner.documents.length > 0; else empty">
            <protctw-attached-file-list [files]="businessOwner.documents"></protctw-attached-file-list>
          </ng-container>
        </div>
      </div>
      <div class="custom-grid-row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Driver license state</p>
          <p class="content-item__value">
            {{ businessOwner.driverLicenseState.code | protctcNullable }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Driver license number</p>
          <p class="content-item__value">
            {{ businessOwner.driverLicenseNumber | protctcNullable }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Ownership percentage</p>
          <p class="content-item__value">
            {{ businessOwner.ownership }}%
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Primary contact</p>
          <p class="content-item__value">
            {{ businessOwner.isPrimaryContact| protctcYesNo }}
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="section bank-section">
    <h2 class="section__header page-content-title">Bank information</h2>

    <div class="section__content">
      <div class="row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Bank account information</p>
          <p class="content-item__value">
            {{ data.bankAccount.name | protctcNullable }}
            <br />
            Bank account ends in
            {{ data.bankAccount.accountNumber | protctcHideWithStars }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="section__content-item content-item">
          <p class="content-item__title">Account type</p>
          <p class="content-item__value">
            {{ data.bankAccount.payrixAccountType | protctcAccountType }}
          </p>
        </div>
        <div class="section__content-item content-item">
          <p class="content-item__title">Account method</p>
          <p class="content-item__value">
            {{ data.bankAccount.payrixAccountPaymentMethod | protctcAccountPaymentMethod }}
          </p>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<ng-template #loading>
  <protctc-page-loader></protctc-page-loader>
</ng-template>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

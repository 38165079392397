import { Pipe, PipeTransform } from '@angular/core';
import { PayrixAccountType } from '@protctc/common/core/enums/payrix-account-type';

/** To readable account type pipe. */
@Pipe({
  name: 'protctcAccountType',
})
export class AccountTypePipe implements PipeTransform {

  /** @inheritdoc */
  public transform(type: PayrixAccountType): string {
    return PayrixAccountType.toReadable(type);
  }
}

import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { environment } from '@protctc/common/environments/environment';
import { filter, fromEvent, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

const M_KEY_CODE = 'KeyM';

/**
 * App config service.
 * Provides information about current application environment configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {

  /** API base URL. */
  public readonly apiUrl = environment.apiUrl;

  /** Short api url. */
  public readonly shortApiUrl = environment.shortApiUrl;

  /** Deployment URl. */
  public readonly deploymentURL = environment.deploymentUrl;

  /** Payrix payment script URl. */
  public readonly payrixPaymentScriptUrl = environment.payrixPaymentScriptUrl;

  /** Stream that emits if debug mode is on. */
  public readonly debugMode$: Observable<boolean>;

  private readonly _debugMode$: BehaviorSubject<boolean>;

  public constructor(
    appRef: ApplicationRef,
    @Inject(DOCUMENT) document: Document,
  ) {
    this._debugMode$ = new BehaviorSubject(environment.debugMode ?? false);
    this.debugMode$ = this._debugMode$.asObservable();

    if (!environment.production) {
      this.listenToDebugModeCombination(document, appRef);
    }
  }

  private listenToDebugModeCombination(document: Document, appRef: ApplicationRef): void {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(event => event.ctrlKey && event.code === M_KEY_CODE),
      )
      .subscribe(() => {
        this._debugMode$.next(!this._debugMode$.value);
        appRef.tick();
      });
  }
}

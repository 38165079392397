import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UserRole } from '@protctc/common/core/enums/user-role';
import { NavigationLink } from '@protctc/common/core/models/navigation-link';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';
import { NavigationService } from '@protctc/common/core/services/navigation.service';
import { UserService } from '@protctc/common/core/services/user.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { routePaths } from '@protctc/common/core/utils/route-paths';
import { tap } from 'rxjs';

/** Mapper to map user role to sidebar links. */
const USER_ROLE_SIDEBAR_LINKS_MAP: Readonly<Record<UserRole, NavigationLink[]>> = {
  [UserRole.BusinessOwner]: [
    {
      text: 'Dashboard',
      route: routePaths.dashboard,
      icon: 'dashboard',
    },
    {
      text: 'Outstanding invoices',
      route: routePaths.invoices,
      icon: 'invoices',
    },
    {
      text: 'Billing and Payments',
      route: routePaths.billingAndPayment,
      icon: 'billing',
    },
    {
      text: 'Products & Services',
      route: routePaths.lineItems,
      icon: 'products',
    },
    {
      text: 'Users',
      route: routePaths.users,
      icon: 'users',
    },
    {
      text: 'Customers',
      route: routePaths.customers,
      icon: 'customers',
    },
    {
      text: 'Invoice setup',
      route: routePaths.invoiceSettings,
      icon: 'settings',
    },
  ],
  [UserRole.SuperAdmin]: [
    {
      text: 'Dashboard',
      route: routePaths.adminDashboard,
      icon: 'dashboard',
    },
    {
      text: 'Applications',
      route: routePaths.adminApplications,
      icon: 'application',
      children: [
        {
          text: 'Pending review',
          route: routePaths.adminPendingReviewApplications,
        },
        {
          text: 'Approved',
          route: routePaths.adminApprovedApplications,
        },
        {
          text: 'Rejected',
          route: routePaths.adminRejectedApplications,
        },
      ],
    },
    {
      text: 'Merchant partners',
      route: routePaths.merchantPartners,
      icon: 'merchant',
      children: [
        {
          text: 'Active',
          route: routePaths.adminMerchantActive,
        },
        {
          text: 'Inactive',
          route: routePaths.adminMerchantInactive,
        },
      ],
    },
    {
      text: 'Invoices',
      route: routePaths.adminInvoices,
      icon: 'invoices',
    },
    {
      text: 'Invoice settings',
      route: routePaths.adminInvoiceSettings,
      icon: 'settings',
    },
    {
      text: 'Users',
      route: routePaths.adminUsers,
      icon: 'users',
    },
  ],
  [UserRole.FieldUser]: [],
};

/** Base layout component. */
@DestroyableComponent()
@Component({
  selector: 'protctw-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit {

  /** List of sidebar navigation links. */
  public readonly sidebarNavigationLinks$ = this.navigationService.sidebarNavigationLinks$;

  public constructor(
    private readonly userService: UserService,
    private readonly navigationService: NavigationService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.userService.currentUser$.pipe(
      filterNull(),
      tap(user => this.navigationService.setSidebarNavigationLinks(USER_ROLE_SIDEBAR_LINKS_MAP[user.role])),
      takeUntilDestroy(this),
    )
      .subscribe();
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@saritasa/angular-typed-forms';

import { AppModule } from './app/app.module';

/** Checks whether the app works in prod environment. */
function isProdEnvironment(): boolean {
  return $ENV.ANGULAR_APP_PRODUCTION.toLowerCase() === 'true';
}

if (isProdEnvironment()) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { Injectable } from '@angular/core';
import { State } from '@protctc/common/core/models/state/state';

import { StateDto } from '../dto/state/state.dto';
import { IMapperFromDto } from '../mappers';

/** State mapper. */
@Injectable({
  providedIn: 'root',
})
export class StateMapper implements IMapperFromDto<StateDto, State> {

  /** @inheritdoc */
  public fromDto(data: StateDto): State {
    return {
      code: data.code,
      name: data.name,
    };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { NgChartsModule } from 'ng2-charts';
import { AutocompleteComponent } from '@protctc/common/shared/components/autocomplete/autocomplete.component';
import { LineItemFormComponent } from '@protctc/common/shared/components/line-item-form/line-item-form.component';
import { PageLoaderComponent } from '@protctc/common/shared/components/page-loader/page-loader.component';
import { ChangeInvoiceStatusDialogComponent } from '@protctc/common/shared/components/change-invoice-status-dialog/change-invoice-status-dialog.component';
import { InvoiceTemplateComponent } from '@protctc/common/shared/components/invoice-template/invoice-template.component';
import { RouterModule } from '@angular/router';

import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { FileSinglePickerComponent } from './components/file-picker/file-single-picker/file-single-picker.component';
import {
  FormControlValidationMessageComponent,
} from './components/form-control-validation-message/form-control-validation-message.component';
import { GetStartedStepperComponent } from './components/get-started-stepper/get-started-stepper.component';
import { ImageUploaderPreviewComponent } from './components/image-uploader-preview/image-uploader-preview.component';
import { CustomStepperComponent } from './components/stepper/stepper.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { AppValidationDirective } from './directives/app-validation.directive';
import { DisabledDirective } from './directives/disabled.directive';
import { HasUserRoleDirective } from './directives/has-user-role.directive';
import { HideDisabledDirective } from './directives/hide-disabled.directive';
import { LetDirective } from './directives/let.directive';
import { LoadingDirective } from './directives/loading.directive';
import { ShowForDebugDirective } from './directives/show-for-debug.directive';
import { MaterialModule } from './material.module';
import { HideWithStarsPipe } from './pipes/hide-with-stars.pipe';
import { LeadingZerosPipe } from './pipes/leading-zeros.pipe';
import { ListTitlePipe } from './pipes/list-title.pipe';
import { CompanyLogoComponent } from './components/company-logo/company-logo.component';
import { PlatformsDirective } from './directives/platforms.directive';
import { WebOnlyDirective } from './directives/web-only.directive';
import { MobileOnlyDirective } from './directives/mobile-only.directive';
import { NavigationBackDirective } from './directives/navigation-back.directive';
import { ToLastNumbersCardPipe } from './pipes/to-last-numbers-card.pipe';
import { UnionLineItemAutocompleteComponent } from './components/union-line-item-autocomplete/union-line-item-autocomplete.component';
import { USDCurrencyPipe } from './pipes/usd-currency.pipe';
import { MatAutocompleteInfiniteScrollDirective } from './directives/mat-autocomplete-infinite-scroll.directive';
import { EnUsDatePipe } from './pipes/en-us-date.pipe';
import { NotePickerComponent } from './components/file-picker/note-picker/note-picker.component';
import { ActiveIframePayFieldDirective } from './directives/active-iframe-pay-field.directive';
import { InvalidControlScrollDirective } from './directives/invalid-control-scroll.directive';
import { MarkAllAsTouchedDirective } from './directives/mark-all-as-touched.directive';
import { NullablePipe } from './pipes/nullable.pipe';
import { IconTooltipComponent } from './components/icon-tooltip/icon-tooltip.component';
import { TableComponent } from './components/table/table.component';
import { TypedMatCellDirective } from './directives/typed-mat-cell.directive';
import { TypedMatRowDirective } from './directives/typed-mat-row.directive';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { PolicyLinksComponent } from './components/policy-links/policy-links.component';
import { AccountPaymentMethodPipe } from './pipes/account-payment-method.pipe';
import { AccountTypePipe } from './pipes/account-type.pipe';
import { CapitalizeDirective } from './directives/capitalize.directive';
import { FocusInputDirective } from './directives/focus-input.directive';
import { AddAsteriskToRequiredFieldDirective } from './directives/add-asterisk-to-required-field.directive';
import { InvoicePrefixPipe } from './pipes/invoice-prefix.pipe';

const EXPORTED_DECLARATIONS = [

  // Components
  FormControlValidationMessageComponent,
  GetStartedStepperComponent,
  CompanyLogoComponent,
  PageLoaderComponent,
  ChangeInvoiceStatusDialogComponent,
  ImageUploaderPreviewComponent,
  InvoiceTemplateComponent,
  LineItemFormComponent,
  FileSinglePickerComponent,
  NotePickerComponent,
  ColorPickerComponent,
  CustomStepperComponent,
  DatepickerComponent,
  AutocompleteComponent,
  UnionLineItemAutocompleteComponent,
  IconTooltipComponent,
  TableComponent,
  AddressFormComponent,
  PolicyLinksComponent,

  // Directives
  AppValidationDirective,
  HideDisabledDirective,
  DisabledDirective,
  MatAutocompleteInfiniteScrollDirective,
  LetDirective,
  LoadingDirective,
  PlatformsDirective,
  WebOnlyDirective,
  MobileOnlyDirective,
  HasUserRoleDirective,
  NavigationBackDirective,
  ActiveIframePayFieldDirective,
  ShowForDebugDirective,
  InvalidControlScrollDirective,
  MarkAllAsTouchedDirective,
  TypedMatCellDirective,
  TypedMatRowDirective,
  CapitalizeDirective,
  FocusInputDirective,
  AddAsteriskToRequiredFieldDirective,

  // Pipes
  ListTitlePipe,
  HideWithStarsPipe,
  LeadingZerosPipe,
  ToLastNumbersCardPipe,
  USDCurrencyPipe,
  EnUsDatePipe,
  NullablePipe,
  AccountPaymentMethodPipe,
  AccountTypePipe,
  InvoicePrefixPipe,
];

const EXPORTED_MODULES = [
  MaterialModule,
  IMaskModule,
  ReactiveFormsModule,
  NgChartsModule,
  RouterModule,
];

/**
 * Common shared module.
 * Contains reusable components, directives and etc can be shared through all apps in the project.
 */
@NgModule({
  declarations: [...EXPORTED_DECLARATIONS],
  imports: [
    CommonModule,
    FormsModule,
    ValidationMessageComponent,
    ...EXPORTED_MODULES,
  ],
  exports: [
    ...EXPORTED_DECLARATIONS,
    ...EXPORTED_MODULES,
  ],
})
export class CommonSharedModule {
}

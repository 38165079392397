import { Injectable } from '@angular/core';

import { LineItemType } from '../../enums/line-item-type';
import { UnionLineItem, UnionLineItemConstructorData, UnionLineItemData } from '../../models/union-line-item';

import { DateMapper } from './date.mapper';
import { UnionLineItemDto } from './dto/union-line-item-dto';
import { LineItemGroupMapper } from './line-item-group.mapper';
import { LineItemMapper } from './line-item.mapper';
import { IMapperFromDto } from './mappers';
import { UserMapper } from './user.mapper';

/** Mapper for union line item entities. */
@Injectable({
  providedIn: 'root',
})
export class UnionLineItemMapper implements IMapperFromDto<UnionLineItemDto, UnionLineItem> {

  public constructor(
    private readonly dateMapper: DateMapper,
    private readonly userMapper: UserMapper,
    private readonly lineItemMapper: LineItemMapper,
    private readonly lineItemGroupMapper: LineItemGroupMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: UnionLineItemDto): UnionLineItem {
    return new UnionLineItem(this.mapConstructorData(data));
  }

  /**
   * Map constructor data for a line item.
   * @param data Data to map.
   */
  private mapConstructorData(data: UnionLineItemDto): UnionLineItemConstructorData {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      price: data.price,
      type: data.type as LineItemType,
      modified: this.dateMapper.fromDto(data.modified),
      updatedBy: data.updated_by ? this.userMapper.fromDto(data.updated_by) : null,
      unionLineItemData: this.mapToUnionLineItemData(data),
    };
  }

  private mapToUnionLineItemData(data: UnionLineItemDto): UnionLineItemData {
    if (data.type === LineItemType.LineItem) {
      return {
        type: LineItemType.LineItem,
        singleLineItem: this.lineItemMapper.fromDto(data.line_item),
      };
    }

    return {
      type: LineItemType.GroupLineItem,
      lineItemGroup: this.lineItemGroupMapper.fromDto(data.line_item_group),
    };
  }
}

<div class="wrapper">
  <header class="header" *ngIf="isInvoiceData(this.props.invoicePreviewData)">
    <div class="header__info">
      <ul class="header-info__list">
        <li class="header-info__item list-item">
          <p class="list-item__title">Invoice #</p>
          <p class="list-item__body">
            {{ this.props.invoicePreviewData.invoicePrefix | protctcInvoicePrefix}}
            {{ this.props.invoicePreviewData.invoiceNumber }}
          </p>
        </li>
        <li class="header-info__item list-item">
          <p class="list-item__title">Transaction ID</p>
          <p class="list-item__body">
            {{ this.props.invoicePreviewData.transactionId | protctcNullable }}
          </p>
        </li>
        <li class="header-info__item list-item">
          <p class="list-item__title">Payment type</p>
          <p class="list-item__body">
            <span
              *ngIf="this.props.invoicePreviewData.paymentMethod; else empty"
            >
              {{
                toReadablePaymentMethod(
                  this.props.invoicePreviewData.paymentMethod
                )
              }}
            </span>
          </p>
        </li>
        <li class="header-info__item list-item">
          <p class="list-item__title">Payment status</p>
          <p class="list-item__body">
            <span *ngIf="this.props.invoicePreviewData.status; else empty">
              {{
                toReadableInvoiceStatus(this.props.invoicePreviewData.status)
              }}
            </span>
          </p>
        </li>
        <li class="header-info__item list-item">
          <p class="list-item__title">Credit card fee saved</p>
          <p class="list-item__body">
            <span *ngIf="this.props.invoicePreviewData.fee; else empty">
              {{ this.props.invoicePreviewData.fee | USDCurrency }}
            </span>
          </p>
        </li>
      </ul>
      <protctw-refund-invoice-button
        [invoice]="this.props.invoicePreviewData"
        (invoiceRefunded)="refundInvoiceComplete($event)"
      ></protctw-refund-invoice-button>
    </div>
    <div class="subheader">
      <div class="header_customer-view-toggler">
        <mat-slide-toggle color="primary" [formControl]="viewAsCustomerControl">
          Toggle to view customer invoice.
        </mat-slide-toggle>
      </div>
      <protctw-invoice-pdf-download-button
        [invoiceId]="this.props.invoicePreviewData.id"
      ></protctw-invoice-pdf-download-button>
    </div>
  </header>
  <div class="container">
    <protctc-invoice-template
      [viewInvoiceAsCustomer]="viewAsCustomer$ | async"
      [invoicePreviewData]="this.props.invoicePreviewData"
    ></protctc-invoice-template>
  </div>
  <button
    *ngIf="this.props.shouldAccept"
    class="button flat accept-button"
    type="button"
    (click)="this.closed.emit(true)"
  >
    Process invoice
  </button>
</div>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

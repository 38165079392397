import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

/**
 * Service for checking the matching state of @media queries.
 */
@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverService {

  public constructor(
    private readonly breakpointObserver: BreakpointObserver,
  ) { }

  /**
   * Gets an observable of boolean for the given queries that will emit
   * new results whether the breakpoint is currently matching of the given queries.
   * @param media Media query for observe.
   */
  public createBreakpointObserver(media: string | readonly string[]): Observable<boolean> {
    return this.breakpointObserver.observe(media)
      .pipe(
        map(result => result.matches),
      );
  }

  /**
   * Create breakpoint observer for mobile screens.
   */
  public createMobileBreakpointObserver(): Observable<boolean> {
    const mobileBorderMediaQuery = '(max-width: 767px)';
    return this.createBreakpointObserver(mobileBorderMediaQuery);
  }
}

import { Injectable } from '@angular/core';
import { MerchantCategoryCode } from '@protctc/common/core/models/company/merchant-category-code';

import { MerchantCategoryCodeDto } from '../dto/company/merchant-category-code.dto';
import { IMapperFromDto } from '../mappers';

/** Merchant category code mapper. */
@Injectable({
  providedIn: 'root',
})
export class MerchantCategoryCodeMapper implements
IMapperFromDto<MerchantCategoryCodeDto, MerchantCategoryCode> {

  /** @inheritdoc */
  public fromDto(data: MerchantCategoryCodeDto): MerchantCategoryCode {
    return {
      id: data.id,
      mcc: data.mcc,
      description: data.description,
    };
  }
}

import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { LineItemType } from '@protctc/common/core/enums/line-item-type';
import { AutocompleteConfiguration } from '@protctc/common/core/models/autocomplete-configuration';
import { UnionLineItem, UnionLineItemData } from '@protctc/common/core/models/union-line-item';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';
import { listenControlChanges } from '@protctc/common/core/rxjs/listen-control-changes';
import { toggleExecutionState } from '@protctc/common/core/rxjs/toggle-execution-state';
import { LineItemService } from '@protctc/common/core/services/line-item.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { BehaviorSubject, filter, tap } from 'rxjs';

/** Autocomplete for line items and line item groups. */
@DestroyableComponent()
@Component({
  selector: 'protctc-union-line-item-autocomplete',
  templateUrl: './union-line-item-autocomplete.component.html',
  styleUrls: ['./union-line-item-autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnionLineItemAutocompleteComponent implements OnInit {

  /** Selected line item emitter. */
  @Output()
  public readonly lineItemSelected = new EventEmitter<UnionLineItemData>();

  /**
   * Placeholder.
   */
  @Input()
  public placeholder = 'Search by line item name';

  /** Configuration for union line item autocomplete. */
  public readonly unionLineItemAutoCompleteConfiguration: AutocompleteConfiguration<UnionLineItem>;

  /** Union line item control to filter values. */
  public readonly unionLineItemControl = this.formBuilder.controlTyped<UnionLineItem | null | string>(null);

  /** Union line item is searching. */
  public readonly isSearchingUnionLineItem$ = new BehaviorSubject(false);

  /** Line item type. */
  public readonly lineItemType = LineItemType;

  public constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly lineItemService: LineItemService,
  ) {
    this.unionLineItemAutoCompleteConfiguration = {
      comparator: UnionLineItem.compare,
      fetch: options => this.lineItemService.getUnionLineItemsWithData({
        ...options,
      }).pipe(toggleExecutionState(this.isSearchingUnionLineItem$)),
      toReadable: UnionLineItem.toReadable,
      trackBy: UnionLineItem.trackBy,
    };
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    listenControlChanges<UnionLineItem | null>(
      this.unionLineItemControl, { debounceTime: 0 },
    ).pipe(
      filter(searchTerm => searchTerm !== null),
      filter(searchTerm => typeof searchTerm !== 'string'),
      filterNull(),
      tap(value => this.lineItemSelected.emit(value.unionLineItemData)),
      tap(() => this.unionLineItemControl.reset()),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * To readable option subtitle.
   * @param data Union line item.
   */
  public toReadableOptionSubtitle(data: UnionLineItem): string {
    return data.type === LineItemType.LineItem ? 'Single Item' : 'Group of Items';
  }
}

import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

/**
 * According to the Angular 2 Material's github
 * the best way to format date without using third party libraries like moment
 * it is using custom date adapter and date formats and providing them.
 * @see {@link https://github.com/angular/components/issues/5722}.
 */

/** Custom date formats. */
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

/**
 * Custom date adapter to transform default
 * M/D/yyyy format to MM/DD/YYYY format with lead zeros.
 */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  /**
   * Format date to custom format.
   * @param date Date to format.
   * @param displayFormat Display format.
   */
  public override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${this._to2digit(month)}/${this._to2digit(day)}/${year}`;
    }
    return date.toDateString();
  }

  /**
   * Pads a number to make it two digits.
   * @param n The number to pad.
   */
  private _to2digit(n: number): string {
    return (`00${n}`).slice(-2);
  }
}

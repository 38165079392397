import { Injectable } from '@angular/core';

import { ExtraPagination } from '../../models/extra-pagination';
import { PaginationOptions } from '../../models/pagination-options';

import { ExtraPaginationDto } from './dto/extra-pagination-dto';
import { IMapperFromDto } from './mappers';
import { MapperFunction, PaginationMapper } from './pagination.mapper';

/** Extra pagination mapper. */
@Injectable({ providedIn: 'root' })
export class ExtraPaginationMapper extends PaginationMapper {

  /** @inheritdoc */
  public mapExtraPaginationFromDto<TDto, TDomain, TExtraDataDto, TExtraDataDomain>(
    page: ExtraPaginationDto<TDto, TExtraDataDto>,
    options: PaginationOptions,
    mapper: IMapperFromDto<TDto, TDomain> | MapperFunction<TDto, TDomain>,
    extraDataMapper: IMapperFromDto<TExtraDataDto, TExtraDataDomain> | MapperFunction<TExtraDataDto, TExtraDataDomain>,
  ): ExtraPagination<TDomain, TExtraDataDomain> {
    const extraDataMapperFn = typeof extraDataMapper === 'function' ? extraDataMapper : extraDataMapper.fromDto.bind(extraDataMapper);
    return new ExtraPagination({
      ...super.mapPaginationFromDto(page, options, mapper),
      extraData: extraDataMapperFn(page.extra_data),
    });
  }
}

import { Injectable } from '@angular/core';

import { InvoiceLineItem, InvoiceLineItemCreationData } from '@protctc/common/core/models/invoice-line-item';
import { PaymentMethod } from '@protctc/common/core/enums/payment-method';
import { InvoiceTemplateMapper } from '@protctc/common/core/services/mappers/invoice-template.mapper';
import { CalculatedShortInvoice } from '@protctc/common/core/models/calculated-short-invoice';

import { InvoiceCalculation } from '../../models/invoice-calculation';

import {
  CalculatedInvoiceDto,
  InvoiceCalculationDto,
  InvoiceLineItemCalculationDto,
} from './dto/invoice-calculation-dto';
import { InvoiceCreationCustomerMapper } from './invoice-creation-customer.mapper';

/** Mapper for invoice calculation. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceCalculationMapper {

  public constructor(
    private readonly invoiceTemplateMapper: InvoiceTemplateMapper,
    private readonly invoiceCreationCustomerMapper: InvoiceCreationCustomerMapper,
  ) {
  }

  /** @inheritdoc */
  public fromDto(data: CalculatedInvoiceDto, lineItems: readonly InvoiceLineItemCreationData[]): CalculatedShortInvoice {
    const newLineItems = this.createdLineItems(lineItems, data.calculated_line_items);
    return {
      cashPrice: Number(data.cash_price),
      invoiceTemplate: this.invoiceTemplateMapper.fromDto(data.invoice_template),
      cardinal: data.temp_cardinal,
      businessInformation: {
        id: data.business_information.id,
        name: data.business_information.name,
        ein: data.business_information.ein,
        email: data.business_information.email,
        url: data.business_information.url,
        phone: data.business_information.phone,
        address1: data.business_information.address_1,
      },
      subtotal: Number(data.subtotal),
      tax: Number(data.tax),
      total: Number(data.total),
      lineItems: newLineItems,
      invoiceNumber: data.invoice_template.increment_number,
      invoicePrefix: data.invoice_template.mask_prefix,
    };
  }

  /** @inheritdoc */
  public toDto(data: InvoiceCalculation): InvoiceCalculationDto {
    return {
      status: data.status,
      payment_method: data.paymentMethod ?? PaymentMethod.Cash,
      line_items: data.lineItems.map((lineItem, index) => ({
        quantity: lineItem.quantity,
        index: (lineItem.lineItemId ?? index).toString(),
        price: lineItem.price.toString(),
        tax_option: lineItem.taxType,
      })),
      customer_info: this.invoiceCreationCustomerMapper.toDto(data.customer),
    };
  }

  /**
   * Correlates the existing line item with the calculated one using the index.
   * @param lineItems Line items.
   * @param calculatedLineItems Line item with calculated price. If the line item is not created then it is correlated by name.
   */
  private createdLineItems(
    lineItems: readonly InvoiceLineItemCreationData[],
    calculatedLineItems: InvoiceLineItemCalculationDto[],
  ): InvoiceLineItem[] {
    const newLineItems: InvoiceLineItem[] = lineItems.map(lineItem => {
      const calculatedLineItem = calculatedLineItems
        .find((lineItemDto, index) => lineItemDto.index.toString() === (lineItem.lineItemId ?? index).toString());
      const priceToCustomer = calculatedLineItem?.price_to_customer ? Number(calculatedLineItem.price_to_customer) : 0;
      const price = calculatedLineItem?.price ? Number(calculatedLineItem.price) : 0;
      return {
        name: lineItem.name,
        quantity: lineItem.quantity,
        price,
        priceToCustomer,
        total: lineItem.quantity * priceToCustomer,
        description: lineItem.description,
        lineItemId: lineItem.lineItemId,
        subtotal: lineItem.quantity * priceToCustomer,
        taxType: lineItem.taxType,
      };
    });
    return newLineItems;
  }
}

import { LineItem } from './line-item';

/** Data to save line item group. */
export type LineItemGroupCreationData = Pick<LineItemGroup, 'name'> & {

  /** Id of line items include to the group. */
  readonly lineItemIds: readonly number[];
};

/** Data needed for the construction of line item group model. */
export type LineItemGroupConstructorData = LineItemGroup;

/** Line item group model. */
export class LineItemGroup {

  /** Id. */
  public readonly id: number;

  /** Group's name. */
  public readonly name: string;

  /** Company id. */
  public readonly companyId: number;

  /** Line items include to the group. */
  public readonly lineItems: readonly LineItem[];

  /** Created date. */
  public readonly createdDate: Date;

  /** Modified date. */
  public readonly modifiedDate: Date;

  public constructor(data: LineItemGroupConstructorData) {
    this.id = data.id;
    this.name = data.name;
    this.companyId = data.companyId;
    this.lineItems = data.lineItems;
    this.createdDate = data.createdDate;
    this.modifiedDate = data.modifiedDate;
  }
}

/**
 * Namespace for group line items.
 * Contains util methods and props for easier enum use.
 */
export namespace LineItemGroup {

  /**
   * Makes line item group human-readable.
   * @param lineItemGroup Group line item.
   */
  export function toReadable(lineItemGroup: LineItemGroup): string {
    return `${lineItemGroup.name} (${toReadableLineItems(lineItemGroup.lineItems)})`;
  }

  /**
   * Map line items names to string with comma separator.
   * @param items Items in the group.
   */
  function toReadableLineItems(items: LineItemGroup['lineItems']): string {
    return `${items.map(item => item.name).join(', ')}`;
  }
}

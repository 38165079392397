import { Pagination, PaginationConstructorData } from './pagination';

export type ExtraPaginationConstructorData<T, E> = PaginationConstructorData<T> & {

  /** Extra data. */
  readonly extraData: E;
};

/** Pagination model with extra data. */
export class ExtraPagination<T, E> extends Pagination<T> {

  /** Extra data. */
  public readonly extraData: E;

  public constructor(
    data: ExtraPaginationConstructorData<T, E>,
  ) {
    super(data);
    this.extraData = data.extraData;
  }
}

/** Represents recurring schedule mode. */
export enum RecurringScheduleModeDto {

  /** Monthly. */
  Monthly = 'monthly',

  /** Quarterly. */
  Quarterly = 'quarterly',

  /** Yearly. */
  Yearly = 'yearly',
}

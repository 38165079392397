import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserRole } from '@protctc/common/core/enums/user-role';
import { AuthorizedGuard } from '@protctc/common/core/guards/authorized.guard';
import { UnauthorizedGuard } from '@protctc/common/core/guards/unauthorized.guard';

import { MainLayoutComponent } from './modules/shared/layouts/main-layout/main-layout.component';

/** Invoice token param. */
export const INVOICE_TOKEN_PARAM = 'invoiceToken';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthorizedGuard],
    data: { roles: [UserRole.BusinessOwner] },
    children: [
      {
        path: 'dashboard',
        data: { breadcrumb: 'Dashboard' },
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'invoice-settings',
        loadChildren: () => import('./modules/invoice-settings/invoice-settings.module').then(m => m.InvoiceSettingsModule),
      },
      {
        path: 'invoices',
        data: { breadcrumb: 'Invoices' },
        loadChildren: () => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule),
      },
      {
        path: 'line-items',
        data: { breadcrumb: 'Products & Services' },
        loadChildren: () => import('./modules/line-items/line-items.module').then(m => m.LineItemsModule),
      },
      {
        path: 'users',
        data: { breadcrumb: 'User management' },
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'customers',
        data: { breadcrumb: 'Customers' },
        loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule),
      },
      {
        path: 'billing-payment',
        data: { breadcrumb: 'Billing & Payments' },
        loadChildren: () => import('./modules/billing-payment/billing-payment.module').then(m => m.BillingPaymentModule),
      },
      {
        path: 'account-settings',
        loadChildren: () => import('./modules/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthorizedGuard],
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    data: { roles: [UserRole.SuperAdmin] },
  },
  {
    path: 'auth',
    canActivate: [UnauthorizedGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'payment',
    loadChildren: () => import('./modules/customer-invoice-payment/customer-invoice-payment.module')
      .then(m => m.CustomerInvoicePaymentModule),
  },
  {
    path: `invoice-preview/:${INVOICE_TOKEN_PARAM}`,
    loadComponent: () => import('./modules/invoice-preview/invoice-preview.component')
      .then(c => c.InvoicePreviewComponent),
  },
  {
    path: 'general-info',
    loadChildren: () => import('./modules/general-info/general-info.module')
      .then(m => m.GeneralInfoModule),
  },
  { path: '**', redirectTo: 'dashboard' },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthorizedGuard, UnauthorizedGuard],
})
export class AppRoutingModule { }

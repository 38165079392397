import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  defer,
  distinctUntilChanged,
  first,
  map,
  mapTo,
  merge,
  Observable,
  of,
  repeatWhen,
  ReplaySubject,
  share,
  Subject,
  switchMap,
  switchMapTo,
  tap,
} from 'rxjs';

import { UsersSortField } from '../enums/users-sort-field';
import { CustomKeyValue } from '../models/custom-key-value';
import { Login } from '../models/login';
import { Pagination } from '../models/pagination';
import { PasswordReset } from '../models/password-reset';
import { PasswordResetConfirmation } from '../models/password-reset-confirmation';
import { User } from '../models/user';
import { UserPaginationOptions } from '../models/user-pagination-options';
import { UserSaveData } from '../models/user-save-data';
import { UserSecret } from '../models/user-secret';
import { TokenKey } from '../models/user/token-key';
import { filterNull } from '../rxjs/filter-null';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { CustomKeyValueMapper } from './mappers/custom-key-value.mapper';
import { CustomKeyValueDto } from './mappers/dto/custom-key-value-dto';
import { DetailsDto } from './mappers/dto/details-dto';
import { PaginationDto } from './mappers/dto/pagination-dto';
import { UserDto } from './mappers/dto/user-dto';
import { UserPaginationOptionsDto } from './mappers/dto/user-pagination-options-dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { SortMapper } from './mappers/sort.mapper';
import { TokenMapper } from './mappers/token.mapper';
import { UserSaveMapper } from './mappers/user-save.mapper';
import { UserMapper } from './mappers/user.mapper';
import { RememberMeService } from './remember-me.service';

/** Key for work with user details from storage. */
const USER_SECRET_STORAGE_KEY = 'user';

/** Mapper to map sort field from domain to local. */
const SORT_FIELD_MAP: Readonly<Record<UsersSortField, string>> = {
  [UsersSortField.Id]: 'id',
  [UsersSortField.EmployeeId]: 'employee_id',
  [UsersSortField.Name]: 'first_name,last_name',
  [UsersSortField.Title]: 'title',
  [UsersSortField.Email]: 'email',
  [UsersSortField.Role]: 'role',
  [UsersSortField.LastActivity]: 'last_login',
};

/**
 * User service.
 * Provides ability to work with current application user.
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {

  /** Auth details info for current user (token, expiry, user).  */
  public readonly currentSecret$: Observable<UserSecret | null>;

  /** Current user. Null when user is not logged in. */
  public readonly currentUser$: Observable<User | null>;

  /** Whether the user is authorized. */
  public readonly isAuthorized$: Observable<boolean>;

  /** Is first login of user. */
  public readonly isFirstLogin$: Observable<boolean>;

  /** Users url. */
  public readonly usersUrl: string;

  /** Is first login subject. */
  private readonly isFirstLoginValue$ = new BehaviorSubject<boolean>(false);

  /** Current user auth details. */
  private readonly currentSecretValue$ = new ReplaySubject<UserSecret | null>(1);

  private readonly currentUserUrl: string;

  private readonly currentUserUpdated$ = new Subject<void>();

  private readonly resetUrlVerifyUrl: string;

  public constructor(
    appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly storageService: LocalStorageService,
    private readonly rememberMeService: RememberMeService,
    private readonly authService: AuthService,
    private readonly userMapper: UserMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly userSaveMapper: UserSaveMapper,
    private readonly paginationMapper: PaginationMapper,
    private readonly sortMapper: SortMapper,
    private readonly router: Router,
    private readonly customKeyValueMapper: CustomKeyValueMapper,
    private readonly tokenMapper: TokenMapper,
  ) {
    this.currentUserUrl = new URL('auth/profile/', appConfig.apiUrl).toString();
    this.usersUrl = new URL('users/', appConfig.apiUrl).toString();
    this.resetUrlVerifyUrl = new URL('auth/password-reset-url-verify/', appConfig.apiUrl).toString();
    this.currentSecret$ = this.initCurrentSecretStream();
    this.currentUser$ = this.initCurrentUserStream();
    this.isFirstLogin$ = this.isFirstLoginValue$.asObservable();
    this.isAuthorized$ = this.currentUser$.pipe(
      map(user => user != null),
    );
  }

  /** Get field users of the company that current user belongs to. */
  public getFieldUserOptions(): Observable<CustomKeyValue<string, number>[]> {
    const url = new URL('field_user_options/', this.usersUrl).toString();
    return this.httpClient.get<CustomKeyValueDto<string, number>[]>(url).pipe(
      map(optionsDto => optionsDto.map(option => this.customKeyValueMapper.fromDto(option))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /** Get all users. */
  public getAllUsers(): Observable<User[]> {
    return this.httpClient.get<UserDto[]>(this.usersUrl).pipe(
      map(usersDto => usersDto.map(userDto => this.userMapper.fromDto(userDto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get a page of users.
   * @param options Pagination options.
   */
  public getUsers(options: UserPaginationOptions): Observable<Pagination<User>> {
    const pagination = this.mapUserPaginationOptions(options);

    const params = new HttpParams({
      fromObject: { ...pagination },
    });
    return this.httpClient.get<PaginationDto<UserDto>>(
      this.usersUrl,
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.userMapper)),
    );
  }

  /**
   * Get user by id.
   * @param userId User id.
   */
  public getUserById(userId: number): Observable<User> {
    const url = new URL(`${userId}/`, this.usersUrl).toString();
    return this.httpClient.get<UserDto>(
      url,
    ).pipe(
      map(userDto => this.userMapper.fromDto(userDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Save user data.
   * @param user User save data.
   */
  public save(user: UserSaveData): Observable<User> {
    if (user.userId) {
      return this.updateUser(user, user.userId).pipe(
        switchMap(updatedUser => this.updateCurrentUser(updatedUser)),
      );
    }
    return this.createUser(user);
  }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   * @param rememberMe Save email for next login flag.
   */
  public login(loginData: Login, rememberMe: boolean): Observable<boolean> {
    return this.authService.login(loginData).pipe(
      switchMap(secret => this.saveSecret(secret)),
      tap(secret => this.isFirstLoginValue$.next(secret.isFirstLogin)),
      switchMap(() => this.rememberMeService.update(loginData, rememberMe)),
      switchMapTo(this.isAuthorized$),
    );
  }

  /**
   * Logout current user.
   */
  public logout(): Observable<void> {
    return this.removeSecret();
  }

  /**
   * Forced user logout.
   * For example token is invalid or expired.
   */
  public forcedLogout(): Observable<void> {
    return this.removeSecret().pipe(
      switchMap(() => this.navigateToAuthPage()),
    );
  }

  /**
   * Sends request to reset the user password.
   * @param id User id.
   */
  public resetPasswordByUserId(id: User['id']): Observable<string> {
    const url = new URL(`${id}/password-reset/`, this.usersUrl).toString();
    return this.httpClient.post<DetailsDto>(url, {}).pipe(
      map(result => result.detail),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Sends request to reset the password.
   * @param data Data for password reset.
   * @returns Success message.
   */
  public resetPassword(data: PasswordReset): Observable<string> {
    return this.authService.resetPassword(data);
  }

  /**
   * Reset user password by id.
   * @param userId User id.
   */
  public resetPasswordById(userId: number): Observable<string> {
    return this.httpClient.post<DetailsDto>(
      new URL(`${userId}/password-reset/`, this.usersUrl).toString(),
      { id: userId },
    ).pipe(
      map(result => result.detail),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Deactivate user by id.
   * @param id User id.
   */
  public deactivateUserById(id: User['id']): Observable<void> {
    return this.httpClient.post<void>(
      new URL(`${id}/deactivate/`, this.usersUrl).toString(),
      { id },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Activate user by id.
   * @param id User id.
   */
  public activateUserById(id: User['id']): Observable<void> {
    return this.httpClient.post<void>(
      new URL(`${id}/activate/`, this.usersUrl).toString(),
      { id },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Confirms password reset and applies new passwords to the account.
   * @param data New passwords data.
   * @returns Success message.
   */
  public confirmPasswordReset(data: PasswordResetConfirmation): Observable<string> {
    return this.authService.confirmPasswordReset(data);
  }

  /**
   * When user firstly open link for reset password we send request to reset link user could't use one link twice.
   * @param data Token key data.
   */
  public resetUrlVerify(data: TokenKey): Observable<void> {
    return this.httpClient.post<void>(
      this.resetUrlVerifyUrl,
      this.tokenMapper.toDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Map user pagination options.
   * @param options User pagination options.
   */
  public mapUserPaginationOptions(options: UserPaginationOptions): UserPaginationOptionsDto {
    const paginationOptions: UserPaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      ordering: this.sortMapper.mapSortOptionsToDto(options.sortOptions, SORT_FIELD_MAP),
    };

    if (options.companyId) {
      return {
        ...paginationOptions,
        company_id: options.companyId,
      };
    }

    return paginationOptions;
  }

  /** Refresh current user. */
  public refreshCurrentUser(): void {
    this.currentUserUpdated$.next();
  }

  /** Init current user stream. */
  private initCurrentUserStream(): Observable<User | null> {
    return this.currentSecret$.pipe(
      switchMap(secret => secret ? this.getCurrentUser().pipe(
        repeatWhen(() => this.currentUserUpdated$),
      ) : of(null)),
      share({ connector: () => new ReplaySubject(1), resetOnComplete: true }),
    );
  }

  /** Init current user secret stream. */
  private initCurrentSecretStream(): Observable<UserSecret | null> {
    const secretChange$ = this.currentSecretValue$;

    return merge(
      defer(() => this.storageService.get<UserSecret>(USER_SECRET_STORAGE_KEY)),
      secretChange$,
    ).pipe(
      distinctUntilChanged((x, y) => x?.token === y?.token),
      share({ connector: () => new ReplaySubject(1), resetOnComplete: true }),
    );
  }

  /** Get current user. */
  private getCurrentUser(): Observable<User> {
    return this.httpClient.get<UserDto>(this.currentUserUrl).pipe(
      map(userDto => this.userMapper.fromDto(userDto)),
    );
  }

  /**
   * Save secret in storage.
   * @param secret Secret.
   */
  private saveSecret(secret: UserSecret): Observable<UserSecret> {
    return defer(() => this.storageService.save(USER_SECRET_STORAGE_KEY, secret)).pipe(
      tap(() => this.currentSecretValue$.next(secret)),
      mapTo(secret),
    );
  }

  /**
   * Remove secret from storage.
   */
  private removeSecret(): Observable<void> {
    return defer(() => this.storageService.remove(USER_SECRET_STORAGE_KEY)).pipe(
      tap(() => this.currentSecretValue$.next(null)),
    );
  }

  /** Navigate user to auth page. */
  private navigateToAuthPage(): Promise<void> {
    return this.router.navigate(['/auth']).then();
  }

  /**
   * Update user.
   * @param user User save data.
   * @param userId User id.
   */
  private updateUser(user: UserSaveData, userId: User['id']): Observable<User> {
    const url = new URL(`${userId}/`, this.usersUrl).toString();
    return this.httpClient.put<UserDto>(
      url,
      this.userSaveMapper.toDto(user),
    ).pipe(
      map(userDto => this.userMapper.fromDto(userDto)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.userSaveMapper),
    );
  }

  /**
   * Create new user.
   * @param user User.
   */
  private createUser(user: UserSaveData): Observable<User> {
    return this.httpClient.post<UserDto>(
      this.usersUrl,
      this.userSaveMapper.toDto(user),
    ).pipe(
      map(userDto => this.userMapper.fromDto(userDto)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.userSaveMapper),
    );
  }

  private updateCurrentUser(updatedUser: User): Observable<User> {
    return this.currentUser$.pipe(
      filterNull(),
      first(),
      tap(currentUser => {
        if (currentUser.id === updatedUser.id) {
          this.currentUserUpdated$.next();
        }
      }),
      mapTo(updatedUser),
    );
  }
}

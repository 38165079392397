/* eslint-disable @angular-eslint/directive-selector */
import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

import { Pagination } from '../../core/models/pagination';

/** Typed mat cell directive. */
@Directive({
  selector: '[matCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: TypedMatCellDirective }],
})
export class TypedMatCellDirective<T> extends MatCellDef {

  /** Leveraging syntactic-sugar syntax when we use *matCellDef. */
  @Input()
  public matCellDefDataSource?: T[] | readonly T[] | Observable<T[]> | MatTableDataSource<T> | Observable<Pagination<T>>;

  /**
   * NgTemplateContextGuard flag to help with the Language Service.
   * @param _dir Directive.
   * @param _ctx Context.
   */
  public static ngTemplateContextGuard<T>(
    _dir: TypedMatCellDirective<T>,
    _ctx: unknown,
  ): _ctx is { $implicit: T; index: number; } {
    return true;
  }
}

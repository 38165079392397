import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

/**
 * Mark fields as touched of the control.
 * @param control Abstract control.
 */
export function markFieldsAsTouched(control: AbstractControl): void {
  control.markAsTouched({ onlySelf: true });
  control.updateValueAndValidity();
  if (control instanceof FormArray || control instanceof FormGroup) {
    Object.values(control.controls).forEach(markFieldsAsTouched);
  }
}

import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AppConfigService } from './app-config.service';

interface IconData {

  /** Name for icon. */
  readonly name: string;

  /** Name of svg file of icon.*/
  readonly path: string;
}

/**
 * Service to register custom icons.
 */
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private readonly icons: IconData[] = [
    {
      name: 'cancel',
      path: 'cancel-cross.svg',
    },
    {
      name: 'sign_out',
      path: 'sign-out.svg',
    },
    {
      name: 'navigate_next',
      path: 'navigate-next.svg',
    },
    {
      name: 'account_settings',
      path: 'account-settings.svg',
    },
    {
      name: 'bill',
      path: 'bill.svg',
    },
    {
      name: 'arrow_drop_down',
      path: 'arrow-drop-down.svg',
    },
    {
      name: 'arrow_drop_up',
      path: 'arrow-drop-up.svg',
    },
    {
      name: 'expand_more',
      path: 'expand-more.svg',
    },
    {
      name: 'expand_less',
      path: 'expand-less.svg',
    },
    {
      name: 'dashboard',
      path: 'dashboard.svg',
    },
    {
      name: 'invoices',
      path: 'invoices.svg',
    },
    {
      name: 'users',
      path: 'users.svg',
    },
    {
      name: 'billing',
      path: 'billing.svg',
    },
    {
      name: 'settings',
      path: 'settings.svg',
    },
    {
      name: 'products',
      path: 'products.svg',
    },
    {
      name: 'help',
      path: 'help.svg',
    },
    {
      name: 'application',
      path: 'application.svg',
    },
    {
      name: 'alert',
      path: 'alert.svg',
    },
    {
      name: 'merchant',
      path: 'merchant.svg',
    },
    {
      name: 'file',
      path: 'file.svg',
    },
    {
      name: 'delete',
      path: 'trash.svg',
    },
    {
      name: 'border',
      path: 'border.svg',
    },
    {
      name: 'filter',
      path: 'filter.svg',
    },
    {
      name: 'warning',
      path: 'warning.svg',
    },
    {
      name: 'done',
      path: 'done.svg',
    },
    {
      name: 'search',
      path: 'search.svg',
    },
    {
      name: 'add',
      path: 'add.svg',
    },
    {
      name: 'error',
      path: 'error.svg',
    },
    {
      name: 'arrow_down',
      path: 'arrow-down.svg',
    },
    {
      name: 'arrow_up',
      path: 'arrow-up.svg',
    },
    {
      name: 'files_multiple',
      path: 'files-multiple.svg',
    },
    {
      name: 'file_single',
      path: 'file-single.svg',

    },
    {
      name: 'pdf',
      path: 'pdf.svg',
    },
    {
      name: 'done_without_circle',
      path: 'done-without-circle.svg',
    },
    {
      name: 'cross',
      path: 'cross.svg',
    },
    {
      name: 'attached_file',
      path: 'attach-file.svg',
    },
    {
      name: 'customers',
      path: 'customers.svg',
    },
  ];

  public constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly appConfigService: AppConfigService,
  ) {}

  /** Register icons. */
  public registerIcons(): void {
    this.icons.forEach(({ name, path }) => {
      const domain = isPlatformServer(this.platformId) ? this.appConfigService.deploymentURL : '';
      this.registerIcon(name, `${domain}assets/icons/${path}`);
    });
  }

  private registerIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}

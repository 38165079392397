import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { BehaviorSubject } from 'rxjs';

/**
 * Sort state.
 * @example
 * ```html
      <table [protctcSort]="sort$ | async" (protctcSortChange)="sort$.next($event)">
        <tr>
          <th [protctcSortButton]="sortOptions.Name">Name</th>
          <th [protctcSortButton]="sortOptions.LastLogin">Last Login</th>
        </tr>
      <table/>
 * ```
 */
@Directive({
  selector: '[protctcSort]',
})
export class SortDirective<T extends string | number> {

  /** Sort state setter. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('protctcSort')
  public set value(v: SortOptions<T> | null) {
    this._value$.next(v);
  }

  private readonly _value$ = new BehaviorSubject<SortOptions<T> | null>(null);

  /** Sort state. */
  public readonly value$ = this._value$.asObservable();

  /** Change emitter. */
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('protctcSortChange')
  public readonly valueChange = new EventEmitter<SortOptions<T>>();
}

import { Pagination, PaginationConstructorData } from '../pagination';

type PayrixPaginationConstructorData<T> =
  PaginationConstructorData<T> & Pick<PayrixPagination<T>, 'hasMore'>;

/** Payrix pagination wrapper. */
export class PayrixPagination<T> extends Pagination<T> {

  /** Whether the items has more or not. */
  public readonly hasMore: boolean;

  public constructor(
    data: PayrixPaginationConstructorData<T>,
  ) {
    super(data);
    this.hasMore = data.hasMore;
  }
}

import { Directive, Inject, Input, OnInit } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { interval, map } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  DestroyableComponent,
  takeUntilDestroy,
} from '@protctc/common/core/utils/public_api';
import { PayFrameField } from '@protctc/common/core/models/payment/payframe-field';

/**
 * Highlights the active the mat form field.
 * The input of which is located in the iframe of the payment library.
 */
@DestroyableComponent()
@Directive({
  selector: '[protctcActiveIframePayField]',
})
export class ActiveIframePayFieldDirective implements OnInit {

  /** Iframe field name in pay-frame library. */
  @Input()
  public protctcActiveIframePayField!: PayFrameField;

  public constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly matFormField: MatFormField,
  ) {}

  /** @inheritDoc */
  public ngOnInit(): void {
    interval(100)
      .pipe(
        map(() => this.document.activeElement),
        distinctUntilChanged(),
        takeUntilDestroy(this),
      )
      .subscribe(activeElement => {
        if (
          this.isCurrentIframeActive(activeElement)
        ) {
          this.matFormField._elementRef.nativeElement.classList.add('active');
        } else {
          this.matFormField._elementRef.nativeElement.classList.remove('active');
        }
      });
  }

  private isCurrentIframeActive(activeElement: Element | null): boolean {
    return activeElement != null &&
      activeElement.tagName === 'IFRAME' &&
      activeElement.id === this.protctcActiveIframePayField;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InvoiceTemplate } from '@protctc/common/core/models/invoice-template';
import { routePaths } from '@protctc/common/core/utils/route-paths';

/** Merchant header. */
@Component({
  selector: 'protctw-merchant-header',
  templateUrl: './merchant-header.component.html',
  styleUrls: ['./merchant-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MerchantHeaderComponent {

  /** Merchant invoice logo. */
  @Input()
  public merchantInvoiceLogo: InvoiceTemplate['logo'] = null;

  /** Route paths. */
  protected readonly routePaths = routePaths;

  /**
   * Get merchant logo source.
   * @param logo Logo from invoice template.
   */
  protected get merchantLogoSrc(): string {
    return typeof this.merchantInvoiceLogo === 'string' ? this.merchantInvoiceLogo : '';
  }
}

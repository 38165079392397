import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { PasswordReset } from '../../models/password-reset';

import { PasswordResetDto } from './dto/password-reset-dto';

import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperToDtoWithErrors } from './mappers';

/** Mapper for reset password data. */
@Injectable({
  providedIn: 'root',
})
export class ResetPasswordMapper implements IMapperToDtoWithErrors<PasswordResetDto, PasswordReset> {

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<PasswordResetDto>): EntityValidationErrors<PasswordReset> {
    return {
      email: extractErrorMessage(errorDto.email) ?? extractErrorMessage(errorDto.non_field_errors),
    };
  }

  /** @inheritdoc */
  public toDto(data: PasswordReset): PasswordResetDto {
    return {
      email: data.email,
    };
  }

}

import { Injectable } from '@angular/core';
import { BankAccount, BankAccountUpdate } from '@protctc/common/core/models/company/bank-account';

import { BankAccountDto, BankAccountUpdateDto } from '../dto/company/bank-account.dto';
import { IMapper } from '../mappers';

/** Bank account mapper. */
@Injectable({
  providedIn: 'root',
})
export class BankAccountMapper implements IMapper<BankAccountDto, BankAccount> {

  /** @inheritdoc */
  public toDto(data: BankAccount): BankAccountDto {
    return {
      bank_account_name: data.name,
      bank_account_number: data.accountNumber,
      bank_routing_number: data.routingNumber,
      payrix_account_payment_method: data.payrixAccountPaymentMethod,
      payrix_account_type: data.payrixAccountType,
    };
  }

  /** @inheritdoc */
  public toUpdateDto(data: BankAccountUpdate): BankAccountUpdateDto {
    return {
      bank_account_name: data.name,
      bank_account_number: data.accountNumber,
      bank_routing_number: data.routingNumber,
      payrix_account_payment_method: data.payrixAccountPaymentMethod,
      payrix_account_type: data.payrixAccountType,
    };
  }

  /** @inheritdoc */
  public fromDto(data: BankAccountDto): BankAccount {
    return {
      name: data.bank_account_name,
      accountNumber: data.bank_account_number,
      routingNumber: data.bank_routing_number,
      payrixAccountPaymentMethod: data.payrix_account_payment_method,
      payrixAccountType: data.payrix_account_type,
    };
  }
}

/** Result containing the uri for the image. */
interface ImageSourceResult {

  /** URI of a provided `File`. */
  readonly unsanitizedUri: string;

  /** Teardown function, revokes requested URI in order to avoid memory leaks. */
  readonly teardown: () => void;
}

/**
 * Returns a URI to a File. To properly render the .
 * @param file File to return the URI for.
 */
export function getImageSource(
  file: File | string,
): ImageSourceResult {
  const url = typeof file === 'string' ? file : URL.createObjectURL(file);

  return {
    unsanitizedUri: url,
    teardown(this: void) {
      URL.revokeObjectURL(url);
    },
  };
}

/** Represents available timezone. */
export enum Timezone {

  /** Alaska. */
  Alaska = 'US/Alaska',

  /** Aleutian. */
  Aleutian = 'US/Aleutian',

  /** Arizona. */
  Arizona = 'US/Arizona',

  /** Central. */
  Central = 'US/Central',

  /** East-Indiana. */
  EastIndiana = 'US/East-Indiana',

  /** Eastern. */
  Eastern = 'US/Eastern',

  /** Hawaii. */
  Hawaii = 'US/Hawaii',

  /** Indiana-Starke. */
  IndianaStarke = 'US/Indiana-Starke',

  /** Michigan. */
  Michigan = 'US/Michigan',

  /** Mountain. */
  Mountain = 'US/Mountain',

  /** Pacific. */
  Pacific = 'US/Pacific',

  /** Samoa. */
  Samoa = 'US/Samoa',
}

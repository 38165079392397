import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/** Header page layout. */
@Component({
  selector: 'protctw-header-page-layout',
  templateUrl: './header-page-layout.component.html',
  styleUrls: ['./header-page-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderPageLayoutComponent {

  /** Page title. */
  @Input()
  public pageTitle: string | null = '';
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

/**
 * Application platform.
 */
export type ProtctPlatform = 'web' | 'mobile';

/**
 * Platform detection service.
 */
@Injectable({
  providedIn: 'root',
})
export class ProtctPlatformService {

  /**
   * Current platform.
   */
  public readonly currentPlatform$ = new BehaviorSubject<ProtctPlatform>('web');

  /**
   * Is mobile platform.
   */
  public readonly isMobile$ = this.currentPlatform$.pipe(
    map(platform => platform === 'mobile'),
  );

  /**
   * Is web platform.
   */
  public readonly isWeb$ = this.currentPlatform$.pipe(
    map(platform => platform === 'web'),
  );

  /**
   * Set current platform to mobile.
   */
  public setPlatformToMobile(): void {
    this.currentPlatform$.next('mobile');
  }
}

import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_SHOWN_CHARACTERS_COUNT = 4;
const DEFAULT_HIDE_STRING_LENGTH = 8;

/** Pipe for hide characters of values with stars. */
@Pipe({
  name: 'protctcHideWithStars',
})
export class HideWithStarsPipe implements PipeTransform {

  /**
   * Hide characters with stars.
   * @param value Value for hiding characters.
   */
  public transform(value: string | number): string {
    const stringValue = value.toString();
    const lastCharacters = stringValue.slice(-DEFAULT_SHOWN_CHARACTERS_COUNT);
    return lastCharacters.padStart(DEFAULT_HIDE_STRING_LENGTH, '*');
  }
}

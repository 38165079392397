<div class="container">
  <table mat-table [dataSource]="(tableData$ | async) ?? []">
    <ng-content></ng-content>
    <tr
      class="table__header"
      mat-header-row
      *matHeaderRowDef="displayedColumns"
    ></tr>

    <ng-container *ngIf="!hasCustomRows">
      <tr
        class="table__row"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </ng-container>
  </table>

  <mat-paginator
    #paginator
    [pageIndex]="paginationFilters.page"
    [length]="(itemsCount$ | async) ?? 0"
    [pageSize]="paginationFilters.pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
  ></mat-paginator>
</div>

import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Capitalized first letter of all words in input.
 */
@Directive({ selector: 'input[protctcCapitalize]' })
export class CapitalizeDirective {

  public constructor(
    private el: ElementRef,
  ) { }

  /** Handle input change. */
  @HostListener('input')
  public onInput(): void {
    const inputValue = this.el.nativeElement.value;
    const cursorPos = this.el.nativeElement.selectionStart;
    this.el.nativeElement.value = this.capitalizeAllWords(inputValue);
    this.el.nativeElement.setSelectionRange(cursorPos, cursorPos);
  }

  private capitalizeAllWords(value: string): string {
    if (!value) {
      return '';
    }
    const words = value.split(' ');
    const capitalizedWords = words.map(word => this.capitalizeFirstLetter(word));
    return capitalizedWords.join(' ');
  }

  private capitalizeFirstLetter(value: string): string {
    return value ?
      value.charAt(0).toUpperCase() + value.slice(1) :
      '';
  }
}

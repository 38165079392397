import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { toggleExecutionState } from '@protctc/common/core/rxjs/toggle-execution-state';
import { InvoiceService } from '@protctc/common/core/services/invoice.service';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { BehaviorSubject } from 'rxjs';

/** Download invoice as pdf file button component. */
@DestroyableComponent()
@Component({
  selector: 'protctw-invoice-pdf-download-button',
  templateUrl: './invoice-pdf-download-button.component.html',
  styleUrls: ['./invoice-pdf-download-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicePdfDownloadButtonComponent {

  /** Invoice id. */
  @Input()
  public invoiceId: number | null = null;

  /** Is invoice preview pdf file loading state. */
  public readonly isLoading$ = new BehaviorSubject(false);

  public constructor(
    private readonly invoiceService: InvoiceService,
  ) { }

  /** Download invoice preview as pdf file. */
  public downloadInvoicePreviewAsPdf(): void {
    assertNonNull(this.invoiceId);
    this.invoiceService.downloadInvoiceAsPdf(this.invoiceId).pipe(
      toggleExecutionState(this.isLoading$),
      takeUntilDestroy(this),
    )
      .subscribe();
  }
}

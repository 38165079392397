import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { NavigationLink } from '../models/navigation-link';

/** Provides logic related to navigation. */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  /** Sidebar navigation links. */
  public readonly sidebarNavigationLinks$: Observable<NavigationLink[]>;

  private readonly _sidebarNavigationLinks$: BehaviorSubject<NavigationLink[]>;

  public constructor() {
    this._sidebarNavigationLinks$ = new BehaviorSubject<NavigationLink[]>([]);
    this.sidebarNavigationLinks$ = this._sidebarNavigationLinks$.asObservable();
  }

  /**
   * Sets sidebar navigation links.
   * @param links List of navigation links to set.
   */
  public setSidebarNavigationLinks(links: NavigationLink[]): void {
    this._sidebarNavigationLinks$.next(links);
  }
}

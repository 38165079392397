import { UntypedFormBuilder, FormGroupTyped } from '@angular/forms';
import { InvoiceStatus } from '@protctc/common/core/enums/invoice-status';
import { PaymentMethod } from '@protctc/common/core/enums/payment-method';
import { DateRangeFormData, initDateRangeFormGroup } from '@protctc/common/core/forms/date-range-form-data';
import { InvoiceShortFilterData } from '@protctc/common/core/models/invoice-short-filter-data';

/** Invoice filter form data. */
export interface InvoiceFilterFormData {

  /** Invoices created date range. */
  readonly created: DateRangeFormData;

  /** Invoices due date range. */
  readonly due: DateRangeFormData;

  /** Payment method. */
  readonly paymentMethod: PaymentMethod;

  /** Invoice status. */
  readonly invoiceStatus: InvoiceStatus;
}

/**
 * Namespace for invoice filter form data.
 * Contains util methods for this entity.
 */
export namespace InvoiceFilterFormData {

  /**
   * Initialize form group with default values.
   * @param fb Form builder.
   */
  export function initializeFormGroup(fb: UntypedFormBuilder): FormGroupTyped<InvoiceFilterFormData> {
    return fb.groupTyped<InvoiceFilterFormData>({
      created: initDateRangeFormGroup(fb),
      due: initDateRangeFormGroup(fb),
      paymentMethod: [PaymentMethod.None],
      invoiceStatus: [InvoiceStatus.All],
    });
  }

  /**
   * Map from form data to domain model.
   * @param data Invoice filter form data.
   */
  export function mapToInvoiceFilterData(data: InvoiceFilterFormData): InvoiceShortFilterData {
    return {
      invoiceStartDate: data.created.start,
      invoiceEndDate: data.created.end,
      invoiceDueStartDate: data.due.start,
      invoiceDueEndDate: data.due.end,
      paymentMethod: data.paymentMethod,
      invoiceStatus: data.invoiceStatus,
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../material.module';

import { TableSortButtonComponent } from './table-sort-button/table-sort-button.component';
import { SortDirective } from './sort.directive';

/** Module for sort components. */
@NgModule({
  declarations: [
    SortDirective,
    TableSortButtonComponent,
  ],
  exports: [
    SortDirective,
    TableSortButtonComponent,
  ],
  imports: [CommonModule, MaterialModule],
})
export class SortModule { }

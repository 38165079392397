import { Injectable } from '@angular/core';
import { BusinessOwner } from '@protctc/common/core/models/company/business-owner';
import { assertFilesUploaded } from '@protctc/common/core/utils/assert-file-uploaded';

import { DateMapper } from '../date.mapper';
import { BusinessOwnerDto } from '../dto/company/business-owner.dto';
import { IMapper } from '../mappers';

import { UploadedDocumentMapper } from './uploaded-document.mapper';

import { CompanyContactMapper } from './company-contact.mapper';

/** Business owner mapper. */
@Injectable({
  providedIn: 'root',
})
export class BusinessOwnerMapper implements IMapper<BusinessOwnerDto, BusinessOwner> {

  public constructor(
    private readonly companyContactMapper: CompanyContactMapper,
    private readonly uploadedDocumentMapper: UploadedDocumentMapper,
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public toDto(data: BusinessOwner): BusinessOwnerDto {
    assertFilesUploaded(data.documents);
    return {
      ...this.companyContactMapper.toDto(data),
      id: data.id,
      ssn: data.ssn,
      driver_license_number: data.driverLicenseNumber,
      driver_license_state: data.driverLicenseState.code,
      birth_date: data.birthDate ? this.dateMapper.toDateOnlyStringDto(data.birthDate) : undefined,
      documents: data.documents.map(document => this.uploadedDocumentMapper.toDto(document)),
      is_primary_contact: data.isPrimaryContact,
      is_significant_responsibility: data.isSignificantResponsibility,

      // Payrix value from 0 to 10000, 100% - 10000.
      payrix_ownership_percentage: data.ownership ? Math.round(data.ownership * 100) : null,
    };
  }

  /** @inheritdoc */
  public fromDto(data: BusinessOwnerDto): BusinessOwner {
    return {
      ...this.companyContactMapper.fromDto(data),
      id: data.id,
      ssn: data.ssn,
      driverLicenseNumber: data.driver_license_number,
      driverLicenseState: { code: data.driver_license_state, name: '' },
      birthDate: data.birth_date ? this.dateMapper.fromDto(data.birth_date) : null,
      documents: data.documents.map(document => this.uploadedDocumentMapper.fromDto(document)),
      isPrimaryContact: data.is_primary_contact,
      isSignificantResponsibility: data.is_significant_responsibility,

      // Payrix value from 0 to 10000, 100% - 10000.
      ownership: data.payrix_ownership_percentage ? data.payrix_ownership_percentage / 100 : null,
    };
  }

}

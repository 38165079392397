import { Pipe, PipeTransform } from '@angular/core';
import { PayrixAccountPaymentMethod } from '@protctc/common/core/enums/payrix-account-payment-method';

/** To readable account payment method pipe. */
@Pipe({
  name: 'protctcAccountPaymentMethod',
})
export class AccountPaymentMethodPipe implements PipeTransform {

  /** @inheritdoc */
  public transform(method: PayrixAccountPaymentMethod): string {
    return PayrixAccountPaymentMethod.toReadable(method);
  }
}

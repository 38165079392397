import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressForm } from '@protctc/common/core/forms/address-form';
import { State } from '@protctc/common/core/models/state/state';
import { StateService } from '@protctc/common/core/services/state.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { masks } from '@protctc/common/core/utils/masks';
import { NonNullableProperties } from '@protctc/common/core/utils/types/non-nullable-properties';
import { map, Observable, shareReplay, tap } from 'rxjs';

export type AddressFormLabelData = Partial<Record<keyof AddressForm, string>>;

const DEFAULT_LABELS: NonNullableProperties<AddressFormLabelData> = {
  line1Address: 'Address 1',
  line2Address: 'Address 2',
  city: 'City',
  state: 'State',
  zip: 'ZIP',
};

/** Address form component. */
@DestroyableComponent()
@Component({
  selector: 'protctc-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit {

  /** Address form group. */
  @Input()
  public formGroup!: FormGroup<AddressForm>;

  /** Address form field's labels. */
  @Input()
  public set formLabels(data: AddressFormLabelData) {
    this.labels = {
      line1Address: data.line1Address ?? DEFAULT_LABELS.line1Address,
      line2Address: data.line2Address ?? DEFAULT_LABELS.line2Address,
      city: data.city ?? DEFAULT_LABELS.city,
      state: data.state ?? DEFAULT_LABELS.state,
      zip: data.zip ?? DEFAULT_LABELS.zip,
    };
  }

  /** Masks. */
  protected readonly masks = masks;

  /** Form labels. */
  protected labels: NonNullableProperties<AddressFormLabelData> = DEFAULT_LABELS;

  /** Available states. */
  protected readonly states$: Observable<State[]>;

  /** State. */
  protected readonly state = State;

  public constructor(
    stateService: StateService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.states$ = stateService.getStates().pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.runDetectChangesSideEffect().pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * Because of `OnPush` strategy, component doesn't update
   * when form status was updated. We have to do it manually.
   */
  private runDetectChangesSideEffect(): Observable<void> {
    return this.formGroup.statusChanges.pipe(
      tap(() => this.cdr.markForCheck()),
      map(() => undefined),
    );
  }
}

import { Component, ChangeDetectionStrategy, EventEmitter, OnInit } from '@angular/core';

import { IDialog, IDialogOptions } from '../dialog';

/**
 * Welcome options.
 */
export interface WelcomeOptions {

  /**
   * Dialog title.
   */
  readonly title?: string;

  /**
   * Welcome message.
   */
  readonly message?: string;
}

/**
 * Welcome let's get started dialog component.
 */
@Component({
  selector: 'protctc-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeDialogComponent implements IDialog<WelcomeOptions, void>, OnInit {

  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritDoc */
  public readonly closed = new EventEmitter<void>();

  /** @inheritdoc */
  public readonly props!: WelcomeOptions;

  /** Default dialog property values. */
  public readonly defaultProps: WelcomeOptions = {
    title: `Welcome to Pantera Software`,
  };

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.options = {
      closable: true,
      closeButton: true,
      title: this.props?.title ?? this.defaultProps.title,
    };
  }
}

<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
  <ul *ngIf="breadcrumbs.length > 1" class="breadcrumbs">
    <li
      class="breadcrumbs__item"
      *ngFor="let crumb of breadcrumbs; trackBy: trackBreadcrumbs"
    >
      <a
        class="breadcrumbs__link"
        [routerLink]="crumb.url"
        [class.active]="isLinkActive(crumb.url)"
      >
        {{ crumb.displayName }}
      </a>
    </li>
  </ul>
</ng-container>

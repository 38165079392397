import { DOCUMENT } from '@angular/common';
import {
  Injectable,
  Injector,
  ApplicationRef,
  ComponentFactoryResolver,
  Type,
  Inject,
  InjectionToken,
  StaticProvider, ComponentRef,
} from '@angular/core';
import { merge } from 'rxjs';
import { delay, first, tap } from 'rxjs/operators';

import { IDialog, IDialogOptions } from './dialog';
import { DialogOverlayContainerComponent } from './dialog-overlay-container/dialog-overlay-container.component';

/** Represents an element into which the dialogs are supposed to be injected. */
export const DIALOG_HOST = new InjectionToken<HTMLElement>('DIALOG_HOST');
export const DEFAULT_DIALOG_HOST_PROVIDER: StaticProvider = {
  provide: DIALOG_HOST,
  deps: [DOCUMENT],
  useFactory: (document: Document) => document.body,
};

export const DEFAULT_DIALOG_OPTIONS: IDialogOptions = {
  closable: false,
};

type DialogType<T> = T extends IDialog<infer _, infer __> ? Type<T> : never;
type DialogProperties<T> = T extends IDialog<infer Options, infer _>
  ? Options
  : never;
type DialogResult<T> = T extends IDialog<infer _, infer Result> ? Result : void;

interface DialogRef<T> {

  /** Dialog container ref. */
  readonly dialogContainerRef: ComponentRef<DialogOverlayContainerComponent>;

  /** Dialog component ref. */
  readonly dialogComponentRef: ComponentRef<T>;
}

/**
 * Dialogs service.
 * Provides functionality to work with dialogs.
 */
@Injectable()
export class DialogsService {

  private readonly dialogRefs: DialogRef<unknown>[] = [];

  /**
   * @param injector Injector.
   * @param applicationRef App ref.
   * @param componentFactoryResolver Component resolver.
   * @param dialogHost Document.
   */
  public constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DIALOG_HOST) private dialogHost: HTMLElement,
  ) { }

  /**
   * Creates popup and adds it to DOM.
   * @param component Any component that implements IDialog.
   * @param props Dialog properties.
   * @param options Dialog options.
   *
   * @see IDialog.
   */
  public openDialog<T>(
    component: DialogType<T>,
    props?: DialogProperties<T>,
  ): Promise<DialogResult<T>> {
    // Create element
    const dialogContainerFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        DialogOverlayContainerComponent,
      );

    // Create the component and wire it up with the element
    const dialogFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    const dialogComponentRef = dialogFactory.create(this.injector);

    // Apply options
    if (props != null) {
      dialogComponentRef.instance.props = props;
    }

    // Attach to the view dialog component to init `options`
    this.applicationRef.attachView(dialogComponentRef.hostView);
    dialogComponentRef.changeDetectorRef.detectChanges();

    const dialogContainerRef = dialogContainerFactory.create(this.injector, [[dialogComponentRef.location.nativeElement]]);

    // In order for all the options properties to be lazily evaluated, define getter
    Object.defineProperty(dialogContainerRef.instance, 'options', {
      get: () => ({
        ...DEFAULT_DIALOG_OPTIONS,
        ...dialogComponentRef.instance.options,
      }),
    });

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(dialogContainerRef.hostView);

    this.dialogRefs.push({ dialogComponentRef, dialogContainerRef });
    const closed$ = merge(
      dialogComponentRef.instance.closed,
      dialogContainerRef.instance.closed.pipe(
        tap(dialogComponentRef.instance.closed),
      ),
    ).pipe(first());

    // Add to the DOM
    this.dialogHost.appendChild(dialogContainerRef.location.nativeElement);

    return closed$
      .pipe(
        first(),
        tap(() => dialogContainerRef.destroy()),

        // Destroy dialog component after some time to preserve the correct animation
        delay(300),
        tap(() => {
          this.dialogRefs.pop();
          dialogComponentRef.destroy();
        }),
      )
      .toPromise() as Promise<DialogResult<T>>;
  }

  /**
   * Open dialog exists.
   */
  public openedDialogExists(): boolean {
    return this.dialogRefs.length !== 0 ;
  }

  /** Close all dialogs. */
  public closeLastDialog(): void {
    const dialogRef = this.dialogRefs.pop();
    if (dialogRef) {
      dialogRef.dialogContainerRef.destroy();
      dialogRef.dialogComponentRef.destroy();
    }
  }
}

import { EventEmitter } from '@angular/core';

/** Dialog options. */
export interface IDialogOptions {

  /** Whether the dialog can be closed or not. */
  readonly closable?: boolean;

  /** Whether the close button should be rendered. When `closable` is falsy, dialog may not be closed by ESC or click on backdrop. */
  readonly closeButton?: boolean;

  /** Dialog title. */
  readonly title?: string;

  /** Is title alignment right. */
  readonly isTitleAlignmentRight?: boolean;
}

/**
 * Base dialog interface.
 */
export interface IDialog<Properties = undefined, Result = void> {

  /** Emitter for closing event. */
  readonly closed: EventEmitter<Result>;

  /**
   * Dialog properties. Available on `ngOnInit`.
   */
  props?: Properties;

  /**
   * Dialog options.
   */
  options?: IDialogOptions;
}

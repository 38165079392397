import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/** Preview for empty table. */
@Component({
  selector: 'protctw-empty-table-preview',
  templateUrl: './empty-table-preview.component.html',
  styleUrls: ['./empty-table-preview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyTablePreviewComponent {

  /** Preview title. */
  @Input()
  public title = '';
}

<ul class="documents">
  <li
    *ngFor="let attachedFile of getAttachedFiles()"
    class="documents__item attached-file"
  >
    <mat-icon class="attached-file__icon" svgIcon="attached_file"></mat-icon>
    <a
      class="attached-file__link"
      [href]="attachedFile.fileUri"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ attachedFile.name }}
    </a>
  </li>
</ul>

import { ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { assertIsFormControl } from '@protctc/common/core/utils/forms';

import { IDialog, IDialogOptions } from '../dialog';

/** Prompt dialog options. */
interface PromptDialogOptions {

  /** Input label. */
  readonly label: string;

  /** Prompt message. */
  readonly message: string;

  /** Prompt title. */
  readonly title: string;

  /** Button title. */
  readonly buttonTitle?: string;

  /** Hint message. */
  readonly hint?: string;
}

/** Reject application dialog. */
@Component({
  selector: 'protctc-reject-application-dialog',
  templateUrl: './reject-application-dialog.component.html',
  styleUrls: ['./reject-application-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectApplicationDialogComponent implements IDialog<PromptDialogOptions, string | void> {

  /** Form containing prompt input. */
  public readonly form: UntypedFormGroup;

  /** @inheritdoc */
  public readonly closed = new EventEmitter<string | void>();

  /** @inheritdoc */
  public props: PromptDialogOptions = {
    label: '',
    message: '',
    title: '',
    hint: '',
    buttonTitle: 'OK',
  };

  public constructor(
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.form = this.formBuilder.group({
      value: [null, [Validators.required, Validators.maxLength(2000)]],
    });
  }

  /** @inheritdoc */
  public get options(): IDialogOptions {
    return {
      closable: true,
      closeButton: true,
      title: this.props.title,
    };
  }

  /** Prompt input form control. */
  public get textControl(): UntypedFormControl {
    assertIsFormControl(this.form.controls.value);

    return this.form.controls.value;
  }

  /** Handle submit action. */
  public onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.closed.next(this.form.controls.value.value);
  }
}

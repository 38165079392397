import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { LineItemType } from '../enums/line-item-type';

import { LineItemsSortField } from '../enums/line-items-sort-field';

import { LineItem } from '../models/line-item';
import { LineItemGroup, LineItemGroupCreationData } from '../models/line-item-group';
import { LineItemPaginationOptions } from '../models/line-item-pagination-options';
import { LineItemSaveData } from '../models/line-item-save-data';
import { Pagination } from '../models/pagination';
import { SearchPaginationOptions } from '../models/search-pagination-options';
import { UnionLineItem } from '../models/union-line-item';
import { CustomKeyValue } from '../models/custom-key-value';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { LineItemDto } from './mappers/dto/line-item-dto';
import { LineItemGroupDto } from './mappers/dto/line-item-group-dto';
import { PaginationDto } from './mappers/dto/pagination-dto';
import { PaginationOptionsDto } from './mappers/dto/pagination-options-dto';
import { UnionLineItemDto } from './mappers/dto/union-line-item-dto';
import { LineItemGroupMapper } from './mappers/line-item-group.mapper';
import { LineItemMapper } from './mappers/line-item.mapper';
import { PaginationMapper } from './mappers/pagination.mapper';
import { SortMapper } from './mappers/sort.mapper';
import { UnionLineItemMapper } from './mappers/union-line-item.mapper';
import { CustomKeyValueMapper } from './mappers/custom-key-value.mapper';

/** Mapper to map sort field from domain to local. */
const SORT_FIELD_MAP: Readonly<Record<LineItemsSortField, string>> = {
  [LineItemsSortField.Id]: 'id',
  [LineItemsSortField.Name]: 'name',
  [LineItemsSortField.Description]: 'description',
  [LineItemsSortField.Price]: 'price',
  [LineItemsSortField.Type]: 'type',
  [LineItemsSortField.Modified]: 'modified',
};

/**
 * Line item service.
 * Provides ability to work with line items entities.
 */
@Injectable({
  providedIn: 'root',
})
export class LineItemService {

  private readonly lineItemUrl: string;

  private readonly groupLineItemUrl: string;

  private readonly unionLineItemsUrl: string;

  public constructor(
    appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly lineItemMapper: LineItemMapper,
    private readonly unionLineItemMapper: UnionLineItemMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly paginationMapper: PaginationMapper,
    private readonly sortMapper: SortMapper,
    private readonly lineItemGroupMapper: LineItemGroupMapper,
    private readonly customKeyValueMapper: CustomKeyValueMapper,
  ) {
    this.lineItemUrl = new URL('companies/lineitem/', appConfig.apiUrl).toString();
    this.unionLineItemsUrl = new URL('companies/lineitem_union/', appConfig.apiUrl).toString();
    this.groupLineItemUrl = new URL('companies/lineitem_group/', appConfig.apiUrl).toString();
  }

  /** Get all line items. */
  public getAllLineItems(): Observable<LineItem[]> {
    return this.httpClient.get<LineItemDto[]>(this.lineItemUrl).pipe(
      map(lineItemsDto => lineItemsDto.map(lineItemDto => this.lineItemMapper.fromDto(lineItemDto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get a page of simple line items.
   * @param options Pagination options.
   */
  public getLineItems(options: SearchPaginationOptions): Observable<Pagination<LineItem>> {
    const pagination: PaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      search: options.searchString,
    };

    const params = new HttpParams({
      fromObject: { ...pagination },
    });

    return this.httpClient.get<PaginationDto<LineItemDto>>(
      this.lineItemUrl,
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.lineItemMapper)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get a page of line items with data.
   * @param options Pagination options.
   */
  public getUnionLineItemsWithData(options: SearchPaginationOptions): Observable<Pagination<UnionLineItem>> {
    const pagination: PaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      search: options.searchString,
    };

    const params = new HttpParams({
      fromObject: { ...pagination },
    });
    return this.httpClient.get<PaginationDto<UnionLineItemDto>>(
      this.unionLineItemsUrl,
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.unionLineItemMapper)),
    );
  }

  /**
   * Get a page of line items.
   * @param options Pagination options.
   */
  public getUnionLineItems(options: LineItemPaginationOptions): Observable<Pagination<UnionLineItem>> {
    const pagination: PaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      ordering: this.sortMapper.mapSortOptionsToDto(options.sortOptions, SORT_FIELD_MAP),
    };

    const params = new HttpParams({
      fromObject: { ...pagination },
    });
    return this.httpClient.get<PaginationDto<UnionLineItemDto>>(
      this.unionLineItemsUrl,
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.unionLineItemMapper)),
    );
  }

  /**
   * Save line item group.
   * @param lineItemGroup Line item save data.
   * @param lineItemGroupId Line item group id.
   */
  public saveLineItemGroup(lineItemGroup: LineItemGroupCreationData, lineItemGroupId?: LineItemGroup['id']): Observable<void> {
    if (lineItemGroupId) {
      return this.updateLineItemGroup(lineItemGroup, lineItemGroupId);
    }
    return this.createLineItemGroup(lineItemGroup);
  }

  /**
   * Save line item.
   * @param lineItem Line item save data.
   */
  public save(lineItem: LineItemSaveData): Observable<void> {
    if (lineItem.id) {
      return this.updateLineItem(lineItem, lineItem.id);
    }
    return this.createLineItem(lineItem);
  }

  /**
   * Delete line item with provided id.
   * @param lineItemId Line item id.
   * @param lineItemType Line item type.
   */
  public deleteLineItem(lineItemId: number, lineItemType: LineItemType): Observable<void> {
    const baseLineItemUrl = lineItemType === LineItemType.LineItem ? this.lineItemUrl : this.groupLineItemUrl;
    const url = new URL(`${lineItemId}/`, baseLineItemUrl).toString();
    return this.httpClient.delete<void>(url).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get group of line items.
   * @param id Line item group id.
   */
  public getGroupLineItemById(id: number): Observable<LineItemGroup> {
    const url = new URL(`${id}/`, this.groupLineItemUrl).toString();
    return this.httpClient.get<LineItemGroupDto>(url).pipe(
      map(lineItemGroupDto => this.lineItemGroupMapper.fromDto(lineItemGroupDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get line item by id.
   * @param id Line item id.
   */
  public getLineItemById(id: number): Observable<LineItem> {
    const url = new URL(`${id}/`, this.lineItemUrl).toString();
    return this.httpClient.get<LineItemDto>(
      url,
    ).pipe(
      map(lineItemDto => this.lineItemMapper.fromDto(lineItemDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get the line items that contain this name.
   * @param lineItemName Line item name.
   */
  public searchLineItemsByName(lineItemName: string): Observable<LineItem[]> {
    const options: PaginationOptionsDto = {
      search: lineItemName,
    };

    const params = new HttpParams({
      fromObject: { ...options },
    });

    return this.httpClient.get<LineItemDto[]>(
      this.lineItemUrl,
      { params },
    ).pipe(
      map(items => items.map(item => this.lineItemMapper.fromDto(item))),
    );
  }

  /**
   * Create new line item.
   * @param lineItem Line item to save.
   */
  private createLineItem(lineItem: LineItemSaveData): Observable<void> {
    return this.httpClient.post<void>(
      this.lineItemUrl,
      this.lineItemMapper.toDto(lineItem),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.lineItemMapper),
    );
  }

  /**
   * Update info about existing line item.
   * @param lineItem Line item edit data.
   * @param lineItemId Line item id.
   */
  private updateLineItem(lineItem: LineItemSaveData, lineItemId: number): Observable<void> {
    const url = new URL(`${lineItemId}/`, this.lineItemUrl).toString();
    return this.httpClient.put<void>(
      url,
      this.lineItemMapper.toDto(lineItem),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.lineItemMapper),
    );
  }

  private createLineItemGroup(lineItemGroup: LineItemGroupCreationData): Observable<void> {
    return this.httpClient.post<void>(
      this.groupLineItemUrl,
      this.lineItemGroupMapper.toDto(lineItemGroup),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.lineItemGroupMapper),
    );
  }

  private updateLineItemGroup(lineItemGroup: LineItemGroupCreationData, lineItemGroupId: number): Observable<void> {
    const url = new URL(`${lineItemGroupId}/`, this.groupLineItemUrl).toString();
    return this.httpClient.put<void>(
      url,
      this.lineItemGroupMapper.toDto(lineItemGroup),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.lineItemGroupMapper),
    );
  }

  /** Get tax types. */
  public getTaxTypes(): Observable<CustomKeyValue<string, string>[]> {
    const url = new URL('tax_options/', this.lineItemUrl).toString();
    return this.httpClient.get<CustomKeyValue<string, string>[]>(url).pipe(
      map(optionsDto => optionsDto.map(option => this.customKeyValueMapper.fromDto(option))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

}

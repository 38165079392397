import { Injectable } from '@angular/core';
import { DateRange } from '@protctc/common/core/models/date-range/date-range';
import { DateUtils } from '@protctc/common/core/utils/date-utils';

import { DateRangeDto } from '../dto/date-range/date-range.dto';
import { IMapperToDto } from '../mappers';

/** Date range mapper. */
@Injectable({
  providedIn: 'root',
})
export class DateRangeMapper implements IMapperToDto<DateRangeDto, DateRange> {

  /** @inheritdoc */
  public toDto(data: DateRange): DateRangeDto {
    return {
      date_after: data.start ?
        DateUtils.toDateTimeString(DateUtils.getUTCDate(data.start)) : undefined,
      date_before: data.end ?
        DateUtils.toDateTimeString(DateUtils.getUTCDate(data.end)) : undefined,
    };
  }
}

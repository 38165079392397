import { Injectable } from '@angular/core';

import { InvoiceStatus } from '../../enums/invoice-status';
import { PaymentMethod } from '../../enums/payment-method';
import { Invoice, InvoiceConstructorData } from '../../models/invoice';

import { DateMapper } from './date.mapper';
import { DeliveryPeriodMapper } from './delivery-period.mapper';
import { InvoiceDto } from './dto/invoice-dto';
import { InvoiceAttachmentMapper } from './invoice-attachment.mapper';
import { InvoiceCustomerMapper } from './invoice-customer.mapper';
import { InvoiceDeliveryMapper } from './invoice-delivery.mapper';
import { InvoiceLineItemMapper } from './invoice-line-item.mapper';
import { InvoiceTemplateMapper } from './invoice-template.mapper';
import { IMapperFromDto } from './mappers';

/** Invoice mapper. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceMapper implements IMapperFromDto<InvoiceDto, Invoice> {

  public constructor(
    private readonly invoiceTemplateMapper: InvoiceTemplateMapper,
    private readonly invoiceCustomerMapper: InvoiceCustomerMapper,
    private readonly invoiceLineItemMapper: InvoiceLineItemMapper,
    private readonly invoiceAttachmentMapper: InvoiceAttachmentMapper,
    private readonly dateMapper: DateMapper,
    private readonly invoiceDeliveryMapper: InvoiceDeliveryMapper,
    private readonly deliveryPeriodMapper: DeliveryPeriodMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: InvoiceDto): Invoice {
    return new Invoice(this.mapToInvoiceConstructorData(data));
  }

  private mapToInvoiceConstructorData(data: InvoiceDto): InvoiceConstructorData {
    return {
      id: data.id,
      fieldUser: data.field_user,
      invoiceTemplate: this.invoiceTemplateMapper.fromDto(data.invoice_template),
      customer: this.invoiceCustomerMapper.fromDto(data.customer),
      lineItems: data.line_items.map(this.invoiceLineItemMapper.fromDto),
      attachmentSet: data.attachment_set.map(this.invoiceAttachmentMapper.fromDto),
      disclaimer: data.disclaimer ?? '',
      customerSignature: data.customer_signature,
      note: data.note ?? '',
      tax: data.tax ? Number(data.tax) : 0,
      subtotal: data.subtotal ? Number(data.subtotal) : 0,
      total: data.total ? Number(data.total) : 0,
      fee: data.fee ? Number(data.fee) : 0,
      status: data.status as InvoiceStatus,
      paymentMethod: data.payment_method as PaymentMethod,
      dueDate: data.due_date ? this.dateMapper.fromDto(data.due_date) : undefined,
      businessInformation: {
        id: data.business_information.id,
        name: data.business_information.name,
        ein: data.business_information.ein,
        email: data.business_information.email,
        url: data.business_information.url,
        phone: data.business_information.phone,
        address1: data.business_information.address_1,
      },
      createdDate: this.dateMapper.fromDto(data.created),
      modifiedDate: this.dateMapper.fromDto(data.modified),
      createdBy: data.created_by,
      updatedBy: data.updated_by,
      cardinal: data.cardinal,
      transactionId: data.transaction_id ?? '',
      transactionCardNumber: data.transaction_card_number ?? '',
      transactionCardExpiration: data.transaction_card_exp ?? '',
      ccFeeRate: data.cc_fee_rate ?? 0,
      cashPrice: data.cash_price ? Number(data.cash_price) : 0,
      deliveryMethod: data.invoice_delivery ? this.invoiceDeliveryMapper.fromDto(data.invoice_delivery) : null,
      deliveryPeriod: data.delivery_period ? this.deliveryPeriodMapper.fromDto(data.delivery_period) : null,
      firstAdditionalFieldValue: data.first_additional_field_value ?? '',
      secondAdditionalFieldValue: data.second_additional_field_value ?? '',
      thirdAdditionalFieldValue: data.third_additional_field_value ?? '',
      invoicePrefix: data.mask_prefix,
      invoiceNumber: data.mask_number,
    };
  }
}

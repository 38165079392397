import { Injectable } from '@angular/core';
import { Address } from '@protctc/common/core/models/address/address';

import { AddressDto } from '../dto/address/address.dto';
import { IMapper } from '../mappers';

/** Address mapper. */
@Injectable({
  providedIn: 'root',
})
export class AddressMapper implements IMapper<AddressDto, Address> {

  /** @inheritdoc */
  public toDto(data: Address): AddressDto {
    return {
      address_1: data.line1Address,
      address_2: data.line2Address,
      address_city: data.city,
      address_state: data.state.code,
      address_zip: data.zip,
    };
  }

  /** @inheritdoc */
  public fromDto(data: AddressDto): Address {
    return {
      line1Address: data.address_1,
      line2Address: data.address_2,
      city: data.address_city,
      state: { code: data.address_state, name: '' },
      zip: data.address_zip,
    };
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

const DEFAULT_DIAMETER = 80;

/**
 * Page loader component.
 * Parent component that contains loader should be relative.
 */
@Component({
  selector: 'protctc-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLoaderComponent {

  /** Spinner diameter. */
  @Input()
  public diameter = DEFAULT_DIAMETER;
}

import { UserRole } from '../enums/user-role';

/** Data needed for the construction of user model. */
export type UserConstructorData = User;

/** User model. */
export class User {

  /** User id. */
  public readonly id: number;

  /** Employee id. */
  public readonly employeeId: string;

  /** First name. */
  public readonly firstName: string;

  /** Last name. */
  public readonly lastName: string;

  /** Role. */
  public readonly role: UserRole;

  /** Email. */
  public readonly email: string;

  /** User's title. */
  public readonly title: string;

  /** User's biography. */
  public readonly bio: string;

  /** Is active user. */
  public readonly isActive: boolean;

  /** Company id. */
  public readonly companyId: number | null;

  /** Last login. */
  public readonly lastLogin: Date | null;

  /** Updated by. */
  public readonly updatedBy: string;

  /** Modified date. */
  public readonly modifiedDate: Date;

  public constructor(data: UserConstructorData) {
    this.id = data.id;
    this.employeeId = data.employeeId;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.title = data.title;
    this.bio = data.bio;
    this.role = data.role;
    this.email = data.email;
    this.isActive = data.isActive;
    this.companyId = data.companyId;
    this.lastLogin = data.lastLogin;
    this.updatedBy = data.updatedBy;
    this.modifiedDate = data.modifiedDate;
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Breadcrumb } from '@protctc/common/core/models/breadcrumb';
import { NavigationLink } from '@protctc/common/core/models/navigation-link';
import { BreadcrumbsService } from '@protctc/common/core/services/breadcrumbs.service';

/** Breadcrumbs. */
@Component({
  selector: 'protctw-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {

  /** Links for breadcrumbs. */
  @Input()
  public links: NavigationLink[] = [];

  /** Breadcrumbs. */
  public readonly breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;

  public constructor(
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly router: Router,
  ) { }

  /**
   * Track by for the breadcrumbs.
   * @param _ Index.
   * @param crumb Breadcrumb.
   */
  public trackBreadcrumbs(_: number, crumb: Breadcrumb): string {
    return crumb.url;
  }

  /**
   * Define is base url or url with query is active link.
   * We can't use routerLinkActive directive directly,
   * because without `exact` parent breadcrumb link is active too,
   * but with `exact` parameter - link with query params is not active.
   * @param url Url.
   */
  public isLinkActive(url: string): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }
}

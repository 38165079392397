import { Injectable } from '@angular/core';

import { InvoiceAttachment } from '../../models/invoice-attachment';

import { InvoiceAttachmentDto } from './dto/invoice-attachment-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';

/** Mapper for invoice template notes entity. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceAttachmentMapper implements
IMapperToDto<InvoiceAttachmentDto, InvoiceAttachment>,
IMapperFromDto<InvoiceAttachmentDto, InvoiceAttachment> {

  /** @inheritdoc */
  public fromDto(data: InvoiceAttachmentDto): InvoiceAttachment {
    return {
      id: data.id,
      name: data.name,
      file: data.file,
      invoiceId: data.invoice,
    };
  }

  /** @inheritdoc */
  public toDto(data: InvoiceAttachment): InvoiceAttachmentDto {
    return {
      id: data.id,
      name: data.name,
      file: data.file,
      invoice: data.invoiceId,
    };
  }
}

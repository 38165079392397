import { Injectable } from '@angular/core';

import { CompanyStatistics, CompanyStatisticsConstructorData } from '../../models/company-statistics';

import { CompanyStatisticsDto } from './dto/company-statistics-dto';
import { IMapperFromDto } from './mappers';

/** Company statistics mapper. */
@Injectable({
  providedIn: 'root',
})
export class CompanyStatisticsMapper
implements IMapperFromDto<CompanyStatisticsDto, CompanyStatistics> {

  /** @inheritdoc */
  public fromDto(data: CompanyStatisticsDto): CompanyStatistics {
    return new CompanyStatistics(this.mapToCompanyStatisticsConstructorData(data));
  }

  private mapToCompanyStatisticsConstructorData(data: CompanyStatisticsDto): CompanyStatisticsConstructorData {
    return {
      totalRevenueCount: data.total_revenue_count,
      totalRevenueDifference: data.total_revenue_diff,
      totalInvoiceCount: data.total_invoice_count,
      totalInvoiceDifference: data.total_invoice_diff,
      totalCustomerCount: data.total_customer_count,
      totalCustomerDifference: data.total_customer_diff,
      totalNewCustomerCount: data.total_new_customer_count,
      totalNewCustomerDifference: data.total_new_customer_diff,
      totalTransactionCount: data.total_transaction_count,
      totalTransactionDifference: data.total_transaction_diff,

      /** TODO (Sakhno V): Update mapper when fee saved data will be appeared. */
      totalFeeSavedCount: data.total_fee_saved_count ?? 0,
      totalFeeSavedCountDifference: data.total_fee_saved_count_diff ?? 0,
    };
  }
}

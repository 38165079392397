<img
  *ngIf="src; else skeleton"
  class="image"
  #imageElement
  [src]="getAvatarUrl(imageElement)"
  alt="Logo Preview"
/>

<ng-template #skeleton>
  <div class="skeleton"></div>
</ng-template>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DestroyableComponent } from '@protctc/common/core/utils/destroyable';

/** Icon tooltip component. */
@DestroyableComponent()
@Component({
  selector: 'protctc-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTooltipComponent {

  /** Tooltip message. */
  @Input()
  public message = '';
}

<div class="autocomplete">
  <mat-form-field class="form-field">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      #autocompleteInput
      #autocompleteTrigger="matAutocompleteTrigger"
      matInput
      type="text"
      [disabled]="disabled"
      [ngModel]="controlValue"
      (ngModelChange)="onChange($event)"
      [matAutocomplete]="auto"
      [required]="required"
      (blur)="onInputBlur($event)"
    />
    <mat-autocomplete
      protctcMatSelectInfiniteScroll
      [autocompleteTrigger]="autocompleteTrigger"
      (infiniteScroll)="autocompleteScroll()"
      [complete]="(hasMoreItems$ | async) ?? false"
      #auto="matAutocomplete"
      autoActiveFirstOption
      [displayWith]="toReadable$ | async"
      (optionSelected)="autocompleteInput.blur()"
    >
      <ng-container
        *ngIf="filterValueControl.value.length >= minSearchTermLength"
      >
        <ng-container *ngLet="data$ | async as data">
          <ng-container *ngIf="trackBy$ | async as trackBy">
            <mat-option
              *ngFor="let item of data; trackBy: trackBy"
              [value]="item"
            >
              <ng-container *ngIf="toReadable$ | async as toReadable">
                <ng-template #defaultTemplate>
                  {{ toReadable(item) }}
                </ng-template>

                <ng-container
                  *ngTemplateOutlet="
                    template ? template : defaultTemplate;
                    context: getOptionViewContext(item, toReadable(item))
                  "
                ></ng-container>
              </ng-container>
            </mat-option>
            <ng-container *ngIf="isLoading">
              <mat-option disabled>
                <mat-spinner diameter="35"></mat-spinner>
              </mat-option>
            </ng-container>

            <ng-container *ngIf="data?.length === 0 && isLoading === false">
              <mat-option disabled>
                <span> No items found</span>
              </mat-option>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-autocomplete>
    <mat-icon
      *ngIf="icon"
      class="autocomplete__icon"
      [svgIcon]="icon"
      matSuffix
    ></mat-icon>
  </mat-form-field>
</div>

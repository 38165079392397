import { Injectable } from '@angular/core';

import { InvoiceStatus } from '../../enums/invoice-status';
import { PaymentMethod } from '../../enums/payment-method';
import { InvoiceShort, InvoiceShortConstructorData } from '../../models/invoice-short';

import { DateMapper } from './date.mapper';
import { InvoiceShortDto } from './dto/invoice-short-dto';
import { IMapperFromDto } from './mappers';
import { RecurringScheduleMapper } from './recurring-schedule/recurring-schedule.mapper';

/** Invoice short mapper. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceShortMapper implements IMapperFromDto<InvoiceShortDto, InvoiceShort> {

  public constructor(
    private readonly dateMapper: DateMapper,
    private readonly recurringScheduleMapper: RecurringScheduleMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: InvoiceShortDto): InvoiceShort {
    return new InvoiceShort(this.mapConstructorData(data));
  }

  /**
   * Map constructor data for a invoice short.
   * @param data Data to map.
   */
  private mapConstructorData(data: InvoiceShortDto): InvoiceShortConstructorData {
    return {
      id: data.id,
      workDetails: data.work_details ?? [],
      status: data.status as InvoiceStatus,
      customerName: data.customer_name,
      createdDate: this.dateMapper.fromDto(data.created),
      dueDate: data.due_date ? this.dateMapper.fromDto(data.due_date) : undefined,
      amount: Number(data.total),
      paymentMethod: data.payment_method as PaymentMethod,
      cardinal: data.cardinal,
      transactionId: data.transaction_id ?? '',
      savedFee: data.fee ? Number(data.fee) : 0,
      invoiceNumber: data.mask_number,
      invoicePrefix: data.mask_prefix,
      recurringSchedule: data.recurring_schedule ? this.recurringScheduleMapper.fromDto(data.recurring_schedule) : null,
    };
  }
}

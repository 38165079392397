import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CompanyLogo } from '../models/company-logo';

const DEFAULT_LOGO_SRC = 'assets/company_avatar_default.svg';

/** Logo service. */
@Injectable({
  providedIn: 'root',
})
export class LogoService {

  /** Logo. */
  public readonly logo$: Observable<CompanyLogo | null>;

  private readonly _logo$ = new BehaviorSubject<CompanyLogo | null>({ id: null, url: DEFAULT_LOGO_SRC });

  public constructor() {
    this.logo$ = this._logo$.asObservable();
  }

  /**
   * Set logo.
   * @param logo New logo.
   */
  public setLogo(logo: CompanyLogo): void {
    this._logo$.next(logo);
  }
}

/** Fields available for sorting the invoice short items. */
export enum InvoiceShortSortField {

  /** Invoice number. */
  InvoiceNumber = 'invoiceNumber',

  /** Created date. */
  CreatedDate = 'createdDate',

  /** Due date. */
  DueDate = 'dueDate',

  /** Customer name. */
  CustomerName = 'customerName',

  /** Status. */
  Status = 'status',

  /** Payment method. */
  PaymentMethod = 'paymentMethod',

  /** Amount. */
  Amount = 'amount',

  /** Fee saved. */
  FeeSaved = 'feeSaved',

  Default = CreatedDate,
}

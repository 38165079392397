/** Content type. */
export enum ContentType {

  /** Image. */
  Image = 'image',

  /** Video. */
  Video = 'video',

  /** Document. */
  Document = 'document',

  /** Unknown. */
  Unknown = 'unknown',
}

/** All existing content types. */
export enum ExistingContentTypes {

  /** Application. */
  Application = 'application',

  /** Audio. */
  Audio = 'audio',

  /** Font. */
  Font = 'font',

  /** Image. */
  Image = 'image',

  /** Message. */
  Message = 'message',

  /** Model. */
  Model = 'model',

  /** Multipart. */
  Multipart = 'multipart',

  /** Text. */
  Text = 'text',

  /** Video. */
  Video = 'video',

  /** For case when type file is empty string. */
  Plain = '',
}

export namespace ContentType {

  /** Map of supported content types. */
  export const SUPPORTED_CONTENT_TYPES: Record<ExistingContentTypes, ContentType> = {
    [ExistingContentTypes.Image]: ContentType.Image,
    [ExistingContentTypes.Video]: ContentType.Video,
    [ExistingContentTypes.Application]: ContentType.Document,
    [ExistingContentTypes.Text]: ContentType.Document,
    [ExistingContentTypes.Plain]: ContentType.Document,


    // Some of this formats may be available in the future.
    [ExistingContentTypes.Font]: ContentType.Unknown,
    [ExistingContentTypes.Message]: ContentType.Unknown,
    [ExistingContentTypes.Audio]: ContentType.Unknown,
    [ExistingContentTypes.Model]: ContentType.Unknown,
    [ExistingContentTypes.Multipart]: ContentType.Unknown,
  };
}

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { InvoiceStatus } from '@protctc/common/core/enums/invoice-status';
import { Invoice } from '@protctc/common/core/models/invoice';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';
import { InvoiceService } from '@protctc/common/core/services/invoice.service';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { AlertDialogComponent } from '@protctc/common/shared/dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '@protctc/common/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogsService } from '@protctc/common/shared/dialogs/dialogs.service';
import {
  BehaviorSubject,
  EMPTY,
  first,
  from,
  mapTo,
  Observable,
  switchMap,
  tap,
} from 'rxjs';

/** Refund invoice button component. */
@DestroyableComponent()
@Component({
  selector: 'protctw-refund-invoice-button',
  templateUrl: './refund-invoice-button.component.html',
  styleUrls: ['./refund-invoice-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefundInvoiceButtonComponent {

  /** Invoice. */
  @Input()
  public invoice: Invoice | null = null;

  /** Invoice refunded emitter. */
  @Output()
  public readonly invoiceRefunded = new EventEmitter<boolean>();

  /** Invoice status. */
  public readonly invoiceStatus = InvoiceStatus;

  private readonly invoiceAfterRefund$ = new BehaviorSubject<Invoice | null>(null);

  public constructor(
    private readonly invoiceService: InvoiceService,
    private readonly dialogsService: DialogsService,
  ) { }

  /** Is disabled refund button. */
  public get isDisabled(): boolean {
    assertNonNull(this.invoice);
    return this.invoice.status !== this.invoiceStatus.Paid;
  }

  /** Refund invoice. */
  public refundInvoice(): void {
    assertNonNull(this.invoice);

    from(this.dialogsService.openDialog(ConfirmDialogComponent, {
      message: 'Do you want to refund this invoice?',
      title: 'Refund invoice',
      isConfirmButtonDanger: true,
      confirmAction: this.invoiceService.refundInvoice(this.invoice.id).pipe(
        tap(invoice => this.invoiceAfterRefund$.next(invoice)),
        mapTo(void 0),
      ),
    })).pipe(
      switchMap(isRefunded => isRefunded ? this.completeRefundInvoice() : EMPTY),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  private completeRefundInvoice(): Observable<void> {
    return this.invoiceAfterRefund$.pipe(
      first(invoice => invoice !== null),
      filterNull(),
      switchMap(invoice => from(this.showRefundStatus(invoice)).pipe(
        tap(() => this.invoiceRefunded.emit(this.isRefundedInvoice(invoice))),
      )),
      mapTo(void 0),
    );
  }

  private showRefundStatus(invoice: Invoice): Promise<void> {
    if (this.isRefundedInvoice(invoice)) {
      return this.showRefundSuccess();
    }
    return this.showRefundFailed();
  }

  private async showRefundSuccess(): Promise<void> {
    await this.dialogsService.openDialog(AlertDialogComponent, {
      buttonTitle: 'OK',
      message: 'Invoice successfully refunded',
      title: 'Invoice refunded',
    });
  }

  private async showRefundFailed(): Promise<void> {
    await this.dialogsService.openDialog(AlertDialogComponent, {
      title: 'Something went wrong',
      message: `Refund invoice operation failed`,
    });
  }

  private isRefundedInvoice(invoice: Invoice): boolean {
    return invoice.status === InvoiceStatus.Refunded;
  }
}

import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlatformsDirective } from '@protctc/common/shared/directives/platforms.directive';
import { ProtctPlatformService } from '@protctc/common/core/services/protct-platform.service';

/**
 * A structural directive for only mobile platform.
 */
@Directive({
  selector: '[protctcMobileOnly]',
})
export class MobileOnlyDirective extends PlatformsDirective {

  public constructor(
    templateRef: TemplateRef<unknown>,
    viewContainer: ViewContainerRef,
    platformService: ProtctPlatformService,
  ) {
    super(
      templateRef,
      viewContainer,
      platformService,
    );
    this.protctcPlatform = ['mobile'];
  }

}

import { Injectable } from '@angular/core';

import { FieldUserExtra } from '../../models/field-user-extra';

import { FieldUserExtraDto } from './dto/field-user-extra-dto';
import { IMapperFromDto } from './mappers';

/** Field user extra data mapper. */
@Injectable({ providedIn: 'root' })
export class FieldUserExtraMapper implements IMapperFromDto<FieldUserExtraDto, FieldUserExtra> {

  /** @inheritdoc */
  public fromDto(data: FieldUserExtraDto): FieldUserExtra {
    return {
      id: data.id,
      fullName: data.full_name,
      email: data.email,
      title: data.title,
      totalGeneratedAmount: data.generated_amount,
      totalInvoiceCount: data.total_invoice_count,
    };
  }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/** Main logo. */
@Component({
  selector: 'protctw-main-logo',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MainLogoComponent {

  /** Logo src. */
  @Input()
  public logo = '';
}

import { enumToArray } from '../utils/enum-to-array';

/** Payrix account type. */
export enum PayrixAccountType {

  /** All. */
  All = 'all',

  /** Credit. */
  Credit = 'credit',

  /** Debit. */
  Debit = 'debit',
}

/**
 * Namespace for payrix account type
 * Contains util methods and props for easier enum use.
 */
export namespace PayrixAccountType {

  const READABLE_MAP: Record<PayrixAccountType, string> = {
    [PayrixAccountType.All]: 'All',
    [PayrixAccountType.Credit]: 'Credit',
    [PayrixAccountType.Debit]: 'Debit',
  };

  /**
   * Return human-readable method representation.
   * @param method Payment method.
   */
  export function toReadable(method: PayrixAccountType): string {
    return READABLE_MAP[method];
  }

  /** Get list for all payment methods. */
  export function toArray(): PayrixAccountType[] {
    return enumToArray(PayrixAccountType);
  }
}

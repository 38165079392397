import { CustomKeyValue } from '../models/custom-key-value';

/** Invoice status. */
export enum InvoiceStatus {

  /** Paid. */
  Paid = 'paid',

  /** Sent. */
  Sent = 'sent',

  /** Overdue. */
  Overdue = 'overdue',

  /** Refunded. */
  Refunded = 'refunded',

  /** Pending. */
  Pending = 'pending',

  /** Assigned. */
  Assigned = 'assigned',

  /** Declined. */
  Declined = 'declined',

  /** Send failed. */
  SentFailed = 'sent_fail',

  /** All. */
  All = '',
}

/**
 * Namespace for invoice status.
 * Contains util methods and props for easier enum use.
 */
export namespace InvoiceStatus {

  const READABLE_MAP: Record<InvoiceStatus, string> = {
    [InvoiceStatus.Paid]: 'Paid',
    [InvoiceStatus.Sent]: 'Sent',
    [InvoiceStatus.Overdue]: 'Overdue',
    [InvoiceStatus.Refunded]: 'Refunded',
    [InvoiceStatus.Pending]: 'Pending',
    [InvoiceStatus.All]: 'All status',
    [InvoiceStatus.Assigned]: 'Assigned',
    [InvoiceStatus.Declined]: 'Declined',
    [InvoiceStatus.SentFailed]: 'Sent failed',
  };

  /**
   * Return human-readable status representation.
   * @param status Invoice status.
   */
  export function toReadable(status: InvoiceStatus): string {
    return READABLE_MAP[status];
  }

  /** Get list for all invoice statuses. */
  export function toArray(): InvoiceStatus[] {
    const enumType = typeof InvoiceStatus.Paid;
    return Object.values(InvoiceStatus)
      .filter(status => typeof status === enumType)
      .map(status => status as InvoiceStatus);
  }

  /** Get statuses for editable invoice. */
  export function getStatusesEditableInvoice(): InvoiceStatus[] {
    const includedStatuses = [
      InvoiceStatus.Pending,
      InvoiceStatus.Declined,
      InvoiceStatus.Assigned,
      InvoiceStatus.SentFailed,
    ];
    return InvoiceStatus.toArray().filter(status => includedStatuses.includes(status));
  }

  /** Get statuses for invoice creation only. */
  export function getInvoiceCreationStatusesOnly(): CustomKeyValue<string, InvoiceStatus>[] {
    const includedStatuses = [
      InvoiceStatus.Paid,
      InvoiceStatus.Pending,
    ];

    return InvoiceStatus.toKeyValueArray().filter(keyValue => includedStatuses.includes(keyValue.value));

  }

  /** Get list for key value invoice statuses array. */
  export function toKeyValueArray(): CustomKeyValue<string, InvoiceStatus>[] {
    return InvoiceStatus.toArray().map(value => ({ value, key: InvoiceStatus.toReadable(value) }));
  }
}

import { enumToArray } from '../utils/enum-to-array';

/** Payrix account payment method. */
export enum PayrixAccountPaymentMethod {

  /** Checking. */
  Checking = 8,

  /** Savings. */
  Savings = 9,

  /** Corporate checking. */
  CorporateChecking = 10,

  /** Corporate savings. */
  CorporateSavings = 11,
}

/**
 * Namespace for payrix account payment method
 * Contains util methods and props for easier enum use.
 */
export namespace PayrixAccountPaymentMethod {

  const READABLE_MAP: Record<PayrixAccountPaymentMethod, string> = {
    [PayrixAccountPaymentMethod.Checking]: 'Checking',
    [PayrixAccountPaymentMethod.Savings]: 'Savings',
    [PayrixAccountPaymentMethod.CorporateChecking]: 'Corporate checking',
    [PayrixAccountPaymentMethod.CorporateSavings]: 'Corporate savings',
  };

  /**
   * Return human-readable method representation.
   * @param method Payment method.
   */
  export function toReadable(method: PayrixAccountPaymentMethod): string {
    return READABLE_MAP[method];
  }

  /** Get list for all payment methods. */
  export function toArray(): PayrixAccountPaymentMethod[] {
    return enumToArray(PayrixAccountPaymentMethod);
  }
}

import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ProtctPlatformService } from '@protctc/common/core/services/protct-platform.service';
import { PlatformsDirective } from '@protctc/common/shared/directives/platforms.directive';

/**
 * A structural directive for only web platform.
 */
@Directive({
  selector: '[protctcWebOnly]',
})
export class WebOnlyDirective extends PlatformsDirective {

  public constructor(
    templateRef: TemplateRef<unknown>,
    viewContainer: ViewContainerRef,
    platformService: ProtctPlatformService,
  ) {
    super(
      templateRef,
      viewContainer,
      platformService,
    );
    this.protctcPlatform = ['web'];
  }

}

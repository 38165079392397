import { Injectable } from '@angular/core';

import { MerchantPartner } from '../../models/company/merchant-partner';

import { DateMapper } from './date.mapper';
import { IMapperFromDto } from './mappers';
import { MerchantPartnerDto } from './dto/merchant-partner-dto';

/** Merchant partner mapper. */
@Injectable({
  providedIn: 'root',
})
export class MerchantPartnerMapper
implements IMapperFromDto<MerchantPartnerDto, MerchantPartner> {

  public constructor(
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: MerchantPartnerDto): MerchantPartner {
    return {
      id: data.id,
      name: data.name,
      ein: data.ein,
      email: data.email,
      invoiceCount: data.invoice_count,
      hasOverdue: data.has_overdue,
      lastActivity: data.last_activity ? this.dateMapper.fromDto(data.last_activity) : null,
      deactivatedAt: data.deactivated_at ? this.dateMapper.fromDto(data.deactivated_at) : null,
      deactivatedBy: data.deactivated_by ?? '',
      isMonthlyFeeEnable: data.is_monthly_payment,
      hasPaymentInfo: data.has_payment_info,
    };
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { UserRole } from '../enums/user-role';
import { User } from '../models/user';
import { UserService } from '../services/user.service';

/**
 * Mapper to map user role to home url.
 * TODO (Sakhno V): Update when field user route will be implemented.
 */
const USER_ROLE_URL_MAP: Readonly<Record<UserRole, string>> = {
  [UserRole.BusinessOwner]: '/',
  [UserRole.SuperAdmin]: '/admin',
  [UserRole.FieldUser]: '/field-user',
};

/** Guard prevents user from accessing /auth route after logging in. */
@Injectable()
export class AuthorizedGuard implements CanActivate {

  private readonly currentUser$: Observable<User | null>;

  public constructor(
    userService: UserService,
    private readonly router: Router,
  ) {
    this.currentUser$ = userService.currentUser$;
  }

  /**
   * Determine whether the user can activate the route
   * according to his role and authorization status.
   * For check role permission should has additional data in definition like in example.
   * @param route Activated route snapshot.
   * @example
   * ```ts
   * {
   *    path: 'admin',
   *    component: AdminComponent,
   *    canActivate: [AuthorizedGuard],
   *    data: { roles: [UserRole.SuperAdmin] }
   * },
   *
   * ```
   */
  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.currentUser$.pipe(
      map(user => {
        if (user) {
          // Check if role is restricted by role.
          if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
            // Role not authorized, so redirect to home page.
            return this.router.parseUrl(USER_ROLE_URL_MAP[user.role]);
          }

          // Authorized so return true.
          return true;
        }
        return this.router.parseUrl('/auth');
      }),
    );
  }
}

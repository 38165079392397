<mat-form-field class="form-field">
  <mat-label>{{ labelText }}</mat-label>
  <input
    matInput
    (complete)="completeDateString($event)"
    (accept)="changeDateString($event)"
    [formControl]="dateStringControl"
    [imask]="dateMask"
    [required]="required"
    [unmask]="true"
  />
  <input
    matInput
    class="hidden-input"
    [matDatepicker]="picker"
    [placeholder]="placeholderInternal"
    [formControl]="dateControl"
    [max]="max"
    [min]="min"
  />
  <mat-datepicker-toggle
    *ngIf="withToggle$ | async"
    matSuffix
    [for]="picker"
  ></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

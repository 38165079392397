/** Fields available for sorting the recurring schedule items. */
export enum RecurringScheduleSortField {

  /** ID. */
  ID = 'id',

  /** Recurring schedule type. */
  RecurringSchedule = 'recurringSchedule',

  /** Start date. */
  StartDate = 'startDate',

  /** Next generate date. */
  NextGenerateDate = 'nextGenerateDate',

  /** Created date. */
  CreatedDate = 'createdDate',

  /** Default. */
  Default = CreatedDate,
}

/** Fields available for sorting the users. */
export enum UsersSortField {

  /** Id. */
  Id = 'id',

  /** Employee id. */
  EmployeeId = 'employeeId',

  /** User name contains first and last names. */
  Name = 'name',

  /** Title of user. */
  Title = 'title',

  /** User role. */
  Role = 'role',

  /** Email. */
  Email = 'email',

  /** Last activity of user - last login of user. */
  LastActivity = 'lastActivity',

  /** Default sort field. */
  Default = LastActivity,
}

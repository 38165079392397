import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { FormControl, FormControlTyped } from '@angular/forms';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { formatDate, masks } from '@protctc/common/core/utils/masks';
import { BehaviorSubject, Observable, filter, map, merge, startWith, tap } from 'rxjs';
import { listenControlChanges } from '@protctc/common/core/rxjs/listen-control-changes';

/** Available types of datepicker view state. */
export type DatepickerType = 'date' | 'datetime' | 'month' | 'year';

type DateMask = ReturnType<typeof masks.dateMaskFactory>;

/** Datepicker component. */
@DestroyableComponent()
@Component({
  selector: 'protctc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements OnInit {

  /** Date control. */
  @Input()
  public dateControl!: FormControlTyped<Date | null> | FormControl<Date | null>;

  /** Type of the input. */
  @Input()
  public datetype: DatepickerType = 'date';

  /** Placeholder. */
  @Input()
  public placeholder: string | null = null;

  /** Max date. */
  @Input()
  public max: Date | null = null;

  /** Min date. */
  @Input()
  public min: Date | null = null;

  /** Max year. */
  @Input()
  public maxYear: Date | null = null;

  /** Label text. */
  @Input()
  public labelText = 'Choose a date';

  /** Show toggle icon. */
  @Input()
  public readonly withToggle$ = new BehaviorSubject(true);

  /** Required flag. */
  @Input()
  public required = false;

  /** Date mask. */
  public dateMask: DateMask | undefined = undefined;

  /**
   * Date mask with control with Date type bad working.
   */
  public dateStringControl = new FormControl<string>('');

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private readonly defaultPlaceholdersDict: Record<DatepickerType, string> = {
    date: 'MM/DD/YYYY',
    datetime: 'MM/DD/YYYY HH:MM',
    month: 'Select',
    year: 'YYYY',
  };

  /** Placeholder. */
  public get placeholderInternal(): string {
    return this.placeholder ?? this.defaultPlaceholdersDict[this.datetype];
  }

  /**
   * When the entire date is entered into the mask, it is converted and sets the value to the control.
   * @param dateString A date string consisting of 8 digits.
   */
  public completeDateString(dateString: string): void {
    const month = parseInt(dateString.slice(0, 2), 10);
    const day = parseInt(dateString.slice(2, 4), 10);
    const year = parseInt(dateString.slice(4, 8), 10);
    this.dateControl.setValue(new Date(year, month - 1, day), { emitEvent: false });
  }

  /**
   *  When mask value has changed to empty string, should to reset date.
   * @param dateString A date string consisting of 8 digits.
   */
  protected changeDateString(dateString: string): void {
    if (dateString === '') {
      this.dateControl.setValue(null);
    }
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    listenControlChanges<Date>(this.dateControl, { debounceTime: 0 }).pipe(
      map(date => formatDate(date)),
      takeUntilDestroy(this),
    )
      .subscribe(value => this.dateStringControl.setValue(value));

    this.dateMask = masks.dateMaskFactory(this.min, this.max);
    const changeWithToggleSideEffect$ = this.dateControl.statusChanges.pipe(
      startWith(this.dateControl.status),
      map(status => {
        const shouldShowToggleIcon = status !== 'DISABLED';
        this.withToggle$.next(shouldShowToggleIcon);
      }),
    );

    if (this.dateControl.disabled) {
      this.dateStringControl.disable();
    }

    merge(
      changeWithToggleSideEffect$,
      this.syncTouchedSideEffect(),
    ).pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  private syncTouchedSideEffect(): Observable<void> {
    return this.dateControl.statusChanges.pipe(
      map(() => this.dateControl.touched),
      filter(touched => touched),
      tap(() => this.dateStringControl.markAsTouched()),
      map(() => undefined),
    );
  }
}

/** Fields available for sorting the invoice short items. */
export enum AdminInvoiceShortSortField {

  /** Transaction id. */
  TransactionId = 'transactionId',

  /** Created date. */
  CreatedDate = 'createdDate',

  /** Business ID. */
  BusinessId = 'businessId',

  /** Business EIN. */
  BusinessEIN = 'businessEIN',

  /** Customer name. */
  CustomerName = 'customerName',

  /** Location. */
  Location = 'location',

  /** Payment method. */
  PaymentMethod = 'paymentMethod',

  /** Amount. */
  Amount = 'amount',

  /** Fee. */
  Fee = 'fee',

  /** Shared revenue. */
  SharedRevenue = 'sharedRevenue',

  /** Status. */
  Status = 'status',

  Default = CreatedDate,
}

import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_ZEROS_LENGTH = 6;

/**
 * Format user id for presentation.
 */
@Pipe({
  name: 'leadingZeros',
})
export class LeadingZerosPipe implements PipeTransform {

  /**
   * Transform value by adding leading zeros.
   * @param value Value.
   * @param zerosLength Length of zeros.
   */
  public transform(value: number | string, zerosLength = DEFAULT_ZEROS_LENGTH): string {
    return String(value).padStart(zerosLength, '0');
  }
}

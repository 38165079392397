export namespace DateUtils {

  const JANUARY_MONTH_INDEX = 0;
  const FIRST_DAY = 1;

  /** Get first day of current year. */
  export function getFirstDayOfCurrentYear(): Date {
    return new Date(new Date().getFullYear(), JANUARY_MONTH_INDEX, FIRST_DAY);
  }

  /**
   * Get next date of the passed date.
   * @param date Date to get the next date.
   */
  export function getNextDate(date: Date): Date {
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    return nextDate;
  }

  /**
   * Get previous date of the passed date.
   * @param date Date to get the next date.
   */
  export function getPreviousDate(date: Date): Date {
    const prevDate = new Date(date);
    prevDate.setDate(date.getDate() - 1);
    return prevDate;
  }

  /**
   * Pads a number with a leading zero.
   * @param num The number to pad.
   * @returns A string representation of the padded number.
   */
  function zeroPad(num: number): string {
    return num.toString().padStart(2, '0');
  }

  /**
   * Format data to Date only string format.
   * @param date Date.
   * @param format Format of date.
   */
  export function toDateOnlyString(date: Date, format: DateOnlyFormat = DateOnlyFormat.First): string {
    const isValidDate = date instanceof Date && !Number.isNaN(date.getDate());
    if (isValidDate && date) {
      const year = date.getFullYear().toString();
      const month = zeroPad(date.getMonth() + 1);
      const day = zeroPad(date.getDate());

      if (format === DateOnlyFormat.First) {
        return [year, month, day].join('-');
      }

      if (format === DateOnlyFormat.Second) {
        return [month, day, year].join('/');
      }
    }
    return '';
  }

  /**
   * Format date to string yyyy-mm-dd hh:mm:ss format.
   * @param date Date.
   */
  export function toDateTimeString(date: Date): string {
    const isValidDate = date instanceof Date && !Number.isNaN(date.getDate());
    if (isValidDate && date) {
      const year = date.getFullYear().toString();
      const month = zeroPad(date.getMonth() + 1);
      const day = zeroPad(date.getDate());
      const hours = zeroPad(date.getHours());
      const minutes = zeroPad(date.getMinutes());
      const seconds = zeroPad(date.getSeconds());
      const time = `${hours}:${minutes}:${seconds}`;

      return `${[year, month, day].join('-')} ${time}`;
    }
    return '';
  }

  /**
   * Get UTC date.
   * @param date Date.
   */
  export function getUTCDate(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );
  }

  // TODO (Svobodina) fix when api updated.
  /**
   * Format to route params.
   * @param date Date.
   */
  export function toRouteParamsString(date: Date | null): string {
    if (date === null) {
      return '';
    }

    const isValidDate = date instanceof Date && !Number.isNaN(date.getDate());
    if (isValidDate && date) {
      return toDateOnlyString(getUTCDate(date));
    }
    return '';

  }

  /** Date only format. */
  export enum DateOnlyFormat {

    /** Yyyy-mm-dd format. */
    First = 'yyyy-mm-dd',

    /** Dd/mm/yyyy format. */
    Second = 'dd/mm/yyyy',

  }

  /**
   * Validate date or string date format.
   * @param date Date for validation.
   */
  export function isValidDateFormat(date: string | Date): boolean {
    const dateCandidate = new Date(date);
    return dateCandidate instanceof Date && !Number.isNaN(dateCandidate);
  }

  /**
   * Parse date from string.
   * @param dateStr Date string.
   */
  export function parseDate(dateStr: string): Date | null {
    if (dateStr && DateUtils.isValidDateFormat(dateStr)) {
      return new Date(dateStr);
    }
    return null;
  }

  /**
   * Compares two dates and determines whether they are equal.
   * @param previousDate Previous date to compare for equality.
   * @param nextDate Next date to compare for equality.
   */
  export function areDatesEqual(previousDate: Date, nextDate: Date): boolean {
    return previousDate.getTime() === nextDate.getTime();
  }
}

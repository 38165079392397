import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, ElementRef, Inject, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { WINDOW } from '@protctc/common/core/services/window.service';

/** Step incorrect data. */
export interface StepIncorrect {

  /** Step index. */
  readonly index: number;

  /** Incorrect. */
  readonly isIncorrect: boolean;
}

/** Stepper component. */
@Component({
  selector: 'protctc-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }],
})
export class CustomStepperComponent extends CdkStepper {

  /** Is loading. */
  @Input()
  public isLoading: boolean | null = false;

  /** Emit when stepper is complete. */
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  public readonly complete = new EventEmitter<void>();

  /** Emit state of errors of current stepper. */
  @Output()
  public readonly stepIncorrect = new EventEmitter<StepIncorrect>();

  public constructor(
    dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    _elementRef: ElementRef<HTMLElement>,
    private readonly location: Location,
    private readonly router: Router,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    super(dir, changeDetectorRef, _elementRef);
  }

  /** Check if current step is last. */
  public get isLastStep(): boolean {
    return this.selected === this.steps.last;
  }

  /** Text of the button to change steps. */
  public get nextButtonText(): string {
    return this.isLastStep ? 'Submit application' : 'Continue';
  }

  /**
   * Handle popstate event to prevent angular navigation and show previos step.
   */
  @HostListener('window:popstate')
  public handleBackButtonClick(): void {
    if (this.selectedIndex > 0) {
      // eslint-disable-next-line no-alert
      if (confirm('There may be unsaved data. Do you really want to leave the page?')) {
        this.previous();
      } else {
        this.keepCorrectBrowserNavigation();
      }
    }
  }

  /** Handle 'click' of a 'Next step' button. */
  public onNextStepClick(): void {
    this.selected?.stepControl?.markAllAsTouched();

    if (this.selected?.stepControl?.valid) {
      if (this.isLastStep) {
        this.linear = false;
        this.complete.emit();
      } else {
        this.next();
        this.window.history.pushState(null, '', this.window.location.href);
        this.window.scrollTo(0, 0);
        this.stepIncorrect.emit({ index: this.selectedIndex, isIncorrect: false });
      }
    } else {
      this.stepIncorrect.emit({ index: this.selectedIndex, isIncorrect: true });
    }
  }

  /**
   * Select step by index.
   * @param stepIndex Number.
   */
  public selectStepByIndex(stepIndex: number): void {
    this.selectedIndex = stepIndex;
  }

  private keepCorrectBrowserNavigation(): void {
    this.location.go(this.router.routerState.snapshot.url);
  }

  /** Get current control. */
  public get currentControl(): AbstractControl | null {
    return this.selected?.stepControl as AbstractControl;
  }
}

import { Component, ChangeDetectionStrategy, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { InvoiceStatus } from '@protctc/common/core/enums/invoice-status';
import { PaymentMethod } from '@protctc/common/core/enums/payment-method';
import { Invoice } from '@protctc/common/core/models/invoice';
import { InvoiceTemplateSaveData } from '@protctc/common/core/models/invoice-template-save-data';
import { listenControlChanges } from '@protctc/common/core/rxjs/listen-control-changes';
import { DestroyableComponent } from '@protctc/common/core/utils/destroyable';

import { InvoiceView } from '@protctc/common/shared/components/invoice-template/invoice-preview-models/invoice-view';

import { IDialog, IDialogOptions } from '../dialog';

/** Invoice preview data type. */
export type InvoicePreviewDataType = Invoice | InvoiceTemplateSaveData | InvoiceView;

/** Invoice preview options. */
export interface InvoicePreviewOptions {

  /**
   * Invoice template preview data.
   * Pass template if preview of invoice template or
   * Invoice if it is preview of invoice.
   */
  readonly invoicePreviewData: InvoicePreviewDataType;

  /** Title. */
  readonly title: string;

  /** Should accept invoice before saving. */
  readonly shouldAccept?: boolean;
}

/** Invoice preview dialog. */
@DestroyableComponent()
@Component({
  selector: 'protctc-invoice-preview-dialog',
  templateUrl: './invoice-preview-dialog.component.html',
  styleUrls: ['./invoice-preview-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicePreviewDialogComponent implements IDialog<InvoicePreviewOptions, boolean>, OnInit {

  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritdoc */
  public readonly closed = new EventEmitter<boolean>();

  /** @inheritdoc */
  public readonly props!: InvoicePreviewOptions;

  /** To readable payment method. */
  public readonly toReadablePaymentMethod = PaymentMethod.toReadable;

  /** To readable invoice status. */
  public readonly toReadableInvoiceStatus = InvoiceStatus.toReadable;

  /** Invoice status. */
  public readonly invoiceStatus = InvoiceStatus;

  /** View as customer toggle control. */
  public readonly viewAsCustomerControl = this.formBuilder.controlTyped<boolean>(false);

  /** View as customer stream. */
  public readonly viewAsCustomer$ = listenControlChanges<boolean>(this.viewAsCustomerControl, { debounceTime: 0 });

  public constructor(
    private readonly formBuilder: UntypedFormBuilder,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.options = {
      closable: true,
      closeButton: true,
      title: this.props.title,
      isTitleAlignmentRight: true,
    };

    if (this.props.invoicePreviewData instanceof Invoice) {
      if (this.props.invoicePreviewData.isInvoiceWithoutTax) {
        this.viewAsCustomerControl.disable();
      }
    }
  }

  /**
   * Is invoice data checking.
   * @param invoicePreviewData Invoice preview data.
   */
  public isInvoiceData(invoicePreviewData: InvoicePreviewDataType): invoicePreviewData is Invoice {
    return invoicePreviewData instanceof Invoice;
  }

  /**
   * Complete refund invoice action.
   * If invoice is refunded, then close.
   * @param isRefunded Is invoice refunded.
   */
  public refundInvoiceComplete(isRefunded: boolean): void {
    if (isRefunded && !this.props.shouldAccept) {
      this.closed.emit(true);
    }
  }
}

import { AbstractControlTyped, ValidationErrors, ValidatorFn } from '@angular/forms';

import { AppError } from '../models/app-error';

import { AppValidators } from './validators';

export namespace FileValidation {
  const BYTES_IN_MB = 1024 * 1024;
  const DEFAULT_FILE_NAME_LENGTH = 100;

  /** Available form formats. */
  export enum FileFormat {
    JPG = '.jpg',
    JPEG = '.jpeg',
    PNG = '.png',
    GIF = '.gif',
    DOCS = '.docs',
    DOC = '.doc',
    DOCX = '.docx',
    ODT = '.odt',
    PPTX = '.pptx',
    MP4 = '.mp4',
    PDF = '.pdf',
    WEBP = '.webp',
    SVG = '.svg',
    TIFF = '.tiff',
    TIF = '.tif',
    TXT = '.txt',
    XML = '.xml',
    ASC = '.asc',
    RTF = '.rtf',
    CSV = '.csv',
    XLS = '.xls',
    ODS = '.ods',
    JSON = 'json',
    SOAP = '.soap',
  }

  /** Only image validation. */
  export const ALLOWED_IMAGE_FILE_EXTENSIONS = [
    FileFormat.GIF,
    FileFormat.JPEG,
    FileFormat.JPG,
    FileFormat.PNG,
    FileFormat.SVG,
    FileFormat.WEBP,
  ];

  /** Extensions for check documents. */
  export const ALLOWED_CHECK_EXTENSIONS = [
    FileFormat.GIF,
    FileFormat.JPEG,
    FileFormat.JPG,
    FileFormat.PNG,
    FileFormat.PDF,
    FileFormat.TIF,
    FileFormat.TIFF,
    FileFormat.TXT,
    FileFormat.XML,
    FileFormat.ASC,
    FileFormat.RTF,
    FileFormat.CSV,
    FileFormat.XLS,
    FileFormat.DOC,
    FileFormat.ODT,
    FileFormat.ODS,
    FileFormat.JSON,
    FileFormat.SOAP,
  ];

  export namespace FileFormat {

    /**
     * Maps format to human-readable one.
     * @param format Format to map.
     */
    export function toReadable(format: FileFormat): string {
      return format;
    }

    /** Get list for all available formats. */
    export function toArray(): FileFormat[] {
      const enumType = typeof FileFormat.JPG;
      return Object.values(FileFormat)
        .filter(format => typeof format === enumType)
        .map(format => format as FileFormat);
    }
  }

  /**
   * @returns Validation message if a file is invalid.
   */
  export type FileValidatorFn = (file: File) => string | null;

  /**
   * File size validator for a file.
   * @param maxSizeMb Max size of a file (MB).
   * @param message Custom error message.
   */
  export function validateMaxSize(maxSizeMb: number, message: string): FileValidatorFn {
    return file => {
      const sizeMb = bytesToMb(file.size);
      if (sizeMb < maxSizeMb) {
        return null;
      }

      return message;
    };
  }

  /**
   * File name length validator for a file.
   * @param maxLength Max length of file's name.
   */
  export function validateMaxLength(maxLength = DEFAULT_FILE_NAME_LENGTH): FileValidatorFn {
    return file => {
      if (file.name.length > maxLength) {
        return `Ensure all file names have no more than ${maxLength} characters`;
      }
      return null;
    };
  }

  /**
   * Validates a file for an accepted format.
   * @param formats Formats to validate.
   */
  export function validateFormat(formats: readonly FileFormat[]): FileValidatorFn {
    return file => {
      if (formats.some(extension => file.name.toLowerCase().endsWith(extension.toLowerCase()))) {
        return null;
      }

      return `Invalid format. "${formats.join(', ')}" expected`;
    };
  }

  /**
   * Decorates a file validator adapting it to be compatible with Angular Forms API.
   * @param validateFile File validator to adapt.
   */
  export function adaptForForms(validateFile: FileValidatorFn): ValidatorFn {
    return ({ value: file }: AbstractControlTyped<File | string | null>): ValidationErrors | null => {
      if (file == null || typeof file === 'string') {
        return null;
      }

      // Since Angular's `ValidatorFn` is not strictly typed, we gotta check for correct types.
      if (!(file instanceof Blob)) {
        throw new AppError('Invalid value for validator. URL or Blob expected');
      }

      const error = validateFile(file);

      if (error) {
        return AppValidators.buildCustomValidationError(error);
      }

      return null;
    };
  }

  /**
   * Transforms megabytes to bytes.
   * @param bytes Bytes.
   * @returns Megabytes.
   */
  function bytesToMb(bytes: number): number {
    return bytes / BYTES_IN_MB;
  }
}

import { UntypedFormGroup, FormGroupTyped } from '@angular/forms';
import { MonoTypeOperatorFunction, throwError, ObservableInput, Subject, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppError, AppValidationError, EntityValidationErrors } from '../models/app-error';
import { AppValidators } from '../utils/validators';

const FORM_ERROR = 'formError';

/**
 * Util operator function to catch `AppValidationError` on presentational logic.
 * @param subjectOrForm Subject to emit data if it was there.
 * @deprecated
 */
export function catchValidationData<T, R>(
  subjectOrForm: Subject<EntityValidationErrors<T>> | FormGroupTyped<T>,
): OperatorFunction<R, R | never> {
  return source => source.pipe(
    catchError(error => {
      // In case error is what we want, pass it to provided subject and finish the stream
      if (error instanceof AppValidationError) {
        const { validationData } = error;
        if (subjectOrForm instanceof Subject) {
          subjectOrForm.next(validationData);
        } else {
          fillFormWithError(subjectOrForm, validationData);
        }
        return throwError(new AppError('Invalid form data.'));
      }

      // Otherwise, let the error go
      return throwError(error);
    }),
  );
}

/**
 * Util operator function to catch `AppValidationError` on presentational logic.
 * @param subject Subject to emit message if it was there.
 * @param form Form to fill with errors from the server.
 * @deprecated
 */
export function catchValidationDataWithMessage<T, R>(
  subject: Subject<string>,
  form: FormGroupTyped<T>,
): OperatorFunction<R, R | never> {
  return source => source.pipe(
    catchError(error => {
      // In case error is what we want, pass it to provided subject and finish the stream
      if (error instanceof AppValidationError) {
        const { message, validationData } = error;

        // No need to make a common form error if there is already an error in the form field
        const validationDataFields = Object.values(validationData);
        const isEmptyValidationData = validationDataFields.filter(value => typeof value === 'string').length === 0;
        if (isEmptyValidationData) {
          subject.next(message);
        } else {
          subject.next('');
        }
        fillFormWithError(form, validationData);
        return throwError(new AppError('Invalid form data.'));
      }

      // Otherwise, let the error go
      return throwError(error);
    }),
  );
}

/**
 * Fill the form with error data.
 * @param form Form to fill.
 * @param errors Array of errors.
 * @deprecated
 */
function fillFormWithError<T>(form: FormGroupTyped<T>, errors: EntityValidationErrors<T>): void {
  const controlKeys = Object.keys(form.controls) as (keyof T)[];
  controlKeys.forEach(key => {
    const error = errors[key];
    const control = form.controls[key];
    if (error && control) {
      // If error is not nested
      if (typeof error === 'string') {
        control.setErrors(AppValidators.buildCustomValidationError(error));
      } else if (control instanceof UntypedFormGroup && typeof error === 'object') {
        // Since we checked the error type, help typescript with error typing
        fillFormWithError(
          control as FormGroupTyped<T[keyof T]>,
          error as Object as EntityValidationErrors<T[keyof T]>,
        );
      }
    }
  });
  const formError = errors[FORM_ERROR as keyof T];
  if (formError && typeof formError === 'string') {
    form.setErrors(AppValidators.buildCustomValidationError(formError));
  }
}

/**
 * Catch application validation error (instance of AppValidationError) operator.
 * Catches only AppValidationError<T> errors.
 * @param selector Selector.
 * @deprecated
 */
export function catchValidationError<T, V, TEntity extends object = T extends object ? T : object>(
  selector: (error: AppValidationError<TEntity>) => ObservableInput<V>,
): MonoTypeOperatorFunction<T | V> {
  return catchError(error => {
    if (error instanceof AppValidationError) {
      return selector(error);
    }
    return throwError(error);
  });
}

import { Injectable } from '@angular/core';
import { RecurringScheduleMode } from '@protctc/common/core/models/recurring-schedule/recurring-schedule-mode';
import { reverseRecord } from '@protctc/common/core/utils/reverse-record';

import { RecurringScheduleModeDto } from '../dto/recurring-schedule/recurring-schedule-mode.dto';
import { IMapper } from '../mappers';

const TO_RECURRING_SCHEDULE_MODE_DTO: Readonly<Record<RecurringScheduleMode, RecurringScheduleModeDto>> = {
  [RecurringScheduleMode.Monthly]: RecurringScheduleModeDto.Monthly,
  [RecurringScheduleMode.Quarterly]: RecurringScheduleModeDto.Quarterly,
  [RecurringScheduleMode.Yearly]: RecurringScheduleModeDto.Yearly,
};

const FROM_RECURRING_SCHEDULE_MODE_DTO = reverseRecord(TO_RECURRING_SCHEDULE_MODE_DTO);

/** Recurring schedule mode mapper. */
@Injectable({
  providedIn: 'root',
})
export class RecurringScheduleModeMapper implements IMapper<RecurringScheduleModeDto, RecurringScheduleMode> {

  /** @inheritdoc */
  public fromDto(data: RecurringScheduleModeDto): RecurringScheduleMode {
    return FROM_RECURRING_SCHEDULE_MODE_DTO[data];
  }

  /** @inheritdoc */
  public toDto(data: RecurringScheduleMode): RecurringScheduleModeDto {
    return TO_RECURRING_SCHEDULE_MODE_DTO[data];
  }
}

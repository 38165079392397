/** Sort order mode. */
export enum SortDirection {

  /** Ascending direction mode. */
  Asc = 'asc',

  /** Descending direction mode. */
  Desc = 'desc',
}

/**
 * Namespace for users sort direction.
 * Contains utils props and methods for easier use this enum.
 */
export namespace SortDirection {
  export const SORT_FIELD_MAP: Readonly<Record<SortDirection, string>> = {
    [SortDirection.Asc]: '',
    [SortDirection.Desc]: '-',
  };
}

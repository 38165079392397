/* eslint-disable @angular-eslint/directive-selector */
import { CdkRowDef } from '@angular/cdk/table';
import { Directive, Input, IterableDiffers, TemplateRef } from '@angular/core';
import { MatRowDef, MatTableDataSource } from '@angular/material/table';
import { Pagination } from '@protctc/common/core/models/pagination';
import { Observable } from 'rxjs';

/** Typed mat row directive. */
@Directive({
  selector: '[matRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: TypedMatRowDirective }],
})
export class TypedMatRowDirective<T> extends MatRowDef<T> {

  /** Leveraging syntactic-sugar syntax when we use *matRowDef. */
  @Input()
  public matRowDefDataSource?: T[] | readonly T[] | Observable<T[]> | MatTableDataSource<T> | Observable<Pagination<T>>;

  public constructor(
    template: TemplateRef<unknown>,
    _differs: IterableDiffers,
  ) {
    super(template, _differs);
  }

  /**
   * NgTemplateContextGuard flag to help with the Language Service.
   * @param _dir Directive.
   * @param _ctx Context.
   */
  public static ngTemplateContextGuard<T>(
    _dir: TypedMatRowDirective<T>,
    _ctx: unknown,
  ): _ctx is { $implicit: T; index: number; } {
    return true;
  }
}

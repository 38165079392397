import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupTyped } from '@angular/forms';
import { DateRange } from '@protctc/common/core/models/date-range/date-range';
import { DateRangeForm } from '@protctc/common/core/models/date-range/date-range-form';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { distinctUntilChanged } from 'rxjs';

/** Date range picker. */
@DestroyableComponent()
@Component({
  selector: 'protctw-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements OnInit {

  /** Date range form. */
  @Input()
  public dateRangeForm!: FormGroupTyped<DateRange> | FormGroup<DateRangeForm>;

  /** Label. */
  @Input()
  public label: string | null = null;

  /** @inheritdoc */
  public ngOnInit(): void {
    assertNonNull(this.dateRangeForm);
    this.subscribeToDateRangeChanges();
  }

  /**
   * Fix moment, when the user selects a wrong date,
   * then corrects the range by changing another date, but the error is still there.
   */
  private subscribeToDateRangeChanges(): void {
    const startDateControl = this.dateRangeForm.controls.start;
    const endDateControl = this.dateRangeForm.controls.end;

    assertNonNull(startDateControl);
    assertNonNull(endDateControl);

    startDateControl.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntilDestroy(this),
    )
      .subscribe(() => endDateControl.updateValueAndValidity());

    endDateControl.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntilDestroy(this),
    )
      .subscribe(endDate => {
        if (endDate) {

          // Set the time to the end of the day
          endDate.setHours(23, 59, 59, 999);

          endDateControl.setValue(endDate);
        }

        startDateControl.updateValueAndValidity();
      });

  }
}

<div *ngIf="saveUserForm" [formGroup]="saveUserForm" class="form">
  <div class="container">
    <div *protctcHideDisabled="'employeeId'" class="two-element-row">
      <div class="form-field-container">
        <mat-form-field class="form-field">
          <mat-label>Employee ID</mat-label>
          <input
            matInput
            type="text"
            [imask]="{ mask: '00000', maxLength: 5 }"
            formControlName="employeeId"
          />
        </mat-form-field>
        <protctc-form-control-validation-message controlName="employeeId">
        </protctc-form-control-validation-message>
      </div>
    </div>

    <div class="two-element-row">
      <div class="form-field-container">
        <mat-form-field class="form-field">
          <mat-label>First name</mat-label>
          <input matInput type="text" formControlName="firstName" />
        </mat-form-field>
        <protctc-form-control-validation-message controlName="firstName">
        </protctc-form-control-validation-message>
      </div>

      <div class="form-field-container">
        <mat-form-field class="form-field">
          <mat-label>Last name</mat-label>
          <input matInput type="text" formControlName="lastName" />
        </mat-form-field>
        <protctc-form-control-validation-message controlName="lastName">
        </protctc-form-control-validation-message>
      </div>
    </div>

    <div class="form-field-container">
      <mat-form-field class="form-field">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" />
      </mat-form-field>
      <protctc-form-control-validation-message controlName="email">
      </protctc-form-control-validation-message>
    </div>

    <div class="two-element-row">
      <div class="form-field-container">
        <mat-form-field class="form-field">
          <mat-label>Title</mat-label>
          <input matInput type="text" formControlName="title" />
        </mat-form-field>
        <protctc-form-control-validation-message controlName="title">
        </protctc-form-control-validation-message>
      </div>

      <div *ngIf="!shouldHideUserRole" class="form-field-container">
        <mat-form-field class="form-field">
          <mat-label>User role</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let role of userRoles" [value]="role">
              {{ toReadableUserRole(role) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <protctc-form-control-validation-message controlName="role">
        </protctc-form-control-validation-message>
      </div>
    </div>
  </div>

  <div *protctcHideDisabled="'bio'" class="form-field-container">
    <mat-form-field class="form-field">
      <mat-label>Bio</mat-label>
      <textarea class="biography" matInput formControlName="bio"></textarea>
    </mat-form-field>
    <protctc-form-control-validation-message controlName="bio">
      <span hint> Maximum 200 characters </span>
    </protctc-form-control-validation-message>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

/** Transform boolean values to yes/no. */
@Pipe({
  name: 'protctcYesNo',
  standalone: true,
})
export class YesNoPipe implements PipeTransform {

  /** @inheritdoc */
  public transform(value: boolean): string {
    return value ? 'Yes' : 'No';
  }
}

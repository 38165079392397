import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadedDocument } from '@protctc/common/core/models/uploaded-document';
import { isFile } from '@protctc/common/core/services/invoice.service';

/** Display files as attached file list. */
@Component({
  selector: 'protctw-attached-file-list',
  templateUrl: './attached-file-list.component.html',
  styleUrls: ['./attached-file-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachedFileListComponent {

  /** Files. */
  @Input()
  public files: readonly File[] | readonly UploadedDocument[] = [];

  public constructor(
    private readonly domSanitizer: DomSanitizer,
  ) { }

  /**
   * Get prepared filed to display them.
   * @param documents Documents.
   */
  public getAttachedFiles(): UploadedDocument[] {
    return this.files.map(file => isFile(file) ? this.mapFileToBaseDocument(file) : file);
  }

  private mapFileToBaseDocument(file: File): UploadedDocument {
    return {
      name: file.name,
      fileUri: this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file)) as string,
    };
  }
}

import { Pipe, PipeTransform } from '@angular/core';

/** Transform invoice prefix. */
@Pipe({
  name: 'protctcInvoicePrefix',
})
export class InvoicePrefixPipe implements PipeTransform {

  /** @inheritdoc */
  public transform(value: string): string {
    return value ? `${value} - ` : value;
  }
}

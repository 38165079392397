import { ChangeDetectionStrategy, Component, EventEmitter, OnInit } from '@angular/core';

import { IDialog, IDialogOptions } from '../dialog';

/** After invoice saved action. */
export type AfterInvoiceSavedAction = 'create' | 'dashboard';

/** Invoice saved options. */
export interface InvoiceSavedOptions {

  /** Dialog title. */
  readonly title?: string;

  /** Alert message. */
  readonly message?: string;
}

/** After invoice saving dialog component. */
@Component({
  selector: 'protctc-invoice-saved-dialog',
  templateUrl: './invoice-saved-dialog.component.html',
  styleUrls: ['./invoice-saved-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceSavedDialogComponent implements IDialog<InvoiceSavedOptions, AfterInvoiceSavedAction>, OnInit {

  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritdoc */
  public readonly closed = new EventEmitter<AfterInvoiceSavedAction>();

  /** @inheritdoc */
  public readonly props: InvoiceSavedOptions | undefined;

  /** Default dialog property values. */
  protected readonly defaultProps: InvoiceSavedOptions = {
    title: 'Invoice processed',
    message: 'Invoice successfully processed',
  };

  /** @inheritdoc */
  public ngOnInit(): void {
    this.options = {
      closable: true,
      closeButton: true,
      title: this.props?.title ?? this.defaultProps.title,
    };
  }

  /** Go to dashboard. */
  protected toDashboard(): void {
    this.closed.emit('dashboard');
  }

  /** Go to invoice creation. */
  protected toInvoiceCreation(): void {
    this.closed.emit('create');
  }
}

import { Injectable } from '@angular/core';

import { PaymentPublicKey } from '../../models/payment-public-key';

import { PaymentPublicKeyDto } from './dto/payment-public-key-dto';
import { IMapper } from './mappers';

/** NMI Public key mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaymentPublicKeyMapper implements IMapper<PaymentPublicKeyDto, PaymentPublicKey> {

  /** @inheritdoc */
  public fromDto(data: PaymentPublicKeyDto): PaymentPublicKey {
    return {
      key: data.key,
      merchantId: data.merchant_id,
    };
  }

  /** @inheritdoc */
  public toDto(data: PaymentPublicKey): PaymentPublicKeyDto {
    return {
      key: data.key,
      merchant_id: data.merchantId,
    };
  }
}

<ng-container *ngIf="page">
  <ng-template
    *ngIf="page.hasItems === false"
    [ngTemplateOutlet]="noInvoices"
  ></ng-template>
  <div class="table" *ngIf="page.hasItems">
    <mat-table
      matSort
      (matSortChange)="onSortChange($event)"
      [matSortDirection]="activeSortDirection"
      [matSortActive]="activeSortColumn"
      [dataSource]="page.items"
      [trackBy]="trackRecurringSchedule"
    >
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Recurring schedule #
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.id | leadingZeros }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Created date
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <time [dateTime]="data.createdDate">
            {{ data.createdDate | date : "MM/dd/yyyy" }}&nbsp;
          </time>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Start date
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <time *ngIf="data.startDate; else empty" [dateTime]="data.startDate">
            {{ data.startDate | date : "MM/dd/yyyy" }}&nbsp;
          </time>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="nextGenerateDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Next generate date
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <time
            *ngIf="data.nextGenerateDate; else empty"
            [dateTime]="data.nextGenerateDate"
          >
            {{ data.nextGenerateDate | date : "MM/dd/yyyy" }}&nbsp;
          </time>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="recurringSchedule">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Recurring schedule
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.recurringScheduleMode }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let data">
          <button
            *ngIf="data.nextGenerateDate"
            class="button-stop button basic warn"
            type="button"
            (click)="stopRecurringSchedule(data.id)"
            title="Stop recurring schedule"
          >
            Stop
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      *ngIf="page"
      (page)="onPageChange($event)"
      [pageIndex]="page.page"
      [length]="page.totalCount"
      [pageSize]="page.size"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

<ng-template #noInvoices>
  <protctw-empty-table-preview
    title="No recurring invoices found"
  ></protctw-empty-table-preview>
</ng-template>

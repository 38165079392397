<p class="message">{{ props.message }}</p>
<div class="controls">
  <button
      class="button flat"
      type="button"
      (click)="closed.emit()"
    >
      {{ props?.buttonTitle || defaultProps.buttonTitle }}
    </button>
</div>

import { enumToArray } from '../utils/enum-to-array';

/** Fields available for sorting the payments history. */
export enum PaymentHistorySortField {

  /** Date. */
  Date = 'date',

  /** Transaction id. */
  TransactionId = 'transactionId',

  /** Details. */
  Detail = 'detail',

  /** Amount. */
  Amount = 'amount',

  /** Default sort field. */
  Default = Date,

}

export namespace PaymentHistorySortField {

  /** Get list for all sorting fields except default. */
  export function toArray(): PaymentHistorySortField[] {
    return enumToArray(PaymentHistorySortField).filter(item => item !== PaymentHistorySortField.Default);
  }

}

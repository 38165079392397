import { Injectable } from '@angular/core';

import { InvoiceLineItem, InvoiceLineItemCreationData } from '../../models/invoice-line-item';

import { InvoiceLineItemDto } from './dto/invoice-line-item-dto';

import { IMapper } from './mappers';

/** Mapper for invoice line item entities. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceLineItemMapper implements
IMapper<InvoiceLineItemDto, InvoiceLineItem> {

  /** @inheritdoc */
  public fromDto(data: InvoiceLineItemDto): InvoiceLineItem {
    const price = data.price ? Number(data.price) : 0;
    return {
      invoiceId: data.id,
      lineItemId: data.line_item,
      name: data.name,
      price,
      description: data.description ?? '',
      quantity: data.quantity ? Number(data.quantity) : 0,
      subtotal: data.subtotal ? Number(data.subtotal) : 0,
      total: data.total ? Number(data.total) : 0,
      priceToCustomer: data.price_to_customer ?? price,
      taxType: data.tax_option,
    };
  }

  /** @inheritdoc */
  public toDto(data: InvoiceLineItemCreationData): InvoiceLineItemDto {

    return {
      id: data.invoiceId,
      line_item: data.lineItemId,
      name: data.name,
      price: data.price?.toString(),
      description: data.description,
      quantity: data.quantity?.toString(),
      tax_option: data.taxType,
    };
  }
}

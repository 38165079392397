<p class="message">Let's get started!</p>
<div class="dialog">
  <protctc-get-started-stepper [linear]="true" (complete)="closed.emit()">
    <cdk-step class="dialog__step">
      <img
        src="assets/welcome_1.png"
        class="dialog__step-image"
        alt="Set up internal user accounts"
      />
      <div class="dialog__step-content">
        <h2 class="dialog__step-title">Set up internal user accounts</h2>
        <p class="dialog__step-body">Get your team ready for success.</p>
      </div>
    </cdk-step>
    <cdk-step class="dialog__step">
      <img
        src="assets/welcome_2.png"
        class="dialog__step-image"
        alt="Set up payment information"
      />
      <div class="dialog__step-content">
        <h2 class="dialog__step-title">Set up payment information</h2>
        <p class="dialog__step-body">
          Allowing you to track the status of your transactions.
        </p>
      </div>
    </cdk-step>
    <cdk-step class="dialog__step">
      <img
        src="assets/welcome_3.png"
        class="dialog__step-image"
        alt="Set up your invoice format"
      />
      <div class="dialog__step-content">
        <h2 class="dialog__step-title">Set up your invoice format</h2>
        <p class="dialog__step-body">Even add your own personal flare.</p>
      </div>
    </cdk-step>
    <cdk-step class="dialog__step">
      <img
        src="assets/welcome_4.png"
        class="dialog__step-image"
        alt="Set up product line items"
      />
      <div class="dialog__step-content">
        <h2 class="dialog__step-title">Set up product line items</h2>
        <p class="dialog__step-body">
          The part of your business that's made for goals.
        </p>
      </div>
    </cdk-step>
  </protctc-get-started-stepper>
</div>

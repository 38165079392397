<div class="color-picker">
  <input
    #colorInput
    type="color"
    class="color-picker__color-input"
    [value]="controlValue"
    (change)="onChange(colorInput)"
  >
  <input
    #textColorInput
    type="text"
    class="color-picker__text-input"
    [value]="controlValue"
    (change)="onChange(textColorInput)"
    [imask]="colorPickerMask"
  >
</div>

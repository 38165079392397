<ul class="cards">
  <li
    class="cards__item"
    *ngFor="let item of items; trackBy: trackByCardDataFunction"
  >
    <mat-progress-bar
      *ngIf="isLoading"
      mode="indeterminate"
      color="primary"
    ></mat-progress-bar>

    <protctw-dashboard-statistic-card
      [data]="item"
    ></protctw-dashboard-statistic-card>
  </li>
</ul>

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Company } from '@protctc/common/core/models/company/company';
import { masks } from '@protctc/common/core/utils/masks';

/** Company general info. */
@Component({
  selector: 'protctw-company-general-info',
  templateUrl: './company-general-info.component.html',
  styleUrls: ['./company-general-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyGeneralInfoComponent {

  /** Company general info. */
  @Input()
  public data: Company | null = null;

  /** Masks. */
  protected readonly masks = masks;
}

/** Line item type. */
export enum LineItemType {

  /** Single line item. */
  LineItem = 'line_item',

  /** Group of line items. */
  GroupLineItem = 'line_item_group',
}

/**
 * Namespace for line item types.
 * Contains util methods and props for easier enum use.
 */
export namespace LineItemType {
  const READABLE_MAP: Record<LineItemType, string> = {
    [LineItemType.LineItem]: 'Line item',
    [LineItemType.GroupLineItem]: 'Group of line items',
  };

  /**
   * Return human-readable line item type representation.
   * @param type Line item type.
   */
  export function toReadable(type: LineItemType): string {
    return READABLE_MAP[type];
  }
}

import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { getFirstInvalidControl, smoothScrollIntoView } from '@protctc/common/core/utils/scroll-to-invalid-control';

import { MarkAllAsTouchedDirective } from './mark-all-as-touched.directive';

/**
 * Directive to smooth scroll to first invalid control.
 * If the button wrapped by form, scroll to first invalid control of this form.
 * Else scroll to first invalid control of the document.
 */
@Directive({ selector: 'button[protctcInvalidControlScroll]' })
export class InvalidControlScrollDirective {

  /** Control. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('protctcInvalidControlScroll')
  public set control(value: AbstractControl | null) {
    if (value) {
      this.markAllAsTouchedDirective.setControl(value);
    }
  }

  private markAllAsTouchedDirective = new MarkAllAsTouchedDirective();

  public constructor(
    private readonly element: ElementRef<HTMLButtonElement>,
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) { }

  /** On click event handler. */
  @HostListener('click')
  public onButtonClick(): void {
    this.markAllAsTouchedDirective.markAsTouched();
    const firstInvalidControl = getFirstInvalidControl(this.document, this.element);
    if (firstInvalidControl !== null) {
      smoothScrollIntoView(firstInvalidControl);
    }
  }

}

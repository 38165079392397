/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../models/user';

import { FileUploadService } from './file-upload';

const DEFAULT_DESTINATION = 'company/documents/';

/** Documents upload service. */
@Injectable({
  providedIn: 'root',
})
export class DocumentsUploadService {

  public constructor(
    private readonly fileUploadService: FileUploadService,
  ) {}

  /**
   * Uploads the files into the storage.
   * @param file File.
   * @param userId Id of a user for which the file should be uploaded.
   */
  public upload(file: File, userId?: User['id']): Observable<string> {
    const destination = userId ? this.getStorageDestination(userId) : DEFAULT_DESTINATION;
    return this.fileUploadService.upload(file, destination);
  }

  private getStorageDestination(userId: User['id']): string {
    return `user/${userId}/`;
  }
}

import { UntypedFormBuilder, FormGroupTyped } from '@angular/forms';
import { InvoiceStatus } from '@protctc/common/core/enums/invoice-status';
import { PaymentMethod } from '@protctc/common/core/enums/payment-method';
import { DateRangeFormData, initDateRangeFormGroup } from '@protctc/common/core/forms/date-range-form-data';
import { AdminInvoiceShortFilterData } from '@protctc/common/core/models/admin-invoice-short-filter-data';
import { State } from '@protctc/common/core/models/state';

/** Admin Invoice filter form data. */
export interface AdminInvoiceFilterFormData {

  /** Invoices created date range. */
  readonly created: DateRangeFormData;

  /** Location. */
  readonly location: State;

  /** Payment method. */
  readonly paymentMethod: PaymentMethod;

  /** Invoice status. */
  readonly invoiceStatus: InvoiceStatus;
}

/**
 * Namespace for admin invoice filter form data.
 * Contains util methods for this entity.
 */
export namespace AdminInvoiceFilterFormData {

  /**
   * Initialize form group with default values.
   * @param fb Form builder.
   */
  export function initializeFormGroup(fb: UntypedFormBuilder): FormGroupTyped<AdminInvoiceFilterFormData> {
    return fb.groupTyped<AdminInvoiceFilterFormData>({
      created: initDateRangeFormGroup(fb),
      location: [{ name: 'All States', code: '' }],
      paymentMethod: [PaymentMethod.None],
      invoiceStatus: [InvoiceStatus.All],
    });
  }

  /**
   * Map from form data to domain model.
   * @param data Invoice filter form data.
   */
  export function mapToInvoiceFilterData(data: AdminInvoiceFilterFormData): AdminInvoiceShortFilterData {
    return {
      invoiceStartDate: data.created.start,
      invoiceEndDate: data.created.end,
      locationState: data.location,
      paymentMethod: data.paymentMethod,
      invoiceStatus: data.invoiceStatus,
    };
  }
}

import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';

import { Login } from '../../models/login';

import { UserSecret } from '../../models/user-secret';

import { DateMapper } from './date.mapper';
import { LoginDto } from './dto/login-dto';
import { UserSecretDto } from './dto/user-secret-dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';

import { IMapperFromDto, IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';

/** User secret mapper. */
@Injectable({
  providedIn: 'root',
})
export class LoginMapper implements
  IMapperFromDto<UserSecretDto, UserSecret>,
  IMapperToDto<LoginDto, Login>,
  ValidationErrorMapper<LoginDto, Login> {

  public constructor(
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: UserSecretDto): UserSecret {
    return {
      expiry: this.dateMapper.fromDto(data.expiry),
      token: data.token,
      isFirstLogin: data.is_first_login,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<LoginDto> | null | undefined,
  ): EntityValidationErrors<Login> {
    return {
      email: extractErrorMessage(errorDto?.email),
      password: extractErrorMessage(errorDto?.password),
      formError: extractErrorMessage(errorDto?.non_field_errors),
    };
  }

  /** @inheritdoc */
  public toDto(data: Login): LoginDto {
    return {
      email: data.email,
      password: data.password,
    };
  }
}

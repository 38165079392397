import { Component, ChangeDetectionStrategy, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroupTyped } from '@angular/forms';
import { InvoiceStatus } from '@protctc/common/core/enums/invoice-status';
import { PaymentMethod } from '@protctc/common/core/enums/payment-method';
import { DateRangeFormData } from '@protctc/common/core/forms/date-range-form-data';
import { AdminInvoiceShortFilterData } from '@protctc/common/core/models/admin-invoice-short-filter-data';
import { State } from '@protctc/common/core/models/state';
import { CompanyService } from '@protctc/common/core/services/company.service';
import { IDialog, IDialogOptions } from '@protctc/common/shared/dialogs/dialog';
import { map, Observable } from 'rxjs';

import { AdminInvoiceFilterFormData } from './admin-invoice-filter-form-data';

/** Define what filter control should be shown or hidden in admin filter invoice dialog. */
export interface ShouldShowAdminInvoiceFilterControlType {

  /** Should show created date. */
  readonly shouldShowCreatedDate?: boolean;

  /** Should show invoice status. */
  readonly shouldShowInvoiceStatus?: boolean;

  /** Should show payment method. */
  readonly shouldShowPaymentMethod?: boolean;

  /** Should show location. */
  readonly shouldShowLocation?: boolean;
}

const DEFAULT_ADMIN_INVOICE_FILTER_CONTROLS_SHOW_MODE: ShouldShowAdminInvoiceFilterControlType = {
  shouldShowCreatedDate: true,
  shouldShowInvoiceStatus: true,
  shouldShowPaymentMethod: true,
  shouldShowLocation: true,
};

/** Admin Invoice filter dialog options. */
interface AdminInvoiceFilterDialogOptions {

  /** Filter data. */
  readonly filterData: AdminInvoiceShortFilterData;

  /** Controls show mode. */
  readonly shouldShowAdminInvoiceFilterControl?: ShouldShowAdminInvoiceFilterControlType;
}

/** Invoice filter dialog. */
@Component({
  selector: 'protctw-admin-invoice-filter-dialog',
  templateUrl: './admin-invoice-filter-dialog.component.html',
  styleUrls: ['./admin-invoice-filter-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminInvoiceFilterDialogComponent
implements IDialog<AdminInvoiceFilterDialogOptions, AdminInvoiceShortFilterData | void>, OnInit {

  /** Form containing prompt input. */
  public readonly form: FormGroupTyped<AdminInvoiceFilterFormData>;

  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritdoc */
  public readonly closed = new EventEmitter<AdminInvoiceShortFilterData | void>();

  /** Props. */
  public readonly props!: AdminInvoiceFilterDialogOptions;

  /** Payment methods. */
  public readonly paymentMethods = PaymentMethod.getFilterPaymentsMethods();

  /** Invoice statuses. */
  public readonly invoiceStatuses = InvoiceStatus.toKeyValueArray();

  /** Available states. */
  public readonly states$ = this.initStatesStream();

  /** Compare state options function for select. */
  public readonly compareState = State.compare;

  /** Invoice filter controls show mode. */
  public filterDialogControlsShowMode = DEFAULT_ADMIN_INVOICE_FILTER_CONTROLS_SHOW_MODE;

  public constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly companyService: CompanyService,
  ) {
    this.form = AdminInvoiceFilterFormData.initializeFormGroup(this.formBuilder);
  }

  /** Created date range. */
  public get createdDateRange(): FormGroupTyped<DateRangeFormData> {
    return this.form.get('created') as FormGroupTyped<DateRangeFormData>;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.options = {
      closable: true,
    };

    if (this.props.shouldShowAdminInvoiceFilterControl) {
      this.filterDialogControlsShowMode = {
        ...this.filterDialogControlsShowMode,
        ...this.props.shouldShowAdminInvoiceFilterControl,
      };
    }

    this.form.patchValue({
      invoiceStatus: this.props.filterData.invoiceStatus,
      paymentMethod: this.props.filterData.paymentMethod,
      created: {
        start: this.props.filterData.invoiceStartDate,
        end: this.props.filterData.invoiceEndDate,
      },
      location: this.props.filterData.locationState,
    });
  }

  /** Handle submit action. */
  public onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.closed.next(AdminInvoiceFilterFormData.mapToInvoiceFilterData(this.form.value));
  }

  private initStatesStream(): Observable<State[]> {
    return this.companyService.getStates().pipe(
      map(states => ([{ code: '', name: 'All States' }, ...states])),
    );
  }
}

import { Injectable } from '@angular/core';

import { PaymentMethod } from '../../enums/payment-method';

import { Payment } from '../../models/payment/payment';

import { CustomerPaymentDto } from './dto/customer-payment-dto';
import { IMapperToDto } from './mappers';

/** Customer payment mapper. */
@Injectable({
  providedIn: 'root',
})
export class CustomerPaymentMapper implements IMapperToDto<CustomerPaymentDto, Payment> {

  /** @inheritdoc */
  public toDto(data: Payment): CustomerPaymentDto {
    return {
      payment_token: data.token,
      payment_method: data.card ? PaymentMethod.CreditCard : PaymentMethod.ACH,
    };
  }
}

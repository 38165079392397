import { Injectable } from '@angular/core';

import { InvoiceNote } from '../../models/invoice-note';

import { InvoiceNoteDto } from './dto/invoice-note-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';

/** Mapper for invoice template notes entity. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceNoteMapper implements
IMapperToDto<InvoiceNoteDto, InvoiceNote>,
IMapperFromDto<InvoiceNoteDto, InvoiceNote> {

  /** @inheritdoc */
  public fromDto(data: InvoiceNoteDto): InvoiceNote {
    return {
      id: data.id,
      name: data.name,
      fileUri: data.file,
    };
  }

  /** @inheritdoc */
  public toDto(data: InvoiceNote): InvoiceNoteDto {
    return {
      name: data.name,
      file: data.fileUri,
    };
  }
}

import { Injectable } from '@angular/core';

import { CustomKeyValue } from '../../models/custom-key-value';

import { CustomKeyValueDto } from './dto/custom-key-value-dto';

/** MApper for key value entities. */
@Injectable({
  providedIn: 'root',
})
export class CustomKeyValueMapper {

  /**
   * Maps from DTO to Domain model.
   * @param data Dto data.
   */
  public fromDto<K, V>(data: CustomKeyValueDto<K, V>): CustomKeyValue<K, V> {
    return {
      key: data.key,
      value: data.value,
    };
  }
}

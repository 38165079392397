/** Available field options. */
export enum FieldOption {

  /** Header field option. */
  Header = 'header',

  /** Footer field option. */
  Footer = 'footer',

  /** Work details option. */
  WorkDetails = 'workDetails',

  /** Logo position. */
  LogoPosition = 'logoPosition',
}

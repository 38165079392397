<a
  [href]="deploymentUrl.concat(routePaths.privacyPolicy.join('/'))"
  target="_blank"
  rel="noopener noreferrer"
>
  Privacy policy
</a>
<a
  [href]="deploymentUrl.concat(routePaths.securityPolicy.join('/'))"
  target="_blank"
  rel="noopener noreferrer"
>
  Security policy
</a>
<a
  [href]="deploymentUrl.concat(routePaths.refundPolicy.join('/'))"
  target="_blank"
  rel="noopener noreferrer"
>
  Refund policy
</a>

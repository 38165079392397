import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { State } from '../models/state/state';

import { AppConfigService } from './app-config.service';
import { StateDto } from './mappers/dto/state/state.dto';
import { StateMapper } from './mappers/state/state.mapper';

/** State service. */
@Injectable({
  providedIn: 'root',
})
export class StateService {

  private readonly companiesUrl: URL;

  public constructor(
    appConfig: AppConfigService,
    private readonly stateMapper: StateMapper,
    private readonly httpClient: HttpClient,
  ) {
    this.companiesUrl = new URL('companies/', appConfig.apiUrl);
  }

  /** Get states. */
  public getStates(): Observable<State[]> {
    const url = new URL('states/', this.companiesUrl).toString();
    return this.httpClient.get<StateDto[]>(url).pipe(
      map(stateDto => stateDto.map(state => this.stateMapper.fromDto(state))),
    );
  }
}

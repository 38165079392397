import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/** Shortcut EN-US date pipe: "MM/dd/yyyy". */
@Pipe({
  name: 'EnUsDate',
})

export class EnUsDatePipe implements PipeTransform {

  public constructor(
    private readonly datePipe: DatePipe,
  ) { }

  /**
   * Transform a date to a EN-US date string.
   * @param value Unformatted date.
   */
  public transform(value: Date | null): string | null {
    return this.datePipe.transform(value, 'MM/dd/yyyy');
  }
}

import { enumToArray } from '../utils/enum-to-array';

import { CustomKeyValue } from './custom-key-value';

/** Delivery period. */
export enum DeliveryPeriod {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

/**
 * Namespace for delivery period.
 * Contains util methods and props for easier enum use.
 */
export namespace DeliveryPeriod {
  const READABLE_MAP: Record<DeliveryPeriod, string> = {
    [DeliveryPeriod.Daily]: 'Daily',
    [DeliveryPeriod.Weekly]: `Week`,
    [DeliveryPeriod.Monthly]: `Month`,
  };

  /** Get list for all delivery periods. */
  export function toArray(): DeliveryPeriod[] {
    return enumToArray(DeliveryPeriod);
  }

  /**
   * Return human-readable delivery period representation.
   * @param period Delivery period.
   */
  export function toReadable(period: DeliveryPeriod): string {
    return READABLE_MAP[period];
  }

  /** Get list for key value delivery periods array. */
  export function toKeyValueArray(): CustomKeyValue<string, DeliveryPeriod>[] {
    return DeliveryPeriod.toArray().map(value => ({ value, key: DeliveryPeriod.toReadable(value) }));
  }
}

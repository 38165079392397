import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/** Download file service. */
@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  /**
   * Download file with name.
   * @param fileSource File source.
   * @param name File name.
   */
  public download(fileSource: Blob, name: string): void {
    const dataType = fileSource.type;
    const binaryData = [];
    binaryData.push(fileSource);
    const downloadLink = this.document.createElement('a');
    downloadLink.href = URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    downloadLink.setAttribute('download', name);
    this.document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
    downloadLink.parentNode?.removeChild(downloadLink);
  }
}

import { AbstractControl } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { distinctUntilChanged, mapTo, shareReplay, startWith } from 'rxjs/operators';

/**
 * Create an observable that emits a boolean value when control becomes enabled / disabled.
 * @param control Control to listen changes.
 * @returns True - control is enabled, false - control is disabled.
 */
export function listenControlAvailabilityChanges(control: AbstractControl): Observable<boolean> {
  return merge(
    catchControlDisabled(control).pipe(mapTo(false)),
    catchControlEnabled(control).pipe(mapTo(true)),
  ).pipe(
    startWith(control.enabled),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}

/**
 * Emits when control becomes disabled.
 * @param control Control to listen state.
 */
function catchControlDisabled(control: AbstractControl): Observable<void> {
  return new Observable(observer => {
    const hostDisableFn = control.disable;
    control.disable = () => {
      observer.next();
      hostDisableFn.call(control);
    };

    return () => {
      control.disable = hostDisableFn;
    };
  });
}

/**
 * Emits when control becomes enabled.
 * @param control Control to listen state.
 */
function catchControlEnabled(control: AbstractControl): Observable<void> {
  return new Observable(observer => {
    const hostEnableFn = control.enable;
    control.enable = () => {
      observer.next();
      hostEnableFn.call(control);
    };

    return () => {
      control.enable = hostEnableFn;
    };
  });
}

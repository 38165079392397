<div class="main-container">
  <ng-container *ngIf="invoicePreviewData$ | async as invoicePreviewData">
    <ng-container *ngLet="isInvoicePreview$ | async as isInvoice">
      <header class="header">
        <section class="customer-information">
          <h3 class="header__title">Customer information</h3>
          <div class="addition-information__item">
            <ng-container *ngFor="let line of invoicePreviewData.headerLines">
              <p *ngIf="line" class="customer-information__value">
                {{ line }}
              </p>
            </ng-container>
          </div>
        </section>

        <section
          *ngIf="invoicePreviewData.additionalLines.length"
          class="additional-information"
        >
          <h3
            class="header__title"
            *ngIf="
              containsAdditionalLineTitle(invoicePreviewData.additionalLines)
            "
          >
            Additional information
          </h3>
          <div class="addition-information__content">
            <div>
              <ng-container
                *ngFor="let line of invoicePreviewData.additionalLines"
              >
                <div
                  *ngIf="line.key && line.value"
                  class="additional-information__content-column"
                >
                  <p class="additional-information__title">{{ line.key }}:</p>
                </div>
              </ng-container>
            </div>
            <div>
              <ng-container
                *ngFor="let line of invoicePreviewData.additionalLines"
              >
                <div
                  *ngIf="line.key && line.value"
                  class="additional-information__content-column"
                >
                  <p class="additional-information__value" *ngIf="line.value">
                    {{ line.value }}
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
        </section>
      </header>
      <div
        class="invoice-container"
        [style.borderColor]="invoicePreviewData.brandingColor"
      >
        <section class="invoice-information">
          <h3 class="visually-hidden">Invoice information</h3>
          <table class="invoice-information__header-table">
            <tr>
              <th class="align-start">Invoice #</th>
              <th>Date</th>
              <th>Date due</th>
              <th class="align-end">Total due</th>
            </tr>
            <tr>
              <td class="align-start">
                {{ invoicePreviewData.invoicePrefix | protctcInvoicePrefix }}
                {{ invoicePreviewData.invoiceNumber }}
              </td>
              <td>
                {{ getDate(invoicePreviewData.createdDate) }}
              </td>
              <td>
                <span *ngIf="invoicePreviewData.dueDate; else empty">
                  {{ getDate(invoicePreviewData.dueDate) }}
                </span>
              </td>
              <td class="bold align-end">
                {{ getCurrency(invoicePreviewData.totalDue) }}
              </td>
            </tr>
          </table>
        </section>

        <section class="work-details-information">
          <h3 class="visually-hidden">Work details information</h3>
          <table class="work-details-table">
            <tr>
              <th
                *ngFor="
                  let col of invoicePreviewData.workDetailsColumnsNames;
                  let last = last
                "
              >
                {{ col }}
                <div class="tax-includes-wrapper">
                  <span
                    *ngIf="
                      last &&
                      isInvoice &&
                      (isInvoiceWithoutTax() | async) === false &&
                      (viewInvoiceAsCustomer$ | async) === false
                    "
                    class="tax-includes"
                  >
                    <span *ngIf="getInvoiceCCFeeRate() | async as feeRate">
                      includes {{ feeRate }}%
                    </span>
                  </span>
                </div>
              </th>
            </tr>
            <tr
              *ngFor="
                let lineItem of invoicePreviewData.lineItems;
                trackBy: trackLineItem
              "
            >
              <td>
                <span
                  class="item__title"
                  [class.item__title_invoice]="isInvoice"
                >
                  {{ lineItem.title }}
                </span>
                <br />
                <span
                  class="item__description"
                  [class.item__description_invoice]="isInvoice"
                >
                  {{ lineItem.description }}
                </span>
              </td>
              <td>
                {{ lineItem.quantity }}
              </td>
              <td>
                {{ getCurrency(lineItem.price) }}
              </td>
              <td>
                {{ getCurrency(lineItem.amount) }}
              </td>
            </tr>
          </table>
        </section>

        <section class="attachments-information">
          <h3 class="visually-hidden">Total & attachments information</h3>
          <div class="attachments-links">
            <a
              class="link"
              *ngFor="let attachment of invoicePreviewData.attachments"
              [href]="attachment.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ attachment.name }}
            </a>
          </div>
          <ng-content></ng-content>
          <table class="total-table">
            <tr>
              <th>Sub total</th>
              <td>
                {{ getCurrency(invoicePreviewData.subtotal) }}
              </td>
            </tr>
            <tr>
              <th>Tax</th>
              <td>
                {{ getCurrency(invoicePreviewData.tax) }}
              </td>
            </tr>
            <tr>
              <th class="total__title">
                <div class="total__title_wrapper">
                  <p class="total__title-label">Total</p>
                  <p class="total__title-currency">(USD)</p>
                </div>
              </th>
              <td class="total__value">
                {{ getCurrency(invoicePreviewData.total) }}
              </td>
            </tr>
            <tr>
              <th class="cash-price" [class.cash-price_invoice]="isInvoice">
                Cash price
              </th>
              <td [class.cash-price_invoice]="isInvoice">
                {{ getCurrency(invoicePreviewData.cashPrice) }}
              </td>
            </tr>
          </table>
        </section>

        <section class="disclaimer" [class.disclaimer_invoice]="isInvoice">
          <h3 *ngIf="isInvoice === false" class="header__title">Disclaimer</h3>
          <p class="disclaimer__text">
            {{ invoicePreviewData.disclaimer }}
          </p>
        </section>

        <footer class="footer">
          <div class="footer-container">
            <protctc-image-uploader-preview
              [class.order]="
                isRightLogoPosition(invoicePreviewData.logoPosition)
              "
              class="footer__logo"
              [src]="invoicePreviewData.logoImage"
              alt="Company logo"
            ></protctc-image-uploader-preview>
            <section class="footer-information">
              <h3 class="visually-hidden">Footer information</h3>
              <p
                *ngFor="let line of invoicePreviewData.footerLines"
                class="header__option"
              >
                {{ line }}
              </p>
            </section>
          </div>
          <div class="footer__customer-signature">
            <protctc-image-uploader-preview
              *ngIf="invoicePreviewData.customerSignature as signature"
              class="customer-signature-preview"
              [src]="signature"
              alt="Customer Signature"
            ></protctc-image-uploader-preview>
            <div class="customer-signature">Customer signature</div>
          </div>
        </footer>
      </div>

      <div class="powered">
        <span class="powered__text">Powered by</span>
        <img
          class="powered__logo"
          src="assets/pms-logo.svg"
          alt="Pantera Software"
        />
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

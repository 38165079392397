import { Directive, EmbeddedViewRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ProtctPlatform, ProtctPlatformService } from '@protctc/common/core/services/protct-platform.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/public_api';

/**
 * A structural directive that, depending on the current platform, shows or hides the template.
 */
@Directive({
  selector: '[protctcPlatform]',
})
@DestroyableComponent()
export class PlatformsDirective implements OnInit {

  private platforms: ProtctPlatform[] = [];

  private currentPlatform: ProtctPlatform = 'web';

  private currentView: EmbeddedViewRef<unknown> | null = null;

  public constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
    private readonly platformService: ProtctPlatformService,
  ) {
  }

  /**
   * The platforms at which the current directive shows template.
   * @param values Platforms.
   */
  @Input()
  public set protctcPlatform(values: ProtctPlatform[]) {
    this.platforms = values;
    this.updateView();
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.platformService.currentPlatform$.pipe(
      takeUntilDestroy(this),
    ).subscribe(platform => {
      this.currentPlatform = platform;
      this.updateView();
    });
  }

  private updateView(): void {
    if (this.checkPlatform()) {
      if (!this.currentView) {
        this.currentView = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPlatform(): boolean {
    return this.platforms.includes(this.currentPlatform);
  }
}

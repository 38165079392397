import { Injectable } from '@angular/core';
import { Timezone } from '@protctc/common/core/models/timezone/timezone';
import { reverseRecord } from '@protctc/common/core/utils/reverse-record';

import { TimezoneDto } from '../dto/timezone/timezone.dto';
import { IMapper } from '../mappers';

const TO_TIMEZONE_DTO: Readonly<Record<Timezone, TimezoneDto>> = {
  [Timezone.Alaska]: TimezoneDto.Alaska,
  [Timezone.Aleutian]: TimezoneDto.Aleutian,
  [Timezone.Arizona]: TimezoneDto.Arizona,
  [Timezone.Central]: TimezoneDto.Central,
  [Timezone.EastIndiana]: TimezoneDto.EastIndiana,
  [Timezone.Eastern]: TimezoneDto.Eastern,
  [Timezone.Hawaii]: TimezoneDto.Hawaii,
  [Timezone.IndianaStarke]: TimezoneDto.IndianaStarke,
  [Timezone.Michigan]: TimezoneDto.Michigan,
  [Timezone.Mountain]: TimezoneDto.Mountain,
  [Timezone.Pacific]: TimezoneDto.Pacific,
  [Timezone.Samoa]: TimezoneDto.Samoa,
};

const FROM_TIMEZONE_DTO = reverseRecord(TO_TIMEZONE_DTO);

/** Timezone mapper. */
@Injectable({
  providedIn: 'root',
})
export class TimezoneMapper implements IMapper<TimezoneDto, Timezone> {

  /** @inheritdoc */
  public fromDto(data: TimezoneDto): Timezone {
    return FROM_TIMEZONE_DTO[data];
  }

  /** @inheritdoc */
  public toDto(data: Timezone): TimezoneDto {
    return TO_TIMEZONE_DTO[data];
  }
}

import { Injectable } from '@angular/core';

import { PaymentTransaction } from '../../models/payment/payment-transaction';

import { DateMapper } from './date.mapper';
import { PaymentTransactionDto } from './dto/payment/payment-transaction.dto';
import { IMapperFromDto } from './mappers';

/** Mapper for payment transactions history entities. */
@Injectable({
  providedIn: 'root',
})
export class PaymentTransactionMapper implements IMapperFromDto<PaymentTransactionDto, PaymentTransaction> {

  public constructor(
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: PaymentTransactionDto): PaymentTransaction {
    return {
      date: this.dateMapper.fromDto(data.date),
      id: data.transaction_id,
      detail: data.detail,
      amount: data.amount,
    };
  }
}

/** Fields available for sorting the invoice short items. */
export enum CompanyFieldUserSortField {

  /** Name. */
  Name = 'name',

  /** Invoice count. */
  InvoiceCount = 'invoiceCount',

  /** Total amount. */
  TotalAmount = 'totalAmount',

  /** Last activity. */
  LastActivity = 'lastActivity',

  /** Default. */
  Default = LastActivity,
}

import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

/** Dialog stepper component. */
@Component({
  selector: 'protctc-get-started-stepper',
  templateUrl: './get-started-stepper.component.html',
  styleUrls: ['./get-started-stepper.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: GetStartedStepperComponent }],
})
export class GetStartedStepperComponent extends CdkStepper {

  /** Emit when stepper is complete. */
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  public readonly complete = new EventEmitter<void>();

  /** Text of the button to change steps. */
  public get nextButtonText(): string {
    return this.isLastStep ? 'Got it!' : 'Next';
  }

  /** Check if current step is last. */
  public get isLastStep(): boolean {
    return this.selected === this.steps.last;
  }

  /**
   * Handle 'click' of a 'Next step' button.
   */
  public onNextStepClick(): void {
    if (this.isLastStep) {
      this.complete.emit();
    } else {
      this.next();
    }
  }

  /**
   * Handle 'click' of the 'step-header' element.
   * @param step Step.
   */
  public onStepClick(step: CdkStep): void {
    if (step.completed && step !== this.selected) {
      step.select();
    }
  }
}

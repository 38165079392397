import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/** Basic page layout. */
@Component({
  selector: 'protctw-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent {

  /** Show required remark in the card. */
  @Input()
  public doesShowRequired = false;
}

import { Injectable } from '@angular/core';

import { InvoiceDetailOption } from '../../models/invoice-detail-option';

import { InvoiceDetailOptionDto } from './dto/invoice-detail-option-dto';
import { IMapper } from './mappers';

/** Mapper for invoice detail option entities. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceDetailOptionMapper implements IMapper<InvoiceDetailOptionDto, InvoiceDetailOption> {

  /** @inheritdoc */
  public toDto(data: InvoiceDetailOption): InvoiceDetailOptionDto {
    return {
      id: data.id,
      column: data.column,
      value: data.value,
    };
  }

  /** @inheritdoc */
  public fromDto(data: InvoiceDetailOptionDto): InvoiceDetailOption {
    return {
      id: data.id,
      column: data.column,
      value: data.value,
    };
  }
}

import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationLink } from '@protctc/common/core/models/navigation-link';
import { UserService } from '@protctc/common/core/services/user.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { UserRole } from '@protctc/common/core/enums/user-role';
import { BreakpointObserverService } from '@protctc/common/core/services/breakpoint-observer.service';
import { routePaths } from '@protctc/common/core/utils/route-paths';
import { DialogsService } from '@protctc/common/shared/dialogs/dialogs.service';
import { WelcomeDialogComponent } from '@protctc/common/shared/dialogs/welcome-dialog/welcome-dialog.component';
import { CompanyLogo } from '@protctc/common/core/models/company-logo';
import { CompanyService } from '@protctc/common/core/services/company.service';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';
import { LogoService } from '@protctc/common/core/services/logo.service';
import {
  filter,
  of,
  Observable,
  shareReplay,
  switchMap,
  tap,
  BehaviorSubject,
  first,
  concat,
  merge,
  ignoreElements,
  switchMapTo,
} from 'rxjs';

interface NavigationMenuSettings {

  /** Title. */
  title: string;

  /** Is submenu opened. */
  isOpened: boolean;
}

/** Sidebar component. */
@DestroyableComponent()
@Component({
  selector: 'protctw-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {

  /** Logo image is loading. */
  public readonly logoLoading$ = new BehaviorSubject<boolean>(true);

  /** Current user data. */
  public readonly currentUser$ = this.userService.currentUser$;

  /** Check the matching state of layout breakpoint stream.  */
  public readonly isCatchBreakpoint$ = this.createCatchBreakpointStream();

  /** Represent user role like human-readable string. */
  public readonly toReadableUserRole = UserRole.toReadable;

  /** User roles. */
  public readonly userRole = UserRole;

  /** Route paths. */
  public readonly routePaths = routePaths;

  /** Array all titles and flag is submenu of this title is opened. */
  public subMenuOpenedSettings: NavigationMenuSettings[] = [];

  /** Company logo. */
  public readonly companyLogo$ = this.initCompanyLogoStream();

  /** Navigation links. */
  public readonly links$ = new BehaviorSubject<NavigationLink[]>([]);

  public constructor(
    private readonly userService: UserService,
    private readonly breakpointObserverService: BreakpointObserverService,
    private readonly router: Router,
    private readonly dialogsService: DialogsService,
    private readonly companyService: CompanyService,
    private readonly logoService: LogoService,
  ) { }

  /** Links. */
  @Input()
  public set links(data: NavigationLink[] | null) {
    if (data) {
      this.links$.next(data);
    }
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToFirstLogin();
    this.subscribeToSubMenuChanges();

    const addSubMenuOpenedSettingsSideEffect$ = this.links$.pipe(
      tap(links => {
        links.forEach(link => {
          const routePath = link.route.join('/');
          this.subMenuOpenedSettings.push({ title: link.text, isOpened: this.router.url.includes(routePath) });
        });
      }),
    );

    merge(
      addSubMenuOpenedSettingsSideEffect$,
    ).pipe(
      ignoreElements(),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * Check if is submenu of title opened.
   * @param title Title.
   */
  public isOpenedMenu(title: string): boolean {
    return this.findSubmenuOpenedSettingByTitle(title)?.isOpened ?? false;
  }

  /**
   * Toggle open submenu of title flag.
   * @param title Title.
   * @param event Event for prevent link redirection.
   */
  public toggleOpenMenu(title: string, event?: Event): void {

    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.subMenuOpenedSettings.forEach(item => {
      if (item.title === title) {
        item.isOpened = !item.isOpened;
      }
    });
  }

  /** Logout. */
  public logout(): void {
    this.userService.logout().pipe(
      takeUntilDestroy(this),
    )
      .subscribe(() => this.router.navigateByUrl('/auth'));
  }

  /**
   * Track by navigation link.
   * @param _ Index.
   * @param link Navigation Link.
   */
  public trackByNavigationLink(_: number, link: NavigationLink): string {
    return link.route.join('/');
  }

  /** Create catch breakpoint stream for change layout. */
  private createCatchBreakpointStream(): Observable<boolean> {
    return this.breakpointObserverService.createMobileBreakpointObserver().pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /** Init company logo stream. */
  private initCompanyLogoStream(): Observable<CompanyLogo | null> {
    return concat(
      this.currentUser$.pipe(
        first(),
        filter(user => user !== null),
        filterNull(),
        switchMap(user => user.companyId ? this.companyService.getCompanyLogo(user.companyId) : of(null)),
        tap(logo => logo ? this.logoService.setLogo(logo) : void 0),
      ),
      this.logoService.logo$,
    );
  }

  /** Subscribe to fist user login for show let's get started dialog. */
  private subscribeToFirstLogin(): void {
    this.userService.isFirstLogin$.pipe(
      filter(isFirstLogin => isFirstLogin),
      tap(() => this.dialogsService.openDialog(WelcomeDialogComponent)),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /** Subscribe to submenu changes. */
  private subscribeToSubMenuChanges(): void {

    this.router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationEnd),
      switchMapTo(this.links$),
      takeUntilDestroy(this),
    )
      .subscribe(links => {
        links.forEach(link => {
          const routePath = link.route.join('/');
          const isCurrentSubmenuSettingOpened = this.findSubmenuOpenedSettingByTitle(link.text)?.isOpened === true;
          const isUrlIncludeRoutePath = this.router.url.includes(routePath);
          const isShouldToggle = link.children && (isUrlIncludeRoutePath && !isCurrentSubmenuSettingOpened) ||
            (!isUrlIncludeRoutePath && isCurrentSubmenuSettingOpened);

          if (isShouldToggle) {
            this.toggleOpenMenu(link.text);
          }
        });
      });
  }

  /**
   * Find submenu opened settings by title.
   * @param title Title.
   */
  private findSubmenuOpenedSettingByTitle(title: string): NavigationMenuSettings | undefined {
    return this.subMenuOpenedSettings.find(item => item.title === title);
  }
}

export type PaginationConstructorData<T> = Pick<Pagination<T>, 'items' | 'totalCount' | 'page' | 'size'>;

/** Pagination wrapper. */
export class Pagination<T> {

  /** Items on the page. */
  public readonly items: readonly T[];

  /** Total count in the store. */
  public readonly totalCount: number;

  /** 0-based page number. */
  public readonly page: number;

  /** Max amount of items on the page. */
  public readonly size: number;

  public constructor(
    data: PaginationConstructorData<T>,
  ) {
    this.items = data.items;
    this.totalCount = data.totalCount;
    this.page = data.page;
    this.size = data.size;
  }

  /** Whether the pagination have next pages. */
  public get haveNext(): boolean {
    return (this.page + 1) * (this.size) < this.totalCount;
  }

  /** Whether the pagination have prev pages. */
  public get havePrev(): boolean {
    return this.page !== 0;
  }

  /** Whether items is present or not. */
  public get hasItems(): boolean {
    return this.items.length > 0;
  }
}

import { Route } from '@angular/router';

/** Data needed for the construction of breadcrumb model. */
export type BreadcrumbConstructorData = Breadcrumb;

/** Information about Breadcrumb. */
export class Breadcrumb {
  /**
   * Id of breadcrumb.
   * Allow you to replace name of breadcrumb in runtime.
   */
  public id?: string;

  /** Display name. */
  public displayName: string;

  /** Is Terminal route. */
  public terminal: boolean;

  /** Url. */
  public url: string;

  /** Route. */
  public route?: Route;

  public constructor(data: BreadcrumbConstructorData) {
    this.id = data.id;
    this.displayName = data.displayName;
    this.terminal = data.terminal;
    this.url = data.url;
    this.route = data.route;
  }
}

import { NotePickerType } from '@protctc/common/shared/components/file-picker/note-picker/note-picker.component';

import { AppError } from '../models/app-error';

/**
 * Asserts that a file is uploaded.
 * @param file File.
 */
export function assertFileUploaded(file: string | File | null): asserts file is (string | null) {
  if (file instanceof File) {
    throw new AppError('File must be uploaded');
  }
}

/**
 * Asserts that a files is uploaded.
 * @param files Files.
 */
export function assertFilesUploaded<T>(files: readonly T[] | readonly File[] | T[] | File[]): asserts files is T[] {
  files.forEach(file => {
    if (file instanceof File) {
      throw new AppError('File must be uploaded');
    }
  });
}

/**
 * Type guard for file.
 * Cordova-plugin-file redefined class File(instanceof won't work).
 * @param file File.
 */
export function isFile(file: NotePickerType): file is File {
  const _file = file as File;
  return (
    _file.size !== undefined &&
    _file.name !== undefined &&
    _file.type !== undefined
  );
}

/** Data needed for the construction of line item model. */
export type LineItemConstructorData = LineItem;

/** Line item model. */
export class LineItem {

  /** Id. */
  public readonly id: number;

  /** Line item name. */
  public readonly name: string;

  /** Price. */
  public readonly price: number;

  /** Description. */
  public readonly description: string;

  /** Company id. */
  public readonly companyId: number;

  /** Created by user id. */
  public readonly createdBy: number;

  /** Updated by user id. */
  public readonly updatedBy: number | null;

  /** Created date. */
  public readonly created: Date;

  /** Modified date. */
  public readonly modified: Date;

  /** Tax type. */
  public readonly taxType: string;

  public constructor(data: LineItemConstructorData) {
    this.id = data.id;
    this.name = data.name;
    this.price = data.price;
    this.description = data.description;
    this.companyId = data.companyId;
    this.createdBy = data.createdBy;
    this.updatedBy = data.updatedBy;
    this.created = data.created;
    this.modified = data.modified;
    this.taxType = data.taxType;
  }
}

/**
 * Namespace for line item.
 * Contains util methods and props for easier enum use.
 */
export namespace LineItem {

  /**
   * Makes line item human-readable.
   * @param lineItem LineItem.
   */
  export function toReadable(lineItem: LineItem): string {
    return lineItem.name;
  }

  /**
   * Checks whether two line items are equal.
   * @param lineItem1 Line item.
   * @param lineItem2 Another line item.
   */
  export function compare(lineItem1: LineItem, lineItem2: LineItem): boolean {
    return lineItem1.id === lineItem2.id;
  }

  /**
   * Track by function.
   * @param _ Index.
   * @param item Line item.
   */
  export function trackBy(_: number, item: LineItem): number {
    return item.id;
  }
}

<ng-container *ngLet="isMobile$ | async as isMobile">
  <div *ngIf="lineItemForm" [formGroup]="lineItemForm" class="form">
    <div class="two-element-row">
      <div class="form-field-container autocomplete-form-field">
        <protctc-autocomplete
          [hasPagination]="true"
          [formControl]="lineItemControl"
          [configuration]="lineItemAutoCompleteConfiguration"
          [isLoading]="isSearchingLineItem$ | async"
          [placeholder]="isMobile ? 'Service' : 'Line item name'"
          icon="search"
          [required]="true"
        ></protctc-autocomplete>
        <protctc-form-control-validation-message [control]="lineItemControl">
          <span class="form__hint" hint>
            Enter new line item or search by the item's name. Maximum
            {{ maxLineItemLengthName }} characters
          </span>
        </protctc-form-control-validation-message>
      </div>
    </div>

    <div class="form-field-container">
      <mat-form-field class="form-field">
        <mat-label *protctcWebOnly>Line item description</mat-label>
        <mat-label *protctcMobileOnly>Description</mat-label>
        <textarea
          class="description"
          matInput
          placeholder="Line Item Description*. Ex: Routi  ne administration cost of maintaining the service"
          formControlName="description"
        ></textarea>
      </mat-form-field>
      <protctc-form-control-validation-message controlName="description">
        <span class="form__hint" hint> Maximum 200 characters </span>
      </protctc-form-control-validation-message>
    </div>

    <div class="two-element-row">
      <div class="form-field-container">
        <mat-form-field class="form-field">
          <mat-label *protctcWebOnly>Line item unit pricing</mat-label>
          <mat-label *protctcMobileOnly>Rate</mat-label>
          <input
            matInput
            type="text"
            inputmode="decimal"
            placeholder="Line Item Unit Pricing*. Ex: $5.95"
            formControlName="price"
            [imask]="masks.priceMask"
            [unmask]="true"
          />
        </mat-form-field>
        <protctc-form-control-validation-message controlName="price">
        </protctc-form-control-validation-message>
      </div>
      <div *protctcHideDisabled="'quantity'" class="form-field-container">
        <mat-form-field class="form-field">
          <mat-label>Quantity</mat-label>
          <input
            matInput
            inputmode="numeric"
            type="text"
            formControlName="quantity"
            [imask]="masks.quantityMask"
            [unmask]="true"
          />
        </mat-form-field>
        <protctc-form-control-validation-message controlName="quantity">
        </protctc-form-control-validation-message>
      </div>
    </div>
    <div>
      <div class="form-field-container">
        <mat-label
          class="page-content-title"
          protctcAddAsteriskToRequiredField
        >
          Tax type
        </mat-label>
        <mat-radio-group
          *ngIf="taxTypes$ | async as taxTypes"
          formControlName="taxType"
          class="tax-type"
        >
          <mat-radio-button
            class="small-radio"
            color="primary"
            *ngFor="let taxType of taxTypes"
            [value]="taxType.value"
          >
            {{ taxType.key }}
          </mat-radio-button>
        </mat-radio-group>
        <protctc-form-control-validation-message controlName="taxType">
        </protctc-form-control-validation-message>
      </div>
    </div>
  </div>
</ng-container>

import { AbstractControl } from '@angular/forms';
import { defer, Observable } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';

export const DEFAULT_DEBOUNCE_TIME = 300;

/**
 * Options of listenControlChanges function.
 */
export interface ListenControlChangesOpts<T> {

  /** Comparator for 'distinctUntilChanged' function. */
  compareFn?: (x: T, y: T) => boolean;

  /** Debounce time. */
  debounceTime: number;
}

/**
 * Listens control's `valueChanges` field.
 * Immediately starts with default value of the control.
 * Adds delay and emits value only if it was changed.
 * @param control Form control.
 * @param opts Options to configure operator.
 */
export function listenControlChanges<T>(
  control: AbstractControl,
  opts?: ListenControlChangesOpts<T>,
): Observable<T> {
  const { compareFn = (x: T, y: T) => x === y, debounceTime: time = DEFAULT_DEBOUNCE_TIME } = opts ?? {};
  return defer(() => control
    .valueChanges
    .pipe(
      startWith(control.value),
      debounceTime(time),
      distinctUntilChanged(compareFn),
    ));
}

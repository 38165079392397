/** List with all export masks for reuse it in pipes also. */
import * as IMask from 'imask';

/**
 * Convert date to string for mask.
 * @param date Date.
 */
export function formatDate(date: Date): string {
  if (date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let dayString = day.toString();
    let monthString = month.toString();
    if (day < 10) {
      dayString = `0${day}`;
    }
    if (month < 10) {
      monthString = `0${month}`;
    }
    return [monthString, dayString, year].join('/');
  }
  return date;
}

export const masks = {

  /** Tax number mask. */
  taxNumberMask: {
    mask: '00-0000000',
    lazy: false,
  },

  /** Phone and fax number. */
  phoneNumber: {
    mask: '(000)000-0000',
    lazy: false,
  },

  /** Social Security Number mask. */
  ssnNumber: {
    mask: '000-00-0000',
    lazy: false,
  },

  /** Driver license number mask. */
  driverLicenseNumber: {
    mask: /(?=^.{1,15}$)\w+$/,
    prepare(str: string) {
      return str.toUpperCase();
    },
  },

  /**
   * Color picker mask.
   * Accept only HEX format (#FFFFFF).
   */
  colorPickerMask: {
    mask: '#hex',
    blocks: {
      hex: {
        mask: /^[0-9a-f]{0,6}$/i,
      },
    },
    lazy: true,
  },

  /** Location number mask. */
  locationNumberMask: {
    mask: Number,
    scale: 0,
    min: 0,
    max: 2147483647,
  },

  /**
   * Decimal mask.
   * Allows 12 numbers in total.
   */
  decimalMask: {
    mask: Number,
    scale: 2,
    radix: '.',
    max: 9999999999.99,
  },

  /**
   * Number mask.
   * Allows 12 numbers in total.
   */
  numberMask: {
    mask: Number,
    scale: 0,
    min: 0,
    max: 99999999999999,
  },

  /** Percent mask. */
  percentMask: {
    mask: Number,
    scale: 2,
    radix: '.',
    max: 100,
    min: 0.01,
  },

  /** Address zip mask. */
  addressZipMask: {
    mask: '00000',
  },

  /**
   * Bank account number mask.
   * US bank accounts can be from 6 to 17 digits.
   */
  bankAccountNumberMask: {
    mask: /^\d{0,17}$/,
  },

  /** Bank routing number mask. */
  bankRoutingNumberMask: {
    mask: '000000000',
  },

  /** Bank card number mask. */
  bankCardNumberMask: {
    mask: '0000000000000000',
  },

  /** Expiry date mask. */
  expiryDateMask: {
    mask: '00/00',
    lazy: false,
  },

  /**
   * Date mask.
   * @param min Min date.
   * @param max Max date.
   */
  dateMaskFactory(min: Date | null = new Date(1900, 0, 1), max: Date | null) {
    const minDate = min ? new Date(min.getTime()) : min;
    if (minDate) {
      minDate.setDate(minDate.getDate() + 1);
    }
    return {
      mask: Date,

      pattern: 'm/`d/`Y',
      blocks: {
        d: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          maxLength: 2,
        },
        m: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
        },
        Y: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: 9999,
        },
      },
      format(date: Date) {
        return formatDate(date);
      },

      parse(str: string) {
        const dayMonthYear = str.split('/');
        return new Date(parseInt(dayMonthYear[2], 10), parseInt(dayMonthYear[0], 10) - 1, parseInt(dayMonthYear[1], 10));
      },

      minDate,
      max,

      autofix: true,
      lazy: true,
      overwrite: true,
    };
  },

  /** CVC mask. */
  cvcMask: {
    mask: '000',
  },

  /** Price mask. */
  priceMask: {
    mask: '$ price',
    blocks: {
      price: {
        mask: Number,
        radix: '.',
        scale: 2,
        max: 9999,
        padFractionalZeros: true,
      },
    },
  },

  /** Price mask without max limit. */
  bigPriceMask: {
    mask: '$ price',
    blocks: {
      price: {
        mask: Number,
        radix: '.',
        scale: 2,
        max: 99999999999,
        padFractionalZeros: true,
      },
    },
  },

  /** Quantity mask. */
  quantityMask: {
    mask: Number,
    scale: 0,
    max: 9999,
    min: 1,
  },
};

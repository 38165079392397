import { Injectable } from '@angular/core';

import { DateUtils } from '../../utils/date-utils';

import { IMapper } from './mappers';

/** Date mapper. */
@Injectable({
  providedIn: 'root',
})
export class DateMapper implements IMapper<string, Date> {

  /** @inheritdoc */
  public fromDto(dateStr: string): Date {
    if (DateUtils.isValidDateFormat(dateStr)) {
      return new Date(dateStr);
    }
    return new Date();
  }

  /** @inheritdoc */
  public toDto(date: Date): string {
    return date.toISOString();
  }

  // TODO (Svobodina) Delete when backend updated logic and will receive DateTime instead of Date only for utc.
  /** @inheritdoc */
  public toUTCDateStringDto(date: Date): string {
    return DateUtils.toDateOnlyString(DateUtils.getUTCDate(date));
  }

  /** @inheritdoc */
  public toDateOnlyStringDto(date: Date): string {
    return DateUtils.toDateOnlyString(date);
  }

  /** @inheritdoc */
  public toDateTimeStringDto(date: Date): string {
    return DateUtils.toDateTimeString(DateUtils.getUTCDate(date));
  }
}

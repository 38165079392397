import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppError, AppValidationError } from '../../models/app-error';

import { ValidationErrorMapper } from './validation-error-mapper';

/**
 * Error mapper type declaration.
 * Could be a simple function to transform errors from DTO to errors of domain model
 * or implementation of IMapper with implemented validationErrorFromDto method.
 */
export type ErrorMapper<TDto, TEntity extends object> = ValidationErrorMapper<TDto, TEntity> |
  ValidationErrorMapper<TDto, TEntity>['validationErrorFromDto'];

/**
 * API errors mapper.
 */
@Injectable({ providedIn: 'root' })
export class AppErrorMapper {
  /**
   * Convert default HttpErrorResponse object to custom application error.
   * @param httpError Http error response.
   */
  public fromDto(httpError: HttpErrorResponse): AppError {
    const { message } = httpError;
    return new AppError(message);
  }

  /**
   * Map HTTP API error response to the appropriate Api error model.
   * @param httpError Http error.
   * @param mapper Mapper function that transform validation DTO errors to the application validation model.
   * @returns AppError if httpError is not "Bad Request" error or AppValidationError if it is "Bad Request"/.
   */
  public fromDtoWithValidationSupport<TDto, TEntity extends object>(
    httpError: HttpErrorResponse,
    mapper: ErrorMapper<TDto, TEntity>,
  ): AppError | AppValidationError<TEntity> {
    if (httpError.status !== 400) {
      // It is not a validation error. Return simple AppError.
      return this.fromDto(httpError);
    }

    if (mapper == null) {
      throw new Error('Provide mapper for API errors.');
    }

    if (typeof mapper !== 'function' && mapper.validationErrorFromDto == null) {
      throw new Error('Provided mapper does not have implementation of validationErrorFromDto');
    }

    // TODO (template preparation): Check that API sends you an error with the same field (detail, data, etc.) and change it if it's needed.

    // This is a validation error => create AppValidationError.
    const message = httpError.error.detail;
    const validationData = typeof mapper === 'function' ?
      mapper(httpError.error.data) :
      mapper.validationErrorFromDto(httpError.error.data);
    return new AppValidationError<TEntity>(message, validationData);
  }

  /**
   * Catch Api Validation Error RxJS operator.
   * Catches only AppValidationError<T> errors.
   * @param callback S.
   */
  public catchHttpErrorToAppError<T>(): MonoTypeOperatorFunction<T> {
    return catchError((httpError: HttpErrorResponse) => {
      const appError = this.fromDto(httpError);
      return throwError(appError);
    });
  }

  /**
   * RxJS operator to catch and map HTTP API error response to the appropriate Api error model.
   * @param mapper Mapper function that transform validation DTO errors to the application validation model.
   * @returns AppError if httpError is not "Bad Request" error or AppValidationError if it is "Bad Request".
   */
  public catchHttpErrorToAppErrorWithValidationSupport<T, TDto, TEntity extends object>(
    mapper: ErrorMapper<TDto, TEntity>,
  ): MonoTypeOperatorFunction<T> {
    return catchError((httpError: HttpErrorResponse) => {
      const appError = this.fromDtoWithValidationSupport<TDto, TEntity>(httpError, mapper);
      return throwError(appError);
    });
  }
}

<button
  type="button"
  class="pdf-button"
  mat-icon-button
  aria-label="Download invoice preview as pdf file"
  title="Download invoice preview as pdf file"
  (click)="downloadInvoicePreviewAsPdf()"
  [protctcLoading]="isLoading$ | async"
>
  <mat-icon svgIcon="pdf"></mat-icon>
</button>

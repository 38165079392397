import { combineLatest, Observable, switchMap } from 'rxjs';

import { ExtraPagination } from '../models/extra-pagination';

import { Pagination } from '../models/pagination';
import { PaginationOptions } from '../models/pagination-options';
import { PayrixPagination } from '../models/pagination/payrix-pagination';

export type AsyncPaginationOptions<T extends PaginationOptions> = {
  readonly [K in keyof T]: Observable<T[K]>;
};

/**
 * Allows paginating data based on provided object containing async properties for pagination.
 * @param asyncOptions Async objects with pagination data.
 * @param fetch Fetch function that accepts the pagination options.
 *
 * @example
 * ```ts
 * const searchString$ = new BehaviorSubject('');
 * const $ = new BehaviorSubject('');
 *
 * ```
 */
export function paginate<T, O extends PaginationOptions>(
  asyncOptions: AsyncPaginationOptions<O>,
  fetch: (options: O) => Observable<Pagination<T>>,
): Observable<Pagination<T>> {
  return combineLatest(asyncOptions).pipe(
    switchMap(syncOptions => fetch(syncOptions)),
  );
}

/**
 * Allows paginating data based on provided object containing async properties for pagination.
 * @param asyncOptions Async objects with pagination data.
 * @param fetch Fetch function that accepts the pagination options.
 */
export function paginatePayrix<T, O extends PaginationOptions>(
  asyncOptions: AsyncPaginationOptions<O>,
  fetch: (options: O) => Observable<PayrixPagination<T>>,
): Observable<PayrixPagination<T>> {
  return combineLatest(asyncOptions).pipe(
    switchMap(syncOptions => fetch(syncOptions)),
  );
}

/**
 * Allows paginating data based on provided object
 * containing async properties for pagination with extra data.
 * @param asyncOptions Async objects with pagination data.
 * @param fetch Fetch function that accepts the pagination options.
 *
 * @example
 * ```ts
 * const searchString$ = new BehaviorSubject('');
 * const $ = new BehaviorSubject('');
 *
 * ```
 */
export function paginateExtra<T, O extends PaginationOptions, E>(
  asyncOptions: AsyncPaginationOptions<O>,
  fetch: (options: O) => Observable<ExtraPagination<T, E>>,
): Observable<ExtraPagination<T, E>> {
  return combineLatest(asyncOptions).pipe(
    switchMap(syncOptions => fetch(syncOptions)),
  );
}

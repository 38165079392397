import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject, concat, map, Observable, tap, withLatestFrom } from 'rxjs';
import { CompanyLogo } from '@protctc/common/core/models/company-logo';
import { LogoService } from '@protctc/common/core/services/logo.service';
import { CompanyService } from '@protctc/common/core/services/company.service';
import { onMessageOrFailed } from '@protctc/common/core/rxjs/public_api';

const DEFAULT_LOGO_SRC = 'assets/company_avatar_default.svg';

const DEFAULT_LOADING_SPINNER_DIAMETER = 50;

/**
 * Company logo.
 */
@Component({
  selector: 'protctc-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyLogoComponent {

  /**
   * Diameter loading spinner.
   */
  @Input()
  public loadingSpinnerDiameter = DEFAULT_LOADING_SPINNER_DIAMETER;

  /**
   * Default logo src.
   */
  public readonly defaultLogoSrc = DEFAULT_LOGO_SRC;

  /** Is loading logo. */
  public readonly isLoadingLogo$ = new BehaviorSubject<boolean>(true);

  /** Company logo. */
  public readonly companyLogo$ = this.initCompanyLogoStream();

  public constructor(
    private readonly logoService: LogoService,
    private readonly companyService: CompanyService,
  ) {
  }

  /** Init company logo stream. */
  private initCompanyLogoStream(): Observable<CompanyLogo | null> {
    return concat(
      this.companyService.currentCompanyLogo$.pipe(
        withLatestFrom(this.logoService.logo$),
        tap(([logo, cachedLogo]) => logo && logo?.id !== cachedLogo?.id ? this.logoService.setLogo(logo) : void 0),
        map(([logo]) => logo),
      ),
      this.logoService.logo$,
    ).pipe(
      onMessageOrFailed(() => this.isLoadingLogo$.next(false)),
    );
  }
}

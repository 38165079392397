<p class="dialog-message">{{ props.message }}</p>
<div class="buttons-block">
  <button class="button basic" type="button" (click)="closed.next(false)">
    {{ props.refuseButtonTitle || defaultProps.refuseButtonTitle }}
  </button>
  <button
    class="button flat"
    [class.warn]="this.props.isConfirmButtonDanger"
    type="button"
    [protctcLoading]="isLoading$ | async"
    (click)="confirm()"
    autofocus
  >
    {{ props.confirmButtonTitle || defaultProps.confirmButtonTitle }}
  </button>
</div>

<div class="table">
  <mat-table
    *ngIf="usersPage"
    matSort
    (matSortChange)="onSortChange($event)"
    [matSortDirection]="activeSortDirection"
    [matSortActive]="activeSortColumn"
    [dataSource]="usersPage.items"
    [trackBy]="trackUser"
  >
    <ng-container matColumnDef="employeeId">
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
        >ID</mat-header-cell
      >
      <mat-cell *matCellDef="let data">
        {{ data.employeeId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
        >Name</mat-header-cell
      >
      <mat-cell *matCellDef="let data">
        <a
          *ngIf="
            data.role === userRole.FieldUser && isBusinessOwnerUsersTable;
            else noFieldUserName
          "
          class="link"
          [routerLink]="['/users', 'details', data.id]"
        >
          <ng-template [ngTemplateOutlet]="noFieldUserName"></ng-template>
        </a>
        <ng-template #noFieldUserName>
          {{ data.firstName }} {{ data.lastName }}
        </ng-template>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
        >Email</mat-header-cell
      >
      <mat-cell *matCellDef="let data">
        {{ data.email }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
        >Title</mat-header-cell
      >
      <mat-cell *matCellDef="let data">
        {{ data.title }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
        >Role</mat-header-cell
      >
      <mat-cell *matCellDef="let data">
        {{ toReadableUserRole(data.role) }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastActivity">
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
        Last activity
      </mat-header-cell>
      <mat-cell *matCellDef="let data">
        <ng-container *ngIf="data.lastLogin; else empty">
          {{ data.lastLogin | date: "M/d/y, h:mm a" }}
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let data">
        <a [routerLink]="['edit', data.id]" class="button-edit button stroked">
          Edit
        </a>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    *ngIf="usersPage"
    (page)="onPageChange($event)"
    [pageIndex]="usersPage.page"
    [length]="usersPage.totalCount"
    [pageSize]="usersPage.size"
    [pageSizeOptions]="[5, 10, 25, 100]"
    showFirstLastButtons
  ></mat-paginator>
</div>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

import { Injectable } from '@angular/core';

import { UserRole } from '../../enums/user-role';
import { User, UserConstructorData } from '../../models/user';

import { DateMapper } from './date.mapper';
import { UserDto } from './dto/user-dto';
import { IMapperFromDto } from './mappers';

/**
 * Mapper for user entities.
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class UserMapper implements IMapperFromDto<UserDto, User> {

  public constructor(
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: UserDto): User {
    return new User(this.mapConstructorData(data));
  }

  /**
   * Maps constructor data for a user.
   * @param data Data to map.
   */
  private mapConstructorData(data: UserDto): UserConstructorData {
    return {
      id: data.id,
      employeeId: data.employee_id,
      email: data.email,
      bio: data.bio,
      title: data.title,
      firstName: data.first_name,
      lastName: data.last_name,
      role: data.role as UserRole,
      isActive: data.is_active,
      companyId: data.company,
      lastLogin: data.last_login ? this.dateMapper.fromDto(data.last_login) : null,
      modifiedDate: this.dateMapper.fromDto(data.modified),
      updatedBy: data.updated_by ?? '',
    };
  }
}

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMapTo } from 'rxjs/operators';

import { AppConfigService } from '../services/app-config.service';
import { UserService } from '../services/user.service';

/**
 * Interceptor for token validation.
 */
@Injectable()
export class ValidationTokenInterceptor implements HttpInterceptor {

  public constructor(
    private readonly appConfigService: AppConfigService,
    private readonly userService: UserService,
  ) { }

  /**
   * Validates a token.
   * @inheritdoc
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status !== 401 || !this.shouldValidateToken(req.url)) {
            return throwError(error);
          }
          return this.userService.forcedLogout().pipe(
            switchMapTo(EMPTY),
          );
        }),
      );
  }

  /**
   * Checks if a token need to be validated.
   * @param url Request url.
   */
  private shouldValidateToken(url: string): boolean {
    return url.startsWith(this.appConfigService.apiUrl) && !url.startsWith(`${this.appConfigService.apiUrl}/auth`);
  }
}

/* eslint-disable @typescript-eslint/member-ordering */
import { IMaskPipe } from 'angular-imask';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  merge,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { LogoPosition } from '@protctc/common/core/enums/logo-position';
import { InvoiceTemplateSaveData } from '@protctc/common/core/models/invoice-template-save-data';
import { Invoice } from '@protctc/common/core/models/invoice';
import { InvoicePreviewDataType } from '@protctc/common/shared/dialogs/invoice-preview-dialog/invoice-preview-dialog.component';
import { InvoiceLineItem } from '@protctc/common/core/models/invoice-line-item';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';

import { InvoiceView } from './invoice-preview-models/invoice-view';
import { DEFAULT_TEMPLATE_INVOICE_VIEW } from './invoice-preview-models/default-values-view-data';
import { InvoiceLineItemView } from './invoice-preview-models/invoice-line-item-view';
import { HEADER_COMPANY_NAME_KEY, InvoicePreviewService } from './invoice-preview.service';

/** Invoice template. */
@DestroyableComponent()
@Component({
  selector: 'protctc-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceTemplateComponent implements OnInit {

  /**
   * View invoice as customer.
   * Affects the tax view on line items.
   */
  @Input()
  public set viewInvoiceAsCustomer(data: boolean | null) {
    if (data !== null) {
      this.viewInvoiceAsCustomer$.next(data);
    }
  }

  /** Invoice preview data setter.*/
  @Input()
  public set invoicePreviewData(data: InvoicePreviewDataType) {
    this.rawInvoicePreviewData$.next(data);

    if (this.isInvoicePreview(data)) {
      this.invoicePreviewData$.next(this.mapInvoiceToInvoiceView(data));
      this.isInvoicePreview$.next(true);
      return;
    }
    if (this.isInvoiceView(data)) {
      this.invoicePreviewData$.next(data);
      this.isInvoicePreview$.next(true);
      return;
    }
    this.invoicePreviewData$.next(this.mapInvoiceTemplateSaveDataToInvoiceView(data));
  }

  /** Invoice preview data stream. */
  public readonly invoicePreviewData$ = new BehaviorSubject<InvoiceView | null>(null);

  /** View invoice as customer. */
  public readonly viewInvoiceAsCustomer$ = new BehaviorSubject(false);

  /** Is invoice preview stream. */
  public readonly isInvoicePreview$ = new BehaviorSubject(false);

  private readonly rawInvoicePreviewData$ = new BehaviorSubject<InvoicePreviewDataType | null>(null);

  public constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly datePipe: DatePipe,
    private readonly currencyPipe: CurrencyPipe,
    private readonly imaskPipe: IMaskPipe,
    private readonly invoicePreviewService: InvoicePreviewService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const toggleViewAsCustomerSideEffect$ = this.viewInvoiceAsCustomer$.pipe(
      distinctUntilChanged(),
      switchMap(viewAsCustomer => this.rawInvoicePreviewData$.pipe(
        filterNull(),
        tap(data => {
          if (this.isInvoicePreview(data)) {
            this.invoicePreviewData$.next(this.mapInvoiceToInvoiceView(data, viewAsCustomer));
          }
        }),
      )),
    );

    merge(
      toggleViewAsCustomerSideEffect$,
    ).pipe(
      ignoreElements(),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * Is right logo position.
   * @param logoPosition Logo position.
   */
  public isRightLogoPosition(logoPosition: string | LogoPosition): boolean {
    return logoPosition === LogoPosition.Right;
  }

  /**
   * Contains additional line key.
   * @param additionalLines Additional lines.
   */
  public containsAdditionalLineTitle(additionalLines: InvoiceView['additionalLines']): boolean {
    return additionalLines.some(line => line.key);
  }

  /**
   * Get date of invoice's date fields.
   * @param date Date.
   */
  public getDate(date: Date | string): string {
    if (typeof date === 'string') {
      return date;
    }
    return this.datePipe.transform(date, 'MM/dd/yyyy') ?? '-';
  }

  /**
   * Get currency on invoice's currency fields.
   * @param currency Currency.
   */
  public getCurrency(currency: string | number): string {
    if (typeof currency === 'string') {
      return currency;
    }
    return this.currencyPipe.transform(currency, 'USD', 'symbol', '1.2-2') ?? 'XX.XX';
  }

  /**
   * Track line item.
   * @param _ Index.
   * @param lineItem Line item.
   */
  public trackLineItem(_: number, lineItem: InvoiceLineItemView): string {
    return lineItem.title;
  }

  /** Is Invoice without tax. */
  public isInvoiceWithoutTax(): Observable<boolean> {
    return this.rawInvoicePreviewData$.pipe(
      filterNull(),
      filter(data => this.isInvoicePreview(data)),
      map(data => this.isInvoicePreview(data) && data.isInvoiceWithoutTax),
    );
  }

  /** Get invoice cc fee rate. */
  public getInvoiceCCFeeRate(): Observable<number> {
    return this.rawInvoicePreviewData$.pipe(
      filterNull(),
      filter(data => this.isInvoicePreview(data)),
      map(data => data as Invoice),
      map(data => data.ccFeeRate),
    );
  }

  private isInvoicePreview(data: InvoicePreviewDataType): data is Invoice {
    return data instanceof Invoice;
  }

  private isInvoiceView(data: InvoicePreviewDataType): data is InvoiceView {
    return !!(data as InvoiceView).headerLines;
  }

  /**
   * Map invoice to invoice view to show invoice preview.
   * @param data Invoice.
   * @param viewAsCustomer Show invoice preview as customer user.
   */
  private mapInvoiceToInvoiceView(data: Invoice, viewAsCustomer = false): InvoiceView {

    const headerLines = [
      HEADER_COMPANY_NAME_KEY,
      data.invoiceTemplate.headerFirstLine,
      data.invoiceTemplate.headerSecondLine,
      data.invoiceTemplate.headerThirdLine,
    ];

    const additionalLines: InvoiceView['additionalLines'] = [
      {
        key: data.invoiceTemplate.firstAdditionalFieldTitle,
        value: data.firstAdditionalFieldValue,
      },
      {
        key: data.invoiceTemplate.secondAdditionalFieldTitle,
        value: data.secondAdditionalFieldValue,
      },
      {
        key: data.invoiceTemplate.thirdAdditionalFieldTitle,
        value: data.thirdAdditionalFieldValue,
      },
    ].filter(line => line.value && line.key);

    const workDetailsColumnsNames = [
      data.invoiceTemplate.workDetailFirstColKey,
      data.invoiceTemplate.workDetailSecondColKey,
      data.invoiceTemplate.workDetailThirdColKey,
      data.invoiceTemplate.workDetailFourthColKey,
    ];

    const footerLines = [
      data.invoiceTemplate.footerFirstLine,
      data.invoiceTemplate.footerSecondLine,
      data.invoiceTemplate.footerThirdLine,
      data.invoiceTemplate.footerFourthLine,
    ];

    const attachments = data.attachmentSet.map(attachment => ({
      name: attachment.name,
      url: attachment.file,
    }));

    const templateAttachments = this.invoicePreviewService.mapNotesToInvoiceAttachmentsView(data.invoiceTemplate.notes, this.domSanitizer);

    return {
      headerLines: headerLines.map(line => this.invoicePreviewService.mapHeaderColumnsToCustomerInformation(
        line, data.customer, this.imaskPipe,
      )),
      additionalLines,
      cardinal: data.cardinal,
      createdDate: data.createdDate,
      dueDate: data.dueDate,
      totalDue: data.total,
      workDetailsColumnsNames,
      lineItems: data.lineItems.map(item => ({
        title: item.name,
        description: item.description,
        quantity: item.quantity,
        price: this.getLineItemPrice(item, viewAsCustomer),
        amount: item.total,
        subtotal: item.subtotal,
      })),
      attachments: [...templateAttachments, ...attachments],
      subtotal: data.subtotal,
      tax: data.tax,
      total: data.total,
      cashPrice: data.cashPrice,
      disclaimer: data.invoiceTemplate.disclaimer,
      footerLines: footerLines.map(line => this.invoicePreviewService.mapFooterColumnsToBusinessInformation(
        line, data.businessInformation, this.imaskPipe,
      )),
      logoPosition: data.invoiceTemplate.logoPosition,
      logoImage: data.invoiceTemplate.logo,
      brandingColor: data.invoiceTemplate.brandingColor,
      customerSignature: data.customerSignature ?? '',
      invoiceNumber: data.invoiceNumber,
      invoicePrefix: data.invoicePrefix,
    };
  }

  /**
   * Map invoice template save data to show invoice preview.
   * @param data Invoice template save data.
   */
  private mapInvoiceTemplateSaveDataToInvoiceView(data: InvoiceTemplateSaveData): InvoiceView {

    const headerLines = [
      HEADER_COMPANY_NAME_KEY,
      data.headerFirstLine,
      data.headerSecondLine,
      data.headerThirdLine,
    ];

    const additionalLines = [
      data.firstAdditionalFieldTitle,
      data.secondAdditionalFieldTitle,
      data.thirdAdditionalFieldTitle,
    ];

    const workDetailsColumnsNames = [
      data.workDetailFirstColKey,
      data.workDetailSecondColKey,
      data.workDetailThirdColKey,
      data.workDetailFourthColKey,
    ];

    const footerLines = [
      data.footerFirstLine,
      data.footerSecondLine,
      data.footerThirdLine,
      data.footerFourthLine,
    ];

    const attachments = this.invoicePreviewService.mapNotesToInvoiceAttachmentsView(data.attachments, this.domSanitizer);

    return {
      headerLines: headerLines.map(line => `[Customer ${line}] goes here`),
      additionalLines: additionalLines
        .filter(line => line !== '')
        .map(line => ({ key: line, value: `[${line}] goes here` })),
      cardinal: DEFAULT_TEMPLATE_INVOICE_VIEW.cardinal,
      createdDate: DEFAULT_TEMPLATE_INVOICE_VIEW.createdDate,
      dueDate: DEFAULT_TEMPLATE_INVOICE_VIEW.dueDate,
      totalDue: DEFAULT_TEMPLATE_INVOICE_VIEW.totalDue,
      workDetailsColumnsNames,
      lineItems: DEFAULT_TEMPLATE_INVOICE_VIEW.lineItems,
      attachments,
      subtotal: DEFAULT_TEMPLATE_INVOICE_VIEW.subtotal,
      tax: DEFAULT_TEMPLATE_INVOICE_VIEW.tax,
      total: DEFAULT_TEMPLATE_INVOICE_VIEW.total,
      cashPrice: DEFAULT_TEMPLATE_INVOICE_VIEW.cashPrice,
      disclaimer: data.disclaimer,
      footerLines: footerLines.map(line => `[Business ${line}] goes here`),
      logoPosition: data.logoPosition,
      logoImage: data.logoImage,
      brandingColor: data.brandingColor,
      customerSignature: '',
      invoiceNumber: data.invoiceMaskStartNumber,
      invoicePrefix: data.invoiceMaskPrefix,
    };
  }

  private getLineItemPrice(data: InvoiceLineItem, viewAsCustomer: boolean): number {
    return viewAsCustomer ? data.priceToCustomer : data.price;
  }
}

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { InvoiceShortSortField } from '@protctc/common/core/enums/invoice-short-sort-field';
import { InvoiceStatus } from '@protctc/common/core/enums/invoice-status';
import { PaymentMethod } from '@protctc/common/core/enums/payment-method';
import { SortDirection } from '@protctc/common/core/enums/sort-direction';
import { ExtraPagination } from '@protctc/common/core/models/extra-pagination';
import { InvoiceExtra } from '@protctc/common/core/models/invoice-extra';
import { InvoiceShort } from '@protctc/common/core/models/invoice-short';
import { Pagination } from '@protctc/common/core/models/pagination';
import { PaginationData } from '@protctc/common/core/models/pagination-data';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { toggleExecutionState } from '@protctc/common/core/rxjs/toggle-execution-state';
import { InvoiceService } from '@protctc/common/core/services/invoice.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { routePaths } from '@protctc/common/core/utils/route-paths';
import { DialogsService } from '@protctc/common/shared/dialogs/dialogs.service';
import { InvoicePreviewDialogComponent } from '@protctc/common/shared/dialogs/invoice-preview-dialog/invoice-preview-dialog.component';
import { BehaviorSubject, from, switchMap, tap } from 'rxjs';

const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30];

/** Type for displayed columns. */
export type InvoiceDisplayedColumnsType =
 | 'invoiceNumber'
 | 'createdDate'
 | 'dueDate'
 | 'customerName'
 | 'amount'
 | 'paymentMethod'
 | 'feeSaved'
 | 'status'
 | 'actions';

/** Invoices table. */
@DestroyableComponent()
@Component({
  selector: 'protctw-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: ['./invoices-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesTableComponent {

  /** Invoices page. */
  @Input()
  public invoicesPage: Pagination<InvoiceShort>
  | ExtraPagination<InvoiceShort, InvoiceExtra>
  | null = null;

  /** Displayed columns. */
  @Input()
  public displayedColumns: InvoiceDisplayedColumnsType[] = [];

  /** Sort options. */
  @Input()
  public sortOptions: SortOptions<InvoiceShortSortField> | null = null;

  /**
   * Is business owner invoices table or super admin.
   * Change markdown of table.
   */
  @Input()
  public isBusinessOwnerInvoicesTable = true;

  /** Empty preview message. */
  @Input()
  public emptyPreviewMessage = '';

  /** Page size options. */
  @Input()
  public pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;

  /** Change page emitter. */
  @Output()
  public readonly pageChanged = new EventEmitter<PaginationData>();

  /** Change sort emitter. */
  @Output()
  public readonly sortChanged = new EventEmitter<SortOptions<InvoiceShortSortField>>();

  /** Refresh table emitter. */
  @Output()
  public readonly refresh = new EventEmitter<void>();

  /** Route paths. */
  public readonly routePaths = routePaths;

  /** To readable payment method. */
  public readonly toReadablePaymentMethod = PaymentMethod.toReadable;

  /** To readable invoice status. */
  public readonly toReadableInvoiceStatus = InvoiceStatus.toReadable;

  /** Invoice status. */
  public readonly invoiceStatus = InvoiceStatus;

  /** Show preview loading state. */
  public readonly showPreviewLoading$ = new BehaviorSubject(false);

  /** Id of preview invoice. */
  public readonly previewInvoiceId$ = new BehaviorSubject<number | null>(null);

  public constructor(
    private readonly invoiceService: InvoiceService,
    private readonly dialogsService: DialogsService,
  ) { }

  /** Get active sort direction. */
  public get activeSortDirection(): SortDirection {
    return this.sortOptions?.direction as SortDirection ?? SortDirection.Asc;
  }

  /** Get active sort column. */
  public get activeSortColumn(): string {
    return this.sortOptions?.column ?? InvoiceShortSortField.Default;
  }

  /**
   * Is editable invoice status.
   * @param status Invoice status.
   */
  public isEditableInvoiceStatus(status: InvoiceStatus): boolean {
    return this.invoiceStatus.getStatusesEditableInvoice().includes(status);
  }

  /** Function to track invoices in array.
   * @param _ Index.
   * @param invoice Invoice to track.
   */
  public trackInvoice(_: number, invoice: InvoiceShort): number {
    return invoice.id;
  }

  /**
   * On change page.
   * @param event Page event.
   */
  public onPageChange(event: PageEvent): void {
    this.pageChanged.emit({
      page: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  /**
   * Change filter's sort configuration.
   * @param sort Sort info.
   */
  public onSortChange(sort: Sort): void {
    const sortDirection = sort.direction || SortDirection.Asc;
    this.sortChanged.emit({
      direction: sortDirection,
      column: sort.active as InvoiceShortSortField,
    });
  }

  /**
   * Show invoice details for invoice.
   * @param id Invoice id.
   */
  public showInvoiceDetails(id: number): void {
    this.previewInvoiceId$.next(id);

    this.invoiceService.getInvoiceById(id).pipe(
      toggleExecutionState(this.showPreviewLoading$),
      switchMap(invoice => from(this.dialogsService.openDialog(InvoicePreviewDialogComponent, {
          invoicePreviewData: invoice,
          title: 'Invoice details',
      }))),
      tap(isRefunded => isRefunded ? this.refresh.next() : undefined),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * Check that invoices has extra data.
   * @param invoices Invoices page.
   */
  public hasInvoicesExtraData(
    invoices: Pagination<InvoiceShort> | ExtraPagination<InvoiceShort, InvoiceExtra>,
  ): invoices is ExtraPagination<InvoiceShort, InvoiceExtra> {
    return invoices instanceof ExtraPagination &&
    (invoices as ExtraPagination<InvoiceShort, InvoiceExtra>).extraData !== undefined;
  }
}

import { Injectable } from '@angular/core';

import { InvoiceDelivery } from '../../enums/invoice-delivery';
import { reverseRecord } from '../../utils/reverse-record';

import { InvoiceDeliveryDto } from './dto/invoice-delivery-dto';
import { IMapper } from './mappers';

const TO_INVOICE_DELIVERY_DTO: Readonly<Record<InvoiceDelivery, InvoiceDeliveryDto>> = {
  [InvoiceDelivery.Email]: InvoiceDeliveryDto.Email,
  [InvoiceDelivery.SMS]: InvoiceDeliveryDto.SMS,
  [InvoiceDelivery.Both]: InvoiceDeliveryDto.Both,

};

const FROM_INVOICE_DELIVERY_DTO = reverseRecord(TO_INVOICE_DELIVERY_DTO);

/** Invoice delivery mapper. */
@Injectable({
  providedIn: 'root',
})
export class InvoiceDeliveryMapper implements IMapper<InvoiceDeliveryDto, InvoiceDelivery> {

  /** @inheritdoc */
  public fromDto(data: InvoiceDeliveryDto): InvoiceDelivery {
    return FROM_INVOICE_DELIVERY_DTO[data];
  }

  /** @inheritdoc */
  public toDto(data: InvoiceDelivery): InvoiceDeliveryDto {
    return TO_INVOICE_DELIVERY_DTO[data];
  }
}

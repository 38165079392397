<button
  type="button"
  class="sort-button"
  [class.sort-button_active]="isActive$ | async"
  (click)="onClicked()"
>
  <ng-content></ng-content>

  <mat-icon *ngIf="sort$ | async as sort" class="sort-button__icon">
    <ng-container *ngIf="sort === 'asc'; else descending">
      arrow_upward
    </ng-container>
    <ng-template #descending>arrow_downward</ng-template>
  </mat-icon>
</button>

/* eslint-disable @typescript-eslint/member-ordering */
import {
  ViewContainerRef,
  TemplateRef,
  Directive,
  ChangeDetectorRef,
  OnInit,
  Input,
  Optional,
  Host,
  SkipSelf,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { listenControlAvailabilityChanges } from '@protctc/common/core/rxjs/listen-control-enable-changes';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ConditionalRenderingDirective } from './conditional-rendering.directive';

/**
 * Directive that render content only if control is enabled.
 */
@DestroyableComponent()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[protctcHideDisabled]',
})
export class HideDisabledDirective extends ConditionalRenderingDirective implements OnInit {

  private readonly formControlName$ = new ReplaySubject<string>(1);

  /** Form control name to listen it's value. */
  @Input('protctcHideDisabled')
  public set controlName(control: string) {
    this.formControlName$.next(control);
  }

  public constructor(
    vcr: ViewContainerRef,
    cdr: ChangeDetectorRef,
    templateRef: TemplateRef<unknown>,
    @Optional() @Host() @SkipSelf() private parent: ControlContainer,
  ) {
    super(vcr, cdr, templateRef);
    assertNonNull(this.parent);
  }

  /** @inheritDoc */
  public ngOnInit(): void {
    this.formControlName$
      .pipe(
        map(controlName => this.parent?.control?.get(controlName)),
        switchMap(control => {
          assertNonNull(control);
          return listenControlAvailabilityChanges(control);
        }),
        takeUntilDestroy(this),
      )
      .subscribe(isEnabled => {
        if (isEnabled) {
          this.renderContent();
        } else {
          this.clearContent();
        }
      });
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { markFieldsAsTouched } from '@protctc/common/core/utils/mark-fields-as-touched';
import { filter, first, ReplaySubject, tap } from 'rxjs';

/** Directive to mark all as touched form control and nested controls. */
@DestroyableComponent()
@Directive({ selector: 'button[protctcMarkAllAsTouched]' })
export class MarkAllAsTouchedDirective {

  /** Control should be mark as touched with nested controls if exists. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('protctcMarkAllAsTouched')
  public set control(value: AbstractControl | null) {
    if (value) {
      this.control$.next(value);
    }
  }

  private readonly control$ = new ReplaySubject<AbstractControl>(1);

  /** On click event handler. */
  @HostListener('click')
  public markAsTouched(): void {
    this.control$.pipe(
      first(),
      filter(control => control.invalid),
      tap(control => markFieldsAsTouched(control)),
      takeUntilDestroy(this),
    ).subscribe();
  }

  /**
   * Set control.
   * @param control Control.
   */
  public setControl(control: AbstractControl): void {
    this.control$.next(control);
  }
}

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map, Observable, switchMap } from 'rxjs';

import { AppConfigService } from '../services/app-config.service';
import { UserService } from '../services/user.service';

const AUTH_HEADER_PREFIX = 'Token';

/**
 * Interceptor to add access token to requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public constructor(
    private readonly appConfigService: AppConfigService,
    private readonly userService: UserService,
  ) { }

  /**
   * Appends bearer token.
   * @inheritdoc
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.shouldInterceptToken(req.url)) {
      const userSecret$ = this.userService.currentSecret$.pipe(first());

      return userSecret$.pipe(
        map(userSecret => userSecret ? req.clone({
          headers: req.headers.set('Authorization', `${AUTH_HEADER_PREFIX} ${userSecret.token}`),
        }) : req),
        switchMap(newReq => next.handle(newReq)),
      );
    }

    // Do nothing.
    return next.handle(req);
  }

  /**
   * Checks if a request is for authorization or refresh token.
   * @param url - Request url.
   */
  private shouldInterceptToken(url: string): boolean {
    return url.startsWith(this.appConfigService.apiUrl);
  }
}

import { Injectable } from '@angular/core';
import { BusinessOwnerCreate } from '@protctc/common/core/models/company/business-owner';
import { assertFilesUploaded } from '@protctc/common/core/utils/assert-file-uploaded';

import { BusinessOwnerCreateDto } from '../dto/company/business-owner.dto';
import { IMapperToDto } from '../mappers';

import { BusinessOwnerMapper } from './business-owner.mapper';

/** Business owner create mapper. */
@Injectable({
  providedIn: 'root',
})
export class BusinessOwnerCreateMapper implements IMapperToDto<BusinessOwnerCreateDto, BusinessOwnerCreate> {

  public constructor(
    private readonly businessOwnerMapper: BusinessOwnerMapper,
  ) { }

  /** @inheritdoc */
  public toDto(data: BusinessOwnerCreate): BusinessOwnerCreateDto {
    assertFilesUploaded(data.documents);
    return {
      ...this.businessOwnerMapper.toDto(data),
      is_created_in_pms: data.isCreatedInPms,
    };
  }

}

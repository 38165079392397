import { ElementRef } from '@angular/core';

/**
 * Get first invalid control.
 * @param document Document object.
 * @param element Element in form.
 */
export function getFirstInvalidControl(document: Document, element?: ElementRef<HTMLElement>): Element | null {
  const closestForm = element?.nativeElement.closest('form');
  const parentNode = closestForm ?? document;
  return parentNode.querySelector(getFirstInvalidControlSelector());
}

/**
 * Smooth scroll into view to element.
 * @param element Element.
 */
export function smoothScrollIntoView(element: Element): void {
  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

/**
 * Smooth scroll to first invalid control.
 * @param document Document object.
 */
export function smoothScrollToFirstInvalidControl(document: Document): void {
  const firstInvalidControl = getFirstInvalidControl(document);
  if (firstInvalidControl !== null) {
    smoothScrollIntoView(firstInvalidControl);
  }
}

/**
 * Get first invalid control selector.
 */
function getFirstInvalidControlSelector(): string {
  // `[formGroup]`, `formGroupName` or `formArrayName` directives on this components
  // can mark them as invalid. So we need get only input controls or custom components implements
  // control value accessor with component's selectors.
  const onlyInputOrCustomControl = ':not(form):not(.form):not(div):not(section)';
  const invalidClass = '.ng-invalid';
  return `${invalidClass}${onlyInputOrCustomControl}`;
}

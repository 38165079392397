import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';

/** Dashboard statistic card data. */
export interface DashboardStatisticCardData {

  /** Title. */
  readonly title: string;

  /** Value. */
  readonly value: string;

  /** Percent difference. */
  readonly percentDifference: number;
}

/**
 * Namespace for dashboard statistics data.
 * Contains util methods and props for easier enum use.
 */
export namespace DashboardStatisticCardData {

  /**
   * Track by dashboard statistics card data function.
   * @param _ Index.
   * @param data Statistics data for the card.
   */
  export function trackBy(_: number, data: DashboardStatisticCardData): string {
    return data.title;
  }
}

/** Dashboard statistic card. */
@Component({
  selector: 'protctw-dashboard-statistic-card',
  templateUrl: './dashboard-statistic-card.component.html',
  styleUrls: ['./dashboard-statistic-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardStatisticCardComponent {

  /** Statistic data. */
  @Input()
  public data: DashboardStatisticCardData | null = null;

  /** Get decimal percent difference. */
  public getDecimalPercentDifference(): number {
    assertNonNull(this.data);
    return this.data.percentDifference / 100;
  }

  /**
   * Add `+` sign to percent string if need.
   * @param value Percent string value.
   */
  public addPlusSignToPercentDifference(value: string | null): string {
    if (value === null) {
      return '';
    }
    return this.isPositiveDifference() ? `+${value}` : value;
  }

  /** Is positive difference value. */
  public isPositiveDifference(): boolean {
    assertNonNull(this.data);
    return this.data.percentDifference > 0;
  }

  /** Is negative difference value. */
  public isNegativeDifference(): boolean {
    assertNonNull(this.data);
    return this.data.percentDifference < 0;
  }
}

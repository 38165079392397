
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'projects/web/src/app/modules/shared/shared.module';

import { CommonSharedModule } from '../common-shared.module';

import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DialogOverlayContainerComponent } from './dialog-overlay-container/dialog-overlay-container.component';
import { DialogsService } from './dialogs.service';
import { InvoicePreviewDialogComponent } from './invoice-preview-dialog/invoice-preview-dialog.component';
import { RejectApplicationDialogComponent } from './reject-application-dialog/reject-application-dialog.component';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { InvoiceSavedDialogComponent } from './invoice-saved-dialog/invoice-saved-dialog.component';

const EXPORTED_DECLARATIONS = [
  AlertDialogComponent,
  ConfirmDialogComponent,
  WelcomeDialogComponent,
  RejectApplicationDialogComponent,
  InvoicePreviewDialogComponent,
  InvoiceSavedDialogComponent,
];

/** Dialogs module. */
@NgModule({
  declarations: [
    DialogContainerComponent,
    DialogOverlayContainerComponent,
    DialogHeaderComponent,
    ...EXPORTED_DECLARATIONS,
  ],
  imports: [
    CommonModule,
    CommonSharedModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    DialogsService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  exports: [...EXPORTED_DECLARATIONS],
})
export class DialogsModule {}

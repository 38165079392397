/** Common. */
const authPath = ['/auth'];

/** Business owner section. */
const usersPath = ['/users'];
const customersPath = ['/customers'];
const dashboardPath = ['/dashboard'];
const invoiceSettingsPath = ['/invoice-settings'];
const invoicesPath = ['/invoices'];
const lineItemsPath = ['/line-items'];
const billingAndPaymentPath = ['/billing-payment'];
const accountSettingsPath = ['/account-settings'];

/** Admin section. */
const adminPath = ['/admin'];
const adminDashboardPath = adminPath.concat(['dashboard']);
const adminApplicationsPath = adminPath.concat(['applications']);
const adminMerchantPartnersPath = adminPath.concat(['merchant-partners']);
const adminInvoicesPath = adminPath.concat(['invoices']);
const adminUsersPath = adminPath.concat(['users']);
const adminInvoiceSettingsPath = adminPath.concat(['invoice-settings']);
const adminAccountSettingsPath = adminPath.concat(['account-settings']);

/** General info. */
const generalInfoPath = ['/general-info'];

/** List with all route paths. */
export const routePaths = {

  /** Home. */
  home: ['/'],

  /** Auth module. */
  auth: authPath,
  login: authPath.concat(['login']),
  forgotPassword: authPath.concat(['forgot-password']),
  confirmResetPassword: authPath.concat(['confirm-password']),
  registration: authPath.concat(['registration']),
  preRegistration: authPath.concat(['pre-registration']),

  /** Dashboard module. */
  dashboard: dashboardPath,
  dashboardFieldUsers: dashboardPath.concat(['field-users']),

  /** Invoice settings module. */
  invoiceSettings: invoiceSettingsPath,

  /** Invoices module. */
  invoices: invoicesPath,
  createInvoice: invoicesPath.concat(['create']),

  /** Line items module. */
  lineItems: lineItemsPath,
  createLineItem: lineItemsPath.concat(['create']),
  editLineItem: lineItemsPath.concat(['edit']),
  createGroupLineItem: lineItemsPath.concat(['create-group']),
  editGroupLineItem: lineItemsPath.concat(['edit-group']),

  /** User module. */
  users: usersPath,
  createUsers: usersPath.concat(['create']),
  editUser: usersPath.concat(['edit']),
  userDetails: usersPath.concat(['details']),

  /** Customers module. */
  customers: customersPath,
  createCustomer: customersPath.concat(['create']),
  editCustomer: customersPath.concat(['edit']),

  /** Billing & Payment module. */
  billingAndPayment: billingAndPaymentPath,
  editBankAccountInfo: billingAndPaymentPath.concat(['edit-bank-account']),
  addPaymentInfo: billingAndPaymentPath.concat(['add-payment']),
  depositTransactionDetails: billingAndPaymentPath.concat(['deposit-transaction-details']),
  paymentTransactionDetails: billingAndPaymentPath.concat(['payment-transaction-details']),

  /** Account Settings module. */
  accountSettings: accountSettingsPath,
  editAccountSettings: accountSettingsPath.concat(['edit']),

  /** Admin module. */
  adminDashboard: adminDashboardPath,
  adminApplications: adminApplicationsPath,
  merchantPartners: adminMerchantPartnersPath,
  adminInvoices: adminInvoicesPath,
  adminUsers: adminUsersPath,
  adminInvoiceSettings: adminInvoiceSettingsPath,
  adminAccountSettings: adminAccountSettingsPath,

  /** Admin applications module. */
  adminPendingReviewApplications: adminApplicationsPath.concat(['pending']),
  adminApprovedApplications: adminApplicationsPath.concat(['approved']),
  adminRejectedApplications: adminApplicationsPath.concat(['rejected']),

  /** Admin merchant module. */
  adminMerchantActive: adminMerchantPartnersPath.concat(['active']),
  adminMerchantInactive: adminMerchantPartnersPath.concat(['inactive']),
  adminMerchantDetails: adminMerchantPartnersPath.concat(['details']),

  /** Admin users module. */
  adminUsersCreate: adminUsersPath.concat(['create']),

  /** Policy info. */
  privacyPolicy: generalInfoPath.concat(['privacy-policy']),
  securityPolicy: generalInfoPath.concat(['security-policy']),
  refundPolicy: generalInfoPath.concat(['refund-policy']),
  termsOfService: generalInfoPath.concat(['terms-of-service']),
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';

import { Breadcrumb } from '../models/breadcrumb';

/**
 * Class for working with Breadcrumbs.
 *
 * This service automatically generates breadcrumbs for current route.
 * Routes with breadcrumbs should has additional data in definition:
 *
 *    {
 *      path: 'users',
 *      data: { breadcrumb: 'Users'},
 *      children: [
 *        {
 *          path: '',
 *          pathMatch: 'full',
 *          component: UserProfilePageComponent,
 *        },
 *        {
 *          path: 'edit',
 *          component: UserEditPageComponent,
 *          data: { breadcrumb: 'User Name', breadcrumbId: 'user-name' },
 *        },
 *      ],
 *    }.
 *
 * Param `breadcrumbId` allows you to change breadcrumb text dynamically in runtime
 * with `changeBreadcrumbById` method:
 *
 *    this.breadcrumbsService.changeBreadcrumbById('user-name', 'John Doe');.
 */
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {

  /** Reactive breadcrumbs value. */
  public readonly breadcrumbs$: Observable<Breadcrumb[]>;

  /** List of all breadcrumbs. */
  private breadcrumbs: Breadcrumb[] = [];

  private readonly breadcrumbsValue$ = new BehaviorSubject<Breadcrumb[]>([]);

  /** Cached route names. */
  private readonly cachedRouteNames = new Map<string, string>();

  public constructor(
    private readonly router: Router,
  ) {
    this.breadcrumbs$ = this.breadcrumbsValue$.asObservable();

    this.router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationEnd),
      tap(() => this.processRoute(router.routerState.root.snapshot)),
    ).subscribe();
  }

  /**
   * Change breadcrumb value by id.
   * @param id Id of breadcrumb (specified in route data).
   * @param name Breadcrumb name, to replace breadcrumb with specified id.
   */
  public changeBreadcrumbById(id: string, name: string): void {
    const breadcrumb = this.breadcrumbs.find(bc => bc.id === id);
    if (breadcrumb) {
      breadcrumb.displayName = name;
      this.updateValue();
    }
    this.cachedRouteNames.set(id, name);
  }

  private processRoute(activatedRoute: ActivatedRouteSnapshot): void {
    let url = '';

    let breadCrumbIndex = 0;
    const newCrumbs = [];

    let route = activatedRoute;

    while (route.firstChild != null) {
      route = route.firstChild;

      if (route.routeConfig === null || !route.routeConfig.path) {
        continue;
      }

      url += `/${this.createRouteUrl(route)}`;

      if (!route.data.breadcrumb) {
        continue;
      }

      const { breadcrumbId } = route.data;

      const newCrumb = new Breadcrumb({
        id: breadcrumbId,
        displayName: route.data.breadcrumb,
        terminal: this.isTerminal(route),
        url,
        route: route.routeConfig,
      });

      this.processRouteCached(breadcrumbId, newCrumb);

      if (breadCrumbIndex < this.breadcrumbs.length) {
        const existing = this.breadcrumbs[breadCrumbIndex++];

        if (existing && existing.route === route.routeConfig) {
          newCrumb.displayName = existing.displayName;
        }
      }

      newCrumbs.push(newCrumb);
    }

    this.updateValue(newCrumbs);
  }

  private updateValue(crumbs?: Breadcrumb[]): void {
    if (crumbs) {
      this.breadcrumbs = crumbs;
    }
    this.breadcrumbsValue$.next(this.breadcrumbs);
  }

  private processRouteCached(breadcrumbId: string, newCrumb: Breadcrumb): void {
    // Try to set cached Name for dynamic route
    if (breadcrumbId) {
      const cached = this.cachedRouteNames.get(breadcrumbId);
      if (cached) {
        newCrumb.displayName = cached;
        this.cachedRouteNames.delete(breadcrumbId);
      }
    }
  }

  /**
   * Check that route is terminal.
   * @param route Route to check.
   */
  private isTerminal(route: ActivatedRouteSnapshot): boolean {
    return (
      route.firstChild === null ||
      route.firstChild.routeConfig === null ||
      !route.firstChild.routeConfig.path
    );
  }

  /**
   * Create url for activate route snapshot.
   * @param route Route generate url.
   */
  private createRouteUrl(route: ActivatedRouteSnapshot): string {
    return route.url.map(s => s.toString()).join('/');
  }
}

<ng-container *ngIf="invoicesPage">
  <ng-template
    *ngIf="invoicesPage.hasItems === false"
    [ngTemplateOutlet]="noInvoices"
  ></ng-template>
  <div class="table" *ngIf="invoicesPage.hasItems">
    <mat-table
      matSort
      (matSortChange)="onSortChange($event)"
      [matSortDirection]="activeSortDirection"
      [matSortActive]="activeSortColumn"
      [dataSource]="invoicesPage.items"
      [trackBy]="trackInvoice"
    >
      <ng-container matColumnDef="invoiceNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Invoice #
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <button
            class="inline-table-button link-button basic"
            type="button"
            (click)="showInvoiceDetails(data.id)"
            [protctcLoading]="
              (showPreviewLoading$ | async) &&
              (previewInvoiceId$ | async) === data.id
            "
          >
            {{ data.invoicePrefix | protctcInvoicePrefix
            }}{{ data.invoiceNumber }}
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Date
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <time [dateTime]="data.createdDate">
            {{ data.createdDate | date : "MM/dd/yyyy" }}&nbsp;
          </time>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Due date
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <time *ngIf="data.dueDate; else empty" [dateTime]="data.dueDate">
            {{ data.dueDate | date : "MM/dd/yyyy" }}&nbsp;
          </time>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="customerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
          >Customer</mat-header-cell
        >
        <mat-cell *matCellDef="let data">
          {{ data.customerName | protctcNullable }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.amount | currency : "USD" : "symbol" : "1.2-2" }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="hasInvoicesExtraData(invoicesPage); else empty">
            {{
              invoicesPage.extraData.totalAmount
                | currency : "USD" : "symbol" : "1.2-2"
            }}
          </ng-container>
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="paymentMethod">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span *ngIf="data.paymentMethod; else empty">
            {{ toReadablePaymentMethod(data.paymentMethod) }}
          </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="feeSaved">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
          >Fee saved</mat-header-cell
        >
        <mat-cell *matCellDef="let data">
          <ng-container *ngIf="data.savedFee; else empty">
            {{ data.savedFee | currency : "USD" : "symbol" : "1.2-2" }}
          </ng-container>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Status
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <span class="status">
            {{ toReadableInvoiceStatus(data.status) }}
            <mat-icon
              *ngIf="data.status === invoiceStatus.Overdue"
              class="overdue-invoice"
              svgIcon="border"
            ></mat-icon>
          </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> Total amount: </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let data">
          <a
            *ngIf="isEditableInvoiceStatus(data.status)"
            [routerLink]="['/invoices', 'edit', data.id]"
            class="button stroked button-edit"
          >
            Edit
          </a>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        [class.display-none]="isBusinessOwnerInvoicesTable"
        *matFooterRowDef="displayedColumns; sticky: true"
      ></mat-footer-row>
    </mat-table>
    <mat-paginator
      *ngIf="invoicesPage"
      (page)="onPageChange($event)"
      [pageIndex]="invoicesPage.page"
      [length]="invoicesPage.totalCount"
      [pageSize]="invoicesPage.size"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

<ng-template #noInvoices>
  <ng-container
    *ngIf="isBusinessOwnerInvoicesTable; else noInvoicesForSuperAdmin"
  >
    <div class="no-invoices">
      <div class="no-invoices__header">
        <img class="no-invoices__icon" src="assets/invoices-empty.svg" />
        <h2 class="no-invoices__title">Time to turn nothing into something</h2>
        <p class="no-invoices__message">
          Create a new invoice template or generate an invoice for each
          transaction.
        </p>
        <a
          class="no-invoices__action-button button flat"
          [routerLink]="routePaths.createInvoice"
        >
          <mat-icon class="no-invoices__add-icon" svgIcon="add"></mat-icon>
          Create an invoice
        </a>
      </div>
      <div class="no-invoices__footer">
        <p class="no-invoices__footer-message">
          Make sure you've set up your invoicing
          <br />
          platform by completing all necessary tab fields.
        </p>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #noInvoicesForSuperAdmin>
  <protctw-empty-table-preview
    title="No invoices found"
  ></protctw-empty-table-preview>
</ng-template>

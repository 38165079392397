<p class="message">{{ props.message }}</p>
<form class="form" (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="form-field-container">
    <mat-form-field class="form-field">
      <mat-label>{{ props.label }}</mat-label>
      <textarea class="reason" matInput formControlName="value" placeholder="Your reason"></textarea>
    </mat-form-field>
    <protctc-form-control-validation-message controlName="value">
      <span *ngIf="props.hint" hint>{{ props.hint }}</span>
    </protctc-form-control-validation-message>
  </div>

  <div class="buttons-block">
    <button
      class="dialog__buttons--cancel button basic"
      type="button"
      (click)="closed.emit()"
    >
      Cancel
    </button>
    <button
      class="dialog__buttons--confirm button flat warn"
      type="submit"
    >
      {{ props.buttonTitle }}
    </button>
  </div>
</form>

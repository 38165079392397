import { Injectable } from '@angular/core';

import { Pagination } from '../../models/pagination';
import { PaginationOptions } from '../../models/pagination-options';
import { PayrixPagination } from '../../models/pagination/payrix-pagination';

import { PaginationDto } from './dto/pagination-dto';
import { PaginationOptionsDto } from './dto/pagination-options-dto';
import { PayrixPaginationDto } from './dto/pagination/payrix-pagination.dto';
import { PayrixPaginationOptionsDto } from './dto/payment/payrix-pagination-options.dto';
import { IMapperFromDto } from './mappers';

export type MapperFunction<TDto, TDomain> = (dto: TDto) => TDomain;

/** Pagination mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaginationMapper {

  /**
   * Maps pagination options to dto representation.
   * @param options Pagination options.
   */
  public mapOptionsToDto(options: PaginationOptions): PaginationOptionsDto {
    return {
      limit: options.paginationData.pageSize,
      offset: (options.paginationData.page * options.paginationData.pageSize),
    };
  }

  /**
   * Maps pagination options to payrix dto representation.
   * @param options Pagination options.
   */
  public mapOptionsToPayrixOptionsDto(options: PaginationOptions): PayrixPaginationOptionsDto {
    return {
      limit: options.paginationData.pageSize,
      number: options.paginationData.page + 1,
    };
  }

  /**
   * Map pagination from dto.
   * @param page Dto page.
   * @param options Local requested page.
   * @param mapper Mapper for the items.
   */
  public mapPaginationFromDto<TDto, TDomain>(
    page: PaginationDto<TDto>,
    options: PaginationOptions,
    mapper: IMapperFromDto<TDto, TDomain> | MapperFunction<TDto, TDomain>,
  ): Pagination<TDomain> {
    return new Pagination({
      items: page.results.map(typeof mapper === 'function' ? mapper : mapper.fromDto.bind(mapper)),
      page: options.paginationData.page,
      totalCount: page.count,
      size: options.paginationData.pageSize,
    });
  }

  /**
   * Map payrix pagination from DTO.
   * @param page DTO page.
   * @param options Options.
   * @param mapper Mapper for the items.
   */
  public mapPayrixPaginationFromDto<TDto, TDomain>(
    page: PayrixPaginationDto<TDto>,
    options: PaginationOptions,
    mapper: IMapperFromDto<TDto, TDomain> | MapperFunction<TDto, TDomain>,
  ): PayrixPagination<TDomain> {
    return new PayrixPagination({
      items: page.results.map(typeof mapper === 'function' ? mapper : mapper.fromDto.bind(mapper)),
      page: options.paginationData.page,
      size: options.paginationData.pageSize,
      totalCount: page.results.length,
      hasMore: page.has_more,
    });
  }
}

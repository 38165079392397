import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { AppError } from '../models/app-error';

/**
 * Get parent form array.
 * @param control Abstract control.
 */
export function getParentFormArray(control: AbstractControl): UntypedFormArray | null {
  if (control.parent && !(control.parent instanceof UntypedFormArray)) {
    const parent = getParentFormArray(control.parent);
    return parent;
  }
  return control.parent;
}

/**
 * Get form control name.
 * @param control Abstract control.
 */
export function getFormControlName(control: AbstractControl): string {
  const DEFAULT_CONTROL_NAME = '';

  if (control.parent && !(control.parent.controls instanceof Array)) {
    for (const formControlName in control.parent.controls) {
      if (control.parent.controls[formControlName] === control) {
        return formControlName;
      }
    }
  }
  return DEFAULT_CONTROL_NAME;
}

/**
 * Type-assertion for form array.
 * @param control Control.
 */
export function assertIsFormArray(control: AbstractControl): asserts control is UntypedFormArray {
  if (!(control instanceof UntypedFormArray)) {
    throw new AppError('Expected FormArray.');
  }
}

/**
 * Type-assertion for form Group.
 * @param control Control.
 */
export function assertIsFormGroup(control: AbstractControl | null): asserts control is UntypedFormGroup {
  if (!(control instanceof UntypedFormGroup)) {
    throw new AppError('Expected FormGroup');
  }
}

/**
 * Type-assertion for form control.
 * @param control Control.
 */
export function assertIsFormControl(control: AbstractControl): asserts control is UntypedFormControl {
  if (!(control instanceof UntypedFormControl)) {
    throw new AppError('Expected FormControl');
  }
}

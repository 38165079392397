import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { getImageSource } from '@protctc/common/core/utils/image-src';

/** Image uploader preview. */
@Component({
  selector: 'protctc-image-uploader-preview',
  templateUrl: './image-uploader-preview.component.html',
  styleUrls: ['./image-uploader-preview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploaderPreviewComponent {

  /** Source. */
  @Input()
  public src: File | string | null | undefined = null;

  public constructor(
    private readonly domSanitizer: DomSanitizer,
  ) {}

  /**
   * Returns avatar url.
   * @param imageElement Image element.
   * @returns Safe avatar url.
   */
  public getAvatarUrl(imageElement: HTMLImageElement): string | SafeUrl {
    if (this.src == null || this.src === '') {
      return '';
    }

    if (typeof this.src === 'string') {
      return this.src;
    }

    const imageSource = getImageSource(this.src);
    imageElement.onload = imageSource.teardown;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(imageSource.unsanitizedUri);
  }
}

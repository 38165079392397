import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RecurringSchedule, RecurringScheduleCreation } from '../models/recurring-schedule/recurring-schedule';
import { Pagination } from '../models/pagination';
import { RecurringSchedulePaginationOptions } from '../models/recurring-schedule/recurring-schedule-pagination.options';
import { RecurringScheduleSortField } from '../models/recurring-schedule/recurring-schedule-sort-field';

import { AppConfigService } from './app-config.service';
import { RecurringScheduleMapper } from './mappers/recurring-schedule/recurring-schedule.mapper';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { RecurringSchedulePaginationOptionsDto } from './mappers/dto/recurring-schedule/recurring-schedule-pagination-options.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { PaginationDto } from './mappers/dto/pagination-dto';
import { RecurringScheduleDto } from './mappers/dto/recurring-schedule/recurring-schedule.dto';
import { SortMapper } from './mappers/sort.mapper';

/** Mapper to map recurring schedule sort field from domain to local. */
export const RECURRING_SCHEDULE_FIELD_MAP: Readonly<Record<RecurringScheduleSortField, string>> = {
  [RecurringScheduleSortField.CreatedDate]: 'created',
  [RecurringScheduleSortField.NextGenerateDate]: 'next_generate_date',
  [RecurringScheduleSortField.RecurringSchedule]: 'recurring_schedule',
  [RecurringScheduleSortField.StartDate]: 'start_date',
  [RecurringScheduleSortField.ID]: 'id',
};

/** Recurring schedule service. */
@Injectable({
  providedIn: 'root',
})
export class RecurringScheduleService {

  private readonly httpClient = inject(HttpClient);

  private readonly appConfig = inject(AppConfigService);

  private readonly recurringScheduleUrl = new URL('invoices/recurring_schedule/', this.appConfig.apiUrl);

  private readonly recurringScheduleMapper = inject(RecurringScheduleMapper);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly paginationMapper = inject(PaginationMapper);

  private readonly sortMapper = inject(SortMapper);

  /**
   * Create recurring payment schedule.
   * @param data Recurring schedule creation data.
   */
  public createRecurringSchedule(data: RecurringScheduleCreation): Observable<void> {
    return this.httpClient.post<void>(
      this.recurringScheduleUrl.toString(),
      this.recurringScheduleMapper.toCreationDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.recurringScheduleMapper),
    );
  }

  /**
   * Get a page of the recurring schedules.
   * @param options Pagination options.
   */
  public getRecurringSchedules(options: RecurringSchedulePaginationOptions): Observable<Pagination<RecurringSchedule>> {
    const pagination: RecurringSchedulePaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      ordering: this.sortMapper.mapSortOptionsToDto(options.sortOptions, RECURRING_SCHEDULE_FIELD_MAP),
      company_id: options.companyId ? options.companyId.toString() : '',
    };

    const params = new HttpParams({
      fromObject: { ...pagination },
    });

    return this.httpClient.get<PaginationDto<RecurringScheduleDto>>(
      this.recurringScheduleUrl.toString(),
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.recurringScheduleMapper)),
    );
  }

  /** Get recurring schedules for current field user. */
  public getMyRecurringSchedules(): Observable<RecurringSchedule[]> {
    const params = new HttpParams({
      fromObject: {
        ordering: this.sortMapper.mapSortOptionsToDto({
          direction: 'desc',
          column: RecurringScheduleSortField.CreatedDate,
        }, RECURRING_SCHEDULE_FIELD_MAP),
      },
    });
    return this.httpClient.get<RecurringScheduleDto[]>(
      this.recurringScheduleUrl.toString(),
      { params },
    ).pipe(
      map(dtos => dtos.map(dto => this.recurringScheduleMapper.fromDto(dto))),
    );
  }

  /**
   * Stop recurring schedule.
   * @param id Recurring schedule ID.
   */
  public stopRecurringSchedule(id: RecurringSchedule['id']): Observable<void> {
    const url = new URL(`${id}/stop/`, this.recurringScheduleUrl);
    return this.httpClient.post<void>(url.toString(), {}).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}

import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

import { DisabledDirective } from './disabled.directive';

/**
 * Loading state directive. Applies loading state on element.
 */
@Directive({ selector: '[protctcLoading]' })
export class LoadingDirective {

  /** Disabled directive. */
  @HostBinding('attr.protctcDisabled')
  public disabledDirective = new DisabledDirective(this.elementRef);

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) { }

  /** Loading beacon. */
  @Input()
  public set protctcLoading(loading: boolean | null) {
    if (loading) {
      this.elementRef.nativeElement.classList.add('protctc-loading');
      this.disabledDirective.disable();
    } else {
      this.elementRef.nativeElement.classList.remove('protctc-loading');
      this.disabledDirective.enable();
    }
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { UserService } from '../services/user.service';

/** Guard prevents user from accessing if not logged in. */
@Injectable()
export class UnauthorizedGuard implements CanActivate, CanLoad {

  /** Whether the user is authorized. */
  private readonly isAuthorized$: Observable<boolean>;

  public constructor(
    userService: UserService,
    private readonly router: Router,
  ) {
    this.isAuthorized$ = userService.isAuthorized$;
  }

  /** @inheritdoc */
  public canLoad(): Observable<boolean | UrlTree> {
    return this.canNavigate();
  }

  /** @inheritdoc */
  public canActivate(): Observable<boolean | UrlTree> {
    return this.canNavigate();
  }

  /** Determine if /auth route can be activated. */
  private canNavigate(): Observable<boolean | UrlTree> {
    return this.isAuthorized$.pipe(
      map(isAuthorized => isAuthorized ? this.router.parseUrl('/') : true),
    );
  }
}

import { UntypedFormBuilder, FormGroupTyped } from '@angular/forms';

import { DateRange } from '../models/date-range/date-range';

/** Date range form data. */
export type DateRangeFormData = DateRange;

/**
 * Initialize date range form group.
 * @param fb Form builder.
 */
export function initDateRangeFormGroup(fb: UntypedFormBuilder): FormGroupTyped<DateRangeFormData> {
  return fb.groupTyped({
    start: [null],
    end: [null],
  });
}

import { Injectable } from '@angular/core';

import { CompanyLogo } from '../../models/company-logo';

import { CompanyLogoDto } from './dto/company-logo-dto';
import { IMapperFromDto } from './mappers';

/** Company logo mapper. */
@Injectable({
  providedIn: 'root',
})
export class CompanyLogoMapper implements IMapperFromDto<CompanyLogoDto, CompanyLogo> {

  /** @inheritdoc */
  public fromDto(data: CompanyLogoDto): CompanyLogo {
    return {
      id: data.id,
      url: data.logo,
    };
  }
}

/** Represents recurring schedule mode. */
export enum RecurringScheduleMode {

  /** Monthly. */
  Monthly = 'Monthly',

  /** Quarterly. */
  Quarterly = 'Quarterly',

  /** Yearly. */
  Yearly = 'Yearly',
}

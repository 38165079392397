import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  delay,
  first,
  forkJoin,
  map, mapTo,
  Observable,
  of,
  repeatWhen,
  ReplaySubject,
  share,
  Subject,
  switchMap,
} from 'rxjs';

import { tap } from 'rxjs/operators';
import {
  CalculatedInvoiceDto,
} from '@protctc/common/core/services/mappers/dto/invoice-calculation-dto';
import { InvoiceCalculationMapper } from '@protctc/common/core/services/mappers/invoice-calculation.mapper';

import { CalculatedShortInvoice } from '@protctc/common/core/models/calculated-short-invoice';
import { NotePickerType } from '@protctc/common/shared/components/file-picker/note-picker/note-picker.component';

import { FieldOption } from '../enums/field-option';
import { InvoiceShortSortField } from '../enums/invoice-short-sort-field';
import { CustomKeyValue } from '../models/custom-key-value';
import { Invoice, InvoiceCreationData } from '../models/invoice';
import { InvoiceAttachmentMultiple } from '../models/invoice-attachment';
import { InvoiceDetailOption } from '../models/invoice-detail-option';
import { InvoiceNote } from '../models/invoice-note';
import { InvoiceShort } from '../models/invoice-short';
import { InvoiceShortPaginationOptions } from '../models/invoice-short-pagination-options';
import { InvoiceTemplate } from '../models/invoice-template';
import { InvoiceTemplateSaveData } from '../models/invoice-template-save-data';
import { Pagination } from '../models/pagination';
import { filterNull } from '../rxjs/filter-null';
import { ExtraPagination } from '../models/extra-pagination';
import { FieldUserExtra } from '../models/field-user-extra';
import { Payment } from '../models/payment/payment';
import { InvoiceCalculation } from '../models/invoice-calculation';

import { AppConfigService } from './app-config.service';
import { DocumentsUploadService } from './documents-upload.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { CustomKeyValueMapper } from './mappers/custom-key-value.mapper';
import { CustomKeyValueDto } from './mappers/dto/custom-key-value-dto';
import { InvoiceDetailOptionDto } from './mappers/dto/invoice-detail-option-dto';
import { InvoiceCreationDataDto, InvoiceDto } from './mappers/dto/invoice-dto';
import { InvoiceShortDto } from './mappers/dto/invoice-short-dto';
import { InvoiceShortPaginationOptionsDto } from './mappers/dto/invoice-short-pagination-options-dto';
import { InvoiceTemplateDto } from './mappers/dto/invoice-template-dto';
import { InvoiceTemplateSaveDto } from './mappers/dto/invoice-template-save-dto';
import { PaginationDto } from './mappers/dto/pagination-dto';
import { InvoiceAttachmentMapper } from './mappers/invoice-attachment.mapper';
import { InvoiceDetailOptionMapper } from './mappers/invoice-detail-option.mapper';
import { InvoiceShortMapper } from './mappers/invoice-short.mapper';
import { InvoiceTemplateMapper } from './mappers/invoice-template.mapper';
import { InvoiceCreationDataMapper } from './mappers/invoice-creation-data.mapper';
import { PaginationMapper } from './mappers/pagination.mapper';
import { SortMapper } from './mappers/sort.mapper';
import { UserService } from './user.service';
import { InvoiceMapper } from './mappers/invoice.mapper';

import { FieldUserInvoicesPaginationOptionsDto } from './mappers/dto/field-user-invoices-pagination-options-dto';
import { ExtraPaginationDto } from './mappers/dto/extra-pagination-dto';
import { FieldUserExtraDto } from './mappers/dto/field-user-extra-dto';
import { FieldUserExtraMapper } from './mappers/field-user-extra.mapper';
import { ExtraPaginationMapper } from './mappers/extra-pagination.mapper';
import { CustomerPaymentMapper } from './mappers/customer-payment.mapper';
import { DownloadFileService } from './download-file.service';
import { DateMapper } from './mappers/date.mapper';

/** Mapper to map field option to URL for get it. */
const FIELD_URL_MAP: Readonly<Record<FieldOption, string>> = {
  [FieldOption.Header]: 'header_field_options/',
  [FieldOption.WorkDetails]: 'work_detail_field_options/',
  [FieldOption.Footer]: 'footer_field_options/',
  [FieldOption.LogoPosition]: 'logo_position_options/',
};

/** Mapper to map invoice short sort field from domain to local. */
export const INVOICE_SORT_FIELD_MAP: Readonly<Record<InvoiceShortSortField, string>> = {
  [InvoiceShortSortField.InvoiceNumber]: 'mask_number',
  [InvoiceShortSortField.CreatedDate]: 'created',
  [InvoiceShortSortField.DueDate]: 'due_date',
  [InvoiceShortSortField.CustomerName]: 'customer_full_name',
  [InvoiceShortSortField.Status]: 'payment_status',
  [InvoiceShortSortField.PaymentMethod]: 'payment_method',
  [InvoiceShortSortField.Amount]: 'total',
  [InvoiceShortSortField.FeeSaved]: 'fee_saved',
};

/**
 * Type guard for file. Cordova-plugin-file redefined class File(instanceof won't work).
 * @param file File.
 */
export function isFile(file: NotePickerType): file is File {
  return (file as File)?.size !== undefined && file?.name !== undefined && (file as File)?.type !== undefined;
}

/**
 * Invoice service.
 * Provides ability to work with invoice template entities.
 */
@Injectable({
  providedIn: 'root',
})
export class InvoiceService {

  /**
   * Invoices of current user.
   */
  public readonly myInvoice$: Observable<InvoiceShort[]>;

  /** Invoice url. */
  public readonly invoiceUrl = new URL('invoices/', this.appConfig.apiUrl).toString();

  /**
   * Latest saved invoice template.
   */
  public readonly latestSavedInvoiceTemplate$: Observable<InvoiceTemplate | null>;

  private readonly invoiceTemplateUrl: string;

  private readonly invoiceOptionDetailsUrl: string;

  private readonly invoicesUpdated$ = new Subject<void>();

  public constructor(
    private readonly appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly dateMapper: DateMapper,
    private readonly userService: UserService,
    private readonly invoiceTemplateMapper: InvoiceTemplateMapper,
    private readonly documentsUploadService: DocumentsUploadService,
    private readonly customKeyValueMapper: CustomKeyValueMapper,
    private readonly invoiceDetailOptionMapper: InvoiceDetailOptionMapper,
    private readonly invoiceShortMapper: InvoiceShortMapper,
    private readonly paginationMapper: PaginationMapper,
    private readonly extraPaginationMapper: ExtraPaginationMapper,
    private readonly sortMapper: SortMapper,
    private readonly invoiceCreationDataMapper: InvoiceCreationDataMapper,
    private readonly invoiceCalculationMapper: InvoiceCalculationMapper,
    private readonly invoiceAttachmentMapper: InvoiceAttachmentMapper,
    private readonly invoiceMapper: InvoiceMapper,
    private readonly fieldUserExtraMapper: FieldUserExtraMapper,
    private readonly customerPaymentMapper: CustomerPaymentMapper,
    private readonly downloadFileService: DownloadFileService,
  ) {
    this.invoiceTemplateUrl = new URL('invoice_template/', this.invoiceUrl).toString();
    this.invoiceOptionDetailsUrl = new URL('invoice_option_detail/', this.invoiceUrl).toString();
    this.myInvoice$ = this.getMyInvoices().pipe(
      repeatWhen(() => this.invoicesUpdated$),
    );
    this.latestSavedInvoiceTemplate$ = this.getLatestSavedInvoiceTemplate().pipe(
      share({ connector: () => new ReplaySubject(1), resetOnComplete: true }),
    );
  }

  /**
   * Download invoice as pdf.
   * @param invoiceId Invoice id.
   */
  public downloadInvoiceAsPdf(invoiceId: number): Observable<void> {
    const url = new URL(`pdf/${invoiceId}/`, this.invoiceUrl).toString();
    const fileName = `invoice_${invoiceId}`;
    return this.httpClient.get<Blob>(url, {
      responseType: 'blob' as 'json',
    }).pipe(
      tap(response => this.downloadFileService.download(response, fileName)),
      mapTo(void 0),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Refund selected invoice.
   * @param invoiceId Invoice id.
   */
  public refundInvoice(invoiceId: Invoice['id']): Observable<Invoice> {
    const url = new URL(`invoice/${invoiceId}/refund/`, this.invoiceUrl).toString();
    return this.httpClient.post<InvoiceDto>(url, {}).pipe(
      map(invoiceDto => this.invoiceMapper.fromDto(invoiceDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get payment invoice for customer.
   * @param accessKey Access key to get invoice information.
   */
  public getInvoiceForCustomerPayment(accessKey: string): Observable<Invoice> {
    const url = new URL(`invoice_pay/${accessKey}/`, this.invoiceUrl).toString();
    return this.httpClient.get<InvoiceDto>(url).pipe(
      map(invoiceDto => this.invoiceMapper.fromDto(invoiceDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Set credit card method for invoice payment.
   * @param accessKey Access key to get invoice information.
   */
  public setCreditCardMethodForInvoice(accessKey: string): Observable<Invoice> {
    const url = new URL(`invoice_pay/${accessKey}/set_credit_card/`, this.invoiceUrl).toString();
    return this.httpClient.post<InvoiceDto>(
      url,
      {},
    ).pipe(
      map(invoiceDto => this.invoiceMapper.fromDto(invoiceDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Set ACH method for invoice payment.
   * @param accessKey Access key to get invoice information.
   */
  public setACHMethodForInvoice(accessKey: string): Observable<Invoice> {
    const url = new URL(`invoice_pay/${accessKey}/set_ach/`, this.invoiceUrl).toString();
    return this.httpClient.post<InvoiceDto>(
      url,
      {},
    ).pipe(
      map(invoiceDto => this.invoiceMapper.fromDto(invoiceDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Confirm payment invoice for customer.
   * @param paymentResponse Payment response.
   * @param accessKey Access key.
   */
  public confirmCustomerInvoicePayment(
    paymentResponse: Payment, accessKey: string,
  ): Observable<Invoice> {
    const url = new URL(`invoice_pay/${accessKey}/pay/`, this.invoiceUrl).toString();
    return this.httpClient.post<InvoiceDto>(
      url,
      this.customerPaymentMapper.toDto(paymentResponse),
    ).pipe(
      map(invoiceDto => this.invoiceMapper.fromDto(invoiceDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get invoice by id.
   * @param invoiceId Invoice id.
   */
  public getInvoiceById(invoiceId: number): Observable<Invoice> {
    const url = new URL(`invoice/${invoiceId}/`, this.invoiceUrl).toString();
    return this.httpClient.get<InvoiceDto>(url).pipe(
      map(invoiceDto => this.invoiceMapper.fromDto(invoiceDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get field user's invoices.
   * @param options Pagination options.
   * @param fieldUserId Field user id.
   */
  public getFieldUserInvoices(
    options: InvoiceShortPaginationOptions,
    fieldUserId: number,
  ): Observable<ExtraPagination<InvoiceShort, FieldUserExtra>> {
    const pagination: FieldUserInvoicesPaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      ordering: this.sortMapper.mapSortOptionsToDto(options.sortOptions, INVOICE_SORT_FIELD_MAP),
      payment_method: options.invoiceShortFilterData.paymentMethod,
      status: options.invoiceShortFilterData.invoiceStatus,
      ...this.mapInvoiceDateFilterOptionsToDto(options),
      field_user: fieldUserId,
    };

    const params = new HttpParams({
      fromObject: { ...pagination },
    });

    const url = new URL('user_invoice/', this.invoiceUrl).toString();
    return this.httpClient.get<ExtraPaginationDto<InvoiceShortDto, FieldUserExtraDto>>(
      url,
      { params },
    ).pipe(
      map(page => this.extraPaginationMapper.mapExtraPaginationFromDto(
        page,
        options,
        this.invoiceShortMapper,
        this.fieldUserExtraMapper,
      )),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get a page of the invoices.
   * @param options Pagination options.
   */
  public getInvoices(options: InvoiceShortPaginationOptions): Observable<Pagination<InvoiceShort>> {
    const pagination: InvoiceShortPaginationOptionsDto = {
      ...this.paginationMapper.mapOptionsToDto(options),
      ordering: this.sortMapper.mapSortOptionsToDto(options.sortOptions, INVOICE_SORT_FIELD_MAP),
      search: options.searchString ?? '',
      payment_method: options.invoiceShortFilterData.paymentMethod,
      status: options.invoiceShortFilterData.invoiceStatus,
      ...this.mapInvoiceDateFilterOptionsToDto(options),
    };

    const params = new HttpParams({
      fromObject: { ...pagination },
    });

    const url = new URL('invoice/', this.invoiceUrl).toString();
    return this.httpClient.get<PaginationDto<InvoiceShortDto>>(
      url,
      { params },
    ).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.invoiceShortMapper)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get a my invoices.
   * TODO (Maslov D): Update method when
   * backend update method to get my invoice.
   */
  public getMyInvoices(): Observable<InvoiceShort[]> {
    const url = new URL('invoice/', this.invoiceUrl).toString();
    const params = new HttpParams({
      fromObject: {
        ordering: this.sortMapper.mapSortOptionsToDto({
          direction: 'desc',
          column: InvoiceShortSortField.CreatedDate,
        }, INVOICE_SORT_FIELD_MAP),
      },
    });
    return this.httpClient.get<InvoiceShortDto[]>(
      url,
      { params },
    ).pipe(
      map(invoices => invoices.map(a => this.invoiceShortMapper.fromDto(a))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );

  }

  /** Get latest saved invoice template for this company. */
  public getLatestSavedInvoiceTemplate(): Observable<InvoiceTemplate | null> {
    return this.httpClient.get<InvoiceTemplateDto[]>(
      this.invoiceTemplateUrl,
    ).pipe(
      map(data => {
        if (data.length > 0) {
          const latestSavedTemplateDto = data[data.length - 1];
          return this.invoiceTemplateMapper.fromDto(latestSavedTemplateDto);
        }
        return null;
      }),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /** Get invoice options details. */
  public getInvoiceOptionDetails(): Observable<InvoiceDetailOption[]> {
    return this.httpClient.get<InvoiceDetailOptionDto[]>(this.invoiceOptionDetailsUrl).pipe(
      map(options => options.map(option => this.invoiceDetailOptionMapper.fromDto(option))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Save invoice option detail.
   * @param option Option to save.
   * TODO (Sakhno V): Update method when
   * backend update feature will be implemented.
   */
  public saveInvoiceOptionDetail(option: InvoiceDetailOption): Observable<void> {
    if (option.id) {
      return this.updateInvoiceOptionDetail();
    }
    return this.createInvoiceOptionDetail(option);
  }

  /**
   * Delete invoice option detail.
   * @param optionId Option id to delete.
   */
  public deleteInvoiceOptionDetail(optionId: number): Observable<void> {
    const url = new URL(`${optionId}/`, this.invoiceOptionDetailsUrl).toString();
    return this.httpClient.delete<void>(url).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Save invoice.
   * @param invoice Invoice.
   */
  public saveInvoice(invoice: InvoiceCreationData): Observable<InvoiceCreationData> {
    if (invoice.id) {
      return this.updateInvoice(invoice, invoice.id);
    }
    return this.createInvoice(invoice);
  }

  /**
   * Delete invoice with provided id.
   * @param invoiceId Invoice id.
   */
  public deleteInvoice(invoiceId: number): Observable<void> {
    const url = new URL(`invoice/${invoiceId}/`, this.invoiceUrl).toString();
    return this.httpClient.delete<void>(url).pipe(
      tap(() => this.invoicesUpdated$.next()),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Save invoice attachments.
   * @param invoiceId Id of invoice to set attachments.
   * @param attachments Attachments.
   * @param mode Create or update mode.
   */
  public saveInvoiceAttachments(invoiceId: number, attachments: File[] | InvoiceNote[], mode: 'create' | 'update'): Observable<void> {
    return this.userService.currentUser$.pipe(
      map(user => user ? user.id : null),
      first(),
      filterNull(),
      switchMap(userId => this.prepareInvoiceNotes(attachments, userId)),
      map(notes => notes.map(note => ({
        name: note.name,
        id: note.id,
        file: note.fileUri,
        invoiceId,
      }))),
      map(notes => ({ attachments: notes })),
      switchMap(multipleAttachments => {
        if (mode === 'update') {
          return this.updateInvoiceAttachments(invoiceId, multipleAttachments);
        }
        return this.createInvoiceAttachments(invoiceId, multipleAttachments);
      }),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Save invoice template.
   * @param invoiceTemplate Invoice template save data.
   */
  public saveTemplate(invoiceTemplate: InvoiceTemplateSaveData): Observable<void> {
    return this.userService.currentUser$.pipe(
      map(user => user ? user.id : null),
      first(),
      filterNull(),
      switchMap(userId => forkJoin([
        this.prepareLogo(invoiceTemplate.logoImage, userId),
        this.prepareInvoiceNotes(invoiceTemplate.attachments, userId),
      ])),
      map(([logo, notes]) => ({ ...invoiceTemplate, logoImage: logo, attachments: notes })),
      switchMap(invoice => {
        if (invoice.id) {
          return this.updateInvoiceTemplate(this.invoiceTemplateMapper.toDto(invoice), invoice.id);
        }
        return this.createInvoiceTemplate(this.invoiceTemplateMapper.toDto(invoice));
      }),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.invoiceTemplateMapper),
    );
  }

  /**
   * Get available header field options.
   * @param fieldOption Field option.
   * @param column Column of current field.
   */
  public getFieldOptions(fieldOption: FieldOption, column?: number): Observable<CustomKeyValue<string, string>[]> {
    const url = new URL(FIELD_URL_MAP[fieldOption], this.invoiceTemplateUrl).toString();
    let params = new HttpParams();

    if (fieldOption === FieldOption.WorkDetails && column) {
      params = params.append('column', column);
    }

    return this.httpClient.get<CustomKeyValueDto<string, string>[]>(url, { params }).pipe(
      map(optionsDto => optionsDto.map(option => this.customKeyValueMapper.fromDto(option))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Sign invoice.
   * @param invoiceSignDataUrl Invoice sign data url.
   * TODO: (Maslov Daniil): Update after put endpoint implementation.
   */
  public signInvoice(invoiceSignDataUrl: string): Observable<void> {
    return of(invoiceSignDataUrl).pipe(delay(1000), mapTo(void 0));
  }

  /**
   * Calculate cost of invoice.Calculate in back-end.
   * @param invoice Invoice.
   */
  public calculateInvoice(invoice: InvoiceCalculation): Observable<CalculatedShortInvoice> {
    const url = new URL('invoice/calculate/', this.invoiceUrl).toString();
    return this.httpClient.post<CalculatedInvoiceDto>(
      url,
      this.invoiceCalculationMapper.toDto(invoice),
    ).pipe(
      map(data => this.invoiceCalculationMapper.fromDto(data, invoice.lineItems)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.invoiceCreationDataMapper),
    );
  }

  /**
   * Map date properties to query params.
   * @param options Invoice pagination options.
   */
  public mapInvoiceDateFilterOptionsToDto(options: InvoiceShortPaginationOptions): Record<string, string> {
    return {
      created_after: options.invoiceShortFilterData.invoiceStartDate ?
        this.dateMapper.toUTCDateStringDto(options.invoiceShortFilterData.invoiceStartDate) : '',
      created_before: options.invoiceShortFilterData.invoiceEndDate ?
        this.dateMapper.toUTCDateStringDto(options.invoiceShortFilterData.invoiceEndDate) : '',
      due_date_after: options.invoiceShortFilterData.invoiceDueStartDate ?
        this.dateMapper.toUTCDateStringDto(options.invoiceShortFilterData.invoiceDueStartDate) : '',
      due_date_before: options.invoiceShortFilterData.invoiceDueEndDate ?
        this.dateMapper.toUTCDateStringDto(options.invoiceShortFilterData.invoiceDueEndDate) : '',
    };
  }

  /**
   * Prepare logo file.
   * @param logo Logo file.
   * @param userId User id.
   */
  private prepareLogo(logo: string | File | null, userId: number): Observable<string | null> {
    if (logo instanceof File) {
      return this.documentsUploadService.upload(logo, userId);
    }
    return of(logo);
  }

  /**
   * Prepare invoice template notes.
   * @param notes Notes files.
   * @param userId User id.
   */
  private prepareInvoiceNotes(
    notes: InvoiceNote[] | File[], userId: number,
  ): Observable<InvoiceNote[]> {

    if (notes.length === 0) {
      return of(notes as InvoiceNote[]);
    }

    return forkJoin(notes.map(note => {
      if (isFile(note)) {
        return this.documentsUploadService.upload(note, userId).pipe(
          map(fileUri => ({
            name: note.name,
            fileUri,
          } as InvoiceNote)),
        );
      }
      return of(note);
    }));
  }

  /**
   * Update invoice attachments.
   * @param invoiceId Invoice id.
   * @param attachments Attachments.
   */
  private updateInvoiceAttachments(invoiceId: number, attachments: InvoiceAttachmentMultiple): Observable<void> {
    const url = new URL(`invoice/${invoiceId}/attachments/`, this.invoiceUrl).toString();
    return this.httpClient.put<void>(
      url,
      { attachments: attachments.attachments.map(this.invoiceAttachmentMapper.toDto) },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create invoice attachments.
   * @param invoiceId Invoice id.
   * @param attachments Attachments.
   */
  private createInvoiceAttachments(invoiceId: number, attachments: InvoiceAttachmentMultiple): Observable<void> {
    const url = new URL(`invoice/${invoiceId}/attachments/`, this.invoiceUrl).toString();
    return this.httpClient.post<void>(
      url,
      { attachments: attachments.attachments.map(this.invoiceAttachmentMapper.toDto) },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create invoice template.
   * @param invoiceTemplateDto Invoice template dto.
   */
  private createInvoiceTemplate(invoiceTemplateDto: InvoiceTemplateSaveDto): Observable<void> {
    return this.httpClient.post<void>(
      this.invoiceTemplateUrl,
      invoiceTemplateDto,
    );
  }

  /**
   * Update invoice template.
   * @param invoiceTemplateDto Invoice template dto.
   * @param invoiceTemplateId Invoice template id.
   */
  private updateInvoiceTemplate(invoiceTemplateDto: InvoiceTemplateSaveDto, invoiceTemplateId: number): Observable<void> {
    const updateInvoiceTemplateUrl = new URL(`${invoiceTemplateId}/`, this.invoiceTemplateUrl).toString();
    return this.httpClient.put<void>(
      updateInvoiceTemplateUrl,
      invoiceTemplateDto,
    );
  }

  /**
   * Create invoice option detail.
   * @param option Invoice Detail Option.
   */
  private createInvoiceOptionDetail(option: InvoiceDetailOption): Observable<void> {
    return this.httpClient.post<void>(
      this.invoiceOptionDetailsUrl,
      this.invoiceDetailOptionMapper.toDto(option),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update invoice option detail.
   * TODO: (Sakhno V): Update after put endpoint implementation.
   */
  private updateInvoiceOptionDetail(): Observable<void> {
    return of(void 0);
  }

  /**
   * Update invoice.
   * @param invoice Invoice.
   * @param invoiceId Invoice id.
   */
  private updateInvoice(invoice: InvoiceCreationData, invoiceId: number): Observable<InvoiceCreationData> {
    const url = new URL(`invoice/${invoiceId}/`, this.invoiceUrl).toString();
    return this.httpClient.put<InvoiceCreationDataDto>(
      url,
      this.invoiceCreationDataMapper.toDto(invoice),
    ).pipe(
      map(data => this.invoiceCreationDataMapper.fromDto(data)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.invoiceCreationDataMapper),
      tap(() => this.invoicesUpdated$.next()),
    );
  }

  /**
   * Create invoice.
   * @param invoice Invoice.
   */
  private createInvoice(invoice: InvoiceCreationData): Observable<InvoiceCreationData> {
    const url = new URL('invoice/', this.invoiceUrl).toString();
    return this.httpClient.post<InvoiceCreationDataDto>(
      url,
      this.invoiceCreationDataMapper.toDto(invoice),
    ).pipe(
      map(data => this.invoiceCreationDataMapper.fromDto(data)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.invoiceCreationDataMapper),
      tap(() => this.invoicesUpdated$.next()),
    );
  }
}

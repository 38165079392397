import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InvoiceCustomerCreationData } from '@protctc/common/core/models/customer';
import { InvoiceBusinessInformation } from '@protctc/common/core/models/invoice';
import { InvoiceNote } from '@protctc/common/core/models/invoice-note';
import { isFile } from '@protctc/common/core/services/invoice.service';
import { masks } from '@protctc/common/core/utils/masks';
import { IMaskPipe } from 'angular-imask';

import { InvoiceAttachmentView } from './invoice-preview-models/invoice-attachment-view';

/** Header company name key. */
export const HEADER_COMPANY_NAME_KEY = 'company name';

/** Invoice preview service. */
@Injectable({
  providedIn: 'root',
})
export class InvoicePreviewService {

  /**
   * Map notes to invoice attachments view.
   * @param notes Invoice note or file.
   * @param domSanitizer Dom sanitizer.
   */
  public mapNotesToInvoiceAttachmentsView(
    notes: InvoiceNote[] | File[], domSanitizer: DomSanitizer,
  ): InvoiceAttachmentView[] {
    return notes.map(note => {
    if (isFile(note)) {
      return {
        name: note.name,
        file: note,
        url: domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(note)),
      };
    }
    return {
      name: note.name,
      file: null,
      url: note.fileUri,
    };
  });
  }

  /**
   * Map header columns to customer information.
   * @param headerColumn Header column.
   * @param customer Customer.
   * @param imaskPipe Angular mask pipe.
   */
  public mapHeaderColumnsToCustomerInformation(
    headerColumn: string,
    customer: InvoiceCustomerCreationData,
    imaskPipe: IMaskPipe,
  ): string {
    const MAP_HEADER_COLUMN_TO_CUSTOMER_INFO: Readonly<Record<string, string>> = {
      [HEADER_COMPANY_NAME_KEY]: customer.companyName,
      phone: imaskPipe.transform(customer.phone, masks.phoneNumber),
      address: `${customer.address.line1Address} ${customer.address.city} ${customer.address.state} ${customer.address.zip}`,
      name: `${customer.firstName} ${customer.lastName}`,
    };

    return MAP_HEADER_COLUMN_TO_CUSTOMER_INFO[headerColumn];
  }

  /**
   * Map footers columns to customer information.
   * @param footerColumn Footer column.
   * @param businessInfo Business info.
   * @param imaskPipe Angular mask pipe.
   */
  public mapFooterColumnsToBusinessInformation(
    footerColumn: string,
    businessInfo: InvoiceBusinessInformation,
    imaskPipe: IMaskPipe,
  ): string {
    const MAP_FOOTER_COLUMN_TO_CUSTOMER_INFO: Readonly<Record<string, string>> = {
      phone: imaskPipe.transform(businessInfo.phone, masks.phoneNumber),
      address: `${businessInfo.address1}`,
      name: `${businessInfo.name}`,
      email: `${businessInfo.email}`,
    };

    return MAP_FOOTER_COLUMN_TO_CUSTOMER_INFO[footerColumn];
  }
}

import { Component, ChangeDetectionStrategy, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Validators, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { AutocompleteConfiguration } from '@protctc/common/core/models/autocomplete-configuration';
import { LineItem } from '@protctc/common/core/models/line-item';
import { filterNull } from '@protctc/common/core/rxjs/filter-null';
import { listenControlChanges } from '@protctc/common/core/rxjs/listen-control-changes';
import { toggleExecutionState } from '@protctc/common/core/rxjs/toggle-execution-state';
import { LineItemService } from '@protctc/common/core/services/line-item.service';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { masks } from '@protctc/common/core/utils/masks';
import { AppValidators } from '@protctc/common/core/utils/validators';
import { BehaviorSubject, Observable, filter, ignoreElements, merge, tap } from 'rxjs';
import { ProtctPlatformService } from '@protctc/common/core/services/protct-platform.service';

import { CustomKeyValue } from '@protctc/common/core/models/custom-key-value';

import { LineItemSaveForm } from './line-item-save-form-data';

export const MAX_QUANTITY = 6;
const MAX_LINE_ITEM_NAME_LENGTH = 50;

/** Line item save form. */
@DestroyableComponent()
@Component({
  selector: 'protctc-line-item-form',
  templateUrl: './line-item-form.component.html',
  styleUrls: ['./line-item-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineItemFormComponent implements OnInit {

  /** Save line item form. */
  @Input()
  public lineItemForm!: FormGroup<LineItemSaveForm>;

  /** Has quantity property. */
  @Input()
  public hasQuantity = false;

  /** Max quantity of line items. */
  @Input()
  public maxQuantity = MAX_QUANTITY;

  /** Tax types. */
  protected taxTypes$: Observable<CustomKeyValue<string, string>[]>;

  /** Max line item length name. */
  protected readonly maxLineItemLengthName = MAX_LINE_ITEM_NAME_LENGTH;

  /** Configuration for line item autocomplete. */
  public readonly lineItemAutoCompleteConfiguration: AutocompleteConfiguration<LineItem>;

  /** Filtered line item control. */
  public readonly lineItemControl = this.formBuilder.control<LineItem | null | string>(null, [
    Validators.required,
    Validators.maxLength(this.maxLineItemLengthName),
    AppValidators.noWhiteSpacesOnly,
  ]);

  /** Line item is searching. */
  public readonly isSearchingLineItem$ = new BehaviorSubject(false);

  /** Is mobile platform. */
  public readonly isMobile$ = this.platformService.isMobile$;

  /** Masks. */
  public readonly masks = masks;

  /** Quantity mask. */
  public readonly quantityMask = {
    mask: Number,
    scale: 0,
    min: 1,
  };

  public constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly lineItemService: LineItemService,
    private readonly platformService: ProtctPlatformService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.lineItemAutoCompleteConfiguration = {
      comparator: LineItem.compare,
      fetch: options => this.lineItemService.getLineItems({
        ...options,
      }).pipe(toggleExecutionState(this.isSearchingLineItem$)),
      toReadable(lineItem: LineItem | string) {
        if (typeof lineItem === 'string') {
          return lineItem;
        }
        return LineItem.toReadable(lineItem);
      },
      trackBy: LineItem.trackBy,
    };

    this.taxTypes$ = this.lineItemService.getTaxTypes();
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    assertNonNull(this.lineItemForm);

    if (!this.hasQuantity) {
      this.lineItemForm.controls.quantity.disable();
    }

    /** When errors come from the backend, there is no rendering. */
    const runDetectChangesSideEffect$ = this.lineItemForm.statusChanges.pipe(
      tap(() => this.cdr.markForCheck()),
    );

    const fillLineItemFormSideEffect$ = listenControlChanges<LineItem | null | string>(
      this.lineItemControl, { debounceTime: 0 },
    ).pipe(
      filter(lineItem => lineItem !== null),
      filterNull(),
      tap(lineItem => {
        this.fillLineItemFormGroup(lineItem);
        this.lineItemForm.controls.name.markAsTouched();
      }),
    );

    const changeLineItemControlSideEffect$ = listenControlChanges<string>(
      this.lineItemForm.controls.name, { debounceTime: 0 },
    ).pipe(
      tap(value => {
        if (value) {
          this.lineItemControl.setValue(value);
          this.lineItemControl.markAsTouched();
          return;
        }
        this.lineItemControl.reset();
      }),
    );

    const setErrorsFromOneNameControlToAnotherSideEffect$ = this.lineItemForm.controls.name.statusChanges.pipe(
      tap(() => this.setErrorsFromFormNameControlToAutocompleteNameControl()),
    );

    const nameStatusChangedSideEffect$ = this.lineItemForm.controls.name.statusChanges.pipe(
      filter(status => status === 'INVALID'),
      tap(() => this.lineItemControl.markAsTouched()),
    );

    merge(
      fillLineItemFormSideEffect$,
      changeLineItemControlSideEffect$,
      runDetectChangesSideEffect$,
      setErrorsFromOneNameControlToAnotherSideEffect$,
      nameStatusChangedSideEffect$,
    ).pipe(
      ignoreElements(),
      takeUntilDestroy(this),
    )
      .subscribe();

  }

  private fillLineItemFormGroup(lineItem: LineItem | string): void {
    assertNonNull(this.lineItemForm);

    if (typeof lineItem === 'string') {
      this.lineItemForm.patchValue({
        name: lineItem,
      });

      return;
    }

    this.lineItemForm.patchValue({
      lineItem: lineItem.id,
      name: lineItem.name,
      price: lineItem.price.toString(),
      description: lineItem.description,
      quantity: '1',
      taxType: lineItem.taxType,
    });
  }

  private setErrorsFromFormNameControlToAutocompleteNameControl(): void {
    this.lineItemControl.setErrors(this.lineItemForm.controls.name.errors);
  }
}

<ng-container *ngIf="merchantsPage">
  <protctw-empty-table-preview
    *ngIf="merchantsPage.hasItems === false"
    [title]="emptyPreviewMessage"
  ></protctw-empty-table-preview>
  <div class="table" *ngIf="merchantsPage.hasItems">
    <mat-table
      matSort
      (matSortChange)="onSortChange($event)"
      [matSortDirection]="activeSortDirection"
      [matSortActive]="activeSortColumn"
      [dataSource]="merchantsPage.items"
      [trackBy]="trackMerchant"
    >
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
          >ID</mat-header-cell
        >
        <mat-cell *matCellDef="let data">
          {{ data.id | leadingZeros }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
          >Business name</mat-header-cell
        >
        <mat-cell *matCellDef="let data">
          <a
            [routerLink]="
              data.deactivatedAt
                ? [
                    '/admin',
                    'merchant-partners',
                    'inactive',
                    'details',
                    data.id
                  ]
                : ['/admin', 'merchant-partners', 'active', 'details', data.id]
            "
          >
            {{ data.name }}
          </a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ein">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Business EIN
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          {{ data.ein | imask : einMask }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
          >Business email</mat-header-cell
        >
        <mat-cell *matCellDef="let data">
          <a class="link" href="mailto:{{ data.email }}">{{ data.email }}</a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="fee">
        <mat-header-cell *matHeaderCellDef>Monthly fee</mat-header-cell>
        <mat-cell *matCellDef="let data">
          <mat-checkbox
            color="primary"
            class="fee"
            [checked]="data.isMonthlyFeeEnable"
            [disabled]="!data.hasPaymentInfo"
            (click)="onFeeClick($event, data)"
            matTooltip = "{{!data.hasPaymentInfo ? tooltipText : ''}}"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="invoiceCount">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
          ># of invoices</mat-header-cell
        >
        <mat-cell *matCellDef="let data">
          <ng-container *ngIf="data.invoiceCount; else empty">
            <span class="invoice-count">
              {{ data.invoiceCount }}
              <mat-icon
                *ngIf="data.hasOverdue"
                class="unpaid-invoice"
                svgIcon="border"
              ></mat-icon>
            </span>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lastActivity">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          <span
            *ngIf="
              isAdminInvoicesForDashboard;
              else basicLastActivityColumnTitle
            "
          >
            Last invoice
          </span>

          <ng-template #basicLastActivityColumnTitle>
            <span>Last activity</span>
          </ng-template>
        </mat-header-cell>
        <mat-cell *matCellDef="let data">
          <ng-container *ngIf="data.lastActivity; else empty">
          <time [dateTime]="data.lastActivity">
            {{ data.lastActivity | date : "MM/dd/yyyy, h:mm a" }}&nbsp;
          </time>
        </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="deactivatedAt">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear
          >Deactivated on</mat-header-cell
        >
        <mat-cell *matCellDef="let data">
          <ng-container *ngIf="data.deactivatedAt; else empty">
            <time [dateTime]="data.deactivatedAt">
              {{ data.deactivatedAt | date : "MM/dd/yyyy, h:mm a" }}&nbsp;
            </time>
            <span *ngIf="data.deactivatedBy">by {{ data.deactivatedBy }}</span>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let data">
          <div class="action-wrapper">
            <ng-container
              *ngTemplateOutlet="
                actionColumn;
                context: getMerchantPartnerViewContext(data)
              "
            ></ng-container>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator
      *ngIf="merchantsPage"
      (page)="onPageChange($event)"
      [pageIndex]="merchantsPage.page"
      [length]="merchantsPage.totalCount"
      [pageSize]="merchantsPage.size"
      [pageSizeOptions]="[10, 20, 30]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</ng-container>

<ng-template #empty>
  <span>&mdash;</span>
</ng-template>

import { Component, ChangeDetectionStrategy, OnInit, EventEmitter } from '@angular/core';
import { IDialog, IDialogOptions } from '@protctc/common/shared/dialogs/dialog';

/** Change invoice status dialog. */
@Component({
  selector: 'protctc-change-invoice-status-dialog',
  templateUrl: './change-invoice-status-dialog.component.html',
  styleUrls: ['./change-invoice-status-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeInvoiceStatusDialogComponent implements IDialog<void, boolean>, OnInit {

  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritdoc */
  public readonly closed = new EventEmitter<boolean>();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.options = {
      closable: true,
      closeButton: true,
    };
  }
}

<protctc-autocomplete
  [hasPagination]="true"
  class="autocomplete"
  [formControl]="unionLineItemControl"
  [configuration]="unionLineItemAutoCompleteConfiguration"
  [isLoading]="isSearchingUnionLineItem$ | async"
  [template]="autocompleteOptionTemplate"
  [placeholder]="placeholder"
  icon="search"
></protctc-autocomplete>

<ng-template #autocompleteOptionTemplate let-context>
  <div class="option">
    <mat-icon
      *ngIf="context.option.type === lineItemType.LineItem"
      svgIcon="file_single"
    ></mat-icon>
    <mat-icon
      class="option__icon_multiple"
      *ngIf="context.option.type === lineItemType.GroupLineItem"
      svgIcon="files_multiple"
    ></mat-icon>
    <div class="option__content">
      <p class="option__title">{{ context.optionName }}</p>
      <p class="option__subtitle">
        {{ toReadableOptionSubtitle(context.option) }}
      </p>
    </div>
  </div>
</ng-template>

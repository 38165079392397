import { Directive, ElementRef, Input } from '@angular/core';

/**
 * Applies disabled state on element.
 */
@Directive({ selector: '[protctcDisabled]' })
export class DisabledDirective {

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) { }

  /** Loading beacon. */
  @Input()
  public set protctcDisabled(disabled: boolean | null) {
    if (disabled) {
      this.disable();
    } else {
      this.enable();
    }
  }

  /** Disable element. */
  public disable(): void {
    this.elementRef.nativeElement.setAttribute('disabled', 'true');
  }

  /** Enable element. */
  public enable(): void {
    this.elementRef.nativeElement.removeAttribute('disabled');
  }
}

/**
 * Describe US State.
 * @deprecated
 */
export interface State {

  /** Code. */
  readonly code: string;

  /** Name. */
  readonly name: string;
}

/**
 * Namespace for state entities.
 * Contains util methods and props for this entity.
 */
export namespace State {

  /**
   * Compare states by code.
   * @param firstState First state.
   * @param secondState Second state.
   */
  export function compare(firstState: State, secondState: State): boolean {
    if (firstState && secondState) {
      return firstState.code === secondState.code;
    }
    return false;
  }

  /**
   * TrackBy function for track state.
   * @param _ Index.
   * @param item Item to track.
   */
  export function trackState(_: number, item: State): string {
    return item.code;
  }
}

import { InvoiceDelivery } from '../enums/invoice-delivery';
import { InvoiceStatus } from '../enums/invoice-status';
import { PaymentMethod } from '../enums/payment-method';

import { InvoiceCustomer, InvoiceCustomerCreationData } from './customer';
import { DeliveryPeriod } from './delivery-period';
import { InvoiceAttachment } from './invoice-attachment';
import { InvoiceLineItem, InvoiceLineItemCreationData } from './invoice-line-item';
import { InvoiceTemplate } from './invoice-template';
import { PaymentCard } from './payment/payment-card';

/** Invoice business information. */
export interface InvoiceBusinessInformation {

  /** Id. */
  readonly id: number;

  /** Name. */
  readonly name: string;

  /** EIN. */
  readonly ein: string;

  /** Company's email. */
  readonly email: string;

  /** Company's web site. */
  readonly url?: string;

  /** Company's phone. */
  readonly phone: string;

  /** First line of the address. */
  readonly address1: string;
}

export type InvoiceCreatedData = InvoiceCreationData & { id: Invoice['id']; };

export type InvoiceCreationData = Omit<Invoice,
  | 'invoiceTemplate'
  | 'attachmentSet'
  | 'tax'
  | 'subtotal'
  | 'total'
  | 'fee'
  | 'businessInformation'
  | 'createdDate'
  | 'modifiedDate'
  | 'createdBy'
  | 'updatedBy'
  | 'cardinal'
  | 'isEditableInvoice'
  | 'cardinal'
  | 'transactionId'
  | 'savedFee'
  | 'transactionCardNumber'
  | 'transactionCardExpiration'
  | 'customer'
  | 'lineItems'
  | 'id'
  | 'isInvoiceWithoutTax'
  | 'ccFeeRate'
  | 'cashPrice'
> & {

  /** Invoice id. */
  readonly id?: number;

  /** Payment token. */
  readonly paymentToken?: string;

  /** Payment details. */
  readonly paymentDetails?: PaymentCard;

  /** Customer only for creation invoice. */
  readonly customer: InvoiceCustomerCreationData;

  /** Customer ID. */
  readonly customerId: number | null;

  /** Invoice line items only for creation. */
  readonly lineItems: readonly InvoiceLineItemCreationData[];
};

export type InvoiceConstructorData = Omit<Invoice, 'isEditableInvoice' | 'isInvoiceWithoutTax'>;

/** Invoice model. */
export class Invoice {

  /** Id. */
  public readonly id: number;

  /** Field user id. */
  public readonly fieldUser: number | null;

  /** Invoice template. */
  public readonly invoiceTemplate: InvoiceTemplate;

  /** Customer. */
  public readonly customer: InvoiceCustomer;

  /** Line items. */
  public readonly lineItems: readonly InvoiceLineItem[];

  /** Attachments. */
  public readonly attachmentSet: readonly InvoiceAttachment[];

  /** Disclaimer that not visible to customer. */
  public readonly disclaimer: string;

  /** Note attached by the field worker. */
  public readonly note: string;

  /** Tax. */
  public readonly tax: number;

  /** Subtotal. */
  public readonly subtotal: number;

  /** Total. */
  public readonly total: number;

  /** Fee. */
  public readonly fee: number;

  /** Status. */
  public readonly status: InvoiceStatus;

  /** Payment method. */
  public readonly paymentMethod: PaymentMethod;

  /** Due date. */
  public readonly dueDate?: Date;

  /** Business information. */
  public readonly businessInformation: InvoiceBusinessInformation;

  /** Created date. */
  public readonly createdDate: Date;

  /** Modified date. */
  public readonly modifiedDate: Date;

  /** Created by User name. */
  public readonly createdBy: string;

  /** Updated by User name. */
  public readonly updatedBy: string;

  /** Cardinal number of this invoice in the company. */
  public readonly cardinal: number;

  /** Transaction id. */
  public readonly transactionId: string;

  /** Transaction card number. */
  public readonly transactionCardNumber: string;

  /** Transaction card expiration. */
  public readonly transactionCardExpiration: string;

  /** Customer signature.Only for field user. Format base64url. */
  public readonly customerSignature?: string;

  /** CC Fee Rate. */
  public readonly ccFeeRate: number;

  /** Cash price. */
  public readonly cashPrice: number;

  /** Invoice delivery method. */
  public readonly deliveryMethod: InvoiceDelivery | null;

  /** Delivery period. */
  public readonly deliveryPeriod: DeliveryPeriod | null;

  /** First additional field value. */
  public readonly firstAdditionalFieldValue: string;

  /** Second additional field value. */
  public readonly secondAdditionalFieldValue: string ;

  /** Third additional field value. */
  public readonly thirdAdditionalFieldValue: string;

  /** Invoice prefix. */
  public readonly invoicePrefix: string;

  /** Invoice number. */
  public readonly invoiceNumber: number;

  public constructor(data: InvoiceConstructorData) {
    this.id = data.id;
    this.customerSignature = data.customerSignature;
    this.invoiceTemplate = data.invoiceTemplate;
    this.customer = data.customer;
    this.lineItems = data.lineItems;
    this.attachmentSet = data.attachmentSet;
    this.disclaimer = data.disclaimer;
    this.note = data.note;
    this.tax = data.tax;
    this.subtotal = data.subtotal;
    this.total = data.total;
    this.fee = data.fee;
    this.status = data.status;
    this.paymentMethod = data.paymentMethod;
    this.dueDate = data.dueDate;
    this.businessInformation = data.businessInformation;
    this.createdDate = data.createdDate;
    this.fieldUser = data.fieldUser;
    this.modifiedDate = data.modifiedDate;
    this.createdBy = data.createdBy;
    this.updatedBy = data.updatedBy;
    this.cardinal = data.cardinal;
    this.transactionId = data.transactionId;
    this.transactionCardNumber = data.transactionCardNumber;
    this.transactionCardExpiration = data.transactionCardExpiration;
    this.ccFeeRate = data.ccFeeRate;
    this.cashPrice = data.cashPrice;
    this.deliveryMethod = data.deliveryMethod;
    this.deliveryPeriod = data.deliveryPeriod;
    this.firstAdditionalFieldValue = data.firstAdditionalFieldValue;
    this.secondAdditionalFieldValue = data.secondAdditionalFieldValue;
    this.thirdAdditionalFieldValue = data.thirdAdditionalFieldValue;
    this.invoicePrefix = data.invoicePrefix;
    this.invoiceNumber = data.invoiceNumber;
  }

  /** This invoice is editable. */
  public get isEditableInvoice(): boolean {
    return InvoiceStatus.getStatusesEditableInvoice().includes(this.status);
  }

  /** Is invoice without tax. */
  public get isInvoiceWithoutTax(): boolean {
    return this.paymentMethod === PaymentMethod.Cash && this.status === InvoiceStatus.Paid;
  }
}
